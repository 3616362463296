import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";
import { Root, Icon } from "./OrderStatusBar.style";

import { Typography } from "../../ui/Typography/Typography";
import { statusMap } from "../../components/Dashboard/config";

export const OrderStatusBar = ({ status, date, notes }) => {
  const intl = useIntl();
  const statusConfig = statusMap(intl)[status];

  return (
    <Root className={`--${status}`}>
      <Icon>{statusConfig.icon}</Icon>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            type="h3"
            color={status === "delivered" ? "#000" : "#7f8696"}
          >
            {statusConfig.title}
          </Typography>
          <Typography
            type="text-small"
            color="#8f95a5"
            style={{ marginTop: 2, marginLeft: 16 }}
          >
            {DateTime.fromMillis(date).toLocaleString(DateTime.DATE_SHORT)}{" "}
            &nbsp;•&nbsp;{" "}
            {DateTime.fromMillis(date).toLocaleString(DateTime.TIME_SIMPLE)}
          </Typography>
        </div>
        {(notes || statusConfig.defaultMessage) && (
          <Typography type="text-small" color="#8f95a5">
            {notes ?? statusConfig.defaultMessage}
          </Typography>
        )}
      </div>
    </Root>
  );
};

OrderStatusBar.propTypes = {
  status: PropTypes.oneOf([
    "delivered",
    "shipped",
    "processing",
    "pending",
    "bonusPoints",
    "reinstatementPoints",
    "canceled",
  ]).isRequired,
  date: PropTypes.number.isRequired,
  notes: PropTypes.string,
};

OrderStatusBar.defaultProps = {
  notes: null,
};
