import React from "react";

import { Root, Tags, Tag } from "./ContentDetailTags.style";

import { Typography } from "../../ui/Typography/Typography";
import { Link } from "../Link/Link";

export const ContentDetailTags = ({ label = "Tags", tags = [], ...props }) => {
  if (tags.length === 0) {
    return null;
  }

  return (
    <Root {...props}>
      <Typography type="text-secondary">
        <b>{label}:</b>
      </Typography>
      <Tags>
        {tags.map((tag) => (
          <Tag key={tag.label} as={Link} to={tag.url}>
            <Typography
              type="text-secondary"
              style={{ textDecoration: "underline" }}
            >
              {tag.label}
            </Typography>
          </Tag>
        ))}
      </Tags>
    </Root>
  );
};
