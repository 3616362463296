import styled from "styled-components";

export const Root = styled.div`
  flex-shrink: 0;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    align-items: stretch;
  }
`;

export const Header = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    position: relative;
    height: 72px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    top: 39px;
    left: 0;
    height: 80px;
  }
`;

export const ImageContainer = styled.div`
  flex-shrink: 0;
  display: flex;

  @media (max-width: 1023px) {
    width: 100%;
    height: 200px;
    margin-bottom: 48px;
  }

  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Logo = styled.img`
  height: auto;

  @media (max-width: 1023px) {
    width: 104px;
  }

  @media (min-width: 1024px) {
    width: 140px;
  }
`;

export const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 32px;
  width: 32px;
  height: 32px;
  padding: 0;
  transform: translateY(-50%);

  @media (max-width: 1023px) {
    font-size: 22px;
  }

  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 50%;
    padding-top: 80px;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1024px) {
    overflow-y: auto;
  }
`;

export const OutletContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 336px;

  @media (min-width: 1024px) {
    align-items: center;
  }
`;

export const Nav = styled.ul`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  list-style: none;

  @media (max-width: 1023px) {
    height: 78px;
  }

  @media (min-width: 1024px) {
    height: 60px;
  }
`;

export const NavItem = styled.li`
  padding: 0 8px;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
`;
