import { gql } from "@apollo/client";

export const GET_BRAND = gql`
  query GetBrand($id: ID!) {
    brand {
      findById(id: $id) {
        e
        _id
        n
        desc
        image {
          media {
            _id
          }
        }
        imageMobile {
          media {
            _id
          }
        }
        links {
          website
          youtube
          instagram
          facebook
          tiktok
        }
        block_1 {
          title
          desc
          ctaLabel
          ctaUrl
          image {
            media {
              _id
            }
          }
          imageMobile {
            media {
              _id
            }
          }
        }
        block_2 {
          title
          desc
          ctaLabel
          ctaUrl
          image {
            media {
              _id
            }
          }
          imageMobile {
            media {
              _id
            }
          }
        }
      }
    }
  }
`;
