import React from "react";
import { FormattedMessage } from "react-intl";

import { Root, NoResultsText, ClearAll } from "./NoResultsContainer.style";

import NoSearchResultsIcon from "../../assets/images/icons/no-search-results.svg?react";

export const NoResultsContainer = ({ onResetFilters = null }) => {
  return (
    <Root>
      <NoSearchResultsIcon width="64" height="64" />
      <NoResultsText>
        <FormattedMessage defaultMessage="No results were found" />
      </NoResultsText>
      {typeof onResetFilters === "function" && (
        <ClearAll type="button" onClick={onResetFilters}>
          <FormattedMessage defaultMessage="Clear All Filters" />
        </ClearAll>
      )}
    </Root>
  );
};
