import React from "react";

import {
  Root,
  Container,
  Append,
  List,
  PageAction,
  ArrowAction,
} from "./Pagination.style";

import { Icon } from "../../ui/Icon/Icon";
import { usePagination, DOTS } from "../../hooks/usePagination";

export const Pagination = ({
  onPaginate,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  append = null,
  ...props
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const noPagination =
    totalCount < pageSize ||
    !Array.isArray(paginationRange) ||
    paginationRange.length < 1;

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPaginate(currentPage + 1);
  };

  const onPrevious = () => {
    onPaginate(currentPage - 1);
  };

  return (
    <Root {...props}>
      <Container>
        {append && <Append>{append}</Append>}

        {noPagination ? (
          <List as="span" />
        ) : (
          <List>
            <li>
              <ArrowAction
                type="button"
                disabled={currentPage === 1}
                onClick={onPrevious}
              >
                <Icon icon="arrow-left-long" />
              </ArrowAction>
            </li>

            {paginationRange.map((pageNumber, idx) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={idx}>
                    <PageAction as="div">&#8230;</PageAction>
                  </li>
                );
              }

              return (
                <li key={idx}>
                  <PageAction
                    type="button"
                    $isActive={pageNumber === currentPage}
                    disabled={pageNumber === currentPage}
                    onClick={() => onPaginate(pageNumber)}
                  >
                    {pageNumber}
                  </PageAction>
                </li>
              );
            })}

            <li>
              <ArrowAction
                type="button"
                disabled={currentPage === lastPage}
                onClick={onNext}
              >
                <Icon icon="arrow-right-long" />
              </ArrowAction>
            </li>
          </List>
        )}
      </Container>
    </Root>
  );
};
