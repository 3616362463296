import React from "react";
import { FormattedMessage } from "react-intl";
import { DateTime } from "luxon";

import {
  Root,
  Body,
  Icon,
  Tier,
  Pts,
  NextLevel,
  Footer,
} from "./LoyaltyCard.style";
import { Typography } from "../../ui/Typography/Typography";

export const LoyaltyCard = ({
  tier = "",
  pts = "",
  memberSince,
  group = "",
  userName = "",
}) => {
  return (
    <Root>
      <Body>
        <Icon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 89">
            <path
              fill="#e1000f"
              d="M71.2,44.5h-5.1v-9.4V19.5v-1.6H54.9v1.6v25l-37.6,0C9.3,44.5,2.8,38,2.8,30s6.5-14.5,14.5-14.5
	c1.8,0,3.5,0.3,5.1,0.9v10.8v12.1V42c0.6-0.1,1.1-0.2,1.7-0.4c2.6-0.8,4.8-2.3,6.5-4.4c0,0,0,0,0,0c1.2-1.5,2.1-3.4,2.6-5.3
	c0,0,0,0,0,0c0.1-0.7,0.2-1.3,0.3-2c0-0.3,0-0.6,0-0.8c0-9.3-7.6-15.2-16.2-15.2C8.3,13.8,1,21.1,1,30s7.3,16.2,16.2,16.2h5.1v9.4
	v15.6v1.6h11.2v-1.6v-25l0,0h21.4v0h0h1.8h6.2h3.2h5.1c8,0,14.5,6.5,14.5,14.5s-6.5,14.5-14.5,14.5c-1.8,0-3.5-0.3-5.1-0.9V63.5
	V51.4v-2.6c-0.6,0.1-1.1,0.2-1.7,0.4c-2.6,0.8-4.8,2.3-6.5,4.4c0,0,0,0,0,0c-1.2,1.5-2.1,3.4-2.6,5.3c0,0,0,0,0,0
	c-0.1,0.7-0.2,1.3-0.3,2c0,0.3,0,0.6,0,0.8C55,71,62.5,77,71.2,77c9,0,16.2-7.3,16.2-16.2S80.1,44.5,71.2,44.5z"
            />
          </svg>
        </Icon>

        <div>
          <Tier>
            <Typography type="h3">
              {tier} <FormattedMessage defaultMessage="Tier" />
            </Typography>
          </Tier>

          <Pts>
            {pts.toLocaleString("en-US")}{" "}
            <FormattedMessage defaultMessage="PTS" />
          </Pts>

          {memberSince && (
            <NextLevel>
              <Typography type="text-secondary" color="#b1aacb">
                <FormattedMessage
                  defaultMessage="Member Since {date}"
                  values={{
                    date: DateTime.fromMillis(memberSince).toFormat("MMM yyyy"),
                  }}
                />
              </Typography>
            </NextLevel>
          )}
        </div>
      </Body>
      <Footer>
        {group && userName ? (
          <>
            <Typography type="text-secondary">{group}</Typography>
            <Typography type="text-secondary">{userName}</Typography>
          </>
        ) : (
          <Typography type="text-secondary">
            <FormattedMessage defaultMessage="Pending Approval" />
          </Typography>
        )}
      </Footer>
    </Root>
  );
};
