import { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
  useLocation,
} from "react-router-dom";
import { useIntl } from "react-intl";
import { locales } from "../../config";

const localeIds = locales.map((itm) => itm.id);

export const Link = forwardRef(({ to, locale, children, ...rest }, ref) => {
  const location = useLocation();
  const intl = useIntl();
  const isAbsolute = to == null || to === "" || to.startsWith("/");

  if (!isAbsolute && locale != null) {
    console.info({ to, locale });
    throw new Error(
      "Locale option can only be provided for non relative links."
    );
  }
  let transformedTo =
    to == null || to === "" ? `${location.pathname}${location.search}` : to;
  const startsWithLocale = localeIds.find((localeId) =>
    transformedTo.startsWith(`/${localeId}`)
  );
  // console.info({ startsWithLocale, isAbsolute, locale, transformedTo });
  if (locale != null && isAbsolute) {
    transformedTo = transformedTo.replace(
      new RegExp(`^\/${startsWithLocale}`),
      `/${locale}`
    );
  }

  const link =
    startsWithLocale || !isAbsolute
      ? `${transformedTo}`
      : `/${locale ?? intl.locale ?? intl.defaultLocale}${transformedTo}`;

  return (
    <ReactRouterLink {...rest} to={link} ref={ref}>
      {children}
    </ReactRouterLink>
  );
});

Link.displayName = "Link";

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  language: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  children: null,
  to: "",
  onClick: null,
};

export const NavLink = forwardRef(({ to, locale, children, ...rest }, ref) => {
  const location = useLocation();
  const intl = useIntl();
  const isAbsolute = to == null || to === "" || to.startsWith("/");

  if (!isAbsolute && locale != null) {
    console.info({ to, locale });
    throw new Error(
      "Locale option can only be provided for non relative links."
    );
  }
  let transformedTo =
    to == null || to === "" ? `${location.pathname}${location.search}` : to;
  const startsWithLocale = localeIds.find((localeId) =>
    transformedTo.startsWith(`/${localeId}`)
  );
  // console.info({ startsWithLocale, isAbsolute, locale, transformedTo });
  if (locale != null && isAbsolute) {
    transformedTo = transformedTo.replace(
      new RegExp(`^\/${startsWithLocale}`),
      `/${locale}`
    );
  }

  const link =
    startsWithLocale || !isAbsolute
      ? `${transformedTo}`
      : `/${locale ?? intl.locale ?? intl.defaultLocale}${transformedTo}`;

  return (
    <ReactRouterNavLink {...rest} to={link} ref={ref}>
      {children}
    </ReactRouterNavLink>
  );
});

NavLink.displayName = "NavLink";

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  language: PropTypes.string,
  onClick: PropTypes.func,
};

NavLink.defaultProps = {
  to: "",
  onClick: null,
};
