import React, { useState } from "react";
import { Link } from "../Link/Link";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Root, Title, Forgot, RedLink } from "./AuthReset.style";

import { TextInput } from "../../ui/TextInput/TextInput";
import { Button } from "../../ui/Button/Button";
import { resetPassword } from "../../utils/auth";
import { frontendDomain } from "../../config";

export function AuthReset() {
  const intl = useIntl();
  const [statusMessage, setStatusMessage] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      password: "",
      context: {
        id: "clubh",
        domain: frontendDomain,
      },
    },
  });
  const watchPassword = watch("password");

  const onSubmit = (data) => {
    return resetPassword({ token, ...data })
      .then(({ result }) => {
        if (result.success !== true) {
          throw new Error("Unknown error.");
        }
        reset();
        //show success message
        setStatusMessage({
          type: "success",
          message: intl.formatMessage({
            defaultMessage:
              "Your password has successfully been updated. Please login with your new password.",
          }),
          action: {
            label: "login here",
            onClick: () => navigate("/auth"),
          },
        });
      })
      .catch((err) => {
        console.error({ err });
        //show error message
        setStatusMessage({
          type: "error",
          message: intl.formatMessage({
            defaultMessage: "An unknown error occurred. Please try again.",
          }),
        });
      });
  };

  return (
    <Root>
      <Title>
        <FormattedMessage defaultMessage="Update Password" />
      </Title>

      {statusMessage != null ? (
        <p>
          {statusMessage.message}{" "}
          <button
            style={{ textDecoration: "underline" }}
            onClick={() => {
              setStatusMessage();
              if (statusMessage?.action?.onClick) {
                statusMessage.action.onClick();
              }
            }}
          >
            {statusMessage?.action?.label ?? (
              <FormattedMessage defaultMessage="dismiss" />
            )}
          </button>
        </p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <p style={{ marginBottom: "20px" }}>
            {errors.password && errors.password.type === "required" && (
              <FormattedMessage
                defaultMessage="Password is required"
                tagName="span"
              />
            )}
            {errors.password && errors.password.type === "maxLength" && (
              <FormattedMessage
                defaultMessage="Pasword max length of 24 exceeded"
                tagName="span"
              />
            )}
            {errors.password && errors.password.type === "minLength" && (
              <FormattedMessage
                defaultMessage="Pasword should be at least 8 characters"
                tagName="span"
              />
            )}
            {errors["confirm-password"] && !errors.password && (
              <FormattedMessage
                defaultMessage="Paswords do not match"
                tagName="span"
              />
            )}
          </p>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                type="password"
                label={intl.formatMessage({
                  defaultMessage: "New Password",
                })}
                autoComplete="new-password"
              />
            )}
            rules={{
              required: true,
              minLength: 8,
              maxLength: 24,
            }}
          />

          <Controller
            name="confirm-password"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                type="password"
                label={intl.formatMessage({
                  defaultMessage: "Confirm Password",
                })}
                autoComplete="new-password"
              />
            )}
            rules={{
              validate: (val) => {
                if (watchPassword != val) {
                  return "Your passwords do no match";
                }
              },
            }}
          />

          <Forgot as={Link} to="/auth">
            <FormattedMessage defaultMessage="Back to Login" />
          </Forgot>
          <Button type="submit" variant="auth" arrow={false}>
            <FormattedMessage defaultMessage="Update Password" />
          </Button>
          <RedLink as={Link} to="/auth/register">
            <FormattedMessage defaultMessage="Register" />
          </RedLink>
        </form>
      )}
    </Root>
  );
}
