import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import rison from "rison";

import { UserContext } from "../../context/UserContext";
import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { SortByPanel } from "../../components/SortByPanel/SortByPanel";
import { Footer } from "../../components/Footer/Footer";
import { FiltersMobile } from "../../components/FiltersMobile/FiltersMobile";
import { FiltersDesktop } from "../../components/FiltersDesktop/FiltersDesktop";
import { ProductsGrid } from "../../components/ProductsGrid/ProductsGrid";
import { ProductsItem } from "../../components/ProductsItem/ProductsItem";
import { Pagination } from "../../components/Pagination/Pagination";
import { ExclusiveBrand } from "../../components/Sections/ExclusiveBrand/ExclusiveBrand";
import { ProposalSlider } from "../../components/Sections/ProposalSlider/ProposalSlider";
import { Typography } from "../../ui/Typography/Typography";
import { ModalRoot } from "../../components/Modals/ModalRoot/ModalRoot";
import { ModalAddedToCart } from "../../components/Modals/ModalAddedToCart/ModalAddedToCart";
import { ActiveFilters } from "../../components/ActiveFilters/ActiveFilters";
import {
  toast,
  ToastNotification,
} from "../../ui/ToastNotification/ToastNotification";

import {
  GET_WISHLIST,
  GET_REWARDS,
  categoriesConfig,
  rewardTagPath,
  GET_REWARD_TAGS,
  sortConfig,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
} from "./rewardsViewConfig";
import { apiDomain, apiSiteId } from "../../config";
import { brands } from "../../config/brands";

export const RewardsView = () => {
  const { user } = useContext(UserContext);
  const intl = useIntl();
  const locale = intl.locale;
  const [modalAddToCartData, setModalAddToCartData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeFilters, setActiveFilters] = useState({});

  const [
    getRewards,
    {
      loading: rewardsLoading,
      // error: rewardsError,
      data: rewardsData,
    },
  ] = useLazyQuery(GET_REWARDS);

  const data = rewardsData?.content?.findClubH;

  const {
    loading: wishlistLoading,
    // error: wishlistError,
    data: wishlistData,
  } = useQuery(GET_WISHLIST, {
    variables: {
      locale,
    },
  });

  const [addToCart] = useMutation(ADD_TO_CART);

  const [addToWishlist] = useMutation(ADD_TO_WISHLIST, {
    refetchQueries: ["GetWishlist", "GetHeaderCartData"],
  });
  const [removeFromWishlist] = useMutation(REMOVE_FROM_WISHLIST, {
    refetchQueries: ["GetWishlist", "GetHeaderCartData"],
  });

  useEffect(() => {
    let filters = searchParams.has("filters")
      ? rison.decode(searchParams.get("filters"))
      : {};

    //hardcode reward type filter
    filters.type = "reward";

    filters = JSON.stringify(filters);
    let sort = searchParams.get("sort");
    sort = sortConfig.find((s) => s.value === sort);
    sort = sort == null ? null : { key: sort.key, direction: sort.dir };

    const page = searchParams.has("page") ? searchParams.get("page") : 1;
    const limit = 51;

    const { type, ...newActiveFilters } = JSON.parse(filters);
    setActiveFilters(newActiveFilters);
    //console.info({ newActiveFilters });
    getRewards({
      variables: {
        sort,
        page,
        filters,
        limit,
        locale,
      },
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-only",
    });
  }, [searchParams, locale]);

  useEffect(() => {
    if (
      location?.state?.onLoad?.method === "handleAddToCart" &&
      data?.docs?.length > 0
    ) {
      handleAddToCart(...location?.state?.onLoad?.params);
      navigate(location.pathname, { replace: true }); //clear state by passing replace=true
    }

    if (
      location?.state?.onLoad?.method === "handleAddToFavourites" &&
      data?.docs?.length > 0
    ) {
      handleAddToFavourites(...location?.state?.onLoad?.params);
      navigate(location.pathname, { replace: true }); //clear state by passing replace=true
    }
  }, [location?.state?.onLoad?.method, data?.docs?.length]);

  const results = rewardsLoading
    ? "Loading..."
    : `${data?.total ?? 0} ${
        data?.total === 1
          ? intl.formatMessage({ defaultMessage: "product" })
          : intl.formatMessage({ defaultMessage: "products" })
      }`;

  const handleAddToCart = ({ item, brandName, imgSrc, qty }) => {
    setModalAddToCartData({
      imgSrc,
      brandName,
      productName: item?.n,
      points: item?.ecommerce?.price?.amount,
      oldPts: item?.ecommerce?.fullPrice?.amount,
      qty,
    });
    return addToCart({
      variables: { productId: item._id, qty: 1, locale },
    });
  };

  const handleAddToFavourites = async ({ item, inFavorites }) => {
    toast.dismiss();
    if (inFavorites) {
      toast.warn(
        <ToastNotification>
          <FormattedMessage
            defaultMessage="{productName} removed from wishlist."
            values={{ productName: item?.n }}
          />
        </ToastNotification>
      );
      await removeFromWishlist({
        variables: { productId: item._id, locale },
      });
    } else {
      toast.success(
        <ToastNotification>
          <FormattedMessage
            defaultMessage="{productName} added to wishlist."
            values={{ productName: item?.n }}
          />
        </ToastNotification>
      );
      await addToWishlist({
        variables: { productId: item._id, locale: intl.locale },
      });
    }
  };

  return (
    <Layout>
      <Header />

      <FiltersMobile
        sortOptions={sortConfig}
        categoriesConfig={categoriesConfig({ intl })}
        tagsConfig={{
          gql: GET_REWARD_TAGS,
          dataPath: rewardTagPath,
        }}
        showPoints={true}
        pointsConfig={{ min: null, max: null }}
        append={
          <Typography type="p4" color="#8f95a5">
            {results}
          </Typography>
        }
        searchPlaceholder="Search rewards"
      />

      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({ defaultMessage: "Home" }),
          },
          {
            to: null,
            label: intl.formatMessage({ defaultMessage: "Rewards" }),
          },
        ]}
        // append={
        //   <Typography type="p4" color="#8f95a5">
        //     {results}
        //   </Typography>
        // }
      />
      <PageTitle title={intl.formatMessage({ defaultMessage: "Rewards" })} />

      <FiltersDesktop
        categoriesConfig={categoriesConfig({ intl })}
        tagsConfig={{
          gql: GET_REWARD_TAGS,
          dataPath: rewardTagPath,
        }}
        pointsConfig={{ min: null, max: null }}
        showPoints={true}
        searchPlaceholder="Search rewards"
      />

      <SortByPanel
        sortOptions={sortConfig}
        append={
          <Typography type="p4" color="#8f95a5">
            <span style={{ marginRight: 24 }}>{results}</span>
            <ActiveFilters activeFilters={activeFilters} />
          </Typography>
        }
      />

      <ProductsGrid
        totalCount={data?.total ?? 0}
        onResetFilters={() => {
          searchParams.delete("filters");
          setSearchParams(searchParams);
        }}
        loading={rewardsLoading || wishlistLoading}
      >
        {(data?.docs || []).map((item, i) => {
          const inFavorites = (wishlistData?.me?.wishlist?.items || []).some(
            (itm) => itm?.product?._id === item._id
          );

          const imgSrc =
            item?.gallery?.[0]?.media?._id != null
              ? `https://${apiDomain}/${apiSiteId}/asset/${item.gallery[0].media._id}?type=fill&width=604&height=752`
              : `https://picsum.photos/id/${i + 50}/1000/1000`;

          const brandConfig = brands.find((b) => b.id === item.brand?._id);
          const brandName = brandConfig?.label ?? item.brand?.n;
          const brandSlug = brandConfig?.slug;

          return (
            <ProductsItem
              key={item._id}
              imageSrc={imgSrc}
              brandName={brandName ?? "Club H"}
              brandUrl={brandSlug ? `/brands/${brandSlug}` : "/rewards"}
              productName={item?.n}
              productUrl={`/rewards/${item.sl}`}
              pts={item?.ecommerce?.price?.amount}
              oldPts={item?.ecommerce?.fullPrice?.amount}
              inFavorites={inFavorites}
              onAddToFavourites={() => {
                if (user?._id == null) {
                  return navigate(
                    `/${intl.locale}/auth?redirect=${encodeURIComponent(
                      location.pathname
                    )}`,
                    {
                      state: {
                        redirectState: {
                          onLoad: {
                            method: "handleAddToFavourites",
                            params: [{ item, inFavorites }],
                          },
                        },
                      },
                    }
                  );
                }
                handleAddToFavourites({ item, inFavorites });
              }}
              onAddToCart={() => {
                if (user?._id == null) {
                  return navigate(
                    `/${intl.locale}/auth?redirect=${encodeURIComponent(
                      location.pathname
                    )}`,
                    {
                      state: {
                        redirectState: {
                          onLoad: {
                            method: "handleAddToCart",
                            params: [
                              {
                                item,
                                brandName,
                                imgSrc,
                                qty: 1,
                                locale,
                              },
                            ],
                          },
                        },
                      },
                    }
                  );
                }
                handleAddToCart({
                  item,
                  brandName,
                  imgSrc,
                  qty: 1,
                  locale,
                });
              }}
              isPromo={i === 6} //TODO
              isNew={item?.data?.isNew}
              isLimited={item?.data?.isLimited}
              isFeatured={item?.data?.isFeatured}
              brandIcon={brandConfig?.icon}
            />
          );
        })}
      </ProductsGrid>

      <Pagination
        totalCount={data?.total ?? 0}
        currentPage={data?.page ?? 1}
        pageSize={data?.limit ?? 50}
        onPaginate={(e) => {
          if (e === 1) {
            searchParams.delete("page");
          } else {
            searchParams.set("page", e);
          }
          setSearchParams(searchParams);
        }}
        append={
          <Typography type="p4" color="#8f95a5">
            {results}
          </Typography>
        }
      />

      <ExclusiveBrand />
      <ProposalSlider
        variant="black"
        title={intl.formatMessage({ defaultMessage: "Trending Experiences" })}
        ctaText={intl.formatMessage({ defaultMessage: "All articles" })}
        ctaUrl="/experience"
        filters='{ "type": ["news"]}'
      />
      <Footer />

      <ModalRoot
        isVisible={modalAddToCartData != null}
        onClose={() => setModalAddToCartData(null)}
      >
        <ModalAddedToCart
          onClose={() => setModalAddToCartData(null)}
          {...modalAddToCartData}
        />
      </ModalRoot>
    </Layout>
  );
};
