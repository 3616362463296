import styled from "styled-components";

export const Root = styled.a`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  background-color: var(--color-bg-3);
  border-radius: 1px;
  transition: background-color 150ms;

  &:hover {
    background-color: #ededed;
  }
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 12px;
  object-fit: cover;
`;

export const Pts = styled.div`
  color: #3b3b3b;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
`;
