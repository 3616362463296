import React from "react";
import { FormattedMessage } from "react-intl";

import { useSearchParams } from "react-router-dom";
import { Root, Container, AppendContainer, Select } from "./SortByPanel.style";

export const SortByPanel = ({
  sortOptions,
  sortQueryParam = "sort",
  append = null,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Root>
      <Container>
        <AppendContainer>{append}</AppendContainer>

        <Select
          value={searchParams.get(sortQueryParam) ?? ""}
          onChange={(e) => {
            if (e.target.value === "") {
              searchParams.delete(sortQueryParam);
            } else {
              searchParams.set(sortQueryParam, e.target.value);
            }
            setSearchParams(searchParams);
          }}
        >
          <option value="">
            <FormattedMessage defaultMessage="Default Sort" />
          </option>
          <>
            {sortOptions.map((itm) => (
              <option key={itm.value} value={itm.value}>
                {itm.label}
              </option>
            ))}
          </>
        </Select>
      </Container>
    </Root>
  );
};
