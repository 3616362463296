import styled from "styled-components";

import cardMobileSrc from "./assets/clubh-card-mobile@x2.png";
import cardDesktopSrc from "./assets/clubh-card-desktop@x2.png";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    padding: 60px 0;

    &.--bg {
      background-color: #f5f4f8;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 56px 80px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1144px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media (min-width: 1400px) {
    padding-left: 0;

    &.--reverse {
      flex-direction: row-reverse;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    max-width: 360px;
  }

  @media (min-width: 1400px) {
    max-width: 444px;
  }
`;

export const ImageContainer = styled.div`
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1023px) {
    width: 100%;
    height: 321px;
    margin-top: 64px;
    background-image: url(${cardMobileSrc});
  }

  @media (min-width: 1024px) {
    width: 480px;
    height: 444px;
    background-image: url(${cardDesktopSrc});
  }

  @media (min-width: 1400px) {
    width: 572px;
  }
`;
