import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Root, Container, CtaContainer } from "./TermsView.style";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/ComingSoon/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { Footer } from "../../components/Footer/Footer";

import { Button } from "../../ui/Button/Button";
import { Link } from "../../components/Link/Link";

import termsMarkdownEnCa from "./terms_en-CA.md";
import termsMarkdownFrCa from "./terms_fr-CA.md";

const termsContent = {
  "en-CA": termsMarkdownEnCa,
  "fr-CA": termsMarkdownFrCa,
};

export const TermsView = () => {
  const intl = useIntl();
  const { locale, defaultLocale } = intl;

  return (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          { to: "/", label: "Home" },
          {
            to: null,
            label: intl.formatMessage({
              defaultMessage: "Terms & Conditions",
            }),
          },
        ]}
      />
      <PageTitle
        title={intl.formatMessage({
          defaultMessage: "Terms & Conditions",
        })}
      />

      <Root>
        <Container>
          <Markdown remarkPlugins={[remarkGfm]}>
            {termsContent[locale] ?? termsContent[defaultLocale]}
          </Markdown>

          <CtaContainer>
            <Button as={Link} to="/">
              <FormattedMessage defaultMessage="back to home" />
            </Button>
          </CtaContainer>
        </Container>
      </Root>

      <Footer />
    </Layout>
  );
};
