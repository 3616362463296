import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding-top: 64px;
  }

  @media (min-width: 1024px) {
    padding: 135px 40px 175px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1144px;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    height: 583px;
    padding-left: 55px;
    padding-right: 80px;
    background-color: #f7f6fa;
  }

  @media (min-width: 1400px) {
    padding-left: 90px;
    padding-right: 128px;
  }
`;

export const ImageContainer = styled.div`
  @media (max-width: 1023px) {
    order: 1;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 430px;
    margin-top: 183px;
    background-color: #f7f6fa;
  }
`;

export const Image = styled.img`
  @media (max-width: 1023px) {
    position: absolute;
    bottom: 71px;
    max-width: 241px;
  }

  @media (min-width: 1024px) {
    max-width: 367px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1023px) {
    align-items: center;
    text-align: center;
    max-width: 450px;
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    max-width: 360px;
  }

  @media (min-width: 1400px) {
    max-width: 444px;
  }
`;

export const CtaContainer = styled.div`
  @media (max-width: 1023px) {
    margin-top: 38px;
  }

  @media (min-width: 1024px) {
    margin-top: 40px;
  }
`;
