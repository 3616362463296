import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Container,
  ScrollContainer,
  CellWrapper,
  Dot,
  BonusContainer,
  MonthlyPromotionsDesc,
  ViewDetails,
} from "./LoyaltyTiers.style";

import { Typography } from "../../ui/Typography/Typography";
import { LoyaltyTable } from "../LoyaltyTable/LoyaltyTable";

export const LoyaltyTiers = () => {
  return (
    <Root>
      <Container>
        <ScrollContainer>
          <LoyaltyTable $withScroll>
            <thead>
              <tr>
                <th />
                <th>
                  <Typography type="h3">
                    <FormattedMessage defaultMessage="Starter" />
                  </Typography>
                </th>
                <th>
                  <Typography type="h3">
                    <FormattedMessage defaultMessage="Enthusiast" />
                  </Typography>
                </th>
                <th>
                  <Typography type="h3">
                    <FormattedMessage defaultMessage="Master" />
                  </Typography>
                </th>
                <th>
                  <Typography type="h3">
                    <FormattedMessage defaultMessage="Icon" />
                  </Typography>
                </th>
                {/* <th>
                  <Typography type="h3">
                    <FormattedMessage defaultMessage="Legend" />
                  </Typography>
                </th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Spend" />
                  </Typography>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="text-secondary">{"<"} $6,999</Typography>
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="text-secondary">
                      $7,000 - $24,999
                    </Typography>
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="text-secondary">
                      $25,000 - $49,999
                    </Typography>
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="text-secondary">$50,000 +</Typography>
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="Invite Only" />
                    </Typography>
                  </CellWrapper>
                </td> */}
              </tr>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Earn" />
                  </Typography>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="h2">10</Typography>
                    &nbsp;
                    <Typography type="text-secondary">PTS/$</Typography>
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="h2">30</Typography>
                    &nbsp;
                    <Typography type="text-secondary">PTS/$</Typography>
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="h2">50</Typography>
                    &nbsp;
                    <Typography type="text-secondary">PTS/$</Typography>
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Typography type="h2">80</Typography>
                    &nbsp;
                    <Typography type="text-secondary">PTS/$</Typography>
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Typography type="h2">100</Typography>
                    &nbsp;
                    <Typography type="text-secondary">PTS/$</Typography>
                  </CellWrapper>
                </td> */}
              </tr>
            </tbody>
          </LoyaltyTable>
        </ScrollContainer>

        <Typography
          type="h3"
          style={{ alignSelf: "flex-start", marginTop: 40, marginBottom: 8 }}
        >
          <FormattedMessage defaultMessage="Rewards" />
        </Typography>
        <ScrollContainer>
          <LoyaltyTable $withScroll>
            <tbody>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Back Bar" />
                  </Typography>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--starter" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--enthousiast" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--master" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--icon" />
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Salon Tools" />
                  </Typography>
                </td>
                <td />
                <td>
                  <CellWrapper>
                    <Dot className="--enthousiast" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--master" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--icon" />
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Marketing Support" />
                  </Typography>
                </td>
                <td />
                <td />
                <td>
                  <CellWrapper>
                    <Dot className="--master" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--icon" />
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Salon Locator" />
                  </Typography>
                </td>
                <td />
                <td />
                <td />
                <td>
                  <CellWrapper>
                    <Dot className="--icon" />
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Tier Welcom Gifts" />
                  </Typography>
                </td>
                <td />
                <td />
                <td />
                <td />
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
            </tbody>
          </LoyaltyTable>
        </ScrollContainer>

        <Typography
          type="h3"
          style={{ alignSelf: "flex-start", marginTop: 40, marginBottom: 8 }}
        >
          <FormattedMessage defaultMessage="Education" />
        </Typography>
        <ScrollContainer>
          <LoyaltyTable $withScroll>
            <tbody>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Online Education" />
                  </Typography>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--starter" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--enthousiast" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--master" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--icon" />
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="In-person Education" />
                  </Typography>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--starter" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--enthousiast" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--master" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--icon" />
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
              <tr>
                <td>
                  <Typography type="text-secondary">
                    <FormattedMessage defaultMessage="Events" />
                  </Typography>
                </td>
                <td />
                <td>
                  <CellWrapper>
                    <Dot className="--enthousiast" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--master" />
                  </CellWrapper>
                </td>
                <td>
                  <CellWrapper>
                    <Dot className="--icon" />
                  </CellWrapper>
                </td>
                {/* <td>
                  <CellWrapper>
                    <Dot className="--legend" />
                  </CellWrapper>
                </td> */}
              </tr>
            </tbody>
          </LoyaltyTable>
        </ScrollContainer>

        <BonusContainer>
          <div>
            <Typography
              type="h3"
              style={{
                alignSelf: "flex-start",
                display: "flex",
                alignItems: "center",
                marginTop: 40,
                marginBottom: 8,
              }}
            >
              <FormattedMessage defaultMessage="Bonus Points" />
              &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
              >
                <path d="M19.037 8.162h-4.953a4.122 4.122 0 0 0 2.214-1.658c.324-.412.396-.968.188-1.449a.952.952 0 0 0-.976-.414c-1.56.126-2.941 1.954-3.51 2.817-.569-.863-1.949-2.691-3.51-2.817a.953.953 0 0 0-.976.414 1.427 1.427 0 0 0 .188 1.449 4.12 4.12 0 0 0 2.213 1.658H4.963a.324.324 0 0 0-.32.326v2.844a.323.323 0 0 0 .32.327h.273v7.987a.323.323 0 0 0 .32.327h12.887a.323.323 0 0 0 .32-.327v-7.985h.271a.323.323 0 0 0 .32-.327V8.489a.328.328 0 0 0-.093-.232.316.316 0 0 0-.224-.095Zm-6 .654v2.19H10.96V8.818l2.077-.002Zm5.677 2.191H13.68V8.818h5.037l-.003 2.189ZM15.56 5.293h.081c.1 0 .246.017.3.1.067.113.039.4-.184.763a3.793 3.793 0 0 1-3.182 1.6c.538-.798 1.742-2.363 2.985-2.463Zm-7.317.865c-.224-.363-.251-.65-.184-.763.052-.087.192-.1.3-.1h.081c1.243.1 2.447 1.665 2.987 2.469a3.8 3.8 0 0 1-3.184-1.606Zm-2.96 2.66h5.037v2.19H5.283v-2.19Zm.592 2.933h4.446v7.567H5.875v-7.567Zm5.086 7.567v-7.569h2.082v7.569h-2.082Zm7.165 0H13.68v-7.569h4.446v7.569Z" />
              </svg>
            </Typography>

            <LoyaltyTable>
              <tbody>
                <tr>
                  <td>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="Complete our Survey" />
                    </Typography>
                  </td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                    <Typography type="points-primary">
                      {[8000].toLocaleString("en-US")}{" "}
                      <FormattedMessage defaultMessage="PTS" />
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="Subscribe to SMS updates" />
                    </Typography>
                  </td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                    <Typography type="points-primary">
                      {[2000].toLocaleString("en-US")}{" "}
                      <FormattedMessage defaultMessage="PTS" />
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="Refer a Friend" />
                    </Typography>
                  </td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                    <Typography type="points-primary">
                      {[5000].toLocaleString("en-US")}{" "}
                      <FormattedMessage defaultMessage="PTS" />
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="Enter your Salon’s Birthday" />
                    </Typography>
                  </td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                    <Typography type="points-primary">
                      {[2000].toLocaleString("en-US")}{" "}
                      <FormattedMessage defaultMessage="PTS" />
                    </Typography>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="Ad Hoc Promotions" />
                    </Typography>
                  </td>
                  <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                    <Typography type="points-primary">
                      {[1500].toLocaleString("en-US")}{" "}
                      <FormattedMessage defaultMessage="PTS" />
                    </Typography>
                  </td>
                </tr> */}
              </tbody>
            </LoyaltyTable>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 40,
              paddingTop: 40,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15 11"
              width="80%"
            >
              <path
                fill="#e5e5e5"
                d="M12.18 5.346A2.823 2.823 0 0 1 15 8.166a2.823 2.823 0 0 1-2.82 2.82c-1.495 0-2.813-1.036-2.813-2.643 0-.05.001-.1.004-.147.009-.12.027-.238.052-.355v-.005a2.284 2.284 0 0 1 1.58-1.684 2.39 2.39 0 0 1 .292-.071v4.439a2.515 2.515 0 0 0 3.398-2.354 2.515 2.515 0 0 0-2.516-2.512H5.646v4.612H3.705V5.654h-.883A2.83 2.83 0 0 1 0 2.834 2.827 2.827 0 0 1 2.82.014c1.493 0 2.813 1.039 2.813 2.647a2.256 2.256 0 0 1-.058.497v.004a2.254 2.254 0 0 1-.446.926 2.279 2.279 0 0 1-1.424.829V.484a2.513 2.513 0 1 0-.883 4.862h6.533V.736h1.94v4.61h.885Z"
              />
            </svg>
          </div>

          {/* <div>
            <Typography
              type="h3"
              style={{
                alignSelf: "flex-start",
                marginTop: 40,
                marginBottom: 8,
              }}
            >
              <FormattedMessage defaultMessage="Monthly Promotions" />
            </Typography>

            <LoyaltyTable>
              <tbody>
                <tr>
                  <td style={{ padding: "24px 0" }}>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="2023 Winter Promotion" />
                    </Typography>
                    <MonthlyPromotionsDesc>
                      <Typography type="text-small">
                        <FormattedMessage defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut posuere nibh, sed commodo ligula. Etiam vulputate eget elit at malesuada. Aliquam erat volutpat." />
                      </Typography>
                    </MonthlyPromotionsDesc>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <ViewDetails>
                      <FormattedMessage defaultMessage="View Details" />
                    </ViewDetails>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "24px 0" }}>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="2023 Winter Promotion" />
                    </Typography>
                    <MonthlyPromotionsDesc>
                      <Typography type="text-small">
                        <FormattedMessage defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut posuere nibh, sed commodo ligula. Etiam vulputate eget elit at malesuada. Aliquam erat volutpat." />
                      </Typography>
                    </MonthlyPromotionsDesc>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <ViewDetails>
                      <FormattedMessage defaultMessage="View Details" />
                    </ViewDetails>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "24px 0" }}>
                    <Typography type="text-secondary">
                      <FormattedMessage defaultMessage="2023 Winter Promotion" />
                    </Typography>
                    <MonthlyPromotionsDesc>
                      <Typography type="text-small">
                        <FormattedMessage defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut posuere nibh, sed commodo ligula. Etiam vulputate eget elit at malesuada. Aliquam erat volutpat." />
                      </Typography>
                    </MonthlyPromotionsDesc>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <ViewDetails>
                      <FormattedMessage defaultMessage="View Details" />
                    </ViewDetails>
                  </td>
                </tr>
              </tbody>
            </LoyaltyTable>
          </div> */}
        </BonusContainer>
      </Container>
    </Root>
  );
};
