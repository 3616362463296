import React from "react";
import PropTypes from "prop-types";

import {
  Root,
  Container,
  CardsList,
  CardsItem,
  CardsIcon,
  CardsText,
} from "./Features.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";
import { Link } from "../../Link/Link";

export const Features = ({
  variant = "primary",
  title = "",
  items = [],
  cta = null,
}) => {
  return (
    <Root className={`--${variant}`}>
      <Container>
        <Typography as="h2" type="h2">
          {title}
        </Typography>

        <CardsList>
          {items.map(({ icon, title, desc }) => (
            <CardsItem key={title}>
              <CardsIcon>{icon}</CardsIcon>
              <CardsText>
                <Typography as="h3" type="h3">
                  {title}
                </Typography>
                <Typography
                  className={`--${variant}`}
                  as="p"
                  type="text-primary"
                  style={{ marginTop: 18 }}
                >
                  {desc}
                </Typography>
              </CardsText>
            </CardsItem>
          ))}
        </CardsList>

        {cta && (
          <Button as={Link} to={cta.url} style={{ marginTop: 54 }}>
            {cta.label}
          </Button>
        )}
      </Container>
    </Root>
  );
};

Features.propTypes = {
  variant: PropTypes.oneOf(["primary", "pattern-light"]),
};
