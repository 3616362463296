import React from "react";
import Lottie from "lottie-react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";

import { Root, LottieContainer } from "./Loading.style";

import clubhAnimation from "./ClubH_Loader.json";

import { Typography } from "../../ui/Typography/Typography";

export const Loading = ({ global = true }) => {
  return global ? (
    createPortal(
      <Root className="--global">
        <LottieContainer>
          <Lottie animationData={clubhAnimation} loop={true} />
        </LottieContainer>
      </Root>,
      document.body
    )
  ) : (
    <Root className="--local">
      <LottieContainer>
        <Lottie animationData={clubhAnimation} loop={true} />
      </LottieContainer>
      <Typography
        type="text-small"
        color="#8f95a5;
"
      >
        <FormattedMessage defaultMessage="Loading" />
      </Typography>
    </Root>
  );
};
