import styled from "styled-components";

export const Root = styled.div`
  width: 100%;

  .slick-slider {
    line-height: 0;
  }

  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-dots {
    bottom: 10px;
  }
  .slick-dots li {
    width: auto;
    height: auto;
    margin: 0;
  }
  .slick-dots li button {
    display: flex;
  }
  .slick-dots li button::before {
    color: #fff;
    font-size: 6px;
    opacity: 1;
  }
  .slick-dots li.slick-active button::before {
    font-size: 10px;
  }
`;

export const ArrowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
  background-color: #000;
  z-index: 1;

  @media (max-width: 1023px) {
    width: 32px;
    height: 32px;
    top: 124px;
    left: ${(p) => p.$dir < 0 && "8px"};
    right: ${(p) => p.$dir > 0 && "8px"};
    font-size: 22px;
    transform: translateY(-50%);
  }

  @media (min-width: 1024px) {
    width: 40px;
    height: 40px;
    right: ${(p) => (p.$dir > 0 ? "40px" : "104px")};
    bottom: 32px;
    font-size: 24px;
  }
`;

export const Slide = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    height: 550px;
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;

  @media (max-width: 1023px) {
    height: 248px;
  }

  @media (min-width: 1024px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  @media (max-width: 1023px) {
    padding: 40px 24px 64px;
    color: #fff;
    background-color: #000;
  }

  @media (min-width: 1024px) {
    position: absolute;
    left: 0;
    bottom: 32px;
    width: 595px;
    padding: 32px 48px;
    background-color: #fff;
  }
`;

export const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const Desc = styled.div`
  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }
`;
