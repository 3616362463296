import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    margin-bottom: 48px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    max-width: 360px;

    &.--desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 16px;
    row-gap: 40px;

    &.--mobile {
      display: none;
    }
  }

  .slick-dots {
    bottom: -52px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button::before {
    color: #27166a;
    opacity: 1;
    transition: all 150ms;
  }

  .slick-dots li.slick-active button::before {
    font-size: 12px;
  }
`;

export const Slide = styled.div`
  padding: 0 4px;
`;

export const ImageLink = styled.a`
  display: block;
  position: relative;
  margin-bottom: 13px;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 16/25;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 8px;
  bottom: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  z-index: 1;
`;
