import React, { useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Root,
  Video,
  Container,
  ContentContainer,
  HeadingImg,
  Form,
  InputContainer,
  Input,
  RegisteredBadge,
} from "./SweepstakesHero.style";

import headingDesktopSrc from "./assets/heading-desktop.svg";
import headingMobileSrc from "./assets/heading-mobile.svg";

import videoMobileSrc from "../../../assets/video/ClubH_Sweepstake_Box_Mobile_V3-Compressed.mp4";
import videoDesktopSrc from "../../../assets/video/ClubH_Sweepstake_Box_Desktop_V2-Compressed.mp4";

import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";

import { UserContext } from "../../../context/UserContext";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export const SweepstakesHero = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    console.info(
      "/auth/register?email=" +
        encodeURIComponent(data.email) +
        "&sweepstakes=true"
    );
    return navigate(
      "/auth/register?email=" +
        encodeURIComponent(data.email) +
        "&sweepstakes=true"
    );
  };

  return (
    <Root>
      <Video autoPlay muted loop>
        <source
          src={videoMobileSrc}
          media="(max-width: 1023px)"
          type="video/mp4"
        />
        <source src={videoDesktopSrc} type="video/mp4" />
      </Video>
      <Container>
        <ContentContainer>
          <HeadingImg className="--desktop" src={headingDesktopSrc} alt="" />
          <HeadingImg className="--mobile" src={headingMobileSrc} alt="" />
          <Typography type="text-primary">
            <FormattedMessage
              defaultMessage="Registering for Club H is your ticket to the sweepstakes. No additional steps are required, sign up by June 30th, 2024 and you're automatically entered to win one of 36 prizes featuring our color, styling and care products*. {br}{br}It’s that simple!"
              values={{ br: <br /> }}
            />
          </Typography>

          {user ? (
            <RegisteredBadge>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="#e1000f"
                  d="M8 0C1.842 0-2.007 6.667 1.072 12c3.079 5.333 10.777 5.333 13.856 0A8.002 8.002 0 0 0 16 8a8.01 8.01 0 0 0-8-8Z"
                />
                <path
                  fill="#fff"
                  d="M11.711 4.927 6.944 9.695h-.34L4.284 7.37l-.808.808 2.9 2.9h.808l5.335-5.344-.808-.807Z"
                />
              </svg>
              <FormattedMessage defaultMessage="you're registered!" />
            </RegisteredBadge>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputContainer>
                <Input
                  placeholder={intl.formatMessage({
                    defaultMessage: "enter email",
                  })}
                  {...register("email")}
                />
                <Button type="submit" style={{ flexShrink: 0 }}>
                  <FormattedMessage defaultMessage="register to win" />
                </Button>
              </InputContainer>
              <Typography type="text-secondary" style={{ color: "#b1aacb" }}>
                {errors.email?.message}
              </Typography>
            </Form>
          )}

          <Typography
            type="text-secondary"
            style={{ color: "#b1aacb", lineHeight: 1.23 }}
          >
            <FormattedMessage
              defaultMessage="Sweepstakes will end at 11:59pm on June 30th, 2024.  Please see <a>official rules</a> for details*."
              values={{
                br: <br />,
                a: (val) => (
                  <a
                    href="/sweeps-terms"
                    target="_blank"
                    style={{ color: "inherit" }}
                  >
                    {val}
                  </a>
                ),
              }}
            />
          </Typography>
        </ContentContainer>
      </Container>
    </Root>
  );
};
