import styled from "styled-components";

export const Root = styled.div`
  display: grid;

  @media (max-width: 1023px) {
    justify-content: center;
    row-gap: 40px;
    padding: 0 12px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 48px;
  }
`;

export const Item = styled.div`
  @media (max-width: 1023px) {
    max-width: 450px;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    height: 230px;
  }

  @media (min-width: 1024px) {
    height: 300px;
  }

  video,
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0 none;
  }
`;
