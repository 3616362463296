import styled from "styled-components";

export const FavouritesContainer = styled.div`
  @media (max-width: 1023px) {
    padding-bottom: 40px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 64px;
  }
`;

export const PaginationContainer = styled.div`
  @media (max-width: 1023px) {
    padding-top: 64px;
  }

  @media (min-width: 1024px) {
    padding-top: 88px;
  }
`;
