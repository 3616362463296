import React from "react";
import { useIntl } from "react-intl";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { HeroSlider } from "../../components/Home/HeroSlider/HeroSlider";
// import { FavoriteBrands } from "../../components/Home/FavoriteBrands/FavoriteBrands";
import { BeautyBrands } from "../../components/Sections/BeautyBrands/BeautyBrands";
import { Categories } from "../../components/Home/Categories/Categories";
import { RewardsCarousel } from "../../components/Sections/RewardsCarousel/RewardsCarousel";
import { AdvantagesGrid } from "../../components/Sections/AdvantagesGrid/AdvantagesGrid";
import { Discover } from "../../components/Home/Discover/Discover";
import { PromoBrand } from "../../components/Sections/PromoBrand/PromoBrand";
import { Faq } from "../../components/Sections/Faq/Faq";
import { Community } from "../../components/Sections/Community/Community";
import { Footer } from "../../components/Footer/Footer";
import { ExclusiveBrand } from "../../components/Sections/ExclusiveBrand/ExclusiveBrand";
import { ProposalSlider } from "../../components/Sections/ProposalSlider/ProposalSlider";
import homepageCareImageSrc from "../../assets/images/homepage/care.jpg";
import homepageColorImageSrc from "../../assets/images/homepage/color.jpg";
import homepageStylingImageSrc from "../../assets/images/homepage/styling.jpg";

const HomeView = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Header />
      <HeroSlider />
      {/* <FavoriteBrands /> */}
      <BeautyBrands
        ctaTextLoggedIn={intl.formatMessage({
          defaultMessage: "Shop Rewards",
        })}
        ctaUrlLoggedIn="/rewards"
        ctaTextLoggedOut={intl.formatMessage({
          defaultMessage: "Membership Benefits",
        })}
        ctaUrlLoggedOut="/membership-benefits"
      />
      <Categories
        items={[
          {
            imageSrc: homepageCareImageSrc,
            title: intl.formatMessage({
              defaultMessage: "care",
            }),
            url: "/rewards?filters=(categories:!(care))",
          },
          {
            imageSrc: homepageStylingImageSrc,
            title: intl.formatMessage({
              defaultMessage: "styling",
            }),
            url: "/rewards?filters=(categories:!(styling))",
          },
          {
            imageSrc: homepageColorImageSrc,
            title: intl.formatMessage({
              defaultMessage: "Color Accessories",
            }),
            url: "/rewards?filters=(categories:!(accessories-tools))",
          },
        ]}
      />
      <RewardsCarousel
        title={intl.formatMessage({
          defaultMessage: "Grow your business with Exclusive Rewards",
        })}
        desc={intl.formatMessage({
          defaultMessage:
            "Dive into our hottest rewards - handpicked and trending, just for you!",
        })}
      />
      <AdvantagesGrid />
      <Discover />
      <ExclusiveBrand />
      <ProposalSlider
        variant="pattern-light"
        title={intl.formatMessage({
          defaultMessage: "Latest Experiences",
        })}
        desc={intl.formatMessage({
          defaultMessage:
            "Catch up on all the new and exciting happenings in the world of Club H",
        })}
        ctaText={intl.formatMessage({
          defaultMessage: "Read More",
        })}
        ctaUrl="/experience"
        filters='{ "type": ["news"]}'
      />
      <PromoBrand />
      {/* <ProposalSlider
        variant="pattern-dark"
        title={intl.$t({ defaultMessage: "Trending Promotions" })}
        desc={intl.formatMessage({
          defaultMessage:
            "Check back later for information on our trending promotions!",
        })}
        // ctaText={intl.formatMessage({
        //   defaultMessage: "View all Promotions",
        // })}
        filters='{ "type": ["education", "news"], "categories": ["promotions"]}'
      /> */}
      <Faq />
      <Community />
      <Footer />
    </Layout>
  );
};

export { HomeView };
