import styled from "styled-components";

export const Tabs = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    justify-content: center;
    align-items: flex-end;
    height: 72px;
    border-bottom: 1px solid #dcdfe9;
  }
`;

export const Tab = styled.button`
  position: relative;
  font-weight: 700;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 336px;
    height: 32px;
    padding: 0 24px;
    color: #27166a;
    font-size: 14px;
    text-transform: uppercase;
    border: solid 1px #b0abc8;
    border-radius: 16px;

    & + & {
      margin-top: 8px;
    }

    &.--active {
      color: #fff;
      background-color: #27166a;
      border: solid 1px #27166a;
    }
  }

  @media (min-width: 1024px) {
    bottom: -1px;
    width: 175px;
    height: 48px;
    color: #8f95a5;
    font-size: 16px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &.--active {
      color: var(--color-accent);
      border-bottom-color: #cf2b23;
    }
  }
`;
