import styled from "styled-components";

export const TooltipStyled = styled.div`
  width: max-content;
  max-width: calc(100vw - 10px);
  min-height: 21px;
  padding: 4px 12px;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 12px;
`;

export const TriggerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  min-height: 18px;
`;
