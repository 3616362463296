import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: #fff;
  z-index: 9999;
`;

export const Close = styled.button`
  position: absolute;
  width: 32px;
  height: 32px;

  @media (max-width: 1023px) {
    top: 24px;
    right: 24px;
  }

  @media (min-width: 1024px) {
    top: 32px;
    right: 32px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const ImagesList = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 152px;
  padding: 32px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ImagesItem = styled.button`
  width: 88px;
  height: 110px;
  background-color: #f4f4f4;

  &.--active {
    box-shadow: 0 0 0 2px #27166a;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ImageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    height: calc(100% - 100px);
  }

  @media (min-width: 1024px) {
    height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const ArrowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  color: #fff;
  background-color: #000;
  font-size: 22px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`;

export const Footer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 24px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
`;
