import styled from "styled-components";

export const LoyaltyTable = styled.table`
  width: 100%;

  @media (max-width: 1023px) {
    min-width: ${(p) => (p.$withScroll ? "865px" : "auto")};
  }

  thead > tr > th {
    height: 64px;
    text-align: center;
  }

  thead > tr > th:nth-child(1n + 2),
  tbody > tr > td:nth-child(1n + 2) {
    @media (max-width: 1023px) {
      width: 150px;
    }

    @media (min-width: 1024px) {
      width: 15.6%;
    }
  }

  thead > tr > th:nth-child(2) {
    border-bottom: 2px solid #e9e14b;
  }
  thead > tr > th:nth-child(3) {
    border-bottom: 2px solid #f0aa3c;
  }
  thead > tr > th:nth-child(4) {
    border-bottom: 2px solid #cf2b23;
  }
  thead > tr > th:nth-child(5) {
    border-bottom: 2px solid #7c2662;
  }
  thead > tr > th:nth-child(6) {
    border-bottom: 2px solid #28325a;
  }

  thead + tbody > tr:first-of-type > td {
    padding-top: 15px;
  }

  tbody td {
    height: 65px;
    border-bottom: 1px solid #dcdfe9;
  }

  tbody > tr > td:nth-child(1n + 2) > div {
    justify-content: center;
  }
`;
