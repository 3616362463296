import React from "react";

import { Root, Item, ItemLink } from "./Social.style";

export function Social() {
  return (
    <Root>
      {/* <Item>
        <ItemLink href="#twitter">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M13.625 5.118c.009.126.009.251.009.377a8.2 8.2 0 0 1-8.251 8.251 8.19 8.19 0 0 1-4.458-1.302c.232.026.466.038.7.036a5.803 5.803 0 0 0 3.6-1.239A2.903 2.903 0 0 1 2.513 9.23c.181.029.364.044.548.045.257-.001.514-.034.763-.1A2.899 2.899 0 0 1 1.5 6.33v-.036c.402.225.851.351 1.311.368a2.899 2.899 0 0 1-.9-3.879 8.238 8.238 0 0 0 5.98 3.035 3.216 3.216 0 0 1-.066-.664c.003-2.232 2.422-3.624 4.353-2.505.245.142.467.318.661.524a5.703 5.703 0 0 0 1.841-.7 2.895 2.895 0 0 1-1.275 1.6 5.796 5.796 0 0 0 1.67-.449 6.222 6.222 0 0 1-1.454 1.5l.004-.006Z"
            />
          </svg>
        </ItemLink>
      </Item> */}
      <Item>
        <ItemLink href="https://www.facebook.com/clubhrewards" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="m11.723 9.005.444-2.9H9.389V4.23a1.448 1.448 0 0 1 1.633-1.564h1.263V.205a15.462 15.462 0 0 0-2.242-.2 3.535 3.535 0 0 0-3.784 3.9v2.2H3.715v2.9h2.544v7h3.13v-7h2.334Z"
            />
          </svg>
        </ItemLink>
      </Item>
      {/* <Item>
        <ItemLink href="#linkedin">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M3.592 15.999H.275V5.317h3.317v10.682ZM1.932 3.86C.446 3.867-.49 2.263.247.973.984-.317 2.841-.326 3.59.958c.17.292.261.625.263.963A1.936 1.936 0 0 1 1.932 3.86Zm14.079 12.139h-3.31v-5.2c0-1.239-.025-2.829-1.725-2.829-1.725 0-1.989 1.346-1.989 2.739v5.29H5.67V5.317h3.182v1.457h.046a3.487 3.487 0 0 1 3.139-1.725c3.357 0 3.974 2.211 3.974 5.082v5.868Z"
            />
          </svg>
        </ItemLink>
      </Item> */}
      <Item>
        <ItemLink href="https://www.instagram.com/clubhrewards" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M8.006 3.898c-3.156 0-5.129 3.417-3.551 6.15 1.578 2.734 5.523 2.734 7.101 0a4.095 4.095 0 0 0-3.55-6.15Zm0 6.766c-2.053 0-3.335-2.221-2.309-3.999 1.026-1.777 3.591-1.777 4.618 0a2.668 2.668 0 0 1-2.309 3.999Zm5.22-6.934a.956.956 0 1 1-1.912 0 .956.956 0 0 1 1.912 0Zm2.716.971A4.736 4.736 0 0 0 14.65 1.35 4.766 4.766 0 0 0 11.299.058c-1.321-.075-5.278-.075-6.6 0a4.76 4.76 0 0 0-3.35 1.288A4.749 4.749 0 0 0 .057 4.698c-.075 1.321-.075 5.278 0 6.6a4.728 4.728 0 0 0 1.292 3.351A4.766 4.766 0 0 0 4.7 15.941c1.321.075 5.278.075 6.6 0a4.727 4.727 0 0 0 3.351-1.292 4.764 4.764 0 0 0 1.292-3.351c.075-1.321.075-5.275 0-6.6l-.001.003Zm-1.706 8.012a2.699 2.699 0 0 1-1.52 1.52c-1.053.418-3.551.321-4.715.321-1.164 0-3.665.093-4.715-.321a2.696 2.696 0 0 1-1.52-1.52c-.418-1.053-.321-3.551-.321-4.715 0-1.164-.093-3.664.321-4.714a2.7 2.7 0 0 1 1.521-1.52c1.053-.418 3.551-.321 4.719-.321 1.168 0 3.661-.093 4.71.321a2.705 2.705 0 0 1 1.52 1.52c.418 1.053.321 3.551.321 4.715 0 1.164.097 3.665-.321 4.714Z"
            />
          </svg>
        </ItemLink>
      </Item>
      <Item>
        <ItemLink href="https://www.youtube.com/@ClubHrewards" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M15.666 4.135a2.011 2.011 0 0 0-1.416-1.423A47.649 47.649 0 0 0 8 2.375a47.63 47.63 0 0 0-6.25.337A2.01 2.01 0 0 0 .334 4.135 20.993 20.993 0 0 0 0 8.011c-.009 1.3.103 2.597.334 3.876a1.978 1.978 0 0 0 1.415 1.4c2.074.25 4.162.363 6.251.337a47.311 47.311 0 0 0 6.251-.337 1.98 1.98 0 0 0 1.415-1.4A21.16 21.16 0 0 0 16 8.011a21.168 21.168 0 0 0-.334-3.876Zm-9.3 6.255V5.632l4.184 2.379-4.186 2.379h.002Z"
            />
          </svg>
        </ItemLink>
      </Item>
      <Item>
        <ItemLink href="https://www.tiktok.com/clubhrewards" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M8.661.012c.873-.013 1.74-.006 2.607-.013a4.151 4.151 0 0 0 1.167 2.78 4.697 4.697 0 0 0 2.827 1.193v2.687a7.13 7.13 0 0 1-2.8-.647 8.2 8.2 0 0 1-1.08-.62c-.007 1.947.007 3.893-.013 5.833a5.098 5.098 0 0 1-.9 2.627 4.968 4.968 0 0 1-3.94 2.14 4.864 4.864 0 0 1-2.72-.687 5.026 5.026 0 0 1-2.434-3.806c-.013-.333-.02-.667-.007-.993a5.019 5.019 0 0 1 5.824-4.454c.013.987-.027 1.973-.027 2.96a2.287 2.287 0 0 0-2.92 1.413 2.637 2.637 0 0 0-.097 1.074 2.268 2.268 0 0 0 2.333 1.913 2.241 2.241 0 0 0 1.847-1.073c.148-.209.242-.452.273-.707.067-1.193.04-2.38.047-3.573.007-2.687-.007-5.367.013-8.047Z"
            />
          </svg>
        </ItemLink>
      </Item>
    </Root>
  );
}
