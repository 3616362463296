import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  text-align: center;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 248px;
  object-fit: cover;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 248px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 248px;
    border: none;
  }
`;

export const BrandName = styled.div`
  display: inline-block;
  margin-top: 13px;
  color: var(--color-accent);
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;

  &:hover {
    color: #cb000e;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;

export const Title = styled.div`
  margin: 8px auto;
  font-weight: 700;
  line-height: 1.38;
  text-align: center;

  &.--pattern-light {
    color: #000;
  }

  &.--pattern-dark {
    color: #b0abc8;
  }

  &.--black {
    color: #fff;
  }

  @media (max-width: 1023px) {
    max-width: 280px;
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    max-width: 378px;
    font-size: 18px;
  }
`;

export const Desc = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 378px;
  margin: 0 auto;
  text-align: center;

  &.--pattern-light {
    color: #000;
  }

  &.--pattern-dark {
    color: #b0abc8;
  }

  &.--black {
    color: #fff;
  }

  a,
  span {
    color: var(--color-accent);
    text-decoration: underline;

    &:hover {
      color: #cb000e;
    }
  }
`;
