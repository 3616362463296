import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 43px;
  padding: 43px 0;
  border-top: 1px solid var(--color-border-1);
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 260px;

  & + & {
    margin-top: 15px;
  }
`;

export const Shipping = styled.div`
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.12px;

  @media (max-width: 1023px) {
    font-size: 12px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;
