import React from "react";
import styled from "styled-components";
import { components } from "react-select";

const OptionIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const BrandsOption = (props) => {
  const { icon } = props?.data;

  return (
    <components.Option {...props}>
      <OptionIconContainer>{icon}</OptionIconContainer>
    </components.Option>
  );
};

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M 12.364 4.509 L 11.491 3.636 L 8.451 6.676 L 7.551 6.676 L 4.509 3.638 L 3.636 4.509 L 6.709 7.58 L 6.709 8.424 L 3.636 11.492 L 4.509 12.365 L 7.578 9.296 L 8.422 9.296 L 11.491 12.365 L 12.364 11.492 L 9.324 8.452 L 9.324 7.552 L 12.364 4.509 Z"></path>
      </svg>
    </components.MultiValueRemove>
  );
};

export const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#8f95a6"
            d="M 15.508 10.932 L 14.333 12.105 L 8.397 6.159 L 7.912 6.159 L 1.975 12.105 L 0.801 10.932 L 7.223 4.5 L 9.085 4.5 L 15.508 10.932 Z"
          ></path>
        </svg>
      ) : (
        <svg width={16} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 0.801 5.673 L 1.976 4.5 L 7.912 10.446 L 8.397 10.446 L 14.334 4.5 L 15.508 5.673 L 9.086 12.105 L 7.224 12.105 L 0.801 5.673 Z"
            fill="#8f95a6"
          ></path>
        </svg>
      )}
    </components.DropdownIndicator>
  );
};

export const selectStyles = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: "100%",
    fontWeight: 400,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: 40,
    borderColor: "#c2c8d0",
    borderRadius: 5,
    boxShadow: "none",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: "#8f95a5",
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#ededed",
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    display: "flex",
    alignItems: "center",
    height: 24,
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    color: "#000",
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.13px",
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: state.isFocused && "#e9e9e9",
    "&:hover": {
      backgroundColor: "#e1e1e1",
    },
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    border: 0,
    borderRadius: 5,
    boxShadow: "0 3px 20px 0 rgba(0, 0, 0, 0.05)",
    zIndex: 2,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    display: "flex",
    flexWrap: "wrap",
    padding: "12px 4px",
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    width: 71.7,
    height: 40,
    margin: 4,
    padding: 0,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: state.isFocused ? "#cf2b23" : "#dcdfe9",
  }),
};
