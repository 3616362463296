import React from "react";

import { Root, Container, Grid } from "./ContentGrid.style";

import { NoResultsContainer } from "../NoResultsContainer/NoResultsContainer";
import { Loading } from "../Loading/Loading";

export const ContentGrid = ({
  loading,
  totalCount = 0,
  onResetFilters = null,
  children,
}) => {
  return (
    <Root>
      {loading ? (
        <Loading global={false} />
      ) : (
        <Container>
          {totalCount < 1 ? (
            <NoResultsContainer onResetFilters={onResetFilters} />
          ) : (
            <Grid>{children}</Grid>
          )}
        </Container>
      )}
    </Root>
  );
};
