const icons = {
  heart:
    "M16.182 4.5a4.778 4.778 0 0 0-3.771 1.852h-.822A4.781 4.781 0 0 0 7.818 4.5 4.864 4.864 0 0 0 3 9.4a4.934 4.934 0 0 0 1.234 3.268l7.435 6.832h.661l7.38-6.776.056-.057A4.937 4.937 0 0 0 21 9.4a4.866 4.866 0 0 0-4.818-4.9Zm2.492 7.12-5.781 5.307h-1.787L5.325 11.62A3.395 3.395 0 0 1 4.5 9.4a3.35 3.35 0 0 1 3.318-3.374 3.3 3.3 0 0 1 2.766 1.511l.223.341h2.388l.223-.341a3.298 3.298 0 0 1 2.766-1.511A3.35 3.35 0 0 1 19.5 9.4a3.406 3.406 0 0 1-.826 2.22Z",
  "heart-filled":
    "M16.182 4.5a4.778 4.778 0 0 0-3.771 1.852h-.822A4.781 4.781 0 0 0 7.818 4.5 4.864 4.864 0 0 0 3 9.4a4.934 4.934 0 0 0 1.234 3.268l7.435 6.832h.661l7.38-6.776.056-.057A4.937 4.937 0 0 0 21 9.4a4.866 4.866 0 0 0-4.818-4.9Z",
  basket:
    "M9.735 17.027h6.727l-.15.166c-1.088 1.196-.473 3.121 1.106 3.465 1.58.344 2.939-1.151 2.448-2.691a2.102 2.102 0 0 0-.447-.774l-.151-.167H20.7v-1.268H6.766v-.875l.792-.792H20.7V8.647L9.058 5.528l-.174-.023-2.018-.012h-.1v-2.2H3.3V4.56h2.2v12.465h1.43l-.151.168c-1.088 1.196-.473 3.121 1.106 3.465 1.58.344 2.939-1.151 2.448-2.691a2.102 2.102 0 0 0-.447-.774l-.151-.166Zm8.13 2.4a.833.833 0 1 1 .002-1.662.833.833 0 0 1-.002 1.66v.002Zm-9.532-.002a.833.833 0 1 1-.001-1.666.833.833 0 0 1 .001 1.666Zm11.1-6.599H6.766V6.764h.1l1.924.007h.022l10.616 2.844.005 3.211Z",
  "ellipsis-vertical":
    "M13.75 12a1.75 1.75 0 1 1-3.499.001A1.75 1.75 0 0 1 13.75 12Zm0-5.5a1.75 1.75 0 1 1-3.501-.001 1.75 1.75 0 0 1 3.501.001Zm0 11a1.75 1.75 0 1 1-3.501-.001 1.75 1.75 0 0 1 3.501.001Z",
  "add-to-cart":
    "M17.595 15.303h1.186v2.452l.186.186h2.453v1.187h-2.475l-.165.164v2.475h-1.185v-2.475l-.164-.164h-2.475v-1.187h2.453l.186-.186v-2.452ZM4.05 19.504a.465.465 0 0 1-.075-.006l-.305-.037a.761.761 0 0 1-.661-.85L4.257 7.938a.762.762 0 0 1 .756-.666.47.47 0 0 1 .075 0h.011a.24.24 0 0 1 .046-.005h2.16v-.942h.009c0-3.057 3.309-4.968 5.956-3.439a3.97 3.97 0 0 1 1.986 3.439v.122c.005.07.005.14 0 .21v.609h2.152a.193.193 0 0 1 .034 0h.023a.47.47 0 0 1 .075 0c.384 0 .708.285.756.666l.713 6.014h-1.842l-.576-4.85h-1.333v1.06a.764.764 0 0 1-.762.762h-.306a.765.765 0 0 1-.759-.75V9.096H9.15v.366h-.009v.694a.764.764 0 0 1-.762.762h-.306a.764.764 0 0 1-.761-.76v-.694h-.007v-.368H5.963l-.875 7.545.74 1.024h7.474v1.83h-9.13a.835.835 0 0 1-.122.009Zm5.1-13.168v.933h4.275v-.754c.005-.054.009-.112.009-.179a2.143 2.143 0 0 0-4.284 0Z",
  cup: "M7.278 20.5v-1.889h3.778v-2.928a5.035 5.035 0 0 1-2.066-.98 4.221 4.221 0 0 1-1.334-1.806 4.576 4.576 0 0 1-2.963-1.547A4.54 4.54 0 0 1 3.5 8.222v-.944c0-.52.185-.964.555-1.335a1.82 1.82 0 0 1 1.334-.554h1.889V3.5h9.444v1.889h1.889c.52 0 .964.185 1.335.554.369.371.554.815.554 1.335v.944c0 1.197-.398 2.24-1.192 3.128a4.58 4.58 0 0 1-2.964 1.547 4.227 4.227 0 0 1-1.333 1.806 5.045 5.045 0 0 1-2.067.98v2.928h3.778V20.5H7.278Zm0-9.633V7.278H5.389v.944c0 .598.173 1.137.519 1.617.347.481.803.823 1.37 1.028ZM12 13.889c.787 0 1.456-.276 2.007-.826a2.734 2.734 0 0 0 .826-2.007V5.389H9.167v5.667c0 .786.275 1.456.826 2.007.551.55 1.22.826 2.007.826Zm4.722-3.022a2.838 2.838 0 0 0 1.37-1.028c.346-.48.519-1.019.519-1.617v-.944h-1.889v3.589ZM12 9.639Z",
  box: "M12.001 20.058a.472.472 0 0 1-.162-.028.104.104 0 0 1-.048-.015l-8.425-2.81A.529.529 0 0 1 3 16.7V7.297c.002-.05.008-.1.021-.15a.382.382 0 0 1 .093-.169.235.235 0 0 1 .094-.108.06.06 0 0 1 .034-.021l.041-.021a.138.138 0 0 1 .062-.03h.011l.052-.02 8.42-2.805a.437.437 0 0 1 .163-.031h.018a.38.38 0 0 1 .075.006l.036.012.05.017.057.019 8.34 2.78h.012l.059.023h.016a.161.161 0 0 1 .058.026.192.192 0 0 1 .074.044.37.37 0 0 1 .107.109.393.393 0 0 1 .075.15.47.47 0 0 1 .032.17v9.1l-.371.145-8.456 3.481a.531.531 0 0 1-.172.034Zm.542-9.554v8.271l7.386-3.087v-7.65l-3.49 1.164a.218.218 0 0 1-.08.023l-3.816 1.279Zm-8.462 5.812 7.385 2.465v-8.277L4.081 8.038v8.278Zm1.148-9.019 6.779 2.263 2.546-.851-6.778-2.264-2.547.852Zm4.233-1.41L16.24 8.15l2.548-.852-6.779-2.263-2.547.852Z",
  user: "M15.121 13.554v-.561A4.924 4.924 0 0 0 17.207 9 5.112 5.112 0 0 0 12 4.001a5.11 5.11 0 0 0-5.207 5 4.92 4.92 0 0 0 2.084 3.994v.561A7.1 7.1 0 0 0 3.667 20h1.39c0-3.102 3.113-5.623 6.943-5.623 3.831 0 6.944 2.522 6.944 5.623h1.39a7.103 7.103 0 0 0-5.213-6.446Zm-6.94-4.553a3.75 3.75 0 0 1 3.818-3.666 3.75 3.75 0 0 1 3.819 3.666 3.751 3.751 0 0 1-3.819 3.667 3.75 3.75 0 0 1-3.818-3.667Z",
  message:
    "M9.049 19.072H6.857V16.5H3V4.929h18V16.5h-9.379l-2.572 2.572Zm-.909-1.286h.377l2.571-2.571h8.624v-9H4.283v9H8.14v2.571Z",
  "arrow-right-from-bracket":
    "M20 11.05v1.901l-3.742 3.752-.943-.944 2.553-2.553-.532-.531h-6.665V11.34h6.669l.534-.535-2.559-2.56.943-.941L20 11.05ZM5.335 18.004l.666.668h6.003v1.333H4V3.996h8.003v1.335H6l-.666.666.001 12.007Z",
  "arrow-left-long":
    "m9.1 17.11-4.809-4.027.578-.484H22v-1.198H4.842l-.563-.472L9.1 6.891l-1.01-.846L2 11.148v1.706l6.093 5.1L9.1 17.11Z",
  "arrow-right-long":
    "m14.9 6.889 4.809 4.027-.578.484H2v1.198h17.158l.563.472-4.821 4.038 1.01.846L22 12.851v-1.706l-6.093-5.1-1.007.844Z",
  "arrow-up-to-line":
    "M4.688 5.047h15.216V6.43H4.688V5.047Zm8.502 5.439-.541.542v9.925h-1.326v-9.955l-.514-.514-5.871 5.878L4 15.429l7.055-7.067h1.89L20 15.429l-.939.933-5.871-5.876Z",
  eye: "M20.948 11.757c-.026-.06-.662-1.468-2.073-2.881C16.992 6.995 14.616 6 12 6c-2.616 0-4.993.995-6.874 2.876-1.413 1.413-2.051 2.824-2.074 2.881a.601.601 0 0 0 0 .488c.026.059.661 1.467 2.074 2.88C7.007 17.005 9.384 18 12 18c2.616 0 4.992-.995 6.875-2.875 1.411-1.413 2.047-2.821 2.073-2.88a.593.593 0 0 0 0-.488ZM12 16.8c-2.309 0-4.326-.839-5.994-2.494A9.993 9.993 0 0 1 4.275 12a9.971 9.971 0 0 1 1.731-2.306C7.674 8.04 9.691 7.2 12 7.2c2.309 0 4.326.84 5.995 2.494A9.965 9.965 0 0 1 19.728 12c-.54 1.009-2.896 4.8-7.728 4.8Zm0-8.4c-2.771 0-4.503 3-3.118 5.4 1.386 2.4 4.85 2.4 6.236 0A3.604 3.604 0 0 0 12 8.4Zm0 6c-1.847 0-3.002-2-2.079-3.6.925-1.6 3.234-1.6 4.157 0A2.4 2.4 0 0 1 12 14.4Z",
  "eye-slash":
    "M6.444 4.997a.6.6 0 1 0-.888.807l1.443 1.588c-2.724 1.671-3.895 4.248-3.947 4.365a.601.601 0 0 0 0 .488c.026.059.661 1.468 2.074 2.88C7.007 17.006 9.384 18 12 18a9.53 9.53 0 0 0 3.905-.812l1.65 1.816a.6.6 0 1 0 .888-.807L6.444 4.997Zm3.55 5.688 3.125 3.439c-1.635.86-3.588-.373-3.516-2.219.018-.434.152-.856.391-1.22ZM12 16.8c-2.308 0-4.325-.839-5.995-2.493A9.98 9.98 0 0 1 4.275 12c.352-.659 1.475-2.504 3.551-3.703l1.35 1.481c-1.705 2.184-.407 5.396 2.338 5.781a3.6 3.6 0 0 0 2.437-.531l1.105 1.215A8.4 8.4 0 0 1 12 16.8Zm.45-7.157a.6.6 0 1 1 .225-1.179 3.612 3.612 0 0 1 2.908 3.198.6.6 0 0 1-.542.653.262.262 0 0 1-.056 0 .6.6 0 0 1-.6-.544 2.408 2.408 0 0 0-1.935-2.128Zm8.496 2.602c-.031.07-.791 1.753-2.502 3.285a.6.6 0 1 1-.8-.894A9.96 9.96 0 0 0 19.729 12a9.996 9.996 0 0 0-1.734-2.307C16.325 8.04 14.309 7.2 12 7.2c-.486 0-.972.039-1.452.118a.6.6 0 1 1-.198-1.183C10.895 6.045 11.447 6 12 6c2.616 0 4.993.995 6.875 2.877 1.412 1.412 2.047 2.821 2.073 2.88a.601.601 0 0 1 0 .488h-.002Z",
  "chevron-down":
    "m3.5 8.96 1.358-1.355 6.862 6.873h.561l6.862-6.873L20.5 8.96l-7.423 7.435h-2.153L3.5 8.96Z",
  "chevron-right":
    "m8.96 20.5-1.355-1.358 6.873-6.862v-.561L7.605 4.857 8.96 3.5l7.435 7.423v2.153L8.96 20.5Z",
  "info-circle":
    "M12.006 3.506c-6.538 0-10.625 7.078-7.355 12.74 3.269 5.663 11.442 5.663 14.711 0a8.493 8.493 0 0 0-7.356-12.74Zm0 3.767c1.107 0 1.798 1.2 1.243 2.157a1.438 1.438 0 1 1-1.243-2.157Zm1.917 8.699a.41.41 0 0 1-.41.41h-3.011a.41.41 0 0 1-.411-.41v-.823a.41.41 0 0 1 .411-.407h.41v-2.195h-.41a.41.41 0 0 1-.411-.41v-.823a.41.41 0 0 1 .411-.41h2.188a.41.41 0 0 1 .411.41v3.428h.41a.41.41 0 0 1 .411.41l.001.82Z",
  "trash-can":
    "m19.087 7.75-.71.709V20.5H5.627V8.459l-.709-.709H3.5V6.334h3.542l.709-.709V3.5h8.5v2.125l.709.709h3.54V7.75h-1.413ZM9.168 6.334h5.667V4.917H9.168v1.417ZM16.96 7.75H7.043v11.334h9.917V7.75Zm-8.5 1.37h1.416v8.546H8.46V9.12Zm5.666 0h1.416v8.546h-1.416V9.12Zm-2.834 0h1.417v8.546h-1.417V9.12Z",
  plus: "m13.92 11.055-.977-.975V3.5h-1.887v6.58l-.975.975H3.5v1.889h6.643l.913.913V20.5h1.889v-6.643l.914-.913H20.5v-1.889h-6.58Z",
  minus:
    "M 20.5 11.056 L 3.5 11.056 L 3.5 12.945 L 20.5 12.945 L 20.5 11.056 Z",
  zoom: "M16.843 17.88h-1c-4.099 2.841-9.769 1.315-11.887-3.201C1.321 9.062 5.755 2.7 11.936 3.227c6.181.526 9.475 7.547 5.928 12.637v.965l2.916 2.916-1.035 1.034Zm-5.576-.029a6.582 6.582 0 0 0 3.292-.882c4.389-2.534 4.389-8.87 0-11.404-4.389-2.534-9.876.634-9.876 5.702a6.59 6.59 0 0 0 6.584 6.584Zm1.213-7.318h2.597v1.3h-2.593l-.648.648v2.597h-1.3v-2.593l-.648-.648H7.295v-1.3h2.589l.648-.648V7.296h1.3v2.592Z",
  share:
    "M17.668 14.852a2.811 2.811 0 0 0-2.715 2.123H13.42l-.705-.707V7.77l.694-.695h1.551c.555 2.095 3.17 2.804 4.708 1.275 1.537-1.528.842-4.148-1.25-4.715a2.817 2.817 0 0 0-3.465 2.025h-3.651v4.935l-.726.726H9.073c-.545-2.11-3.17-2.839-4.724-1.313-1.555 1.528-.874 4.164 1.225 4.748a2.831 2.831 0 0 0 3.499-2.02h1.535l.694.695v4.953h3.658c.562 2.087 3.172 2.782 4.698 1.252 1.526-1.531.824-4.139-1.264-4.696a2.832 2.832 0 0 0-.727-.094l.001.006Zm0-9.905a1.402 1.402 0 0 1 1.218 2.101 1.402 1.402 0 1 1-1.218-2.102v.001ZM6.349 13.449a1.415 1.415 0 1 1 0-2.827 1.415 1.415 0 0 1-.001 2.826l.001.001Zm11.319 5.625a1.402 1.402 0 0 1-1.218-2.101 1.401 1.401 0 1 1 1.218 2.1v.001Z",
  play: "M3.469 1.5v21h2.48l14.581-8.287V9.792L6.121 1.5H3.469Zm14.172 9.987v1.018l-10.92 6.203-.625-.71V6.001l.668-.767 10.877 6.253Z",
  settings:
    "M8.458 11.217H20.5v1.566H7.75v2.351H6.333v-1.567l-.708-.784H3.5v-1.566h2.833V8.868H7.75v1.565l.708.784Zm7.792 5.367h4.25v1.567h-4.958v2.35h-1.417v-1.566l-.708-.784H3.5v-1.567h10.625v-2.349h1.417v1.567l.708.782ZM20.5 5.849v1.567h-2.125v2.348h-1.417V8.199l-.708-.783H3.5V5.849h13.458V3.5h1.417v1.565l.708.784H20.5Z",
  certificate:
    "m21.706 13.75.037.036a.93.93 0 0 1-.453 1.546l-2.447.624.69 2.422a.946.946 0 0 1-1.159 1.159l-2.42-.69-.625 2.447-.008.027a.932.932 0 0 1-1.567.389l-1.757-1.797-1.757 1.792a.928.928 0 0 1-1.574-.416l-.625-2.447-2.421.69a.946.946 0 0 1-1.158-1.159l.69-2.423-2.447-.625-.038-.01a.93.93 0 0 1-.378-1.565L4.085 12l-1.796-1.761-.028-.027a.93.93 0 0 1 .448-1.547l2.447-.625-.69-2.422A.945.945 0 0 1 5.624 4.46l2.417.688.625-2.446.003-.011a.937.937 0 0 1 1.571-.406l1.757 1.81 1.757-1.81.009-.008a.937.937 0 0 1 1.566.428l.625 2.447 2.42-.69a.946.946 0 0 1 1.159 1.159l-.69 2.421 2.447.625.038.011a.93.93 0 0 1 .378 1.565L19.91 12l1.796 1.75Zm-10.785-.506h-.036l-2.213-2.27-1.16 1.19 2.897 2.973h.987l5.085-5.215-1.16-1.19-4.4 4.512Z",
  instagram:
    "M12.007 6.622c-4.141 0-6.728 4.482-4.658 8.067 2.07 3.584 7.244 3.584 9.314 0A5.377 5.377 0 0 0 17.384 12a5.371 5.371 0 0 0-5.377-5.378Zm0 8.873c-2.693 0-4.375-2.914-3.029-5.245s4.71-2.331 6.056 0c.307.531.47 1.135.47 1.749a3.504 3.504 0 0 1-3.497 3.496Zm6.854-9.096a1.255 1.255 0 1 1-1.255-1.255 1.252 1.252 0 0 1 1.25 1.258l.005-.003Zm3.563 1.274a6.215 6.215 0 0 0-1.695-4.397 6.254 6.254 0 0 0-4.396-1.695c-1.733-.098-6.925-.098-8.657 0a6.249 6.249 0 0 0-4.401 1.692A6.23 6.23 0 0 0 1.581 7.67c-.099 1.733-.099 6.924 0 8.657a6.201 6.201 0 0 0 1.694 4.397 6.26 6.26 0 0 0 4.397 1.694c1.732.099 6.924.099 8.657 0a6.217 6.217 0 0 0 4.396-1.694 6.258 6.258 0 0 0 1.695-4.397c.098-1.733.098-6.92 0-8.657l.004.003Zm-2.238 10.51a3.542 3.542 0 0 1-1.995 1.995c-1.381.548-4.659.421-6.184.421-1.526 0-4.809.122-6.185-.421a3.536 3.536 0 0 1-1.995-1.995c-.548-1.381-.421-4.659-.421-6.184 0-1.526-.122-4.809.421-6.185a3.543 3.543 0 0 1 1.99-1.992c1.381-.549 4.659-.421 6.19-.421 1.53 0 4.808-.122 6.184.421a3.542 3.542 0 0 1 1.995 1.995c.548 1.381.421 4.659.421 6.184 0 1.526.122 4.806-.426 6.185l.005-.003Z",
  tiktok:
    "M12.459 1.525c1.145-.017 2.282-.008 3.419-.017a5.446 5.446 0 0 0 1.531 3.646 6.163 6.163 0 0 0 3.708 1.565v3.525a9.364 9.364 0 0 1-3.673-.849 10.741 10.741 0 0 1-1.416-.813c-.009 2.553.009 5.106-.017 7.65a6.68 6.68 0 0 1-1.181 3.446 6.515 6.515 0 0 1-5.168 2.807 6.382 6.382 0 0 1-3.567-.901 6.59 6.59 0 0 1-3.193-4.992 16.683 16.683 0 0 1-.009-1.303 6.583 6.583 0 0 1 7.639-5.842c.017 1.295-.035 2.588-.035 3.883a3 3 0 0 0-3.83 1.853 3.463 3.463 0 0 0-.128 1.409 2.975 2.975 0 0 0 3.06 2.509 2.941 2.941 0 0 0 2.423-1.407c.194-.274.317-.593.358-.928.088-1.564.053-3.121.062-4.686.009-3.525-.009-7.04.017-10.555Z",
  youtube:
    "M22.055 6.933a2.635 2.635 0 0 0-1.857-1.868A62.718 62.718 0 0 0 12 4.624a62.746 62.746 0 0 0-8.198.441 2.639 2.639 0 0 0-1.857 1.868 27.771 27.771 0 0 0-.438 5.083 27.76 27.76 0 0 0 .438 5.082 2.598 2.598 0 0 0 1.857 1.836c2.721.327 5.461.475 8.199.441a62.365 62.365 0 0 0 8.197-.441 2.594 2.594 0 0 0 1.856-1.836c.303-1.677.45-3.379.438-5.082a27.51 27.51 0 0 0-.438-5.083h.001ZM9.857 15.135v-6.24l5.488 3.121-5.491 3.119h.003Z",
  facebook:
    "m16.883 13.319.583-3.804h-3.644V7.056a1.9 1.9 0 0 1 2.142-2.051h1.656V1.777a20.253 20.253 0 0 0-2.94-.263A4.638 4.638 0 0 0 9.716 6.63v2.885H6.38v3.804h3.336V22.5h4.106v-9.181h3.061Z",
  search:
    "M 19.503 20.493 L 20.5 19.496 L 17.685 16.677 L 17.685 15.743 C 21.109 10.827 17.928 4.045 11.957 3.536 C 5.985 3.029 1.704 9.177 4.25 14.601 C 6.297 18.962 11.773 20.436 15.734 17.692 L 16.703 17.692 L 19.503 20.493 Z M 4.954 11.305 C 4.954 6.409 10.254 3.349 14.494 5.797 C 18.734 8.245 18.734 14.365 14.494 16.813 C 10.254 19.262 4.954 16.201 4.954 11.305 Z",
  mail: "M4.562 6.687v10.625h14.875V6.687H4.562Zm8.28 5.844h-1.685L6.376 7.75h11.248l-4.782 4.781Zm-4.12-.933v.804l-3.097 3.097V8.501l3.097 3.097Zm1.153 1.153.842.842h2.565l.843-.842 3.499 3.499H6.376l3.499-3.499Zm5.404-.349v-.805l3.096-3.096v6.998l-3.096-3.097Z",
  pinterest:
    "M12.365 4.408c-3.128 0-6.209 2.082-6.209 5.452 0 2.138 1.205 3.359 1.936 3.359.302 0 .475-.84.475-1.077 0-.283-.721-.886-.721-2.063a4.112 4.112 0 0 1 4.273-4.182c2.073 0 3.606 1.177 3.606 3.338 0 1.616-.648 4.647-2.749 4.647a1.35 1.35 0 0 1-1.401-1.329c0-1.15.803-2.264.803-3.451 0-2.018-2.858-1.65-2.858.785-.018.53.082 1.057.292 1.543a33.345 33.345 0 0 0-1.275 6.365c0 .574.082 1.141.137 1.717.107.115.052.106.213.045 1.519-2.1 1.469-2.511 2.163-5.26a2.473 2.473 0 0 0 2.109 1.101c3.233 0 4.685-3.156 4.685-5.995a5.18 5.18 0 0 0-5.479-4.995Z",
  x: "M16.097 5.625h2.164l-4.753 5.41 5.552 7.34h-4.356l-3.412-4.462-3.908 4.462H5.223l5.03-5.786-5.312-6.964h4.462L12.484 9.7l3.613-4.075ZM15.338 17.1h1.2L8.775 6.848H7.491L15.338 17.1Z",
  link: "m16.121 12.497-1.298 1.298a3.266 3.266 0 0 1-4.618 0l.753-.752c.859.86 2.254.86 3.114 0l1.298-1.298 1.294-1.298a2.203 2.203 0 0 0 .618-2.296c-.514-1.616-2.583-2.071-3.727-.818l-1.558 1.558-.751-.751 1.557-1.559a3.264 3.264 0 0 1 3.226-.863c2.419.683 3.192 3.728 1.391 5.481Zm-8.24-.994 1.298-1.299a3.266 3.266 0 0 1 4.618 0l-.753.752a2.202 2.202 0 0 0-3.114 0l-1.298 1.298-1.299 1.295a2.202 2.202 0 0 0-.59 2.206c.474 1.628 2.532 2.132 3.705.908l1.558-1.555.751.751-1.558 1.559a3.264 3.264 0 0 1-3.154.844c-2.428-.65-3.241-3.684-1.464-5.462Z",
  globe:
    "M12.008 3.007c-6.924 0-11.251 7.494-7.789 13.49 3.461 5.998 12.114 5.998 15.577 0A9.001 9.001 0 0 0 21 11.999a9.002 9.002 0 0 0-8.992-8.992Zm7.707 8.992c0 .877-.152 1.745-.451 2.57H16.32c.123-.85.184-1.709.183-2.57.001-.859-.06-1.716-.183-2.568h2.944c.299.824.451 1.693.451 2.568Zm-7.707 7.709c-.941 0-1.943-1.212-2.572-3.211l.644-.644h3.853l.643.644c-.625 2.002-1.629 3.211-2.568 3.211Zm-3.019-5.139a17.15 17.15 0 0 1 0-5.138h6.039c.131.85.195 1.709.189 2.568.002.862-.062 1.721-.194 2.57H8.989Zm-4.238 0c-.6-1.66-.6-3.478 0-5.138h2.941a18.197 18.197 0 0 0 0 5.138H4.751Zm7.257-10.277c.939 0 1.943 1.211 2.568 3.211l-.643.642H10.08l-.644-.642c.629-2 1.631-3.211 2.572-3.211Zm6.323 3.312-.543.541h-1.709a10.219 10.219 0 0 0-1.359-3.35 7.736 7.736 0 0 1 3.611 2.81v-.001ZM9.292 4.796a10.266 10.266 0 0 0-1.359 3.349H6.225l-.542-.541a7.736 7.736 0 0 1 3.609-2.808ZM5.683 16.399l.542-.546h1.708c.251 1.19.711 2.327 1.359 3.355a7.738 7.738 0 0 1-3.609-2.812v.003Zm9.037 2.809a10.245 10.245 0 0 0 1.359-3.355h1.709l.543.543a7.733 7.733 0 0 1-3.611 2.812Z",
};

export default icons;
