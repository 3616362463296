import styled from "styled-components";
import { motion } from "framer-motion";

export const Root = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const HeaderActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const HeaderAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  color: ${(p) => (p.$isActive ? "var(--color-accent)" : "#000")};
  font-size: 12px;
  font-weight: 500;
  border-top: 1px solid #edeff4;
  border-bottom: 1px solid #edeff4;

  & + & {
    border-left: 1px solid #edeff4;
  }

  svg {
    margin-right: 8px;
    font-size: 16px;
  }
`;

export const SortBySelect = styled.select`
  padding-right: 22px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath d='m.649 4.753 1.058-1.055 5.1 5.11h.387l5.1-5.11 1.057 1.056-5.539 5.548H6.187L.649 4.753Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: 14px;
  text-align: right;
  border: 0 none;
  appearance: none;
`;

export const ModalContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 152px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 900;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 0 24px;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
`;

export const AppendContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearButton = styled.button`
  color: var(--color-accent);
  letter-spacing: 0.3px;
  text-decoration: underline;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const Container = styled.div`
  padding: 0 24px;
  overflow-y: auto;
`;

export const FieldContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const SubmitContainer = styled.div`
  margin-top: 36px;
`;

export const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.12px;
  text-align: center;
  text-decoration: underline;
`;
