import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

import {
  Root,
  Close,
  Container,
  ImageContainer,
  ImagesList,
  ImagesItem,
  ArrowButton,
  Footer,
  FooterActions,
} from "./Lightbox.style";

import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

import { Icon } from "../../ui/Icon/Icon";
import { Typography } from "../../ui/Typography/Typography";

export const Lightbox = ({ images = [], initialImage = 0, onClose }) => {
  useLockBodyScroll();
  const [currentSlide, setCurrentSlide] = useState(initialImage);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 && onClose) onClose();
    if (event.keyCode === 37 || event.keyCode === 38) handlePrevClick();
    if (event.keyCode === 39 || event.keyCode === 40) handleNextClick();
  };

  const handlePrevClick = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNextClick = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return createPortal(
    <Root tabIndex={-1} role="dialog" aria-modal>
      <Close onClick={onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M4 28.9a.9.9 0 0 1-.636-1.536L14.678 16.05 3.366 4.825a.901.901 0 0 1 1.268-1.28l11.318 11.231L27.364 3.364a.9.9 0 0 1 1.273 1.273L17.23 16.044l11.404 11.317a.9.9 0 0 1-1.268 1.278l-11.41-11.321L4.637 28.637A.896.896 0 0 1 4 28.9Z" />
        </svg>
      </Close>

      <Container>
        <ImagesList>
          {images.map(({ id, image }, index) => (
            <ImagesItem
              key={id}
              className={index === currentSlide ? "--active" : ""}
              type="button"
              onClick={() => setCurrentSlide(index)}
            >
              <img src={image} alt="" />
            </ImagesItem>
          ))}
        </ImagesList>

        <ImageContainer>
          <img src={images[currentSlide].image} alt="" />
        </ImageContainer>
        <ImagesList />

        <Footer>
          <Typography type="text-primary">
            {currentSlide + 1} of {images.length}
          </Typography>
          <FooterActions>
            <ArrowButton
              type="button"
              disabled={currentSlide === 0}
              onClick={handlePrevClick}
            >
              <Icon icon="arrow-left-long" />
            </ArrowButton>
            <ArrowButton
              type="button"
              disabled={currentSlide === images.length - 1}
              onClick={handleNextClick}
            >
              <Icon icon="arrow-right-long" />
            </ArrowButton>
          </FooterActions>
        </Footer>
      </Container>
    </Root>,
    document.body
  );
};
