import React from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";

const ToastProvider = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    padding: 0 28px;
  }

  &&&.Toastify__toast-container--bottom-center {
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1023px) {
      bottom: 24px;
    }

    @media (min-width: 1024px) {
      bottom: 16px;
    }
  }

  .Toastify__toast {
    max-width: 600px;
    min-height: auto;
    margin-bottom: 0;
    padding: 7px 16px;
    font-family: "Yellix";
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    border-radius: 16px;

    a,
    button {
      text-decoration: underline;
    }
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast--success {
    color: #3b3b3b;
    background-color: #a2ecba;
  }

  .Toastify__toast--error {
    color: #fff;
    background-color: #e1000f;
  }
`;

const ToastNotification = ({ closeToast, children }) => {
  return (
    <>
      {children}{" "}
      <button type="button" onClick={closeToast}>
        Dismiss
      </button>
    </>
  );
};

export { ToastProvider, ToastNotification, toast };
