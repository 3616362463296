import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  ImageContainer,
  ImageLink,
  RemoveButton,
  OutOfStock,
  TextContainer,
  CtaContainer,
} from "./FavouritesItem.style";

import { Link } from "../Link/Link";
import { Typography } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import { Icon } from "../../ui/Icon/Icon";

export const FavouritesItem = ({
  imageSrc = "",
  brandName = "",
  brandUrl = "/",
  productName = "",
  productUrl = "/",
  pts = 0,
  oldPts,
  outOfStock = false,
  onRemove = null,
  onAddCart = null,
}) => {
  return (
    <Root>
      <ImageContainer>
        <ImageLink
          className={outOfStock && "--disabled"}
          as={Link}
          to={productUrl}
        >
          <img src={imageSrc} alt={productName} />
        </ImageLink>
        {outOfStock && (
          <OutOfStock>
            <Typography type="tags">
              <FormattedMessage defaultMessage="OUT OF STOCK" />
            </Typography>
          </OutOfStock>
        )}
        <RemoveButton onClick={onRemove}>
          <Icon icon="trash-can" />
        </RemoveButton>
      </ImageContainer>

      <TextContainer className={outOfStock && "--disabled"}>
        <Typography as={Link} to={brandUrl} type="brand-primary">
          {brandName}
        </Typography>
        <Typography
          as={Link}
          to={productUrl}
          type="h4"
          style={{ maxWidth: 210 }}
        >
          {productName}
        </Typography>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          {oldPts && (
            <Typography
              type="points-secondary"
              style={{ marginRight: 6, textDecoration: "line-through" }}
            >
              {oldPts.toLocaleString("en-US")}{" "}
              <FormattedMessage defaultMessage="PTS" />
            </Typography>
          )}
          <Typography type="points-primary" color={oldPts ? "#e1000f" : "#000"}>
            {pts.toLocaleString("en-US")}{" "}
            <FormattedMessage defaultMessage="PTS" />
          </Typography>
        </div>
        {!outOfStock && (
          <CtaContainer>
            <Button variant="action" arrow={false} onClick={onAddCart}>
              <Icon icon="add-to-cart" />
              <FormattedMessage defaultMessage="move to cart" tagName="span" />
            </Button>
          </CtaContainer>
        )}
      </TextContainer>
    </Root>
  );
};
