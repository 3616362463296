import React from "react";
import { FormattedMessage } from "react-intl";

import { NoResultsContainer } from "./Dashboard.style";

import { Typography } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import { Link } from "../Link/Link";

export const NoResults = () => {
  return (
    <NoResultsContainer>
      <svg xmlns="http://www.w3.org/2000/svg" width="66.75" height="64">
        <path
          fill="#dbdfea"
          stroke="#fff"
          strokeWidth=".5"
          d="m40.348 48.999-11.58 3.85V27.323l11.751-3.936a.41.41 0 0 0 .2-.057l10.867-3.622v14.574h2.852V17.739a1.28 1.28 0 0 0-.086-.457 1.043 1.043 0 0 0-.2-.4.914.914 0 0 0-.285-.285.463.463 0 0 0-.2-.114c-.057-.057-.143-.057-.2-.086l-.143-.057h-.028L27.969 7.897l-.171-.057a1.128 1.128 0 0 0-.428-.086h-.057a1.128 1.128 0 0 0-.428.086l-.171.057-12.517 4.168-.314.114-12.521 4.164-.143.057c-.057.028-.143.028-.2.086l-.171.086a.028.028 0 0 0-.028.028.512.512 0 0 0-.257.285 1.011 1.011 0 0 0-.257.456 1.694 1.694 0 0 0-.057.4v28.524a1.386 1.386 0 0 0 .969 1.34l25.584 8.528a.1.1 0 0 0 .086.028 1.257 1.257 0 0 0 .913 0l.086-.028 13.376-4.45-.913-2.681Zm-13-38.334 21.192 7.073-8.357 2.8-21.195-7.073Zm-1.426 42.183L3.104 45.233V19.708l22.818 7.615Zm1.426-28.037L6.156 17.738l8.357-2.8 21.192 7.074Z"
        />
        <g fill="#dbdfea">
          <path d="M53.25 57.25a12.17 12.17 0 0 1-8.662-3.588A12.17 12.17 0 0 1 41 45a12.17 12.17 0 0 1 3.588-8.662 12.17 12.17 0 0 1 8.662-3.588 12.17 12.17 0 0 1 8.662 3.588A12.17 12.17 0 0 1 65.5 45a12.17 12.17 0 0 1-3.588 8.662 12.17 12.17 0 0 1-8.662 3.588Z" />
          <path
            fill="#fff"
            d="M53.25 34c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11m0-2.5c3.606 0 6.996 1.404 9.546 3.954A13.412 13.412 0 0 1 66.75 45c0 3.606-1.404 6.996-3.954 9.546A13.412 13.412 0 0 1 53.25 58.5a13.412 13.412 0 0 1-9.546-3.954A13.412 13.412 0 0 1 39.75 45c0-3.606 1.404-6.996 3.954-9.546A13.412 13.412 0 0 1 53.25 31.5Z"
          />
        </g>
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth=".5"
          d="m58.826 40.538-1.115-1.115-3.886 3.886h-1.153l-3.885-3.886-1.116 1.115 3.923 3.923v1.078l-3.923 3.924 1.115 1.115 3.923-3.923h1.079l3.923 3.923 1.115-1.115-3.882-3.886v-1.153Z"
        />
      </svg>
      <Typography
        type="text-small"
        color="#8f95a5"
        style={{ marginTop: 28, marginBottom: 13 }}
      >
        <FormattedMessage defaultMessage="No results yet" />
      </Typography>
      <Button as={Link} to="/rewards">
        <FormattedMessage defaultMessage="shop rewards" />
      </Button>
    </NoResultsContainer>
  );
};
