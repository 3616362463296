import { useEffect } from "react";

const useScript = (url, attributes = {}, onUnload) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    Object.keys(attributes).forEach((attributeKey) => {
      script.setAttribute(attributeKey, attributes[attributeKey]);
    });

    document.body.appendChild(script);

    return () => {
      if (typeof onUnload === "function") onUnload();
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
