import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Container,
  ContentContainer,
  ImageContainer,
  Image,
  TextContainer,
  CtaContainer,
} from "./EarnRedeemPoints.style";

import phoneSrc from "../../../assets/images/smartphone@x2.png";

import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";
import { UserContext } from "../../../context/UserContext";
import { Link } from "../../Link/Link";

export const EarnRedeemPoints = () => {
  const { user } = useContext(UserContext);

  return (
    <Root>
      <Container>
        <ContentContainer>
          <ImageContainer>
            <Image src={phoneSrc} alt="" />
          </ImageContainer>

          <TextContainer>
            <Typography as="h2" type="h2" style={{ maxWidth: 320 }}>
              <FormattedMessage defaultMessage="Elevate your craft with Club H: Earn & redeem points for top brands" />
            </Typography>
            <Typography as="p" type="text-primary" style={{ marginTop: 16 }}>
              <FormattedMessage defaultMessage="Are you ready to sculpt your success with unmatched perks such as top-tier education, broad selection of products, and exclusive event invites? Join Club H and transform every point into a step towards the forefront of hairstyling excellence." />
              {/* <br />
              <br />
              <FormattedMessage
                defaultMessage="Mark the date: Club H registration opens{br}January 1, 2024."
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              /> */}
            </Typography>
            <CtaContainer>
              {user ? (
                <Button as={Link} to="/terms">
                  <FormattedMessage defaultMessage="Terms & Conditions" />
                </Button>
              ) : (
                <Button as={Link} to="/auth/register">
                  <FormattedMessage defaultMessage="join now" />
                </Button>
              )}
            </CtaContainer>
          </TextContainer>
        </ContentContainer>
      </Container>
    </Root>
  );
};
