import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useController } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import request from "superagent";

import { apiDomain, apiSiteId } from "../../config";

import {
  Root,
  AvatarContainer,
  ProgressContainer,
  Progress,
  UploadActions,
  UploadButton,
  DeleteButton,
  UploadAcceptedInfo,
} from "./AvatarUploader.style";

import { Icon } from "../../ui/Icon/Icon";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../ui/Tooltip/Tooltip";

// function makePreview(files) {
//   return files.map((file) =>
//     Object.assign(file, {
//       preview: URL.createObjectURL(file),
//     })
//   );
// }

export const AvatarUploader = ({
  control,
  maxSize = 6000000,
  showAcceptedFilesInfo = false,
  ...props
}) => {
  const [file, setFile] = useState();
  // const { fields, append, replace, remove } = useFieldArray({
  //   control,
  //   name: "avatar",
  // });

  const {
    field,
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name: "avatar",
    control,
  });

  const handleUpload = (uploadFile) => {
    const req = request
      .post(`https://${apiDomain}/${apiSiteId}/upload`)
      .withCredentials()
      .field("file", uploadFile);

    setFile((prevFile) => ({
      ...prevFile,
      status: "uploading",
    }));

    req.on("progress", (event) => {
      const precentUploaded = Math.round((event.loaded * 100) / event.total);
      setFile((prevFile) => ({
        ...prevFile,
        precentUploaded,
      }));
    });

    req.end((err, res) => {
      if (err) {
        console.error(err);
        setFile((prevFile) => ({
          ...prevFile,
          status: "error",
        }));
        return;
      }
      if (res.body instanceof Object) {
        field.onChange({ media: res.body._id, focalSelector: "CC" });
        setFile((prevFile) => ({
          ...prevFile,
          status: "uploaded",
        }));
      }
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/png": [".png", ".PNG"],
      "image/jpeg": [".jpeg", ".jpg", ".JPEG", ".JPG"],
    },
    maxFiles: 1,
    maxSize,
    onDrop: (acceptedFiles) => {
      if (file?.preview != null) {
        URL.revokeObjectURL(file.preview);
      }
      field.onChange(null);
      setFile({
        file: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
        status: "initialized",
        precentUploaded: 0,
      });
      handleUpload(acceptedFiles[0]);
    },
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    return () => {
      if (file?.preview != null) {
        URL.revokeObjectURL(file.preview);
      }
    };
  }, []);

  return (
    <Root {...props}>
      <AvatarContainer
        className={file?.status === "uploading" && "--uploading"}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {file?.status === "uploading" && (
          <ProgressContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1 -1 34 34"
              width={100}
              height={100}
              style={{ transform: "rotate(-90deg)" }}
            >
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                fill="none"
                stroke="#fff"
                strokeDasharray="100 100"
                strokeLinecap="round"
                strokeWidth="2.5"
                strokeDashoffset={100 - file?.precentUploaded}
                style={{ transition: "stroke-dashoffset 250ms ease-in-out" }}
              />
            </svg>
            <Progress>{file?.precentUploaded}%</Progress>
          </ProgressContainer>
        )}
        {(file?.preview != null || field.value?.media) && (
          <img
            src={
              file?.preview
                ? file.preview
                : `https://${apiDomain}/${apiSiteId}/asset/${field.value?.media}?width=125&height=125&type=fill`
            }
            alt="avatar"
          />
        )}
        {file == null && <Icon icon="user" />}
      </AvatarContainer>

      <UploadActions>
        {file == null ? (
          <UploadButton
            type="button"
            onClick={open}
            disabled={file?.status === "uploading"}
          >
            <Icon icon="arrow-up-to-line" style={{ fontSize: 20 }} />
            <span>
              <FormattedMessage defaultMessage="Upload avatar" />
            </span>
          </UploadButton>
        ) : (
          <>
            <DeleteButton
              type="button"
              onClick={open}
              disabled={file?.status === "uploading"}
            >
              <Icon icon="arrow-up-to-line" />
            </DeleteButton>
            <Tooltip placement="top-start">
              <TooltipTrigger>
                <DeleteButton
                  type="button"
                  disabled={file?.status === "uploading"}
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  <Icon icon="trash-can" />
                </DeleteButton>
              </TooltipTrigger>
              <TooltipContent>
                <FormattedMessage defaultMessage="Delete Avatar" />
              </TooltipContent>
            </Tooltip>
          </>
        )}
      </UploadActions>

      {showAcceptedFilesInfo && (
        <UploadAcceptedInfo>
          <FormattedMessage
            defaultMessage="Accepted formats: png, jpg, jpeg (max. {size}MB)"
            values={{ size: maxSize / 1000000 }}
          />
        </UploadAcceptedInfo>
      )}
    </Root>
  );
};
