import React from "react";
import { FormattedMessage } from "react-intl";

import { Root, Container, Logo, Tagline } from "./Header.style";
import { Link } from "../../Link/Link";
import clubhLogoSrc from "../../../assets/clubh-logo.svg";

export const Header = () => {
  return (
    <Root>
      <Container>
        <Link to="/">
          <Logo src={clubhLogoSrc} alt="Club H" />
        </Link>
        <Tagline>
          <FormattedMessage defaultMessage="Industry-defining rewards. Lifelong brand loyalty." />
        </Tagline>
      </Container>
    </Root>
  );
};
