import styled from "styled-components";

import cardMobileSrc from "../../../assets/images/clubh-card-mobile@x2.jpg";
import cardSrc from "../../../assets/images/clubh-card@x2.jpg";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    padding-top: 61px;
    padding-bottom: 40px;
  }

  @media (min-width: 1024px) {
    padding: 0 40px 104px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1144px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
  }

  @media (min-width: 1400px) {
    padding-left: 0;
  }
`;

export const TextContainer = styled.div`
  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    max-width: 360px;
  }

  @media (min-width: 1400px) {
    max-width: 444px;
  }
`;

export const ImageContainer = styled.div`
  background-repeat: no-repeat;

  @media (max-width: 1023px) {
    width: 100%;
    height: 321px;
    margin-top: 64px;
    background-image: url(${cardMobileSrc});
    background-position: center;
    background-size: contain;

    &.--desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    width: 572px;
    height: 444px;
    background-image: url(${cardSrc});
    background-size: contain;
    background-position-x: calc(100% + 45px);

    &.--mobile {
      display: none;
    }
  }

  @media (min-width: 1400px) {
    background-position-x: left;
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    margin-top: 48px;
    padding-left: 24px;
  }

  @media (min-width: 1024px) {
    margin-top: 72px;
  }
`;

export const TableScrollContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    padding-bottom: 24px;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      background-color: #f5f4f8;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dbdfea;
      border-radius: 4px;
    }

    &::-webkit-scrollbar {
      height: 4px;
    }
  }
`;

export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
`;
