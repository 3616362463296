import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 376px;

  @media (max-width: 1023px) {
    padding: 0 8px 40px;
  }

  @media (min-width: 1024px) {
    padding: 0 48px 48px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1304px;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    column-gap: 15px;
    row-gap: 30px;
  }

  @media (min-width: 1024px) {
    column-gap: 30px;
    row-gap: 88px;
  }

  @media (min-width: 1400px) {
    column-gap: 32px;
  }
`;
