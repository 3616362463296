import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { DateTime } from "luxon";
import { IMask } from "react-imask";

import { TextInput } from "../../ui/TextInput/TextInput";
import { MaskedInput } from "../../ui/MaskedInput/MaskedInput";
import { Select } from "../../ui/Select/Select";
import { Checkbox } from "../../ui/Checkbox/Checkbox";
import { provinces } from "../../config";

export function Step2({ control, errors }) {
  const intl = useIntl();

  return (
    <div style={{ width: "100%", marginBottom: 48 }}>
      <Controller
        name="salonName"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Salon Name",
            })}
            error={errors?.salonName}
          />
        )}
      />
      <Controller
        name="salonBirthday"
        render={({ field: { onChange, onBlur } }) => (
          <MaskedInput
            mask={Date}
            unmask={false}
            pattern="MM/dd/yyyy"
            format={(date) => DateTime.fromISO(date).toFormat("MM/dd/yyyy")}
            parse={(str) => {
              const localDateTime = DateTime.fromFormat(str, "MM/dd/yyyy", {
                zone: "America/Los_Angeles",
              });
              const utcDateTime = localDateTime.toUTC().toISO();
              return utcDateTime;
            }}
            blocks={{
              MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
              },
              dd: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
              },
              yyyy: {
                mask: IMask.MaskedRange,
                from: 1800,
                to: DateTime.now().year,
              },
            }}
            label={intl.formatMessage({
              defaultMessage: "Salon Birthday (Founding Date) - MM/DD/YYYY",
            })}
            error={errors?.salonBirthday}
            onBlur={onBlur}
            onChange={(v) => onChange(v)}
          />
        )}
        control={control}
      />
      <Controller
        name="salonAddress1"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Salon Address 1",
            })}
            error={errors?.salonAddress1}
          />
        )}
      />
      <Controller
        name="salonAddress2"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Salon Address 2 (optional)",
            })}
            error={errors?.salonAddress2}
          />
        )}
      />
      <Controller
        name="province"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            control={control}
            label={intl.formatMessage({
              defaultMessage: "Province",
            })}
            options={provinces}
            error={errors?.province}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "City",
            })}
            error={errors?.city}
          />
        )}
      />
      <Controller
        name="postalCode"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Postal Code",
            })}
            error={errors?.postalCode}
          />
        )}
      />
      <Controller
        name="website"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Website (optional)",
            })}
            error={errors?.website}
          />
        )}
      />
      <Controller
        name="terms"
        control={control}
        render={({ field }) => (
          <Checkbox
            {...field}
            label={
              <FormattedMessage
                defaultMessage={`By clicking "Join Now", I agree to be bound by the <henkelTerms>Henkel Terms of Use</henkelTerms> and the <clubhTerms>Club H Terms & Conditions</clubhTerms>. I also acknowledge and consent to the practices described in Henkel's <privacyPolicy>Privacy Policy</privacyPolicy>.`}
                values={{
                  henkelTerms: (val) => (
                    <a
                      href="https://www.henkel-northamerica.com/terms-of-use-na?view=content-blank"
                      target="_blank"
                    >
                      {val}
                    </a>
                  ),
                  clubhTerms: (val) => (
                    <a href="/terms" target="_blank">
                      {val}
                    </a>
                  ),
                  privacyPolicy: (val) => (
                    <a
                      href="https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank"
                      target="_blank"
                    >
                      {val}
                    </a>
                  ),
                }}
                tagName="span"
              />
            }
            error={errors?.terms}
          />
        )}
      />
      <Controller
        name="promotions"
        control={control}
        render={({ field }) => (
          <Checkbox
            {...field}
            label={
              <FormattedMessage
                defaultMessage="I agree to receive special offers and promotions"
                tagName="span"
              />
            }
            error={errors?.promotions}
          />
        )}
      />
    </div>
  );
}
