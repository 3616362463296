import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  text-align: left;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 0 13px 0 28px;
  color: ${(p) => (p.$isOpen ? "#fff" : "#27166a")};
  background-color: ${(p) => (p.$isOpen ? "#27166a" : "#fff")};
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #27166a;
  transition: all 250ms;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 0;
`;
