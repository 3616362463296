const env = import.meta.env || {};

export const locales = [
  {
    id: "en-CA",
    title: "English",
    timezone: "America/New_York",
    formats: {
      shortDate: "MM/DD",
      shortTime: "h:mma",
    },
  },
  {
    id: "fr-CA",
    title: "French",
    timezone: "America/New_York",
    formats: {
      shortDate: "MM/DD",
      shortTime: "h:mma",
    },
  },
];

export const defaultLocale = "en-CA";

export const socialLinks = [
  {
    title: "Instagram",
    url: "https://www.instagram.com/clubhrewards",
  },
  {
    title: "TiKTok",
    url: "https://www.tiktok.com/clubhrewards",
  },
  {
    title: "Facebook",
    url: "https://www.facebook.com/clubhrewards",
  },
  {
    title: "YouTube",
    url: "https://www.youtube.com/@ClubHrewards",
  },
];

export const environment = env.MODE;
export const isLocalEnv = env.MODE == null || env.MODE === "development";

export const isMasterEnv = env.MODE === "master";

export const isStagingEnv = env.MODE === "staging";

export const isHostedEnv = isMasterEnv || isStagingEnv;

export const isSSR = env.SSR;

export const isBrowser = isSSR === false;

export const helmetConfig = {
  defaultTitle: "Club H Rewards: Loyalty for Life",
  titleTemplate: "%s - Club H Rewards: Loyalty for Life",
};

export const apiDomain = env.VITE_API_DOMAIN;

export const apiSiteId = env.VITE_API_SITEID;

export const frontendDomain = env.VITE_FRONTEND_DOMAIN;

export const provinces = [
  {
    label: "Alberta",
    value: "AB",
  },
  {
    label: "British Columbia",
    value: "BC",
  },
  {
    label: "Manitoba",
    value: "MB",
  },
  {
    label: "New Brunswick",
    value: "NB",
  },
  {
    label: "Newfoundland and Labrador",
    value: "NL",
  },
  {
    label: "Nova Scotia",
    value: "NS",
  },
  {
    label: "Northwest Territories",
    value: "NT",
  },
  {
    label: "Nunavut",
    value: "NU",
  },
  {
    label: "Ontario",
    value: "ON",
  },
  {
    label: "Prince Edward Island",
    value: "PE",
  },
  {
    label: "Quebec",
    value: "QC",
  },
  {
    label: "Saskatchewan",
    value: "SK",
  },
  {
    label: "Yukon",
    value: "YT",
  },
];

export const distributors = [
  { label: "Beauté Star", value: "65cba01448950591c41b0ca8" },
  { label: "Canrad Beauty", value: "652c232cd8ba7f013db7b0f9" },
  { label: "Capilex", value: "657b3503042c184a9bca613a" },
  { label: "Central Beauty Supply", value: "652c2354d8ba7f013db7b129" },
  { label: "Chalut", value: "65491ac5042c184a9bc2263d" },
  { label: "Concept JP", value: "65cba02448950591c41b0cb8" },
  { label: "Direct Sales", value: "6525b84b85b23b704a2d18c3" },
  { label: "ESP Salon Sales", value: "652c2338d8ba7f013db7b109" },
  { label: "Icon Salon Systems", value: "65a19cd72c7e4d4b232995a9" },
  { label: "Jean Julien", value: "654d11cf042c184a9bc55850" },
  { label: "Maritime Beauty", value: "652c4ba4f14850a617bad730" },
  { label: "Modern Beauty Supplies", value: "652c15bed8ba7f013db78ba6" },
  { label: "Radiant Beauty Supplies", value: "652c2346d8ba7f013db7b119" },
  { label: "Salon Centre (SK/MB)", value: "654d1959042c184a9bc5ad8b" },
  { label: "SalonCentric", value: "65a15aeb2c7e4d4b23299380" },
  { label: "West Coast Beauty", value: "654d0459042c184a9bc49fb1" },
  { label: "Windsor Beauty Supply", value: "65524f37042c184a9bc7b303" },
];
