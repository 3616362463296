import React from "react";
import { IMaskMixin } from "react-imask";

import { Root, Input, Label, Error } from "./MaskedInput.style";

export const MaskedInput = ({
  value,
  label = " ",
  error,
  mask,
  unmask = true,
  onChange,
  ...props
}) => {
  return (
    <Root>
      <IMaskInput
        {...props}
        mask={mask}
        unmask={unmask}
        onAccept={onChange}
        value={value?.toString()}
        placeholder={label}
      />
      <Label>{label}</Label>
      {error && <Error>{error?.message}</Error>}
    </Root>
  );
};

const IMaskInput = IMaskMixin(({ inputRef, ...props }) => (
  <Input ref={inputRef} {...props} />
));
