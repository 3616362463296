import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Title,
  Desc,
  Items,
  Item,
  Image,
  ItemTitle,
} from "./Categories.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Link } from "../../Link/Link";

export function Categories({ items = [] }) {
  return (
    <Root>
      <Title>
        <Typography as="h2" type="h2">
          <FormattedMessage defaultMessage="Rewards Tailored to Your Every Need" />
        </Typography>
      </Title>
      <Desc>
        <Typography as="p" type="text-primary">
          <FormattedMessage defaultMessage="Step into a realm of thrilling rewards and take your hairstyling artistry to new heights with Club H." />
        </Typography>
      </Desc>

      {items.length > 0 ? (
        <Items>
          {items.map((item, idx) => (
            <Item key={idx} as={Link} to={item.url}>
              <Image src={item.imageSrc} alt={item.title} />
              <ItemTitle>{item.title}</ItemTitle>
            </Item>
          ))}
        </Items>
      ) : (
        <FormattedMessage defaultMessage="No data" tagName="div" />
      )}
    </Root>
  );
}
