import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { Root, CloseButton } from "./ModalBonusPointsEarned.style";

import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";

import { Typography } from "../../../ui/Typography/Typography";
import { HeaderUserBadge } from "../../HeaderUserBadge/HeaderUserBadge";
import { Button } from "../../../ui/Button/Button";
import { ButtonLink } from "../../../ui/ButtonLink/ButtonLink";
import { first } from "lodash";

export const ModalBonusPointsEarned = ({
  pts,
  pointsBalance,
  onClose = null,
  firstName,
  avatarSrc,
  bonusItemName = "",
}) => {
  useLockBodyScroll();
  const navigate = useNavigate();

  return (
    <Root>
      <CloseButton onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
        >
          <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.8}
            d="m4.5 19.501 15-15M4.5 4.5l15 14.885"
          />
        </svg>
      </CloseButton>

      <Typography type="h2">
        <FormattedMessage defaultMessage="Bonus Points Unlocked!" />
      </Typography>

      <Typography
        type="text-primary"
        color="#7f8696"
        style={{ textAlign: "center" }}
      >
        <FormattedMessage
          defaultMessage="You earned <b>{pts} PTS</b> by unlocking {bonusItemName}!"
          values={{
            b: (str) => <b style={{ color: "#000" }}>{str}</b>,
            pts: (pts ?? 0).toLocaleString("en-US"),
            bonusItemName: (bonusItemName || "").toLowerCase(),
          }}
        />
      </Typography>

      <HeaderUserBadge
        url="/dashboard"
        imageSrc={avatarSrc}
        name={firstName}
        pts={pointsBalance}
      />

      <Button
        type="submit"
        variant="auth"
        arrow={false}
        onClick={() => {
          onClose();
          navigate("/rewards");
        }}
      >
        <FormattedMessage defaultMessage="Shop Rewards" />
      </Button>

      <ButtonLink
        as="button"
        type="button"
        variant="secondary"
        onClick={() => {
          onClose();
          navigate("/dashboard");
        }}
      >
        <FormattedMessage defaultMessage="Back to Dashboard" />
      </ButtonLink>
    </Root>
  );
};
