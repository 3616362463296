import React from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Root, Container } from "./SweepsTermsView.style";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Footer } from "../../components/Footer/Footer";

import termsMarkdownEnCa from "./sweepsTerms_en-CA.md";

const termsContent = {
  "en-CA": termsMarkdownEnCa,
  "fr-CA": termsMarkdownEnCa,
};

export const SweepsTermsView = () => {
  const intl = useIntl();
  const { locale, defaultLocale } = intl;

  return (
    <Layout>
      <Helmet>
        <title>Sweepstakes Terms & Conditions</title>
      </Helmet>

      <Header />
      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          {
            to: null,
            label: intl.formatMessage({
              defaultMessage: "Terms & Conditions",
            }),
          },
        ]}
      />

      <Root>
        <Container>
          <Markdown remarkPlugins={[remarkGfm]}>
            {termsContent[locale] ?? termsContent[defaultLocale]}
          </Markdown>
        </Container>
      </Root>

      <Footer />
    </Layout>
  );
};
