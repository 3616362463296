import { gql } from "@apollo/client";

export const GET_SEARCH_CONTENT = gql`
  query GetSearchContent(
    $sort: [SortInput]
    $page: Int
    $filters: String
    $limit: Int
    $locale: String
  ) {
    content {
      rewards: findClubH(
        sort: $sort
        page: $page
        filters: $filters
        limit: $limit
        locale: $locale
        type: "reward"
      ) {
        docs {
          ... on Reward {
            _id
            n
            sl
            dc
            dm
            brand {
              _id
              n
            }
            gallery {
              media {
                _id
              }
            }
            data {
              category
              isNew
              isLimited
              isFeatured
            }
            ecommerce {
              price {
                amount
                currency
              }
              fullPrice {
                amount
                currency
              }
              sku
            }
          }
        }
        page
        pages
        limit
        total
      }
      experience: findClubH(
        sort: $sort
        page: $page
        filters: $filters
        limit: $limit
        locale: $locale
        type: "news"
      ) {
        docs {
          ... on News {
            _id
            n
            sl
            dc
            dm
            brand {
              _id
              n
            }
            gallery {
              media {
                _id
              }
            }
            data {
              category
            }
          }
        }
        page
        pages
        limit
        total
      }
      education: findClubH(
        sort: $sort
        page: $page
        filters: $filters
        limit: $limit
        locale: $locale
        type: "news"
        category: "education"
      ) {
        docs {
          ... on News {
            _id
            n
            sl
            dc
            dm
            brand {
              _id
              n
            }
            gallery {
              media {
                _id
              }
            }
            data {
              category
            }
          }
        }
        page
        pages
        limit
        total
      }
    }
  }
`;

/* 
 ... on Experience {
            _id
            n
            sl
            dc
            dm
            brand {
              _id
              n
            }
            gallery {
              media {
                _id
              }
            }
            data {
              category
              isNew
              isLimited
              isFeatured
            }
          }
*/
