import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    width: 50%;
    width: ${(p) =>
      p.$isPromo ? "100%" : "calc((100% / 2) - 15px + (15px / 2))"};
  }

  @media (min-width: 1024px) {
    width: ${(p) =>
      p.$isPromo
        ? "calc((100% / 1.5) - 30px + (30px / 1.5))"
        : "calc((100% / 3) - 30px + (30px / 3))"};
  }

  @media (min-width: 1400px) {
    width: ${(p) =>
      p.$isPromo
        ? "calc((100% / 2) - 32px + (32px / 2))"
        : "calc((100% / 4) - 32px + (32px / 4))"};
  }
`;

export const Container = styled.div`
  display: ${(p) => (p.$isPromo ? "flex" : "block")};
  align-items: ${(p) => p.$isPromo && "center"};
  position: relative;
  width: 100%;
  background-color: ${(p) => p.$isPromo && "var(--color-bg-2)"};

  @media (max-width: 1023px) {
    height: 248px;
  }

  @media (min-width: 1024px) {
    height: 376px;
  }
`;

export const ImageContainer = styled.a`
  flex-shrink: 0;
  display: block;
  width: ${(p) => (p.$isPromo ? "47.4548%" : "100%")};
  height: 100%;
  border-top: ${(p) => p.$isPromo && "2px solid var(--color-bg-2)"};
  border-bottom: ${(p) => p.$isPromo && "2px solid var(--color-bg-2)"};
  border-left: ${(p) => p.$isPromo && "2px solid var(--color-bg-2)"};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 0;
  color: ${(p) => (p.$fg ? p.$fg : "#000")};
  background-color: ${(p) => (p.$bg ? p.$bg : "#fff")};
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    height: 20px;
    padding: 0 8px;
    font-size: 10px;
    letter-spacing: 0.1px;
  }

  @media (min-width: 1024px) {
    height: 27px;
    padding: 0 12px;
    font-size: 12px;
    letter-spacing: 0.12px;
  }
`;

export const FavouriteContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const AddToButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }
`;

export const AddToCartContainer = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;

  @media (min-width: 1024px) {
    display: none;

    ${Container}:hover & {
      display: block;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${(p) => !p.$isPromo && "13px"};
  padding: ${(p) => p.$isPromo && "24px 32px 24px 24px"};
  text-align: center;
`;

export const BrandName = styled.div`
  color: var(--color-accent);
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;

  &:hover {
    color: #cb000e;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }
`;

export const BrandImage = styled.div`
  @media (max-width: 1023px) {
    width: 130px;
  }

  @media (min-width: 1024px) {
    width: 200px;
  }
`;

export const ProductName = styled.div`
  display: block;
  color: inherit;
  font-weight: 500;

  @media (max-width: 1023px) {
    margin: 4px 0;
    font-size: 13px;
    line-height: 1.54;
  }

  @media (min-width: 1024px) {
    margin: 8px 0;
    font-size: 18px;
    line-height: 1.38;
  }
`;
