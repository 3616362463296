export default {
  // Person: {
  //   // In some user account systems, names or emails alone do not have to
  //   // be unique, but the combination of a person's name and email is
  //   // uniquely identifying.
  //   keyFields: ['name', 'email'],
  // },
  Query: {
    fields: {
      content: {
        merge(existing, incoming) {
          return incoming; // abandon existing value and replace with new values
        },
      },
      me: {
        merge(existing, incoming) {
          return incoming; // abandon existing value and replace with new values
        },
      },
      brand: {
        merge(existing, incoming) {
          return incoming; // abandon existing value and replace with new values
        },
      },
      loyaltyresult: {
        merge(existing, incoming) {
          return incoming; // abandon existing value and replace with new values
        },
      },
    },
  },
  Reward: {
    fields: {
      ecommerce: {
        merge(existing, incoming) {
          return incoming; // abandon existing value and replace with new values
        },
      },
      data: {
        merge(existing, incoming) {
          return incoming; // abandon existing value and replace with new values
        },
      },
    },
  },
  Salon: {
    fields: {
      address: {
        merge(existing, incoming) {
          return incoming; // abandon existing value and replace with new values
        },
      },
    },
  },
};
