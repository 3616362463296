import React from "react";

import {
  Root,
  Container,
  TextContainer,
  ImageContainer,
} from "./ClubHJourney.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";
import { Link } from "../../Link/Link";

export const ClubHJourney = ({
  title = "",
  desc = "",
  cta = null,
  reverse = true,
  bgColor = true,
}) => {
  return (
    <Root className={bgColor && "--bg"}>
      <Container className={reverse && "--reverse"}>
        <TextContainer>
          <Typography as="h2" type="h2">
            {title}
          </Typography>
          <Typography as="p" type="text-primary">
            {desc}
          </Typography>

          {cta && (
            <Button as={Link} to={cta.url}>
              {cta.label}
            </Button>
          )}
        </TextContainer>

        <ImageContainer />
      </Container>
    </Root>
  );
};
