import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 0 8px;
  }

  @media (min-width: 1024px) {
    padding: 0 48px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1304px;
`;

export const Grid = styled.div`
  display: grid;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 32px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 40px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
  }
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 376px;
`;
