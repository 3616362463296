import React from "react";
import PropTypes from "prop-types";

import { Root } from "./ButtonLink.style";

export const ButtonLink = ({ variant = "primary", children, ...props }) => {
  return (
    <Root $variant={variant} {...props}>
      {children}
    </Root>
  );
};

ButtonLink.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]),
};
