import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Cookies from "js-cookie";
import {
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import escapeStringRegexp from "escape-string-regexp";

import UserProvider from "./context/UserContext";

import {
  helmetConfig,
  apiDomain,
  environment,
  isLocalEnv,
  isBrowser,
} from "./config";
import { router } from "./routes";
import { client } from "./graphql/client";

Sentry.init(
  isLocalEnv
    ? {}
    : {
        environment,
        dsn: "https://3be1b96c8218c667c1bdb5c018ea1d0c@o4506707276660736.ingest.us.sentry.io/4506963462193152",
        integrations: [
          // Sentry.browserTracingIntegration(),
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.01, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          new RegExp(
            "/^" + escapeStringRegexp(`https://${apiDomain}/`) + "/",
            "i"
          ),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: [
          "undefined is not an object (evaluating 'webkit.messageHandlers.linkClickDetectionMessageHandler.postMessage')",
          "top.GLOBALS", // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          "AbortError: The play() request was interrupted by a call to pause()",
          "Non-Error promise rejection captured with value: undefined",
          /Network error/,
          /^Network request failed$/,
          "TypeError: Load failed",
          "TypeError: Failed to fetch",
          "TypeError: NetworkError when attempting to fetch resource.",
          "TypeError: cancelled",
          "AbortError: The user aborted a request",
          "AbortError: Request timed out.",
          "Can't find variable: logMutedMessage",
          "Non-Error promise rejection captured with value: Object Not Found Matching Id",
        ],
        denyUrls: [
          /cdn\.cookielaw\.org/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          /^chrome-extension:\/\//i,
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
        ],
      }
);

import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { set } from "lodash";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Root = styled.div`
  height: 100%;
  animation: 1s ${fadeIn} ease;
`;

const App = () => {
  useEffect(() => {
    if (isBrowser && `${window.location.hash}`.startsWith("#invite-")) {
      //set cookie and wipe hash
      Cookies.set("referral", window.location.hash.replace("#invite-", ""), {
        expires: 365,
      });
      setTimeout(() => {
        history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      }, 100);
    }
  }, []);

  return (
    <Root>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <UserProvider>
            <Helmet {...helmetConfig} />
            <RouterProvider router={router} />
          </UserProvider>
        </ApolloProvider>
      </HelmetProvider>
    </Root>
  );
};

export default App;
