import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import icons from "./icons";

const Root = styled.svg`
  display: inline-block;
  vertical-align: middle;
`;

export function Icon({ icon = "", color = "currentColor", ...props }) {
  return (
    <Root
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill={color} d={icons[icon]} />
    </Root>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
};
