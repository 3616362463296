import styled from "styled-components";

export const Root = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 376px;
  margin-bottom: 80px;
`;
