import styled from "styled-components";

export const Root = styled.ul`
  display: flex;
  align-items: center;
  width: fit-content;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    margin-left: 16px;
  }
`;

export const ItemLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 16px;

  svg {
    width: 1em;
    height: 1em;
  }
`;
