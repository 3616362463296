import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  inset: 0;
  z-index: 1000;

  &.--global {
    position: fixed;
  }

  &.--local {
    position: absolute;
  }
`;

export const LottieContainer = styled.div`
  width: 120px;
`;
