import { gql } from "@apollo/client";

export const GET_REWARDS_CAROUSEL = gql`
  query GetRewardsCarousel(
    $sort: [SortInput]
    $page: Int
    $filters: String
    $limit: Int
    $locale: String
  ) {
    me {
      wishlist(locale: $locale) {
        _id
        items {
          _id
          product {
            ... on Reward {
              _id
            }
          }
        }
      }
    }
    content {
      findClubH(
        sort: $sort
        page: $page
        filters: $filters
        limit: $limit
        locale: $locale
      ) {
        docs {
          ... on Reward {
            _id
            n
            sl
            dc
            dm
            brand {
              _id
              n
            }
            gallery {
              media {
                _id
              }
            }
            howToUse
            data {
              category
              isNew
              isLimited
              isFeatured
              features {
                heatProtection
                colorSafe
                ammoniaFree
                sulfateFree
                parabenFree
                crueltyFree
                vegan
              }
            }
            ecommerce {
              price {
                amount
                currency
              }
              fullPrice {
                amount
                currency
              }
              sku
            }
          }
        }
        page
        pages
        limit
        total
      }
    }
  }
`;
