import styled from "styled-components";

export const Root = styled.div`
  width: 100%;
  text-align: left;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  color: ${(p) => p.$isOpen && "var(--color-accent)"};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(p) => (p.$isOpen ? "transparent" : "#dcdfe9")};
  transition: all 250ms;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

export const Body = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #dcdfe9;
`;
