import React, { useContext, useRef } from "react";
import { useIntl } from "react-intl";
import Slider from "react-slick";

import {
  Root,
  Slide,
  Image,
  ContentContainer,
  Title,
  Desc,
  Actions,
  Arrows,
  Arrow,
  Card,
  Video,
} from "./HeroSlider.style";

import { UserContext } from "../../../context/UserContext";

import { Link } from "../../Link/Link";
import { Button } from "../../../ui/Button/Button";
import { Icon } from "../../../ui/Icon/Icon";
// import { LoyaltyCard } from "../../LoyaltyCard/LoyaltyCard";

import heroSlide1 from "../../../assets/images/homepage/hero/hp-hero-01-min.jpg";
import heroSlide2 from "../../../assets/images/homepage/hero/hp-hero-1a-Desktop.mp4";
import heroSlide2Mobile from "../../../assets/images/homepage/hero/hp-hero-1a-Mobile.mp4";
import heroSlide3 from "../../../assets/images/homepage/hero/hp-hero-02-min.jpg";
import heroSlide4 from "../../../assets/images/homepage/hero/hp-hero-03-min.jpg";
import heroSlide5 from "../../../assets/images/homepage/hero/hp-hero-05-min.jpg";
// import heroSlide5 from "../../../assets/images/homepage/hero/ClubH_Sweepstake_Box_Desktop.mp4";
// import heroSlide5Mobile from "../../../assets/images/homepage/hero/ClubH_Sweepstake_Box_Mobile.mp4";

//fix club h logos
// add mobile video and photos

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  // autoplay: true,
  // autoplaySpeed: 6000,
  speed: 800,
};

const getSlides = (intl) => [
  {
    id: "1",
    aligned: "right",
    background: "dark",
    images: [heroSlide1],
    title: intl.formatMessage({
      defaultMessage: "Rewards That Work as Hard as You Do",
    }),
    desc: intl.formatMessage({
      defaultMessage:
        "Membership to Club H is FREE – earn points for your Henkel beauty product purchases and invest them back into your craft. It's smart, simple, and designed for the pros.",
    }),
    cta: {
      loggedIn: {
        label: intl.formatMessage({
          defaultMessage: "Shop Rewards",
        }),
        url: "/rewards",
      },
      loggedOut: {
        label: intl.formatMessage({
          defaultMessage: "Join Now",
        }),
        url: "/auth",
      },
    },
  },
  {
    id: "2",
    aligned: "right",
    background: "dark",
    video: heroSlide2,
    videoMobile: heroSlide2Mobile,
    title: intl.formatMessage({
      defaultMessage: "Top Brands, One Loyalty Club",
    }),
    desc: intl.formatMessage({
      defaultMessage:
        "Discover Henkel Beauty Care's elite brands in one loyalty program. Elevate your craft with rewards that matter. Welcome to Club H.",
    }),
    cta: {
      loggedIn: {
        label: intl.formatMessage({
          defaultMessage: "Shop Rewards",
        }),
        url: "/rewards",
      },
      loggedOut: {
        label: intl.formatMessage({
          defaultMessage: "Join Now",
        }),
        url: "/auth",
      },
    },
  },
  {
    id: "3",
    aligned: "right",
    background: "light",
    images: [heroSlide3],
    title: intl.formatMessage({
      defaultMessage: "Unlock Your Potential with Club H",
    }),
    desc: intl.formatMessage({
      defaultMessage:
        "Club H is your gateway to inspiration. Explore new trends, try innovative products, and learn from industry leaders. We’re not just supporting your career—we’re transforming it.",
    }),
    cta: {
      loggedIn: {
        label: intl.formatMessage({
          defaultMessage: "Shop Rewards",
        }),
        url: "/rewards",
      },
      loggedOut: {
        label: intl.formatMessage({
          defaultMessage: "Join Now",
        }),
        url: "/auth",
      },
    },
  },
  {
    id: "4",
    aligned: "right",
    background: "light",
    images: [heroSlide4],
    title: intl.formatMessage({
      defaultMessage: "More Than Rewards – Join the Revolution",
    }),
    desc: intl.formatMessage({
      defaultMessage:
        "Club H celebrates your unique style. Connect, learn, and thrive in a community as creative and bold as you. Experience loyalty redefined.",
    }),
    cta: {
      loggedIn: {
        label: intl.formatMessage({
          defaultMessage: "Shop Rewards",
        }),
        url: "/rewards",
      },
      loggedOut: {
        label: intl.formatMessage({
          defaultMessage: "Join Now",
        }),
        url: "/auth",
      },
    },
  },
  // {
  //   id: "5",
  //   aligned: "right",
  //   background: "light",
  //   images: [heroSlide5],
  //   title: intl.formatMessage({
  //     defaultMessage: "Sweep Into Style",
  //   }),
  //   desc: intl.formatMessage({
  //     defaultMessage:
  //       "Registering for Club H is your ticket to the sweepstakes. No additional steps are required, sign up by June 30th, 2024 and you're automatically entered to win one of 36 prizes featuring our color, styling and care products*.",
  //   }),
  //   cta: {
  //     loggedIn: {
  //       label: intl.formatMessage({
  //         defaultMessage: "Read more",
  //       }),
  //       url: "/sweepintostyle",
  //     },
  //     loggedOut: {
  //       label: intl.formatMessage({
  //         defaultMessage: "Read more",
  //       }),
  //       url: "/sweepintostyle",
  //     },
  //   },
  // },
  // {
  //   id: "4",
  //   aligned: 4,
  //   background: "dark",
  //   image: "https://picsum.photos/id/13/2800/1100",
  //   loyaltyCard: {
  //     tier: 1,
  //     pts: 27524,
  //     nextLvl: 28724,
  //     group: "Mat & Mat’s Group",
  //     userName: "Anna Meyer",
  //   },
  // },
];

export function HeroSlider() {
  const sliderRef = useRef();
  const { user } = useContext(UserContext);
  const intl = useIntl();
  const slides = getSlides(intl);

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const type4Slide = slides.find((slide) => slide.aligned === 4);
  const restSlides = slides.filter((slide) => slide.aligned !== 4);

  return (
    <Root>
      <Slider ref={sliderRef} {...settings}>
        {/* {user && type4Slide && (
          <Slide key={type4Slide.id}>
            <Image
              className={`--aligned-${type4Slide.aligned}`}
              src={type4Slide.image}
              alt=""
            />
            <Card>
              <LoyaltyCard
                tier={type4Slide.loyaltyCard.tier}
                pts={type4Slide.loyaltyCard.pts}
                memberSince={new Date()}
                group={type4Slide.loyaltyCard.group}
                userName={type4Slide.loyaltyCard.userName}
              />
            </Card>
          </Slide>
        )} */}

        {restSlides.map((slide) => {
          return (
            <Slide
              key={slide.id}
              className={`--aligned-${slide.aligned} --background-${slide.background}`}
            >
              {slide?.images?.length > 0 ? (
                <picture>
                  {slide?.imagesMobile?.length > 0 && (
                    <source
                      media="(max-width: 1023px)"
                      srcSet={slide.imagesMobile[0]}
                    />
                  )}
                  <Image
                    src={slide.images[0]}
                    alt={slide.title}
                    className={`--aligned-${slide.aligned}`}
                  />
                </picture>
              ) : (
                <Video autoPlay muted loop playsInline>
                  <source
                    src={slide.videoMobile}
                    media="(max-width: 1023px)"
                    type="video/mp4"
                  />
                  <source src={slide.video} type="video/mp4" />
                </Video>
              )}
              <ContentContainer
                className={`--aligned-${slide.aligned} --background-${slide.background}`}
              >
                <Title>{slide.title}</Title>
                <Desc>{slide.desc}</Desc>
                <Actions>
                  {user ? (
                    <Button as={Link} to={slide.cta.loggedIn.url}>
                      {slide.cta.loggedIn.label}
                    </Button>
                  ) : (
                    <Button as={Link} to={slide.cta.loggedOut.url}>
                      {slide.cta.loggedOut.label}
                    </Button>
                  )}
                  <Arrows>
                    <Arrow onClick={handlePrevSlide}>
                      <Icon icon="arrow-left-long" />
                    </Arrow>
                    <Arrow onClick={handleNextSlide}>
                      <Icon icon="arrow-right-long" />
                    </Arrow>
                  </Arrows>
                </Actions>
              </ContentContainer>
              {/* {slide.aligned === "centered" && (
                <Image
                  className={`--aligned-${slide.aligned}`}
                  src={slide.images[1]}
                  alt=""
                />
              )} */}
            </Slide>
          );
        })}
      </Slider>
    </Root>
  );
}
