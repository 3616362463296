import styled, { css } from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 16px 24px;
  }

  @media (min-width: 1024px) {
    padding: 16px 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1304px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Append = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1023px) {
    justify-content: center;
    margin-top: 26px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  min-height: 40px;
  list-style: none;
`;

const itemCommonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export const PageAction = styled.button`
  ${itemCommonStyles};
  color: ${(p) => (p.$isActive ? "#fff" : "#8f95a5")};
  background-color: ${(p) =>
    p.$isActive ? "var(--color-accent)" : "transparent"};
  font-size: 16px;
  font-weight: 700;
`;

export const ArrowAction = styled.button`
  ${itemCommonStyles};
  color: #8f95a5;
  background-color: transparent;
  font-size: 23px;

  &:disabled {
    color: #dcdfe9;
  }
`;
