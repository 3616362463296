import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";

import { Root, Input, Label, IconButton, Error } from "./TextInput.style";

import { Icon } from "../../ui/Icon/Icon";

export const TextInput = forwardRef(
  ({ type = "text", label = " ", error, ...props }, ref) => {
    const [passwordIsShow, setPasswordIsShow] = useState(false);
    return (
      <Root>
        <Input
          ref={ref}
          type={
            type === "password" ? (passwordIsShow ? "text" : "password") : type
          }
          placeholder={label}
          {...props}
        />
        <Label>{label}</Label>
        {type === "password" && (
          <IconButton
            type="button"
            onClick={() => setPasswordIsShow(!passwordIsShow)}
          >
            {passwordIsShow ? <Icon icon="eye" /> : <Icon icon="eye-slash" />}
          </IconButton>
        )}
        {error && <Error>{error?.message}</Error>}
      </Root>
    );
  }
);

TextInput.propTypes = {
  type: PropTypes.oneOf(["text", "email", "password", "number", "tel"]),
};
