import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  PosterImg,
  Container,
  CloseButton,
  Header,
  TitleContainer,
  Title,
  Desc,
  ItemContainer,
  ItemImageContainer,
  ItemImg,
  ItemTitle,
  ContinueButton,
} from "./ModalAddedToCart.style";

import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";

import posterSrc from "../../../assets/images/added-to-cart-poster.jpg";

import { Icon } from "../../../ui/Icon/Icon";
import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";
import { Link } from "../../Link/Link";

export const ModalAddedToCart = ({
  onClose = null,
  imgSrc,
  brandName,
  productName,
  qty = 1,
  points,
  oldPts,
}) => {
  useLockBodyScroll();

  return (
    <Root>
      <PosterImg src={posterSrc} alt="" />
      <Container>
        <CloseButton onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.8}
              d="m4.5 19.501 15-15M4.5 4.5l15 14.885"
            />
          </svg>
        </CloseButton>

        <Header>
          <Icon icon="basket" style={{ fontSize: 20 }} />
          <TitleContainer>
            <Title>
              <FormattedMessage defaultMessage="Added to your cart!" />
            </Title>
            <Desc>
              <FormattedMessage defaultMessage="Item has been successfully added to cart." />
            </Desc>
          </TitleContainer>
        </Header>

        <ItemContainer>
          <ItemImageContainer>
            <ItemImg src={imgSrc} alt="" />
          </ItemImageContainer>
          <div>
            <Typography type="brand-secondary">{brandName}</Typography>
            <ItemTitle>{productName}</ItemTitle>
            <Typography type="text-label" color="#8f95a5">
              Quantity: <span style={{ color: "#000" }}>{qty}</span>
            </Typography>
            <div
              style={{ display: "flex", alignItems: "baseline", marginTop: 4 }}
            >
              {oldPts && (
                <Typography
                  type="points-secondary"
                  style={{ marginRight: 6, textDecoration: "line-through" }}
                >
                  {oldPts.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </Typography>
              )}
              <Typography
                type="points-secondary"
                color={oldPts ? "#e1000f" : "#000"}
              >
                {points.toLocaleString("en-US")}{" "}
                <FormattedMessage defaultMessage="PTS" />
              </Typography>
            </div>
          </div>
        </ItemContainer>

        <Button as={Link} to="/cart" variant="auth" arrow={false}>
          <FormattedMessage defaultMessage="go to cart" />
        </Button>
        <ContinueButton onClick={onClose}>
          <FormattedMessage defaultMessage="Continue Shopping" />
        </ContinueButton>
      </Container>
    </Root>
  );
};
