import styled from "styled-components";
import { motion } from "framer-motion";

import heroBkgd from "../../assets/images/dashboard-hero.jpg";

export const Hero = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 375px;
  background-image: url(${heroBkgd});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1023px) {
    align-items: flex-end;
    padding: 0 24px 104px;
  }

  @media (min-width: 1024px) {
    align-items: center;
    padding: 0 48px;
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1144px;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const TabsContainer = styled.div`
  @media (max-width: 1023px) {
    display: none;
    padding: 32px 24px;
    border-bottom: 1px solid #dcdfe9;
  }
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 56px 0;
  }

  @media (min-width: 1024px) {
    padding: 64px 0;
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 48px 16px;

  @media (min-width: 1024px) {
    display: none;
  }
`;
