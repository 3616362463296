import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Root, CloseButton, Container } from "./ModalReferFriend.style";

import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";

import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";
import { ButtonLink } from "../../../ui/ButtonLink/ButtonLink";

import { frontendDomain } from "../../../config";

export const ModalReferFriend = ({ onClose, userId }) => {
  useLockBodyScroll();
  const intl = useIntl();
  const [isCopied, setIsCopied] = useState(false);

  const copyCode = () => {
    navigator.clipboard
      .writeText(`https://${frontendDomain}/${intl.locale}#invite-${userId}`)
      .then(() => setIsCopied(true))
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Root>
      <CloseButton onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
        >
          <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.8}
            d="m4.5 19.501 15-15M4.5 4.5l15 14.885"
          />
        </svg>
      </CloseButton>

      <Container>
        <Typography type="h2">
          <FormattedMessage defaultMessage="Earn 5000 Points for Every Referral!" />
        </Typography>
        <Typography type="text-primary" style={{ color: "#7f8696" }}>
          <FormattedMessage defaultMessage="Once your friend registers and is approved, you'll earn 5,000 points. Keep referring to earn more rewards!" />
        </Typography>

        <Button
          type="submit"
          variant="auth"
          arrow={false}
          style={{ gap: 8, backgroundColor: isCopied && "#27166a" }}
          onClick={copyCode}
        >
          {!isCopied && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width={16}
              height={16}
            >
              <path
                fill="#fff"
                d="m11.879 8.468-1.222 1.221a3.073 3.073 0 0 1-4.346 0l.708-.707c.81.809 2.122.809 2.931 0l1.222-1.222 1.218-1.222a2.074 2.074 0 0 0 .581-2.161c-.483-1.521-2.431-1.948-3.507-.77L7.997 5.074l-.707-.707L8.756 2.9a3.074 3.074 0 0 1 3.036-.813c2.277.643 3.004 3.509 1.309 5.159Zm-7.756-.936L5.345 6.31c1.2-1.2 3.146-1.2 4.346 0l-.708.707a2.073 2.073 0 0 0-2.931 0L4.83 8.239 3.608 9.458a2.074 2.074 0 0 0-.556 2.076c.446 1.532 2.383 2.007 3.487.855l1.467-1.464.707.707-1.467 1.467a3.072 3.072 0 0 1-2.968.796c-2.285-.613-3.05-3.469-1.378-5.142Z"
              />
            </svg>
          )}
          {isCopied ? (
            <FormattedMessage defaultMessage="link copied!" tagName="span" />
          ) : (
            <FormattedMessage defaultMessage="copy link" tagName="span" />
          )}
        </Button>

        <ButtonLink
          as="button"
          type="button"
          variant="secondary"
          onClick={onClose}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </ButtonLink>
      </Container>
    </Root>
  );
};
