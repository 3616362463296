import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Container,
  ImageContainer,
  Label,
  FavouriteContainer,
  AddToButton,
  AddToCartContainer,
  Body,
  BrandName,
  BrandImage,
  ProductName,
} from "./ProductsItem.style";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../ui/Tooltip/Tooltip";
import { Link } from "../Link/Link";
import { Icon } from "../../ui/Icon/Icon";
import { Typography } from "../../ui/Typography/Typography";

import { UserContext } from "../../context/UserContext";

export const ProductsItem = ({
  imageSrc = null,
  inFavorites = false,
  onAddToFavourites = null,
  onAddToCart = null,
  brandName = "",
  brandUrl = "",
  productName = "",
  pts = 0,
  oldPts,
  productUrl = "",
  isPromo = false,
  brandIcon = null,
  isNew = false,
  isFeatured = false,
  isLimited = false,
}) => {
  const { user } = useContext(UserContext);
  let label = null;
  if (isNew) {
    label = { label: "New", bg: "#000", fg: "#fff" };
  } else if (isLimited) {
    label = { label: "Limited" };
  } else if (isFeatured) {
    label = { label: "Featured" };
  }
  return (
    <Root $isPromo={isPromo}>
      <Container $isPromo={isPromo}>
        <ImageContainer as={Link} to={productUrl} $isPromo={isPromo}>
          <img src={imageSrc} alt={productName} />
        </ImageContainer>
        {label?.label && (
          <Label $bg={label.bg} $fg={label.fg}>
            {label.label}
          </Label>
        )}

        <FavouriteContainer>
          <Tooltip placement="top-start">
            <TooltipTrigger>
              <AddToButton type="button" onClick={onAddToFavourites}>
                {inFavorites ? (
                  <Icon icon="heart-filled" />
                ) : (
                  <Icon icon="heart" />
                )}
              </AddToButton>
            </TooltipTrigger>
            <TooltipContent>
              <FormattedMessage defaultMessage="Add to favourites" />
            </TooltipContent>
          </Tooltip>
        </FavouriteContainer>
        <AddToCartContainer>
          <Tooltip placement="top-start">
            <TooltipTrigger>
              <AddToButton type="button" onClick={onAddToCart}>
                <Icon icon="add-to-cart" />
              </AddToButton>
            </TooltipTrigger>
            <TooltipContent>
              <FormattedMessage defaultMessage="Add to cart" />
            </TooltipContent>
          </Tooltip>
        </AddToCartContainer>

        {isPromo && (
          <Body $isPromo={isPromo}>
            <Link to={brandUrl}>
              <BrandImage>{brandIcon}</BrandImage>
              <BrandName>{brandName}</BrandName>
            </Link>
            <ProductName as={Link} to={productUrl}>
              {productName}
            </ProductName>
            {user && pts && (
              <div style={{ display: "flex", alignItems: "baseline" }}>
                {oldPts && (
                  <Typography
                    type="points-secondary"
                    style={{ marginRight: 6, textDecoration: "line-through" }}
                  >
                    {oldPts.toLocaleString("en-US")}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </Typography>
                )}
                <Typography
                  type="points-primary"
                  color={oldPts ? "#e1000f" : "#000"}
                >
                  {pts.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </Typography>
              </div>
            )}
          </Body>
        )}
      </Container>

      {!isPromo && (
        <Body>
          <Link to={brandUrl}>
            <BrandName>{brandName}</BrandName>
          </Link>
          <ProductName as={Link} to={productUrl}>
            {productName}
          </ProductName>
          {user && pts && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              {oldPts && (
                <Typography
                  type="points-secondary"
                  style={{ marginRight: 6, textDecoration: "line-through" }}
                >
                  {oldPts.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </Typography>
              )}
              <Typography
                type="points-primary"
                color={oldPts ? "#e1000f" : "#000"}
              >
                {pts.toLocaleString("en-US")}{" "}
                <FormattedMessage defaultMessage="PTS" />
              </Typography>
            </div>
          )}
        </Body>
      )}
    </Root>
  );
};
