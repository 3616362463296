import React from "react";

import { Root, Container } from "./PageSubheader.style";

export const PageSubheader = ({ children }) => {
  return (
    <Root>
      <Container>{children}</Container>
    </Root>
  );
};
