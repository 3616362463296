import styled, { css } from "styled-components";

export const Root = styled.span`
  display: ${(p) => (p.$block ? "block" : "inline-block")};
  color: ${(p) => p.$color};

  a {
    color: #e1000f;
    text-decoration: underline;

    &:hover {
      color: #cb000e;
    }
  }

  ${(p) =>
    p.$type === "h1" &&
    css`
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: 1023px) {
        font-size: 34px;
        line-height: 1.06;
      }

      @media (min-width: 1024px) {
        font-size: 40px;
        line-height: 1.05;
      }
    `}

  ${(p) =>
    p.$type === "h2" &&
    css`
      font-weight: 700;

      @media (max-width: 1023px) {
        font-size: 28px;
        line-height: 1.25;
      }

      @media (min-width: 1024px) {
        font-size: 34px;
        line-height: 1.14;
      }
    `}

  ${(p) =>
    p.$type === "h3" &&
    css`
      font-weight: 700;
      line-height: 1.38;

      @media (max-width: 1023px) {
        font-size: 16px;
      }

      @media (min-width: 1024px) {
        font-size: 20px;
      }
    `}

  ${(p) =>
    p.$type === "h4" &&
    css`
      font-weight: 500;

      @media (max-width: 1023px) {
        font-size: 13px;
        line-height: 1.54;
      }

      @media (min-width: 1024px) {
        font-size: 16px;
        line-height: 1.38;
      }
    `}

  ${(p) =>
    p.$type === "h5" &&
    css`
      font-size: 13px;
      font-weight: 500;
      line-height: 1.38;
    `}

  ${(p) =>
    p.$type === "points-primary" &&
    css`
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: 1023px) {
        font-size: 10px;
        line-height: 2.6;
      }

      @media (min-width: 1024px) {
        font-size: 16px;
        line-height: 1.63;
      }
    `}

  ${(p) =>
    p.$type === "points-secondary" &&
    css`
      font-size: 10px;
      font-weight: 700;
      line-height: 2.6;
      text-transform: uppercase;
    `}

  ${(p) =>
    p.$type === "text-primary" &&
    css`
      font-size: 17px;
      line-height: 1.5;
      letter-spacing: 0.17px;
    `}

  ${(p) =>
    p.$type === "text-secondary" &&
    css`
      font-size: 17px;
      font-weight: 500;
      line-height: 1.85;
      letter-spacing: 0.17px;
    `}

  ${(p) =>
    p.$type === "text-small" &&
    css`
      font-size: 12px;
      font-weight: 500;
      line-height: 1.67;
      letter-spacing: 0.12px;
    `}

  ${(p) =>
    p.$type === "text-label" &&
    css`
      font-size: 10px;
      font-weight: 500;
      line-height: 1.6;
    `}

  ${(p) =>
    p.$type === "brand-primary" &&
    css`
      color: #e1000f;
      text-transform: uppercase;
      text-decoration: underline;

      &:hover {
        color: #cb000e;
      }

      @media (max-width: 1023px) {
        font-size: 10px;
        font-weight: 700;
        line-height: 2.4;
      }

      @media (min-width: 1024px) {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
      }
    `}

  ${(p) =>
    p.$type === "brand-secondary" &&
    css`
      color: #e1000f;
      font-size: 10px;
      font-weight: 700;
      line-height: 2.4;
      text-transform: uppercase;
      text-decoration: underline;

      &:hover {
        color: #cb000e;
      }
    `}

  ${(p) =>
    p.$type === "link" &&
    css`
      color: #e1000f;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.67;
      letter-spacing: 0.12px;
      text-decoration: underline;

      &:hover {
        color: #cb000e;
      }
    `}

  ${(p) =>
    p.$type === "tags" &&
    css`
      font-size: 12px;
      font-weight: 700;
      line-height: 2;
      letter-spacing: 0.12px;
      text-transform: uppercase;
    `}


  ${(p) =>
    p.$type === "p4" &&
    css`
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.12px;
      line-height: 18px;
    `}
`;
