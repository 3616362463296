import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 0 24px 88px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 104px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
`;

export const ItemsGrid = styled.div`
  display: grid;
  row-gap: 40px;
`;

export const ContinueContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    margin-top: 24px;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 376px;
`;

export const UserCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 48px;
  padding: 0 16px 0 12px;
  background-color: #f4f4f4;
  border-radius: 1px;

  @media (min-width: 1024px) {
    max-width: 260px;
    margin-left: auto;
  }
`;

export const UserCardAvatar = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserCardBody = styled.div`
  display: flex;
  align-items: center;
`;

export const UserCardPoints = styled.div`
  color: #8f95a5;
  font-size: 13px;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
`;
