import styled from "styled-components";

export const SearchField = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 32px;
  margin-bottom: 24px;
  background-color: var(--color-bg-3);
  border-radius: 16px;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  width: 16px;
  height: 32px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16px'%3E%3Cpath fill='%238f95a5' d='m13.294 14 .706-.706-1.992-1.994v-.66c2.424-3.479.172-8.277-4.052-8.636-4.225-.359-7.255 3.99-5.453 7.828 1.448 3.086 5.323 4.129 8.124 2.186h.686L13.294 14ZM3 7.5c0-3.464 3.75-5.629 6.75-3.897 3 1.732 3 6.063 0 7.795A4.5 4.5 0 0 1 3 7.5Z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 16px;
  background-color: transparent;
  letter-spacing: 0.16px;
  border: 0 none;

  &::placeholder {
    color: #7f8696;
  }
`;

export const RangeSliderContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    height: 14px;
    margin-bottom: 16px;
    padding: 0 8px;
  }

  @media (min-width: 1024px) {
    max-width: 181px;
    height: 40px;
    margin-right: 24px;
  }
`;

export const RangeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 8px;
`;

export const RangeInput = styled.input`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  font-size: 14px;
  letter-spacing: 0.14px;
  text-align: center;
  border: solid 1px #c2c8d0;
  border-radius: 5px;
  outline: none;

  @media (max-width: 1023px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 100%;
    /* max-width: 56px; */
  }
`;
