import React from "react";
import { components } from "react-select";
import styled from "styled-components";
import Highlighter from "react-highlight-words";

const OptionIconContainer = styled.div`
  width: 28px;
  height: 32px;
  margin-right: 17px;
  background-color: var(--color-bg-3);
`;

const OptionIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const OptionText = styled.span`
  color: #7f8696;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.13px;
`;

export const SearchClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={16}
        style={{ marginRight: 4 }}
      >
        <rect width="16" height="16" fill="#ededed" rx="8" />
        <path
          fill="#9095a4"
          d="M5.5 11.1a.6.6 0 0 1-.424-1.024L7.14 8.012 5.077 5.964a.6.6 0 1 1 .845-.852l2.067 2.051 2.087-2.087a.6.6 0 1 1 .849.849L8.841 8.009l2.081 2.065a.6.6 0 1 1-.845.852L7.993 8.857l-2.068 2.068a.604.604 0 0 1-.425.175Z"
        />
      </svg>
    </components.ClearIndicator>
  );
};

export const SearchOption = (props) => {
  const input = props?.selectProps?.inputValue || "";
  const { icon, label } = props?.data;
  const { isSelected } = props;

  return (
    <components.Option {...props}>
      <OptionIconContainer>
        <OptionIcon src={icon} alt={label} />
      </OptionIconContainer>

      <OptionText $isSelected={isSelected}>
        <Highlighter
          highlightStyle={{ color: "#000", backgroundColor: "transparent" }}
          searchWords={[input]}
          autoEscape={true}
          textToHighlight={label}
        />
      </OptionText>
    </components.Option>
  );
};

export const searchSelectStyles = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: "100%",
    height: "100%",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    height: "100%",
    minHeight: 30,
    paddingLeft: 32,
    backgroundColor: "transparent",
    fontSize: 14,
    borderWidth: 0,
    borderRadius: 0,
    boxShadow: "none",
  }),
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    cursor: "pointer",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: "#8f95a5",
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: "#000",
    letterSpacing: "0.14px",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    border: 0,
    borderRadius: 5,
    boxShadow: "0 3px 20px 0 rgba(0, 0, 0, 0.05)",
    zIndex: 2,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    paddingTop: 8,
    paddingBottom: 8,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    display: "flex",
    alignItems: "center",
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 24,
    paddingRight: 8,
    backgroundColor:
      (state.isSelected && "#ededed") ||
      (state.isFocused && "var(--color-bg-2)"),
  }),
};
