import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 808px;
  margin: 0 auto;

  @media (max-width: 1023px) {
    padding: 56px 16px;
  }

  @media (min-width: 1024px) {
    padding: 104px 48px;
  }
`;

export const Title = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  color: #8f95a5;

  @media (max-width: 1023px) {
    margin-top: 16px;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
`;

export const OrderStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;

  @media (max-width: 1023px) {
    margin-top: 38px;
  }

  @media (min-width: 1024px) {
    margin-top: 56px;
  }
`;

export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  height: 28px;
  margin-top: 8px;
  padding: 0 8px;
  color: #8f95a5;

  &:hover {
    color: var(--color-accent);
  }

  @media (min-width: 1024px) {
    margin-left: auto;
    text-align: right;
  }
`;

export const SummaryTable = styled.table`
  width: 100%;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    margin-top: 48px;
  }

  thead > tr {
    background-color: #f5f4f8;
  }

  th {
    height: 64px;
  }

  th,
  td {
    padding: 0 24px;
  }
`;

export const SummaryTableCellContainer = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
`;

export const SummaryContainerMobile = styled.div`
  width: 100%;
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid #dcdfe9;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const AddressContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const AddressItem = styled.div`
  border: 1px solid #dcdfe9;

  @media (max-width: 1023px) {
    padding: 32px;

    &:first-child {
      border-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    padding: 35px 40px;

    &:first-child {
      border-right: 0;
    }
  }
`;
