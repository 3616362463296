import styled from "styled-components";

export const Root = styled.div`
  display: flex;

  @media (max-width: 1023px) {
  }

  @media (min-width: 1024px) {
    justify-content: center;
    padding: 16px 48px 64px;
  }
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    height: 480px;
  }

  @media (min-width: 1024px) {
    height: 640px;
  }
`;

export const Container = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: flex;
    column-gap: 48px;
    max-width: 1304px;
  }

  @media (min-width: 1400px) {
    column-gap: 96px;
  }
`;

export const ImagesContainer = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    width: 580px;
    column-gap: 24px;
  }
`;

export const DotsList = styled.ul`
  list-style: none;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const DotsButton = styled.button`
  width: 56px;
  height: 70px;
  background-color: var(--color-bg-3);

  &.--active {
    box-shadow: 0 0 0 2px #27166a;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  height: fit-content;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 500px;
  }

  .slick-slider {
    width: 100%;
  }
`;

export const ArrowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;

  @media (max-width: 1023px) {
    width: 32px;
    height: 32px;
    color: #fff;
    background-color: #000;
    top: 50%;
    left: ${(p) => p.$dir < 0 && "8px"};
    right: ${(p) => p.$dir > 0 && "8px"};
    font-size: 22px;
    transform: translateY(-50%);
  }

  @media (min-width: 1024px) {
    width: 24px;
    height: 24px;
    right: ${(p) => (p.$dir > 0 ? "24px" : "64px")};
    bottom: 24px;
    font-size: 24px;
  }
`;

export const Slide = styled.div`
  background-color: var(--color-bg-3);

  @media (max-width: 1023px) {
    height: 480px;
  }

  @media (min-width: 1024px) {
    height: 640px;
  }
`;

export const SlideImage = styled.img`
  object-fit: cover;
  cursor: zoom-in;

  @media (max-width: 1023px) {
    width: 100%;
    height: 480px;
  }

  @media (min-width: 1024px) {
    width: 500px;
    height: 640px;
  }
`;

export const SliderFeat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 0;
  height: 29px;
  padding: 0 12px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const AddToFavouriteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    display: ${(p) => p.$isDesktop && "none"};
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    flex-shrink: 0;
    display: ${(p) => p.$isMobile && "none"};
    width: 48px;
    height: 48px;
    margin-left: 24px;
    background-color: #f9f9f9;
    font-size: 24px;
    border-radius: 24px;
  }
`;

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    padding: 24px;
  }

  @media (min-width: 1400px) {
    padding: 0 24px;
  }
`;

export const BrandNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;

  @media (max-width: 1023px) {
    align-items: center;
    margin-bottom: 16px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 8px;
  }
`;

export const BrandNameLink = styled.a`
  color: var(--color-accent);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: underline;

  &:hover {
    color: #cb000e;
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 21px;
  border-radius: 50%;

  @media (min-width: 1024px) {
    transform: translateY(-8px);
  }

  &:hover,
  &.--isActive {
    background-color: var(--color-bg-3);
  }

  svg {
    pointer-events: none;
  }
`;

export const DescContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  @media (min-width: 1400px) {
    max-width: 472px;
  }
`;

export const DetailsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  list-style: none;

  @media (max-width: 1399px) {
    column-gap: 16px;
  }

  @media (min-width: 1400px) {
    column-gap: 32px;
  }

  span {
    color: var(--color-text-2);
  }
`;

export const MoreLessButton = styled.button`
  color: var(--color-accent);
  font: inherit;
  text-decoration: underline;
`;

export const IconsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 16px;
  margin-top: 40px;
  list-style: none;
`;

export const IconsItem = styled.li`
  display: flex;
  justify-content: center;
  width: 60px;
`;

export const IconsLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  color: #8f95a5;

  &:hover {
    color: #cb000e;

    svg path {
      fill: #cb000e;
    }
  }

  svg {
    font-size: 32px;
  }
`;

export const IconsText = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const InputButton = styled.button`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 19px;
  border: solid 1px #dcdfe9;
  border-radius: 2px;

  &:disabled {
    color: #dbdfea;
  }
`;

export const Input = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0 16px;
  padding: 0 8px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: solid 1px #dcdfe9;
  border-radius: 2px;
  -moz-appearance: textfield;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 64px;
  }
`;

export const AddContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`;

export const ZoomOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
  cursor: zoom-out;

  img {
    object-fit: contain;

    @media (max-width: 1023px) {
      max-width: 100%;
      max-height: 100%;
    }

    @media (min-width: 1024px) {
      max-width: 90%;
      max-height: 90%;
    }
  }
`;
