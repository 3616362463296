export const featuresData = (intl) => [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width="1em"
        height="1em"
      >
        <path
          fill="#b1aacb"
          d="M29.102 8.045c-1.455-.003-2.368 1.57-1.643 2.831.029.051.06.1.094.148a3.93 3.93 0 0 1-2.931.3c-1.336-.434-2.435-1.71-3.274-3.786 1.449-.198 2.141-1.89 1.245-3.046-.895-1.156-2.707-.909-3.26.444a1.9 1.9 0 0 0 .915 2.422c-.31.486-1.953 2.881-4.2 2.881s-3.875-2.398-4.19-2.884c1.307-.649 1.421-2.468.206-3.276-1.215-.807-2.848.004-2.94 1.459a1.896 1.896 0 0 0 1.634 1.997c-.837 2.068-1.932 3.345-3.263 3.782a3.965 3.965 0 0 1-2.941-.294c.888-1.223.119-2.948-1.383-3.106-1.503-.158-2.613 1.371-1.998 2.751a1.963 1.963 0 0 0 2.439 1.055l1.676 7.091v3.741c0 .295.239.534.534.534h20.463a.534.534 0 0 0 .534-.534v-3.739l1.676-7.091c.196.071.402.108.61.11 1.459.106 2.485-1.407 1.848-2.723a1.901 1.901 0 0 0-1.848-1.067h-.003Zm-8.014-3.206a.827.827 0 1 1 0 1.654.827.827 0 0 1 0-1.654Zm-10.9.827a.826.826 0 0 1 1.24-.716.826.826 0 1 1-1.24.716ZM2.173 9.94a.826.826 0 0 1 1.24-.716.826.826 0 1 1-1.24.716Zm13.879 12.076h-9.7v-2.73H25.75v2.73h-9.698Zm9.808-3.8H6.244l-1.4-5.921c.96.328 2 .342 2.969.04a6.44 6.44 0 0 0 3.637-3.708c.812 1.037 2.436 2.676 4.6 2.676 2.164 0 3.79-1.639 4.6-2.676a6.446 6.446 0 0 0 3.637 3.708 4.783 4.783 0 0 0 2.969-.04l-1.396 5.921Zm3.242-7.452a.826.826 0 0 1-.716-1.24.826.826 0 0 1 1.543.413.826.826 0 0 1-.827.829v-.002Z"
        />
      </svg>
    ),
    title: intl.formatMessage({
      defaultMessage: "No Cost, All Fun",
    }),
    desc: intl.formatMessage({
      defaultMessage:
        "Dive into our community groups at zero cost – it's all about your passion, no strings attached.",
    }),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width="1em"
        height="1em"
      >
        <path
          fill="#b1aacb"
          d="M16.368 13.762a.5.5 0 0 1 .5-.5h2.173a.5.5 0 0 1 .467.322l.568 1.482a.5.5 0 0 1-.935.359l-.445-1.161h-1.827a.5.5 0 0 1-.5-.5l-.001-.002Zm-4 8.047a.5.5 0 0 0-.462-.322H9.729a.5.5 0 0 0 0 1h1.829l.445 1.161a.5.5 0 0 0 .935-.358l-.57-1.481Zm18.319 5.322c-.296.43-.785.688-1.308.688h-12.1a1.581 1.581 0 0 1-1.189-.554c-.297.348-.731.55-1.189.554H2.793a1.587 1.587 0 0 1-1.482-2.154l1.982-5.174a2.142 2.142 0 0 1 1.99-1.368h3.535a1.516 1.516 0 0 1-.368-1.686l1.982-5.173a2.14 2.14 0 0 1 1.989-1.368h7.126a2.14 2.14 0 0 1 1.989 1.368l1.981 5.173a1.587 1.587 0 0 1-.174 1.467 1.663 1.663 0 0 1-.193.218h3.739a2.142 2.142 0 0 1 1.99 1.368l1.981 5.172a1.587 1.587 0 0 1-.174 1.467l.001.002Zm-12.912-6.64c.138-.355.368-.666.668-.9h-4.712c.3.234.53.545.668.9l1.686 4.408 1.69-4.408ZM9.45 18.337c.108.16.289.255.482.254h12.1c.41 0 .692-.411.546-.794l-1.972-5.174a1.134 1.134 0 0 0-1.052-.724h-7.133a1.134 1.134 0 0 0-1.052.724l-1.983 5.173a.577.577 0 0 0 .065.541H9.45Zm5.994 7.685-1.982-5.172a1.134 1.134 0 0 0-1.052-.724H5.282a1.134 1.134 0 0 0-1.052.724l-1.983 5.172a.585.585 0 0 0 .547.795h12.1c.41 0 .693-.412.547-.795h.003Zm14.479 0-1.979-5.172a1.134 1.134 0 0 0-1.052-.724h-7.128a1.134 1.134 0 0 0-1.052.724l-1.983 5.172a.586.586 0 0 0 .547.795h12.1a.585.585 0 0 0 .547-.795Zm-3.073-4.213a.5.5 0 0 0-.468-.322h-2.171a.5.5 0 0 0 0 1h1.829l.445 1.161a.501.501 0 0 0 .936-.358l-.571-1.481ZM16.086 8.015a.5.5 0 0 0 .5-.5V4.508a.5.5 0 1 0-1 0v3.006a.5.5 0 0 0 .5.501ZM5.278 13.371a.502.502 0 0 0 .578-.82l-2.45-1.728a.501.501 0 1 0-.578.819l2.45 1.729Zm21.328.1a.497.497 0 0 0 .289-.092l2.455-1.733a.502.502 0 0 0-.578-.819l-2.455 1.733a.5.5 0 0 0 .289.91v.001Z"
        />
      </svg>
    ),
    title: intl.formatMessage({
      defaultMessage: "VIP Deals Just for You",
    }),
    desc: intl.formatMessage({
      defaultMessage:
        "Enjoy exclusive discount codes and enter members-only contests.",
    }),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width="1em"
        height="1em"
      >
        <path
          fill="#b1aacb"
          d="M22.409 29.224H8.582a2.253 2.253 0 0 1-2.246-2.242v-4.051a.53.53 0 0 1 1.058 0v1.016h16.2v-7.848l-3.485-3.485a3.113 3.113 0 0 1-.902-2.653 3.078 3.078 0 0 1 1.081-1.945 3.148 3.148 0 0 1 2-.721c.664 0 1.31.217 1.84.617a3.058 3.058 0 0 1 1.814-.595 3.142 3.142 0 0 1 2.209.922c.581.58.905 1.369.9 2.19 0 .822-.327 1.61-.908 2.191l-3.485 3.485v10.88a2.25 2.25 0 0 1-2.249 2.239ZM7.395 25.005v1.976a1.19 1.19 0 0 0 1.187 1.187h13.827a1.19 1.19 0 0 0 1.186-1.186v-1.977h-16.2Zm5.919 2.195a.53.53 0 0 1 0-1.058h4.363a.53.53 0 1 1 0 1.058h-4.363Zm-7.306-5.309H4.336c-.768 0-1.39-.623-1.388-1.391v-4.9a1.39 1.39 0 0 1 1.388-1.386h1.673c.111.002.22.018.327.047V5.017a2.251 2.251 0 0 1 2.245-2.241h13.827a2.25 2.25 0 0 1 2.245 2.241v1.517a.53.53 0 1 1-1.059 0V5.017a1.19 1.19 0 0 0-1.187-1.187H8.581a1.19 1.19 0 0 0-1.183 1.187v10.192l.671-.166v-1.95a1.764 1.764 0 0 1 1.756-1.761c.972.001 1.76.789 1.761 1.761v1.83h1.7a1.387 1.387 0 0 1 1.35 1.724l-.862 3.477a1.381 1.381 0 0 1-1.344 1.053H8.908a.565.565 0 0 1-.108-.011l-1.453-.3a1.39 1.39 0 0 1-1.339 1.025Zm-1.672-6.615a.33.33 0 0 0-.329.328v4.9c0 .182.147.329.329.329h1.673c.18 0 .326-.144.329-.324v-4.906a.329.329 0 0 0-.329-.327H4.336ZM8.96 20.12h3.46a.328.328 0 0 0 .319-.25l.862-3.477a.328.328 0 0 0-.317-.408h-2.233a.53.53 0 0 1-.529-.529v-2.363a.699.699 0 0 0-.35-.606.683.683 0 0 0-.349-.1.7.7 0 0 0-.7.7v2.374a.53.53 0 0 1-.4.513l-.068.017-1.256.312v3.5l1.561.317ZM22.28 8.35c-.191 0-.381.026-.564.079-1.513.435-1.988 2.344-.855 3.437l3.267 3.267 3.266-3.265c1.135-1.092.661-3.003-.852-3.44a2.039 2.039 0 0 0-.576-.08 2.016 2.016 0 0 0-1.463.626.529.529 0 0 1-.75 0 2.06 2.06 0 0 0-1.473-.624Z"
        />
      </svg>
    ),
    title: intl.formatMessage({
      defaultMessage: "Mix, Mingle, Create",
    }),
    desc: intl.formatMessage({
      defaultMessage:
        "Get involved in our events and connect with fellow mavens in the hairdressing world.",
    }),
  },
];
