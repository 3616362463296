import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  Root,
  Container,
  ItemsGrid,
  ContinueContainer,
  EmptyContainer,
  UserCard,
  UserCardAvatar,
  UserCardBody,
  UserCardPoints,
} from "./CartView.style";

import { UserContext } from "../../context/UserContext";
import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { PageSubheader } from "../../components/PageSubheader/PageSubheader";
import { Typography } from "../../ui/Typography/Typography";
import { CartItem } from "../../components/CartItem/CartItem";
import { TotalDetails } from "../../components/TotalDetails/TotalDetails";
import { Button } from "../../ui/Button/Button";
import { ButtonLink } from "../../ui/ButtonLink/ButtonLink";
import { Link } from "../../components/Link/Link";
import { ExclusiveBrand } from "../../components/Sections/ExclusiveBrand/ExclusiveBrand";
import { TrendingRewards } from "../../components/Sections/TrendingRewards/TrendingRewards";
import { Footer } from "../../components/Footer/Footer";
import {
  toast,
  ToastNotification,
} from "../../ui/ToastNotification/ToastNotification";
import {
  GET_CART_DETAILS,
  UPDATE_CART,
} from "../RewardsView/rewardsViewConfig";
import { getUserAvatar } from "../../utils/user";
import { apiDomain, apiSiteId } from "../../config";
import { brands } from "../../config/brands";
import defaultAvatarSrc from "../../assets/images/avatar.png";

export const CartView = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    loading: cartLoading,
    error: cartError,
    data: cartData,
  } = useQuery(GET_CART_DETAILS, {
    variables: {
      locale: intl.locale,
    },
  });

  const [updateCart] = useMutation(UPDATE_CART, {
    refetchQueries: ["GetHeaderCartData", "GetCartDetails"],
  });

  const { user } = useContext(UserContext);

  const cart = cartData?.me?.cart;
  const pointsBalance = cartData?.me?.pointsBalance;
  const cartItems = cart?.items ?? [];

  // console.info({ pointsBalance, cart });

  const handleRemoveFromCart = ({ item }) => {
    return updateCart({
      variables: { productId: item._id, qty: 0, locale: intl.locale },
    })
      .then(() => {
        toast.dismiss();
        toast.warn(
          <ToastNotification>
            <FormattedMessage
              defaultMessage="{productName} removed from cart."
              values={{ productName: item?.n }}
            />
          </ToastNotification>
        );
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(
          <ToastNotification>
            <FormattedMessage
              defaultMessage="An error occurred while removing {productName} from cart. Please refresh and try again."
              values={{ productName: item?.n }}
            />
          </ToastNotification>
        );
        console.error(err);
        Sentry.captureException(err);
      });
  };

  const handleQtyUpdate = ({ item, qty }) => {
    return updateCart({
      variables: { productId: item._id, qty, locale: intl.locale },
    })
      .then(() => {
        toast.dismiss();
        toast.warn(
          <ToastNotification>
            <FormattedMessage
              defaultMessage="{productName} quantity updated."
              values={{ productName: item?.n }}
            />
          </ToastNotification>
        );
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(
          <ToastNotification>
            <FormattedMessage
              defaultMessage="An error occurred while updating qty for {productName}. Please refresh and try again."
              values={{ productName: item?.n }}
            />
          </ToastNotification>
        );
        console.error(err);
        Sentry.captureException(err);
      });
  };

  const results = cartLoading
    ? "Loading..."
    : `${cartItems.length ?? 0} ${
        cartItems.length === 1
          ? intl.formatMessage({ defaultMessage: "product" })
          : intl.formatMessage({ defaultMessage: "products" })
      }`;

  return (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          { to: "/", label: intl.formatMessage({ defaultMessage: "Home" }) },
          { to: null, label: intl.formatMessage({ defaultMessage: "Cart" }) },
        ]}
      />
      <PageTitle title={intl.formatMessage({ defaultMessage: "Cart" })} />
      {cartItems?.length > 0 && (
        <PageSubheader>
          <Typography type="p4" color="#8f95a5">
            {results}
          </Typography>
        </PageSubheader>
      )}

      <Root>
        {cartItems?.length > 0 ? (
          <Container>
            <ItemsGrid>
              {cartItems.map((item, i) => {
                const product = item?.product;
                const brandConfig = brands.find(
                  (b) => b.id === product.brand?._id
                );
                const imgSrc =
                  product?.gallery?.[0]?.media?._id != null
                    ? `https://${apiDomain}/${apiSiteId}/asset/${product.gallery[0].media._id}?type=fill&width=604&height=752`
                    : `https://picsum.photos/id/${i + 50}/1000/1000`;
                return (
                  <CartItem
                    key={item._id}
                    imageSrc={imgSrc}
                    brandName={brandConfig?.label}
                    brandUrl={`/brands/${brandConfig?.slug}`}
                    productName={product?.n}
                    subtotalPts={item?.totals?.pointsSubTotal?.amount}
                    pts={product?.ecommerce?.price?.amount}
                    oldPts={product?.ecommerce?.fullPrice?.amount}
                    qty={item?.qty}
                    onRemove={() => handleRemoveFromCart({ item: product })}
                    onUpdateQty={(val) =>
                      handleQtyUpdate({ item: product, qty: val })
                    }
                  />
                );
              })}
            </ItemsGrid>

            <TotalDetails
              subtotalPts={cart?.totals?.pointsTotal?.amount}
              totalPts={cart?.totals?.pointsTotal?.amount}
              shipping={Math.floor(cart?.totals?.shippingTotal?.amount / 100)}
            />

            {pointsBalance < cart?.totals?.pointsTotal?.amount && (
              <Typography
                type="text-label"
                color="#e1000f"
                style={{ textAlign: "right", marginBottom: "12px" }}
              >
                <FormattedMessage defaultMessage="You don’t have enough points for this order" />
              </Typography>
            )}
            <UserCard>
              <UserCardBody>
                <UserCardAvatar>
                  <img src={getUserAvatar(user) || defaultAvatarSrc} />
                </UserCardAvatar>
                <Typography type="text-small" color="#8f95a5">
                  <FormattedMessage defaultMessage="Available Points" />
                </Typography>
              </UserCardBody>
              <UserCardPoints>
                {pointsBalance.toLocaleString("en-US")}{" "}
                <FormattedMessage defaultMessage="PTS" />
              </UserCardPoints>
            </UserCard>
            <Button
              variant="auth"
              arrow={false}
              disabled={pointsBalance < cart?.totals?.pointsTotal?.amount}
              onClick={() => navigate("/checkout")}
            >
              <FormattedMessage defaultMessage="go to checkout" />
            </Button>

            <ContinueContainer>
              <ButtonLink as={Link} to="/rewards" variant="secondary">
                <FormattedMessage defaultMessage="Continue Shopping" />
              </ButtonLink>
            </ContinueContainer>
          </Container>
        ) : (
          <EmptyContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              width={64}
              height={64}
            >
              <path
                fill="#dbdfea"
                stroke="#fff"
                strokeWidth="2"
                d="M18.5 38.75H59V21.5l-35.965-9.636-.573-.077-6.212-.022V5H5v4.5h6.75v38.25h4.006c-3.495 3.845-1.518 10.032 3.559 11.136 5.078 1.105 9.447-3.701 7.865-8.65a6.755 6.755 0 0 0-1.435-2.486h19.261c-3.495 3.845-1.518 10.032 3.559 11.136 5.078 1.105 9.447-3.701 7.865-8.65a6.755 6.755 0 0 0-1.435-2.486H59v-4.5H16.25V41l2.25-2.25Zm2.25 15.75c-1.732 0-2.815-1.875-1.949-3.375.866-1.5 3.032-1.5 3.898 0A2.252 2.252 0 0 1 20.75 54.5Zm29.25 0c-1.732 0-2.815-1.875-1.949-3.375.866-1.5 3.032-1.5 3.898 0A2.252 2.252 0 0 1 50 54.5ZM16.25 16.266l5.9.022 32.35 8.665v9.3H16.25V16.266Z"
              />
            </svg>
            <Typography
              type="text-small"
              color="#8f95a5"
              style={{ marginTop: 32, marginBottom: 16 }}
            >
              <FormattedMessage defaultMessage="Your cart is empty" />
            </Typography>
            <Button as={Link} to="/rewards">
              <FormattedMessage defaultMessage="shop rewards" />
            </Button>
          </EmptyContainer>
        )}
      </Root>

      <ExclusiveBrand />
      <TrendingRewards
        title={intl.formatMessage({
          defaultMessage: "Trending Rewards",
        })}
      />
      <Footer />
    </Layout>
  );
};
