import styled from "styled-components";

export const Root = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    padding-top: 300px;
    background-size: auto 300px;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    height: 575px;
    background-size: auto 100%;
  }
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;

  @media (max-width: 1023px) {
    height: 300px;
  }

  @media (min-width: 1024px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1142px;
    padding: 0 56px;
  }
`;

export const ContentContainer = styled.div`
  @media (max-width: 1023px) {
    padding: 40px 32px;
    color: #000;
  }

  @media (min-width: 1024px) {
    width: 378px;
    padding-top: 64px;
    color: #fff;
  }
`;

export const HeadingImg = styled.img`
  margin-bottom: 32px;

  @media (max-width: 1023px) {
    max-width: 390px;

    &.--desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    &.--mobile {
      display: none;
    }
  }
`;

export const Form = styled.form`
  position: relative;

  @media (max-width: 1023px) {
    margin: 48px 0;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
    margin-bottom: 40px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 332px;
  height: 32px;
  background-color: #fff;
  border-radius: 16px;

  @media (max-width: 1023px) {
    border: solid 1px #b0abc8;
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 100%;
  padding-left: 16px;
  padding-right: 8px;
  color: #000;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;

  &::placeholder {
    color: #b1aacb;
  }
`;

export const RegisteredBadge = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: fit-content;
  height: 32px;
  margin-top: 32px;
  margin-bottom: 40px;
  padding: 8px 16px 8px 8px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 16px;

  @media (max-width: 1023px) {
    color: #fff;
    background-color: #27166a;
  }

  @media (min-width: 1024px) {
    color: #27166a;
    background-color: #fff;
  }
`;
