import styled from "styled-components";

export const Root = styled.ul`
  padding-left: ${(p) =>
    (p.as === "ul" && "26px") || (p.as === "ol" && "16px")};

  li {
    padding-left: 4px;
  }

  li::marker {
    font-size: 12px;
    font-weight: 700;
  }
`;
