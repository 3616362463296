import styled from "styled-components";

export const Root = styled.div`
  display: grid;
  grid-template-columns: 78px 1fr;
  column-gap: 24px;
`;

export const Image = styled.img`
  width: 78px;
  height: 104px;
  object-fit: cover;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BrandName = styled.a`
  color: #e1000f;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: underline;

  @media (max-width: 1023px) {
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }

  &:hover {
    color: #cb000e;
  }
`;

export const ProductName = styled.div`
  font-weight: 500;
  line-height: 1.38;

  @media (max-width: 1023px) {
    font-size: 13px;
  }

  @media (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const QtyPts = styled.span`
  font-weight: 500;
  line-height: 1.6;

  @media (max-width: 1023px) {
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;

export const TitleContainer = styled.div`
  margin-top: 14px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Subtotal = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    column-gap: 4px;
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    text-align: right;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;

  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`;

export const RemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 20px;

  @media (max-width: 1023px) {
    margin-left: 24px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InputButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  border: solid 1px var(--color-border-1);
  border-radius: 2px;

  &:disabled {
    color: var(--color-border-1);
  }
`;

export const Input = styled.input`
  width: 40px;
  height: 24px;
  margin: 0 8px;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  border: solid 1px var(--color-border-1);
  border-radius: 2px;
  outline: none;
`;
