import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  max-width: 336px;

  #sms {
    scroll-margin-top: 150px;
  }

  #birthday {
    scroll-margin-top: 100px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdfe9;
`;

export const DistributorList = styled.ul`
  margin-top: 18px;
  padding-top: 42px;
  list-style: none;
  border-top: 1px solid #dcdfe9;
`;

export const DistributorItem = styled.li`
  margin-bottom: 8px;
`;

export const DistributorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const DistributorTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

export const DistributorTooltip = styled.div`
  display: flex;
  margin-left: 4px;
  color: #8f95a5;
  font-size: 17px;
`;

export const DistributorRemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 20px;
`;

export const EarnBonusPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 0 8px;
`;

export const Table = styled.table`
  width: 100%;

  th,
  td {
    padding: 26px 24px;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  th {
    color: #8f95a5;
    background-color: #f5f4f8;
  }

  td {
    border-bottom: 1px solid #dcdfe9;
  }
`;

export const TableLink = styled.button`
  text-align: left;
  cursor: pointer;

  &:hover {
    color: var(--color-accent);
    text-decoration: underline;
  }
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    height: 480px;
  }

  @media (min-width: 1024px) {
    height: 640px;
  }
`;
