import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
// import { navigate } from "gatsby";
// import { Button, Modal } from "@uplft/react-ui-kit";
import * as Sentry from "@sentry/react";
import {
  signIn,
  signOut,
  getUserFromStorage,
  currentAuthenticatedUser,
  isLoggedIn,
} from "../utils/auth";
import EventHub from "../utils/events/EventHub";
import Event from "../utils/events/Event";
// import { ModalContext } from "./ModalContext";
// import Login from '../components/Auth/Login';
// import CountdownTimer from "../components/CountdownTimer/CountdownTimer";

export const UserContext = React.createContext({ user: null });
export const UserConsumer = UserContext.Consumer;

const UserProvider = ({ children }) => {
  const loggedIn = isLoggedIn();
  const [state, setState] = useState({
    user: loggedIn ? getUserFromStorage() : null,
    isAdmin: false,
    lastUserEvent: null,
    isLoggedIn,
    initialized: false,
    signIn: (userInput) => signIn(userInput),
    signOut,
    isActive: false,
  });

  // const { pushModalQueue, popModalQueue } = useContext(ModalContext);

  const onAuthEvent = ({ event, action, data }) => {
    // console.info({ onAuthEvent: { event, action, data } });
    const eventName = `${event}.${action}`;
    switch (eventName) {
      case "auth.userStateChange":
        break;
      case "auth.signIn":
      case "auth.currentAuthenticatedUser":
        Sentry.setUser({ email: data?.email });
        setState((prevState) => ({
          ...prevState,
          lastUserEvent: { eventName, date: new Date() },
          user: data,
          isLoggedIn: data != null,
          isAdmin: (data.roles || []).includes("admin"),
        }));
        // TODO: any additionoal lookups after initial load
        break;
      case "auth.signUp":
        // do nothing for now
        break;
      case "auth.signOut":
        Sentry.setUser(null);
        setState((prevState) => ({
          ...prevState,
          lastUserEvent: { eventName, date: new Date() },
          isLoggedIn: false,
          user: null,
        }));

        break;
      // case "auth.sessionExpiring":
      //   pushModalQueue(
      //     <Modal
      //       // onClose={() => popModalQueue()}
      //       closeOnClickBackground={false}
      //       title={
      //         <span>
      //           Your session is about to expire in{" "}
      //           <CountdownTimer
      //             expire={60}
      //             onExpire={() => {
      //               popModalQueue();
      //               state.signOut();
      //               navigate("/");
      //             }}
      //           />{" "}
      //           seconds
      //         </span>
      //       }
      //     >
      //       <Button
      //         style={{ marginBottom: 16 }}
      //         onClick={() => popModalQueue()}
      //       >
      //         Stay Logged In
      //       </Button>
      //       <Button
      //         variant="secondary"
      //         onClick={() => {
      //           popModalQueue();
      //           state.signOut();
      //           navigate("/");
      //         }}
      //       >
      //         Logout
      //       </Button>
      //     </Modal>
      //   );
      //   break;
      default:
        // do nothing
        break;
    }
  };

  // const activity = activityListener({
  //   callback(e) {
  //     setState(prevState => ({ ...prevState, isActive: e.active }));
  //     const trustDevice = Cookies.get('trustDevice', { path: '/' });
  //     if (e.active === false && state.user != null && trustDevice !== 'true') {
  //       EventHub.dispatch({ event: Event.AUTH, action: 'sessionExpiring' });
  //     }
  //   },
  //   inactiveUserTimeThreshold,
  //   userActivityThrottlerTime: 1000 * 5, // 5 seconds in milliseconds
  //   userActivityStorageKey: 'active',
  // });

  useEffect(() => {
    EventHub.listen(Event.AUTH, onAuthEvent);
    // activity.listen();
    currentAuthenticatedUser().finally(() => {
      setState((prevState) => ({ ...prevState, initialized: true }));
    });
    return () => {
      EventHub.remove(Event.AUTH, onAuthEvent);
      // EventHub.close();
      // activity.remove();
    };
  }, []);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default UserProvider;
