import styled from "styled-components";
import DiscoverImageSrc from "../../../assets/images/homepage/discover.jpg";

export const Root = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url(${DiscoverImageSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 1023px) {
    height: 400px;
    padding: 32px 8px;
  }

  @media (min-width: 1024px) {
    height: 450px;
    padding: 32px;
  }
`;

export const Title = styled.div`
  width: 100%;
  color: #fff;
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 375px;
  }

  @media (min-width: 1024px) {
    max-width: 524px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 1023px) {
    gap: 8px;
  }

  @media (min-width: 1024px) {
    gap: 16px;
  }
`;
