import styled, { css } from "styled-components";

export const Root = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  padding-left: 16px;
  padding-right: ${(p) => (p.$withArrow ? "32px" : "16px")};
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 16px;
  transition: all 150ms;

  ${(p) =>
    p.$variant === "primary" &&
    css`
      height: 32px;
      color: #fff;
      background-color: #e1000f;
      border: 1px solid #e1000f;

      @media (max-width: 1023px) {
        font-size: 12px;
        letter-spacing: 0.12px;
      }

      @media (min-width: 1024px) {
        padding-top: 1px;
        font-size: 14px;
      }

      &:hover {
        background-color: #cb000e;
        border: 1px solid #cb000e;
      }

      &:disabled {
        background-color: #dbdfea;
        border: 1px solid #dbdfea;
        cursor: default;
      }
    `}

  ${(p) =>
    p.$variant === "secondary" &&
    css`
      height: 32px;
      color: #fff;
      background-color: transparent;
      border: 1px solid #fff;

      @media (max-width: 1023px) {
        font-size: 12px;
        letter-spacing: 0.12px;
      }

      @media (min-width: 1024px) {
        padding-top: 1px;
        font-size: 14px;
      }

      &:hover {
        color: #e1000f;
        background-color: #fff;
        border: 1px solid #fff;
      }

      &:disabled {
        color: #dbdfea;
        border: 1px solid #dcdfe9;
        cursor: default;
      }
    `}

  ${(p) =>
    p.$variant === "auth" &&
    css`
      justify-content: center;
      width: 100%;
      height: 48px;
      color: #fff;
      background-color: #000;
      font-size: 14px;
      font-weight: 700;
      border-radius: 24px;

      &:disabled {
        background-color: #dbdfea;
        cursor: default;
      }
    `}

  ${(p) =>
    p.$variant === "action" &&
    css`
      justify-content: center;
      width: 100%;
      height: 32px;
      color: #8f95a5;
      border: solid 1px #dcdfe9;
      border-radius: 16px;

      &:hover,
      &:focus {
        border: solid 1px #7f8696;
      }

      &:disabled {
        color: #dbdfea;
        border: solid 1px #dbdfea;
        pointer-events: none;
        cursor: default;
      }

      @media (max-width: 1023px) {
        font-size: 12px;
      }

      @media (min-width: 1024px) {
        font-size: 14px;
      }
    `}
`;

export const Arrow = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
`;
