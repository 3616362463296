import React from "react";
import { useIntl } from "react-intl";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Helmet } from "react-helmet-async";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { CommunityHero } from "../../components/Sections/CommunityHero/CommunityHero";
import { Features } from "../../components/Sections/Features/Features";
import { Community } from "../../components/Sections/Community/Community";
import { Social } from "../../components/Sections/Social/Social";
import { InstagramGrid } from "../../components/Sections/Social/InstagramGrid/InstagramGrid";
import { TiktokGrid } from "../../components/Sections/Social/TiktokGrid/TiktokGrid";
import { YoutubeGrid } from "../../components/Sections/Social/YoutubeGrid/YoutubeGrid";
import { FacebookGrid } from "../../components/Sections/Social/FacebookGrid/FacebookGrid";
import { WeAreClub } from "../../components/Sections/WeAreClub/WeAreClub";
import { Faq } from "../../components/Sections/Faq/Faq";
import { Footer } from "../../components/Footer/Footer";

import { featuresData } from "./config";

export const CommunityView = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Helmet>
        <title>Community</title>
      </Helmet>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          {
            to: null,
            label: intl.formatMessage({
              defaultMessage: "Community",
            }),
          },
        ]}
      />

      <CommunityHero />
      <Features
        title={intl.formatMessage({
          defaultMessage: "Perks of the Club H Community",
        })}
        items={featuresData(intl)}
      />
      <Community />
      <Social
        title={intl.formatMessage({
          defaultMessage: "Discover Hair Inspo on Instagram",
        })}
        icon="instagram"
      >
        <InstagramGrid />
      </Social>
      {/* <Social title="Get amazing tips on TikTok" icon="tiktok" withBackground>
        <TiktokGrid />
      </Social> */}
      <Social
        title={intl.formatMessage({
          defaultMessage: "Dive into Tutorials on YouTube",
        })}
        icon="youtube"
        followUrl="https://www.youtube.com/@ClubHrewards"
        withBackground
      >
        <YoutubeGrid />
      </Social>
      {/* <Social
        title="Don’t miss a thing on Facebook"
        icon="facebook"
        withBackground
      >
        <FacebookGrid />
      </Social> */}
      <WeAreClub />
      <Faq />
      <Footer />
    </Layout>
  );
};
