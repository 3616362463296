import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  @media (min-width: 1024px) {
    padding: 120px 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .slick-slider {
    width: 100%;
    margin-bottom: 56px;

    @media (max-width: 1023px) {
      max-width: 375px;
    }

    @media (min-width: 1024px) {
      max-width: 947px;
    }

    @media (min-width: 1400px) {
      max-width: 1328px;
    }
  }

  .slick-prev,
  .slick-next {
    top: 108px;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center;
    transform: none;
    z-index: 1;

    &::before {
      display: none;
    }

    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
      background-size: 20px;
    }

    @media (min-width: 1024px) {
      width: 40px;
      height: 40px;
      background-size: 24px;
    }
  }

  .slick-prev {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' d='m9.1 17.11-4.809-4.027.578-.484H22v-1.198H4.842l-.563-.472L9.1 6.891l-1.01-.846L2 11.148v1.706l6.093 5.1L9.1 17.11Z'/%3E%3C/svg%3E");

    @media (max-width: 1023px) {
      left: 7px;
    }

    @media (min-width: 1024px) {
      left: 10px;
    }

    @media (min-width: 1400px) {
      left: 12px;
    }
  }

  .slick-next {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' d='m14.9 6.889 4.809 4.027-.578.484H2v1.198h17.158l.563.472-4.821 4.038 1.01.846L22 12.851v-1.706l-6.093-5.1-1.007.844Z'/%3E%3C/svg%3E");

    @media (max-width: 1023px) {
      right: 6px;
    }

    @media (min-width: 1024px) {
      right: 7px;
    }

    @media (min-width: 1400px) {
      right: 8px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    margin-bottom: 48px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 56px;
  }
`;

export const Slide = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 0 7px;
  }

  @media (min-width: 1024px) {
    padding: 0 10px;
  }

  @media (min-width: 1400px) {
    padding: 0 12px;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  position: relative;
  background-color: var(--color-bg-3);
`;

export const Image = styled.img`
  display: block;
  object-fit: cover;
  height: 248px;
`;

export const SlideBrandName = styled.div`
  margin: 8px 0;
`;

export const Price = styled.p`
  margin-top: 6px;
`;

export const AddToFavouriteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  z-index: 1;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 0;
  height: 27px;
  padding: 0 12px;
  color: #fff;
  background-color: #000;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  text-transform: uppercase;
`;
