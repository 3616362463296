import styled from "styled-components";

export const Root = styled.label`
  display: block;
  position: relative;
  margin-bottom: 7px;
  padding-left: 32px;
  user-select: none;
  cursor: pointer;
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  min-height: 18px;

  a,
  button {
    color: var(--color-accent);
    text-decoration: underline;
  }
`;

export const CheckmarkContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: solid 1px var(--color-accent);
  border-radius: 8px;
`;

export const Checkmark = styled.span`
  height: 8px;
  width: 8px;
  color: transparent;
  border-radius: 4px;
`;

export const Input = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
  cursor: pointer;

  &:checked + ${CheckmarkContainer} > ${Checkmark} {
    background-color: var(--color-accent);
  }
`;

export const Error = styled.span`
  color: #cf2b23;
  font-size: 12px;
`;
