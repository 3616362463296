import React from "react";
import Alterna from "../assets/images/brands/logo-alterna.svg?react";
import Authentic from "../assets/images/brands/logo-authentic-beauty-concept.svg?react";
import Deva from "../assets/images/brands/logo-deva-curl.svg?react";
import Joico from "../assets/images/brands/logo-joico.svg?react";
import Kenra from "../assets/images/brands/logo-kenra-professional.svg?react";
import MyIdentity from "../assets/images/brands/logo-mydentiy.svg?react";
import Pravana from "../assets/images/brands/logo-pravana.svg?react";
import Schwarzkopf from "../assets/images/brands/logo-schwarzkopf.svg?react";
import Sexyhair from "../assets/images/brands/logo-sexyhair.svg?react";
import Stmnt from "../assets/images/brands/logo-stmnt.svg?react";
// import Zotos from "../assets/images/brands/logo-zotos.svg?react";

export const brands = [
  {
    slug: "alterna",
    label: "Alterna",
    icon: <Alterna />,
    id: "6525b86685b23b704a2d18e3",
    pillar: 1,
    sort: 1,
    order: 7,
  },
  {
    slug: "authenticbeautyconcept",
    label: "Authentic Beauty Concept",
    icon: <Authentic />,
    id: "6525b8d285b23b704a2d1923",
    pillar: 2,
    order: 9,
  },
  {
    slug: "devacurl",
    label: "DevaCurl",
    icon: <Deva />,
    id: "6525b88585b23b704a2d18f3",
    pillar: 1,
    order: 4,
  },
  {
    slug: "joico",
    label: "Joico",
    icon: <Joico />,
    id: "6525b8de85b23b704a2d1933",
    pillar: 2,
    order: 2,
  },
  {
    slug: "kenra",
    label: "Kenra Professional",
    icon: <Kenra />,
    id: "6525b90a85b23b704a2d1963",
    pillar: 3,
    order: 3,
  },
  {
    slug: "mydentity",
    label: "MyDentity",
    icon: <MyIdentity />,
    id: "6525b93b85b23b704a2d1973",
    pillar: 3,
    order: 10,
  },
  {
    slug: "pravana",
    label: "Pravana",
    icon: <Pravana />,
    id: "6525b90385b23b704a2d1953",
    pillar: 3,
    order: 6,
  },
  {
    slug: "schwarzkopf",
    label: "Schwarzkopf Professional",
    icon: <Schwarzkopf />,
    id: "6525b85c85b23b704a2d18d3",
    pillar: 1,
    order: 1,
  },
  {
    slug: "sexyhair",
    label: "SexyHair",
    icon: <Sexyhair />,
    id: "6525b8b085b23b704a2d1913",
    pillar: 3,
    order: 5,
  },
  {
    slug: "stmnt",
    label: "STMNT Grooming Goods",
    icon: <Stmnt />,
    id: "6525b8ee85b23b704a2d1943",
    pillar: 2,
    order: 8,
  },
  // {
  //   slug: "zotos",
  //   label: "Zotos",
  //   icon: <Zotos />,
  //   id: "6525b89885b23b704a2d1903",
  //   pillar: 2,
  // },
];
