import React from "react";
import PropTypes from "prop-types";

import { Root, Quote, Caption } from "./Citation.style";
import { Typography } from "../Typography/Typography";

export const Citation = ({ quote = "", caption = "" }) => {
  return (
    <Root>
      <Quote>
        <Typography type="h4">“{quote}”</Typography>
      </Quote>
      <Caption>
        <Typography type="text-small">{caption}</Typography>
      </Caption>
    </Root>
  );
};

Citation.propTypes = {
  quote: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
