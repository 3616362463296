import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "../../Link/Link";
import Slider from "react-slick";
import { useQuery } from "@apollo/client";
import { ContentItem } from "../../ContentItem/ContentItem.jsx";

import {
  Root,
  Title,
  Desc,
  Slide,
  Image,
  BrandName,
  SlideTitle,
  SlideDesc,
} from "./ProposalSlider.style";

import { Button } from "../../../ui/Button/Button";
import { Typography } from "../../../ui/Typography/Typography";

import { GET_CONTENT } from "../../../views/ContentView/contentViewConfig";
import { apiDomain, apiSiteId } from "../../../config/index.js";
import { brands } from "../../../config/brands.jsx";

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const ProposalSlider = ({
  variant = "black",
  title = "",
  desc = "",
  ctaText = "",
  ctaUrl = "/",
  filters = '{ "type": ["education", "news"] }',
  sort = [
    { key: "isFeatured", direction: "1" },
    { key: "startDate", direction: "-1" },
    { key: "dc", direction: "-1" },
  ],
  limit = 8,
  descLimit = 150,
}) => {
  const intl = useIntl();
  const locale = intl.locale;

  const {
    loading: contentLoading,
    error: contentError,
    data: contentData,
  } = useQuery(GET_CONTENT, {
    variables: {
      sort,
      filters,
      limit,
      page: 1,
      locale,
    },
  });

  const data = contentData?.content?.findClubH;
  // console.info({ contentData, data });

  return (data?.docs || []).length === 0 ? null : (
    <Root className={`--${variant}`}>
      <Title className={`--${variant}`}>
        <Typography as="h2" type="h2">
          {title}
        </Typography>
      </Title>
      <Desc className={`XXX --${variant}`}>
        <Typography as="p" type="text-primary">
          {desc}
        </Typography>
      </Desc>

      <Slider {...sliderSettings}>
        {(data?.docs || []).map((item) => {
          const imgSrc =
            item?.gallery?.[0]?.media?._id != null
              ? `https://${apiDomain}/${apiSiteId}/asset/${item.gallery[0].media._id}?type=fill&width=604&height=752`
              : `https://picsum.photos/id/${i + 50}/1000/1000`;
          const brandConfig = brands.find((b) => b.id === item.brand?._id);
          const brandName = brandConfig?.label ?? item.brand?.n;
          const brandSlug = brandConfig?.slug;

          return (
            <Slide key={item._id}>
              <ContentItem
                variant={variant}
                imageSrc={imgSrc}
                videoSrc={item?.data?.youtubeUrl} //https://youtu.be/mRVH1hpjak0?si=fyK8kjIvXz1ev1mk
                brandName={brandName ?? "Club H"}
                brandUrl={brandSlug ? `/brands/${brandSlug}` : "/experience"}
                title={item?.n}
                desc={item?.desc}
                url={`/${item?.type}/${item.sl}`}
              />
              {/* <Link to={`/${item.type}/${item.sl}`}>
                <Image src={imgSrc} alt={item?.n} />
              </Link>
              <Link to={`/brands/${brandSlug}`}>
                <BrandName>{brandName}</BrandName>
              </Link>
              <Link to={`/${item.type}/${item.sl}`}>
                <SlideTitle className={`--${variant}`}>
                  <Typography type="h3">{item?.n}</Typography>
                </SlideTitle>
                <SlideDesc className={`--${variant}`}>
                  <Typography as="p" type="text-primary">
                    {`${item?.desc}`.length > descLimit
                      ? `${item?.desc.substring(0, descLimit).trim()}…`
                      : item?.desc}{" "}
                    <FormattedMessage
                      defaultMessage="read more"
                      tagName="span"
                    />
                  </Typography>
                </SlideDesc>
              </Link> */}
            </Slide>
          );
        })}
      </Slider>

      {ctaUrl && ctaText && (
        <Link to={ctaUrl}>
          <Button variant="primary">{ctaText}</Button>
        </Link>
      )}
    </Root>
  );
};

ProposalSlider.propTypes = {
  variant: PropTypes.oneOf(["pattern-light", "pattern-dark", "black"]),
};
