import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Container,
  TitleContainer,
  Title,
  Desc,
  UsersGrid,
  UserItem,
} from "./Community.style";

import { Button } from "../../../ui/Button/Button";
import { Typography } from "../../../ui/Typography/Typography";
import { Link } from "../../Link/Link";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../ui/Tooltip/Tooltip";

import { communityUsers } from "../../../config/community";
import { UserContext } from "../../../context/UserContext";

export const Community = () => {
  const { user } = useContext(UserContext);

  return (
    <Root>
      <Container>
        <TitleContainer>
          <Title>
            <Typography as="h2" type="h2">
              <FormattedMessage
                defaultMessage="Join the ranks of over 50,000 hair styling enthusiasts"
                values={{ br: <br /> }}
              />
            </Typography>
          </Title>
          <Desc>
            <Typography as="p" type="text-primary">
              <FormattedMessage defaultMessage="Become a part of our vibrant Club H community. Engage with your fellow style innovators and share in the excitement of the latest trends every day. Connect, learn, and grow alongside the best in the beauty biz." />
            </Typography>
          </Desc>
          {!user && (
            <Button as={Link} to="/auth">
              <FormattedMessage defaultMessage="join the community" />
            </Button>
          )}
        </TitleContainer>

        <UsersGrid>
          {communityUsers.map((user, idx) => (
            <UserItem
              key={idx}
              className={
                ((idx >= 0 && idx <= 5) || (idx >= 12 && idx <= 17)) &&
                "--offset"
              }
              as="a"
              href={user.link}
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip placement="top-center">
                <TooltipTrigger>
                  <img src={user.image} alt={user.name} />
                </TooltipTrigger>
                <TooltipContent>{user.name}</TooltipContent>
              </Tooltip>
            </UserItem>
          ))}
        </UsersGrid>
      </Container>
    </Root>
  );
};
