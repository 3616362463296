import styled from "styled-components";

export const ButtonDropdown = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  height: 28px;
  padding: 0 8px;
  color: #8f95a5;

  &:hover {
    color: #000;
  }

  &:focus,
  &:active {
    color: var(--color-accent);
  }

  &:disabled {
    color: #dbdfea;
  }
`;
