import React from "react";

import { UploadContainer } from "./AuthRegister.style";

import { AvatarUploader } from "../AvatarUploader/AvatarUploader";

export function Step3({ control }) {
  return (
    <UploadContainer>
      <AvatarUploader control={control} showAcceptedFilesInfo />
    </UploadContainer>
  );
}
