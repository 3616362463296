import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  column-gap: 4px;

  & + & {
    margin-top: 6px;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 4px;
`;

export const Tag = styled.div`
  display: flex;
  color: var(--color-accent);

  &:not(:last-child)::after {
    content: ",";
    font-size: 17px;
    font-weight: 500;
    line-height: 1.85;
    letter-spacing: 0.17px;
  }
`;
