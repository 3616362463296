import styled from "styled-components";

import imgSrc from "../../assets/images/order-confirmation-desktop.jpg";

export const Root = styled.div`
  flex-grow: 1;
  min-height: 550px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 88px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  background-image: url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1023px) {
    height: 200px;
    background-position-y: 40%;
  }

  @media (min-width: 1024px) {
    background-position: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  text-align: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;

  @media (max-width: 1023px) {
    padding-top: 60px;
  }
`;
