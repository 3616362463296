import styled from "styled-components";

export const Hero = styled.div`
  position: relative;
`;

export const HeroImage = styled.div`
  width: 100%;
  /* background-image: url(${heroBgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 100%; */

  @media (max-width: 1023px) {
    height: 248px;
  }

  @media (min-width: 1024px) {
    height: 735px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const HeroCard = styled.div`
  color: #fff;
  background-color: var(--color-primary);

  @media (max-width: 1023px) {
    padding: 32px 24px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 596px;
    padding: 32px 48px;
  }
`;

export const HeroCta = styled.div`
  @media (max-width: 1023px) {
    margin-top: 24px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const HairStylingImage = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;

  @media (max-width: 1023px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 2000ms cubic-bezier(0.16, 0.85, 0.45, 1);
  }

  &:hover img {
    transform: scale(1.1);
  }
`;
