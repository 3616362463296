import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { debounce, sortBy } from "lodash";
import { motion, AnimatePresence } from "framer-motion";

import {
  Root,
  Header,
  Logo,
  Close,
  Form,
  InputLabel,
  Input,
  InputClearButton,
  ClearAllButtonContainer,
  BrandsContainer,
  Checkbox,
  BrandsAction,
  ResultsGrid,
  ResultsHeaderContainer,
  ResultsHeader,
  ResultsContainer,
  CategoriesContainer,
  CategoriesLinks,
  CategoriesLink,
  ResultsHeaderIcon,
  NoResultsContainer,
} from "./ModalSearch.style";

import LogoSvg from "../../../assets/clubh-logo.svg?react";
import NoResultsSvg from "../../../assets/images/icons/no-search-results-light.svg?react";

import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";
import { brands } from "../../../config/brands";
import {
  DropdownIndicator,
  MultiValueRemove,
  BrandsOption,
  selectStyles,
} from "./selectCustom";

import { Typography } from "../../../ui/Typography/Typography";
import { Icon } from "../../../ui/Icon/Icon";
import { ButtonLink } from "../../../ui/ButtonLink/ButtonLink";
import { SearchResultCard } from "../../SearchResultCard/SearchResultCard";
import { Link } from "../../Link/Link";

import { GET_SEARCH_CONTENT } from "./modalSearchConfig";
import { apiDomain, apiSiteId } from "../../../config";

export const ModalSearch = ({ onClose = null }) => {
  const [rewardsIsShow, setRewardsIsShow] = useState(true);
  const [educationIsShow, setEducationIsShow] = useState(true);
  const [experiencesIsShow, setExperiencesIsShow] = useState(true);
  useLockBodyScroll();
  const intl = useIntl();
  const locale = intl.locale;
  const {
    control,
    register,
    watch,
    reset,
    resetField,
    getFieldState,
    handleSubmit,
    formState,
  } = useForm({
    defaultValues: {
      search: "",
      brands: [],
    },
  });

  const {
    loading: searchLoading,
    data: searchData,
    // error: searchError,
    refetch: searchRefetch,
  } = useQuery(GET_SEARCH_CONTENT, {
    variables: {
      filters: JSON.stringify({}),
      limit: 10,
      locale,
    },
  });

  // console.info({ searchData });

  const debouncedOnChange = useMemo(() => {
    const onChange = (data) => {
      // console.log(data);
      searchRefetch({
        filters: JSON.stringify({ ...data }),
        limit: 10,
        locale,
      });
    };

    return debounce((data) => onChange(data), 300, {
      leading: false,
      trailing: true,
      maxWait: 1000,
    });
  }, []);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel(); // Stop the invocation of the debounced function
    };
  }, []);

  watch((data, opts) => {
    debouncedOnChange(data);
  });

  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1024);
  const updateIsDesktop = () => {
    setDesktop(window.innerWidth >= 1024);
  };
  useEffect(() => {
    window.addEventListener("resize", updateIsDesktop);
    return () => window.removeEventListener("resize", updateIsDesktop);
  });

  // const watchFields = watch();
  // console.log(watchFields);
  const isResults = true;

  const { isDirty } = formState;

  const onSubmit = (data) => {
    //console.log(data);
  };

  return (
    <Root>
      <Header>
        <Logo>
          <LogoSvg />
        </Logo>
        <Close type="button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 26"
            width="1em"
            height="1em"
          >
            <path d="M25.092 25.906a.899.899 0 0 1-.638-.263l-11.45-11.36L1.645 25.731a.907.907 0 0 1-1.289-1.277l11.361-11.449L.269 1.647A.908.908 0 0 1 1.547.356l11.448 11.361L24.354.269a.907.907 0 1 1 1.289 1.278l-11.36 11.448 11.448 11.361a.906.906 0 0 1-.639 1.55Z" />
          </svg>
        </Close>
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            width={24}
            height={24}
          >
            <path
              fill="#8f95a5"
              d="m11.308 12.009.706-.706-1.992-1.994v-.66C12.445 5.17 10.194.372 5.969.012 1.744-.347-1.285 4.002.516 7.84c1.448 3.086 5.323 4.129 8.125 2.187h.686l1.981 1.982Zm-10.294-6.5c0-3.464 3.75-5.629 6.75-3.897 3 1.732 3 6.062 0 7.794a4.5 4.5 0 0 1-6.75-3.897Z"
            />
          </svg>
          <Input
            {...register("search")}
            type="text"
            placeholder={intl.formatMessage({
              defaultMessage: "Search",
            })}
          />
          {getFieldState("search", formState).isDirty && (
            <InputClearButton
              type="button"
              onClick={() => resetField("search")}
            />
          )}
          {isDirty && (
            <ClearAllButtonContainer>
              <ButtonLink as="button" type="button" onClick={() => reset()}>
                <FormattedMessage defaultMessage="Clear All" />
              </ButtonLink>
            </ClearAllButtonContainer>
          )}
        </InputLabel>

        {isDesktop ? (
          <BrandsContainer className="--desktop">
            {brands.map((brand) => (
              <div key={brand.id}>
                <Checkbox
                  {...register("brands")}
                  type="checkbox"
                  name="brands"
                  value={brand.id}
                  id={`${brand.id}-globalSearch`}
                />
                <BrandsAction htmlFor={`${brand.id}-globalSearch`}>
                  {brand.icon}
                </BrandsAction>
              </div>
            ))}
          </BrandsContainer>
        ) : (
          <BrandsContainer className="--mobile">
            <Typography type="h4" style={{ marginBottom: 4 }}>
              <FormattedMessage defaultMessage="Brands" />
            </Typography>
            <Controller
              name="brands"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select brands",
                  })}
                  isClearable={false}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                    MultiValueRemove,
                    Option: BrandsOption,
                  }}
                  isMulti
                  options={sortBy(brands, ["label"]).map((b) => ({
                    value: b.id,
                    label: b.label,
                    icon: b.icon,
                  }))}
                  value={brands
                    .filter((b) => (field.value || []).includes(b.id))
                    .map((b) => ({
                      label: b.label,
                      value: b.id,
                    }))}
                  onChange={(e, { name }) => {
                    const val = Array.isArray(e)
                      ? e.flatMap((itm) => itm.value)
                      : e?.value;
                    return field.onChange(val);
                  }}
                  styles={selectStyles}
                />
              )}
            />
          </BrandsContainer>
        )}

        {isResults ? (
          <ResultsGrid>
            <div>
              {/* REWARDS RESULTS  */}
              <ResultsHeaderContainer>
                <ResultsHeader
                  type="button"
                  onClick={() => setRewardsIsShow(!rewardsIsShow)}
                  style={{ marginBottom: !rewardsIsShow && 0 }}
                >
                  <Typography type="h4">
                    <FormattedMessage defaultMessage="Rewards" />
                  </Typography>
                  <ResultsHeaderIcon className={rewardsIsShow && "--isShow"}>
                    <Icon icon="chevron-down" />
                  </ResultsHeaderIcon>
                </ResultsHeader>
              </ResultsHeaderContainer>

              {rewardsIsShow && (
                <ResultsHeaderContainer>
                  {isDirty && false ? (
                    <>
                      <CategoriesContainer>
                        <Typography type="text-small" color="#8f95a5">
                          <FormattedMessage defaultMessage="Categories" /> (3)
                        </Typography>
                        <CategoriesLinks>
                          <CategoriesLink as={Link} to="/">
                            <FormattedMessage defaultMessage="Care" />
                          </CategoriesLink>
                          <CategoriesLink as={Link} to="/">
                            <FormattedMessage defaultMessage="Colors" />
                          </CategoriesLink>
                          <CategoriesLink as={Link} to="/">
                            <FormattedMessage defaultMessage="Styling" />
                          </CategoriesLink>
                        </CategoriesLinks>
                      </CategoriesContainer>

                      <Typography type="text-small" color="#8f95a5">
                        <FormattedMessage defaultMessage="Products" /> (21)
                      </Typography>
                    </>
                  ) : (
                    <Typography type="text-small" color="#8f95a5">
                      {searchLoading && (
                        <FormattedMessage defaultMessage="Loading Rewards..." />
                      )}

                      {!searchLoading &&
                        (searchData?.content?.rewards?.docs || []).length ===
                          0 && <FormattedMessage defaultMessage="No Results" />}

                      {!searchLoading &&
                        (searchData?.content?.rewards?.docs || []).length >
                          0 && (
                          <FormattedMessage defaultMessage="Trending Rewards" />
                        )}
                    </Typography>
                  )}
                </ResultsHeaderContainer>
              )}

              <AnimatePresence initial={false}>
                {rewardsIsShow && (
                  <motion.div
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.35,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ResultsContainer>
                      {(searchData?.content?.rewards?.docs || []).map(
                        (item, i) => {
                          const imgSrc =
                            item?.gallery?.[0]?.media?._id != null
                              ? `https://${apiDomain}/${apiSiteId}/asset/${item.gallery[0].media._id}?type=fill&width=604&height=752`
                              : `https://picsum.photos/id/${i + 50}/1000/1000`;

                          const brandConfig = brands.find(
                            (b) => b.id === item.brand?._id
                          );
                          const brandName = brandConfig?.label ?? item.brand?.n;
                          const brandSlug = brandConfig?.slug;

                          return (
                            <SearchResultCard
                              key={item._id}
                              type="product"
                              imageSrc={imgSrc}
                              itemName={item?.n}
                              itemUrl={`/rewards/${item.sl}`}
                              brandName={brandName}
                              brandUrl={`/brands/${brandSlug}`}
                              pts={item?.ecommerce?.price?.amount}
                              oldPts={item?.ecommerce?.fullPrice?.amount}
                            />
                          );
                        }
                      )}
                    </ResultsContainer>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div style={{ display: "none" }}>
              {/* EDUCATION RESULTS  */}
              <ResultsHeaderContainer>
                <ResultsHeader
                  type="button"
                  onClick={() => setEducationIsShow(!educationIsShow)}
                  style={{ marginBottom: !educationIsShow && 0 }}
                >
                  <Typography type="h4">
                    <FormattedMessage defaultMessage="Education" />
                  </Typography>
                  <ResultsHeaderIcon className={educationIsShow && "--isShow"}>
                    <Icon icon="chevron-down" />
                  </ResultsHeaderIcon>
                </ResultsHeader>
              </ResultsHeaderContainer>

              {educationIsShow && (
                <ResultsHeaderContainer>
                  {isDirty && false ? (
                    <>
                      <CategoriesContainer>
                        <Typography type="text-small" color="#8f95a5">
                          <FormattedMessage defaultMessage="Categories" /> (1)
                        </Typography>
                        <CategoriesLinks>
                          <CategoriesLink as={Link} to="/">
                            <FormattedMessage defaultMessage="Tutorials" />
                          </CategoriesLink>
                        </CategoriesLinks>
                      </CategoriesContainer>

                      <Typography type="text-small" color="#8f95a5">
                        <FormattedMessage defaultMessage="Articles" /> (2)
                      </Typography>
                    </>
                  ) : (
                    <Typography type="text-small" color="#8f95a5">
                      {searchLoading && (
                        <FormattedMessage defaultMessage="Loading Education..." />
                      )}

                      {!searchLoading &&
                        (searchData?.content?.education?.docs || []).length ===
                          0 && <FormattedMessage defaultMessage="No Results" />}

                      {!searchLoading &&
                        (searchData?.content?.education?.docs || []).length >
                          0 && (
                          <FormattedMessage defaultMessage="Trending Education" />
                        )}
                    </Typography>
                  )}
                </ResultsHeaderContainer>
              )}

              <AnimatePresence initial={false}>
                {educationIsShow && (
                  <motion.div
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.35,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ResultsContainer>
                      {(searchData?.content?.education?.docs || []).map(
                        (item, i) => {
                          const imgSrc =
                            item?.gallery?.[0]?.media?._id != null
                              ? `https://${apiDomain}/${apiSiteId}/asset/${item.gallery[0].media._id}?type=fill&width=604&height=752`
                              : `https://picsum.photos/id/${i + 50}/1000/1000`;

                          const brandConfig = brands.find(
                            (b) => b.id === item.brand?._id
                          );
                          const brandName = brandConfig?.label ?? item.brand?.n;
                          const brandSlug = brandConfig?.slug;

                          return (
                            <SearchResultCard
                              key={item._id}
                              type="article"
                              imageSrc={imgSrc}
                              itemName={item?.n}
                              itemUrl={`/education/${item.sl}`}
                              brandName={brandName}
                              brandUrl={`/brands/${brandSlug}`}
                            />
                          );
                        }
                      )}
                    </ResultsContainer>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div>
              {/* EXPERIENCES RESULTS  */}
              <ResultsHeaderContainer>
                <ResultsHeader
                  type="button"
                  onClick={() => setExperiencesIsShow(!experiencesIsShow)}
                  style={{ marginBottom: !experiencesIsShow && 0 }}
                >
                  <Typography type="h4">
                    <FormattedMessage defaultMessage="Experiences" />
                  </Typography>
                  <ResultsHeaderIcon
                    className={experiencesIsShow && "--isShow"}
                  >
                    <Icon icon="chevron-down" />
                  </ResultsHeaderIcon>
                </ResultsHeader>
              </ResultsHeaderContainer>

              {experiencesIsShow && (
                <ResultsHeaderContainer>
                  {isDirty && false ? (
                    <Typography type="text-small" color="#8f95a5">
                      <FormattedMessage defaultMessage="No Results" />
                    </Typography>
                  ) : (
                    <Typography type="text-small" color="#8f95a5">
                      {searchLoading && (
                        <FormattedMessage defaultMessage="Loading Experiences..." />
                      )}

                      {!searchLoading &&
                        (searchData?.content?.experience?.docs || []).length ===
                          0 && <FormattedMessage defaultMessage="No Results" />}

                      {!searchLoading &&
                        (searchData?.content?.experience?.docs || []).length >
                          0 && (
                          <FormattedMessage defaultMessage="Trending Experiences" />
                        )}
                    </Typography>
                  )}
                </ResultsHeaderContainer>
              )}

              <AnimatePresence initial={false}>
                {experiencesIsShow && (
                  <motion.div
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 0.35,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ResultsContainer>
                      {(searchData?.content?.experience?.docs || []).map(
                        (item, i) => {
                          const imgSrc =
                            item?.gallery?.[0]?.media?._id != null
                              ? `https://${apiDomain}/${apiSiteId}/asset/${item.gallery[0].media._id}?type=fill&width=604&height=752`
                              : `https://picsum.photos/id/${i + 50}/1000/1000`;

                          const brandConfig = brands.find(
                            (b) => b.id === item.brand?._id
                          );
                          const brandName = brandConfig?.label ?? item.brand?.n;
                          const brandSlug = brandConfig?.slug;

                          return (
                            <SearchResultCard
                              key={item._id}
                              type="article"
                              imageSrc={imgSrc}
                              itemName={item?.n}
                              itemUrl={`/experience/${item.sl}`}
                              brandName={brandName}
                              brandUrl={`/brands/${brandSlug}`}
                            />
                          );
                        }
                      )}
                    </ResultsContainer>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </ResultsGrid>
        ) : (
          <NoResultsContainer>
            <NoResultsSvg width={64} height={64} />
            <Typography
              type="text-small"
              color="#8f95a5"
              style={{ marginTop: 32, marginBottom: 16 }}
            >
              <FormattedMessage defaultMessage="No results were found" />
            </Typography>
            <Typography type="text-small">
              <a
                role="button"
                onClick={() => reset()}
                style={{ cursor: "pointer" }}
              >
                <FormattedMessage defaultMessage="Clear All Filters" />
              </a>
            </Typography>
          </NoResultsContainer>
        )}

        <input type="submit" style={{ display: "none" }} />
      </Form>
    </Root>
  );
};
