import styled from "styled-components";

export const Root = styled.div`
  background-color: #fff;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    max-width: 416px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 832px;
    height: 500px;
  }
`;

export const PosterImg = styled.img`
  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Container = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    padding: 72px 16px 40px;
  }

  @media (min-width: 1024px) {
    padding: 64px 40px 0;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
`;

export const Header = styled.div`
  display: flex;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1024px) {
    height: 88px;
    padding: 23px 32px;
    background-color: #f9f9f9;
  }
`;

export const TitleContainer = styled.div`
  @media (max-width: 1023px) {
    text-align: center;
  }

  @media (min-width: 1024px) {
    padding-left: 16px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;

  @media (max-width: 1023px) {
    margin: 8px 0;
  }
`;

export const Desc = styled.p`
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.12px;
`;

export const ItemContainer = styled.div`
  display: flex;

  @media (max-width: 1023px) {
    margin-top: 56px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 60px;
    margin-bottom: 51px;
  }
`;

export const ItemImageContainer = styled.div`
  flex-shrink: 0;
  width: 66px;
  height: 88px;
  margin-right: 24px;
  background-color: var(--color-bg-3);
`;

export const ItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ItemTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

export const ContinueButton = styled.button`
  width: 100%;
  margin-top: 28px;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-align: center;
  text-decoration: underline;
`;
