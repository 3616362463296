import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Root, Title, Forgot, RedLink } from "./AuthLogin.style";

import { Link } from "../Link/Link";
import { TextInput } from "../../ui/TextInput/TextInput";
import { Button } from "../../ui/Button/Button";

import { signIn } from "../../utils/auth";
import { frontendDomain } from "../../config";
// import {
//   toast,
//   ToastNotification,
// } from "../../ui/ToastNotification/ToastNotification";

export function AuthLogin() {
  const intl = useIntl();
  const [statusMessage, setStatusMessage] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: "",
      context: "clubh",
    },
  });
  const onSubmit = (data) => {
    return signIn(data)
      .then((res) => {
        console.info({ res });
        if (res?.user == null) {
          throw new Error("Unknown error. User should not be null.");
        }
        const urlParams = new URLSearchParams(location.search);
        let redirect = urlParams.get("redirect");
        redirect =
          redirect != null
            ? redirect.replace(`https://${frontendDomain}`, "")
            : null;
        return redirect == null || redirect.startsWith(`http`)
          ? navigate("/", { replace: true }) // prevent open redirect
          : navigate(redirect, {
              replace: true,
              state: location?.state?.redirectState ?? {},
            });
      })
      .catch((err) => {
        console.error({ err });
        //show error message
        setStatusMessage({
          type: "error",
          message: intl.formatMessage({
            defaultMessage: "Incorrect login credentials. Please try again.",
          }),
        });
        // toast.error(
        //   <ToastNotification>
        //     <FormattedMessage defaultMessage="Incorrect login credentials. Please try again." />
        //   </ToastNotification>
        // );
      });
  };

  return (
    <Root>
      <Title>
        <FormattedMessage defaultMessage="Login" />
      </Title>
      {statusMessage != null ? (
        <p>
          {statusMessage.message}{" "}
          <button
            style={{ textDecoration: "underline" }}
            onClick={() => {
              setStatusMessage();
              if (statusMessage?.action?.onClick) {
                statusMessage.action.onClick();
              }
            }}
          >
            {statusMessage?.action?.label ?? (
              <FormattedMessage defaultMessage="dismiss" />
            )}
          </button>
        </p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                type="email"
                label={intl.formatMessage({
                  defaultMessage: "Email",
                })}
                autoComplete="username"
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                type="password"
                label={intl.formatMessage({
                  defaultMessage: "Password",
                })}
                autoComplete="current-password"
              />
            )}
          />

          <Forgot as={Link} to="/auth/forgot">
            <FormattedMessage defaultMessage="Forgot your password?" />
          </Forgot>
          <Button type="submit" variant="auth" arrow={false}>
            <FormattedMessage defaultMessage="Login" />
          </Button>
          <RedLink as={Link} to="/auth/register">
            <FormattedMessage defaultMessage="Register" />
          </RedLink>
        </form>
      )}
    </Root>
  );
}
