import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Image,
  Body,
  BrandName,
  ProductName,
  QtyPts,
  TitleContainer,
  Subtotal,
  ActionsContainer,
  RemoveButton,
  InputContainer,
  InputButton,
  Input,
} from "./CartItem.style";

import { Link } from "../Link/Link";
import { Typography } from "../../ui/Typography/Typography";
import { Icon } from "../../ui/Icon/Icon";

export const CartItem = ({
  imageSrc = "",
  brandName = "",
  brandUrl = "/",
  productName = "",
  pts = 0,
  subtotalPts = 0,
  oldPts,
  qty = 1,
  onRemove = null,
  onUpdateQty = null,
}) => {
  const [qtyValue, setQtyValue] = useState(qty);

  const handleButtonChange = (dir) => {
    if (dir < 0 && qtyValue > 1) {
      setQtyValue(qtyValue - 1);
      onUpdateQty(qtyValue - 1);
    }
    if (dir > 0) {
      setQtyValue(qtyValue + 1);
      onUpdateQty(qtyValue + 1);
    }
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!value || isNaN(value)) return;
    setQtyValue(value);
    onUpdateQty(value);
  };

  return (
    <Root>
      <Image src={imageSrc} alt={productName} />

      <Body>
        <BrandName as={Link} to={brandUrl}>
          {brandName}
        </BrandName>

        <TitleContainer>
          <div>
            <ProductName>{productName}</ProductName>
            <div>
              <QtyPts style={{ color: "#8f95a5" }}>
                {qtyValue}&nbsp;x&nbsp;
              </QtyPts>
              {oldPts && (
                <QtyPts
                  style={{
                    marginRight: 6,
                    textDecoration: "line-through",
                    textTransform: "uppercase",
                  }}
                >
                  <strong>
                    {oldPts.toLocaleString("en-US")}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </strong>
                </QtyPts>
              )}
              <QtyPts style={{ color: oldPts ? "#e1000f" : "#000" }}>
                <strong>
                  {pts.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </strong>
              </QtyPts>
            </div>
          </div>
          <Subtotal>
            <QtyPts style={{ color: "#8f95a5" }}>
              <FormattedMessage defaultMessage="Subtotal:" />
            </QtyPts>
            <QtyPts style={{ display: "block" }}>
              <strong>{subtotalPts.toLocaleString("en-US")} PTS</strong>
            </QtyPts>
          </Subtotal>
        </TitleContainer>

        <ActionsContainer>
          <InputContainer>
            <InputButton
              type="button"
              disabled={qtyValue === 1}
              onClick={() => handleButtonChange(-1)}
            >
              -
            </InputButton>
            <Input type="text" value={qtyValue} onChange={handleInputChange} />
            <InputButton type="button" onClick={() => handleButtonChange(1)}>
              +
            </InputButton>
          </InputContainer>

          <RemoveButton type="button" onClick={onRemove}>
            <Icon icon="trash-can" />
          </RemoveButton>
        </ActionsContainer>
      </Body>
    </Root>
  );
};
