import React, { useState, useMemo, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { useApolloClient } from "@apollo/client";
import Select from "react-select/async";
import { debounce, pick, get, isEqual, sortBy } from "lodash";
import { useSearchParams } from "react-router-dom";
import Slider from "rc-slider";
import rison from "rison";
import "rc-slider/assets/index.css";

import {
  Root,
  Container,
  ActionsList,
  ActionsItem,
  BrandAction,
  Checkbox,
  CategoryAction,
  ExpandButtonContainer,
  ClearButton,
  ExpandButton,
  AdvancedContainer,
  Fields,
  FieldsContainer,
  RangeContainer,
} from "./FiltersDesktop.style";
import {
  SearchField,
  SearchIcon,
  RangeSliderContainer,
  RangeInputContainer,
  RangeInput,
  SearchInput,
} from "../FiltersSharedComponents/Filters.style";

import {
  SearchClearIndicator,
  SearchOption,
  searchSelectStyles,
} from "../FiltersSharedComponents/searchSelectCustom";
import {
  DropdownIndicator,
  MultiValueRemove,
  selectStyles,
} from "../FiltersSharedComponents/selectCustom";
import { brands } from "../../config/brands";
import { Typography } from "../../ui/Typography/Typography";
// import { parseSearchParams } from "../../utils/location";

export const FiltersDesktop = ({
  categoriesConfig = [],
  showPoints = true,
  pointsConfig = { min: 0, max: 100 },
  tagsConfig,
  advancedFilters = true,
  searchPlaceholder = "Search experiences",
}) => {
  const intl = useIntl();
  const client = useApolloClient();
  const firstRender = useRef(true);
  const onChangeEnabled = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExpandedFilters, setIsExpandedFilters] = useState(false);

  const defaultValues = {
    brands: [],
    categories: [],
    search: "",
    tags: [],
    points: [pointsConfig.min, pointsConfig.max],
  };

  const {
    register,
    control,
    watch,
    reset,
    resetField,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const filters = searchParams.get("filters");
    const formValue = {
      ...defaultValues,
      ...pick(filters != null ? rison.decode(filters) : {}, [
        "brands",
        "search",
        "categories",
        "tags",
        "points",
      ]),
    };

    //deal with bullshit where single values are not arrays
    const arrayVals = ["brands", "categories", "tags"];
    arrayVals.forEach((key) => {
      if (!Array.isArray(formValue[key])) {
        formValue[key] = [formValue[key]];
      }
    });

    //disable onchange for browner back/forward buttons
    if (!firstRender.current) {
      onChangeEnabled.current = false;
    }
    Object.keys(formValue).forEach((key) => {
      if (!isEqual(watchForm[key], formValue[key])) {
        setValue(key, formValue[key], { shouldDirty: true });
      }
    });
    //re-enable onchange
    if (!firstRender.current) {
      onChangeEnabled.current = true;
    }

    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [searchParams]);

  const debouncedOnChange = useMemo(() => {
    const onChange = (data) => {
      let scrubbedData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) => v != null && v !== "" && v?.length !== 0
        )
      );

      //scrub points
      if (scrubbedData.points[0] === "") {
        scrubbedData.points[0] = null;
      }
      if (scrubbedData.points[1] === "") {
        scrubbedData.points[1] = null;
      }
      if (
        (parseInt(scrubbedData.points[0], 10) === pointsConfig.min &&
          parseInt(scrubbedData.points[1], 10) === pointsConfig.max) ||
        (scrubbedData.points[0] == null && scrubbedData.points[1] == null)
      ) {
        delete scrubbedData.points;
      }

      if (Object.keys(scrubbedData).length === 0) {
        searchParams.delete("filters");
      } else {
        searchParams.set("filters", rison.encode(scrubbedData));
      }

      setSearchParams(searchParams, { preventScrollReset: true });
    };

    return debounce(
      (data) => {
        onChange(data);
      },
      300,
      { leading: false, trailing: true, maxWait: 1000 }
    );
  }, [searchParams]);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel(); // Stop the invocation of the debounced function
    };
  }, []);

  const watchCategories = watch("categories");
  // console.info("watchCategories: ", watchCategories);
  // console.info("isDirty: ", isDirty);

  watch((data, opts) => {
    if (onChangeEnabled.current) {
      debouncedOnChange(data);
    }
  });

  const watchForm = watch();

  return (
    <Root>
      <Container className="--bg">
        <ActionsList>
          {sortBy(brands, ["label"]).map((brand) => (
            <ActionsItem key={brand.slug}>
              <Checkbox
                {...register("brands")}
                type="checkbox"
                name="brands"
                value={brand.id}
                id={brand.id}
              />
              <BrandAction htmlFor={brand.id}>{brand.icon}</BrandAction>
            </ActionsItem>
          ))}
        </ActionsList>

        <ActionsList>
          <CategoryAction
            as="button"
            type="button"
            $isActive={watchCategories?.length === 0}
            onClick={() => resetField("categories")}
          >
            <FormattedMessage defaultMessage="all" />
          </CategoryAction>
          {categoriesConfig.map((category) => (
            <ActionsItem key={category.value}>
              <Checkbox
                {...register("categories")}
                type="checkbox"
                name="categories"
                value={category.value}
                id={category.value}
              />
              <CategoryAction
                htmlFor={category.value}
                $isActive={(watchCategories || []).includes(category.value)}
              >
                {category.label}
              </CategoryAction>
            </ActionsItem>
          ))}
        </ActionsList>

        <ExpandButtonContainer>
          {isDirty && (
            <ClearButton type="button" onClick={() => reset()}>
              <FormattedMessage defaultMessage="Clear All" />
            </ClearButton>
          )}
          {advancedFilters && (
            <ExpandButton
              type="button"
              onClick={() => setIsExpandedFilters(!isExpandedFilters)}
            >
              <span>
                {isExpandedFilters ? (
                  <FormattedMessage defaultMessage="Hide Advanced Filters" />
                ) : (
                  <FormattedMessage defaultMessage="Show Advanced Filters" />
                )}
                &nbsp;&nbsp;
              </span>
              {isExpandedFilters ? (
                <svg
                  width={14}
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M 2.75 6.306 L 11.25 6.306 L 11.25 7.695 L 2.75 7.695 Z"
                  ></path>
                </svg>
              ) : (
                <svg
                  width={14}
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M6.306 2.75h1.389v3.243l.313.313h3.242v1.389H7.978l-.283.283v3.272H6.306V7.978l-.283-.283H2.75v-1.39h3.243l.313-.313V2.75Z"
                  />
                </svg>
              )}
            </ExpandButton>
          )}
        </ExpandButtonContainer>
      </Container>

      {isExpandedFilters && (
        <Container>
          <AdvancedContainer>
            <Fields style={{ maxWidth: showPoints ? 685 : 460 }}>
              <SearchField>
                <SearchIcon />
                <SearchInput
                  type="text"
                  placeholder={searchPlaceholder}
                  {...register("search")}
                />
                {/* <Controller
                  name="search"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      placeholder="Search a reward"
                      type="text"
                    />
                  )}
                /> */}
              </SearchField>
              <FieldsContainer className={showPoints && "--twoColumns"}>
                <div>
                  <Typography
                    as="label"
                    type="text-small"
                    style={{ marginBottom: 8 }}
                  >
                    <FormattedMessage defaultMessage="Tags" />
                  </Typography>
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Select tags",
                          })}
                          isClearable={false}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator,
                            MultiValueRemove,
                          }}
                          isMulti
                          defaultOptions={true}
                          loadOptions={async (inputValue) => {
                            return client
                              .query({
                                query: tagsConfig.gql,
                                variables: {
                                  startsWith: inputValue,
                                },
                                fetchPolicy: "network-only",
                              })
                              .then((response) => {
                                const tags = get(response, tagsConfig.dataPath);
                                return tags.map((tag) => ({
                                  label: tag,
                                  value: tag,
                                }));
                              });
                          }}
                          value={field.value.map((itm) => ({
                            label: itm,
                            value: itm,
                          }))}
                          //remove label and only pass value
                          onChange={(e, { name }) => {
                            const val = Array.isArray(e)
                              ? e.flatMap((itm) => itm.value)
                              : e?.value;
                            return field.onChange(val);
                          }}
                          styles={selectStyles}
                        />
                      );
                    }}
                  />
                </div>
                {showPoints && (
                  <div>
                    <Typography
                      as="label"
                      type="text-small"
                      style={{ marginBottom: 8 }}
                    >
                      Points
                    </Typography>
                    <RangeContainer>
                      {/* <RangeSliderContainer>
                        <Controller
                          name="points"
                          control={control}
                          render={({ field }) => (
                            <Slider
                              range
                              {...field}
                              min={pointsConfig.min}
                              max={pointsConfig.max}
                              styles={{
                                handle: {
                                  height: 14,
                                  width: 14,
                                  backgroundColor: "#e1000f",
                                  opacity: 1,
                                  border: 0,
                                  boxShadow: "none",
                                },
                                track: {
                                  backgroundColor: "#ebe8e8",
                                },
                              }}
                            />
                          )}
                        />
                      </RangeSliderContainer> */}
                      <RangeInputContainer>
                        <Controller
                          name="points"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <span style={{ fontSize: "12px" }}>
                                <FormattedMessage defaultMessage="Min:" />
                              </span>
                              <RangeInput
                                placeholder="0"
                                value={value[0] ?? ""}
                                onChange={(e) => {
                                  const min = e.target.value;
                                  const max = value[1];
                                  onChange([min, max]);
                                }}
                              />
                            </>
                          )}
                        />
                        <Controller
                          name="points"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <span style={{ fontSize: "12px" }}>
                                <FormattedMessage defaultMessage="Max:" />
                              </span>
                              <RangeInput
                                value={value[1] ?? ""}
                                placeholder="100000"
                                onChange={(e) => {
                                  const min = value[0];
                                  const max = e.target.value;
                                  onChange([min, max]);
                                }}
                              />
                            </>
                          )}
                        />
                      </RangeInputContainer>
                    </RangeContainer>
                  </div>
                )}
              </FieldsContainer>
            </Fields>
          </AdvancedContainer>
        </Container>
      )}
    </Root>
  );
};
