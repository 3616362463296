import React from "react";
import { FormattedMessage } from "react-intl";

import { Root, Row, Shipping } from "./TotalDetails.style";

import { Typography } from "../../ui/Typography/Typography";

export const TotalDetails = ({
  subtotalPts = 0,
  shipping = 0,
  totalPts = 0,
}) => {
  return (
    <Root>
      {/* <Row>
        <Typography type="text-small" color="#8f95a5">
          Subtotal
        </Typography>
        <Typography
          type="text-small"
          color="#8f95a5"
          style={{ textAlign: "right" }}
        >
          {subtotalPts.toLocaleString("en-US")} PTS
        </Typography>
      </Row> */}
      <Row>
        <Shipping style={{ color: "#8f95a5" }}>
          <FormattedMessage defaultMessage="Shipping" />
        </Shipping>

        <Shipping style={{ color: "#8f95a5", textAlign: "right" }}>
          ${shipping.toFixed(2)}
        </Shipping>
      </Row>
      <Row>
        <Typography type="text-secondary">
          <FormattedMessage defaultMessage="Total" />
        </Typography>
        <Typography type="text-secondary" style={{ textAlign: "right" }}>
          {totalPts.toLocaleString("en-US")}{" "}
          <FormattedMessage defaultMessage="PTS" />
        </Typography>
      </Row>
    </Root>
  );
};
