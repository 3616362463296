import styled from "styled-components";

import imgSrc1 from "./assets/img-hover-zoom-1.jpg";
import imgSrc2 from "./assets/img-hover-zoom-2.jpg";
import imgSrc3 from "./assets/img-hover-zoom-3.jpg";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f5f4f8;

  @media (max-width: 1023px) {
    padding: 64px 0 32px;
  }

  @media (min-width: 1024px) {
    padding: 80px 0 48px;
  }

  @media (min-width: 1400px) {
    padding: 80px 36px 48px 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 64px;
  width: 100%;
  max-width: 1324px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  width: 100%;
  padding: 0 24px;
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 422px;
  }

  @media (min-width: 1024px) {
    max-width: 549px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  position: relative;
  color: #fff;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;

  &.--1 {
    background-image: url(${imgSrc1});
  }
  &.--2 {
    background-image: url(${imgSrc2});
  }
  &.--3 {
    background-image: url(${imgSrc3});
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #27166a;
    opacity: 0.3;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 149px;
  }

  @media (min-width: 1024px) {
    height: 269px;
  }
`;

export const Divider = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    width: 100%;
    height: 57px;
  }

  @media (min-width: 1024px) {
    width: 56px;
    height: 100%;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;
