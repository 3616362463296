import styled from "styled-components";
import cardBgDarkSrc from "../../../assets/images/pattern-purple-bright-dark@2x.png";
import cardBgLightSrc from "../../../assets/images/pattern-purple-light@2x.png";

export const Root = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1023px) {
    height: 708px;
  }

  @media (min-width: 1024px) {
    height: 550px;
  }

  .slick-dots {
    bottom: 16px;
  }

  .slick-dots li {
    margin: 0 2px;
  }

  .slick-dots li button {
    position: relative;
  }

  .slick-dots li button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 3px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;
    opacity: 1;
  }

  .slick-dots li.slick-active button::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
`;

export const Slide = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1023px) {
    height: 708px;

    &.--aligned-centered {
      display: flex !important;
    }
  }

  @media (min-width: 1024px) {
    height: 550px;

    &.--aligned-centered {
      display: grid !important;
      grid-template-columns: 1fr 451px 1fr;
    }
  }

  @media (min-width: 1400px) {
    &.--aligned-centered {
      grid-template-columns: 1fr 516px 1fr;
    }
  }
`;

export const Image = styled.img`
  display: block;
  object-fit: cover;

  @media (max-width: 1023px) {
    &.--aligned-right {
      width: 100%;
      height: 300px;
    }

    &.--aligned-left,
    &.--aligned-4 {
      width: 100%;
      height: 100%;
    }

    &.--aligned-centered {
      width: 50%;
      height: 300px;
    }
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
  pointer-events: none;

  @media (max-width: 1023px) {
    height: 300px;
  }

  @media (min-width: 1024px) {
    height: 100%;
  }
`;

export const ContentContainer = styled.div`
  background-repeat: repeat-x;
  background-position-y: top;

  &.--background-dark {
    color: #fff;
    background-color: var(--color-primary);
    background-image: url(${cardBgDarkSrc});
    background-size: 120px;
  }

  &.--background-light {
    background-color: var(--color-bg-1);
    background-image: url(${cardBgLightSrc});
    background-size: 120px;
  }

  @media (max-width: 1023px) {
    height: 408px;
    padding: 40px 56px 24px 40px;

    &.--aligned-right,
    &.--aligned-left,
    &.--aligned-centered {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    &.--aligned-right,
    &.--aligned-left {
      position: absolute;
      top: 54px;
      bottom: 55px;
      width: 435px;
      height: 440px;
      padding: 64px 56px 60px;
    }

    &.--aligned-right {
      right: 54px;
    }

    &.--aligned-left {
      left: 54px;
    }

    &.--aligned-centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 48px 64px 64px;
    }
  }
`;

export const Title = styled.div`
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    max-width: 280px;
    font-size: 32px;
    line-height: 1.06;
  }

  @media (min-width: 1024px) {
    font-size: 40px;
    line-height: 1.05;
  }
`;

export const Desc = styled.div`
  @media (max-width: 1023px) {
    max-width: 280px;
    margin-top: 24px;
    margin-bottom: 40px;
    font-size: 13px;
    line-height: 1.85;
    letter-spacing: 0.13px;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
    margin-bottom: 55px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.14px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Arrows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1023px) {
    position: absolute;
    top: -36px;
    left: 40px;
    right: 40px;
    color: #fff;
  }
`;

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 24px;

  & + & {
    margin-left: 16px;
  }
`;

export const Card = styled.div`
  position: absolute;

  @media (max-width: 1023px) {
    left: 50%;
    bottom: 111px;
    transform: translateX(-50%);
  }

  @media (min-width: 1024px) {
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
  }

  @media (min-width: 1400px) {
    right: 128px;
  }
`;
