import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Root, Item, VideoContainer } from "./YoutubeGrid.style";

import { Typography } from "../../../../ui/Typography/Typography";

import { communityVideos } from "../../../../config/community";

import useScript from "../../../../hooks/useScript";

export const YoutubeGrid = () => {
  const intl = useIntl();
  const players = useRef([]);
  useScript("https://www.youtube.com/iframe_api");

  useEffect(() => {
    const initPlayers = () => {
      communityVideos({ intl }).forEach((_, index) => {
        players.current[index] = new window.YT.Player(
          `youtube-player-${index}`,
          {
            videoId: getYouTubeID(_.link),
            playerVars: {
              controls: 1, // Hides controls
              rel: 0, // Hides recommended videos after playback
            },
            events: {
              onStateChange: (event) => handlePlayerStateChange(event, index),
            },
          }
        );
      });
    };

    window.onYouTubeIframeAPIReady = () => {
      initPlayers();
    };

    if (window.YT != null && typeof YT.Player != null) {
      initPlayers();
    }

    // return () => {
    //   players.current.forEach((player) => {
    //     console.info("Destroying player");
    //     player.pauseVideo();
    //     player.destroy();
    //     player = null;
    //     console.info("doneDestroying player");
    //   });
    //   delete window.onYouTubeIframeAPIReady;
    //   // delete window.YT;
    // };
  }, []);

  const getYouTubeID = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : null;
  };

  const handlePlayerStateChange = (event, currentPlayerIndex) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      players.current.forEach((player, index) => {
        if (index !== currentPlayerIndex) {
          try {
            player.pauseVideo();
          } catch (error) {}
        }
      });
    }
  };

  return (
    <Root>
      {communityVideos({ intl }).map((video, idx) => (
        <Item key={idx}>
          <VideoContainer>
            <div id={`youtube-player-${idx}`}></div>
          </VideoContainer>
          <Typography type="h3" style={{ marginTop: 21 }}>
            {video.title}
          </Typography>
          <Typography
            as="p"
            type="text-primary"
            style={{ maxWidth: 500, marginTop: 12 }}
          >
            {video.description}
          </Typography>
        </Item>
      ))}
    </Root>
  );
};
