import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    border-bottom: 1px solid #dddfe8;
  }

  @media (min-width: 1024px) {
    padding: 0 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1304px;

  @media (max-width: 1023px) {
    justify-content: center;
    height: 72px;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    height: 120px;
  }
`;

export const Logo = styled.img`
  width: 100%;

  @media (max-width: 1023px) {
    max-width: 104px;
  }

  @media (min-width: 1024px) {
    max-width: 164px;
  }
`;

export const Tagline = styled.div`
  color: #27166a;
  font-size: 16px;
  font-weight: 700;
  text-align: right;

  @media (max-width: 1023px) {
    display: none;
  }
`;
