export const faqs = {
  default: [
    {
      "question_en-CA": "What is the Club H Rewards Program?",
      "answer_en-CA":
        "Club H is a rewards program offered by Henkel Canada Corporation designed to appreciate and reward our valued customers like you. By participating, you can earn points for completing eligible purchases and activities, which can then be redeemed for rewards like free products and education. ",
      "question_fr-CA": "Qu’est-ce que le programme Club H Rewards ?",
      "answer_fr-CA":
        "Le Club H est un programme de récompenses offert par Henkel Canada Corporation conçu pour apprécier et récompenser nos précieux clients comme vous. En participant, vous pouvez gagner des points en effectuant des achats et des activités éligibles, qui peuvent ensuite être échangés contre des récompenses telles que des produits gratuits.",
    },
    {
      "question_en-CA": "Who is eligible to join Club H?",
      "answer_en-CA": `To join Club H, you must be:

1.	The owner of an independent salon or an independent operator within a salon in Canada.
2.	Operating your salon as a business in Canada and participating in the program exclusively for business purposes.
3.	A legal resident of Canada and have reached the age of majority in your province or territory.`,
      "question_fr-CA": "Qui peut adhérer au Club H?",
      "answer_fr-CA": `Pour adhérer au Club H, vous devez être :

1. Le propriétaire d’un salon indépendant ou un exploitant indépendant au sein d’un salon au Canada.
2. L’exploitant de votre salon en tant qu’entreprise au Canada et devez participer au programme exclusivement à des fins commerciales.
3. Un résident légal du Canada et avoir atteint l’âge de la majorité dans votre province  ou territoire.`,
    },
    {
      "question_en-CA":
        "Are there any business types that cannot join the program?",
      "answer_en-CA": `Yes, the following are not eligible:

- Chain accounts, which include:
  - Hairdressing Schools, both government and private institutions offering hairdressing education.
  - Multiple Salons, businesses with 5 or more locations providing full salon services, whether independently owned, franchised, or corporate.
  - Beauty Outlets, which are independent or chain stores selling professional beauty products for wholesale and/or retail.
- Retailers: consumer beauty store chains selling contracted brands through their physical and online stores, possibly with in-store salons.
- Agent Sellers: wholesalers distributing contracted brands through a limited, approved network of beauty retailers.`,
      "question_fr-CA":
        "Y a-t-il des types d’entreprises qui ne peuvent pas se joindre au programme ?",
      "answer_fr-CA": `Oui, les organisations suivantes ne sont pas éligibles :

- Les réseaux de salons de coiffure, qui comprennent :
  - Écoles de coiffure, institutions gouvernementales et privées proposant une formation en coiffure.
  - Salons multiples, entreprises comptant 5 emplacements ou plus offrant des services de salon complets, qu’ils soient indépendants, franchisés ou corporatifs.
  - Boutiques de produits de beauté qui sont des magasins indépendants ou des chaînes de magasins vendant des produits de beauté professionnels en gros ou au détail.
- Détaillants : chaînes de magasins de produits de beauté grand public vendant des marques sous contrat via leurs magasins physiques et en ligne, même avec des salons en magasin.
- Agents vendeurs : grossistes distribuant des marques sous contrat à travers un réseau de détaillants de produits de beauté limité et agréé.`,
    },
    {
      "question_en-CA": "Can an individual or a consumer join Club H?",
      "answer_en-CA": `The program is not open to individuals who are not operating as a business, nor to consumers purchasing products for personal, family, or household purposes.`,
      "question_fr-CA":
        "Un particulier ou un consommateur peut-il adhérer au Club H ?",
      "answer_fr-CA":
        "Le programme n’est pas ouvert aux personnes qui n’exercent pas leurs activités en tant qu’entreprise ni aux consommateurs qui achètent des produits à des fins personnelles, familiales ou domestiques.",
    },
    {
      "question_en-CA":
        "How do I register for Club H and start earning points?",
      "answer_en-CA": `To create an account, please complete the registration form here: (https://clubh.ca/en-CA/auth/register). You will need to provide all distributor account numbers that you would like to earn points for. If you shop across multiple participating distributor houses, you must provide all account numbers to receive points and have them credited to your account for associated purchases.

To include multiple distributors or distributor accounts, use the “Add a Distributor” button on the registration page. For each account, select the distributor in the dropdown and enter the distributor account number in the required field.`,
      "question_fr-CA":
        "Comment puis-je m’inscrire au Club H et commencer à gagner des points ?",
      "answer_fr-CA": `Pour créer un compte, veuillez remplir le formulaire d’inscription disponible sur le site Internet (https://clubh.ca/fr-CA/auth/register). Vous devrez fournir tous les numéros de compte de distributeur pour lesquels vous souhaitez gagner des points. Si vous magasinez auprès de plusieurs distributeurs participants, vous devez fournir tous les numéros de compte pour recevoir des points et les faire créditer sur votre compte pour les achats associés.

Pour inclure plusieurs distributeurs ou comptes de distributeurs, utilisez le bouton « Ajouter un distributeur » sur la page d'inscription. Pour chaque compte, sélectionnez le distributeur dans le menu déroulant et entrez le numéro de compte du distributeur dans le champ requis.`,
    },
    {
      "question_en-CA": "How do I update my Club H Account information?",
      "answer_en-CA":
        "You can update your account information and saved preferences by logging into your account and visiting your dashboard (https://clubh.ca/en-CA/dashboard).",
      "question_fr-CA":
        "Comment mettre à jour les informations de mon compte Club H ?",
      "answer_fr-CA":
        "Vous pouvez mettre à jour les informations de votre compte et vos préférences enregistrées en vous connectant à votre compte et en visitant votre tableau de bord (https://clubh.ca/fr-CA/dashboard).",
    },
    {
      "question_en-CA": "How do I earn points?",
      "answer_en-CA":
        "You can earn points by making qualifying purchases of our products, participating in qualifying activities, and more. The specific details about what counts as qualifying activities and purchases (including the applicable terms and conditions) can be found in in the account dashboard.",
      "question_fr-CA": "Comment puis-je gagner des points ?",
      "answer_fr-CA":
        "Vous pouvez gagner des points en effectuant des achats éligibles de nos produits, en participant à des activités éligibles et bien plus encore. Les détails spécifiques sur ce qui compte comme activités et achats éligibles (y compris les conditions générales applicables) peuvent être trouvés dans le tableau de bord du compte.",
    },
    {
      "question_en-CA": "Which brands are included in the program?",
      "answer_en-CA":
        "Participating Henkel Canada Beauty Professional Brands include Alterna, Authentic Beauty Concepts, DevaCurl, Joico, Kenra Professional, MyDentity, Schwarzkopf Professional, SexyHair, STMNT, and PRAVANA.",
      "question_fr-CA": "Quelles marques sont incluses dans le programme ?",
      "answer_fr-CA":
        "Les marques professionnelles de beauté Henkel Canada participantes comprennent Alterna, Authentic Beauty Concepts, DevaCurl, Joico, Kenra Professional, MyDentity, Schwarzkopf Professional, SexyHair, STMNT et PRAVANA.",
    },
    {
      "question_en-CA": "Where can I make purchases to collect points?",
      "answer_en-CA": `Qualifying purchases may include specific products purchased directly from a Henkel Canada direct sales representative or through a participating Authorized Affiliated Canadian Distributor House.

Participating distributors include CanRad Beauty, Capilex Beaute, Central Beauty Supply, Chalut (Schwarzkopf Professional only), ESP Salon Sales, Jean Julien, Maritime Beauty, Modern Beauty Supplies, Radiant Beauty Supplies, Salon Center, West Coast Beauty and Windsor Beauty Supply (Schwarzkopf Professional only).

Additionally the following distributors are participating but have a longer period of time to approve your account registration: Beauté Star, Icon Salon Systems, and SalonCentric.`,
      "question_fr-CA":
        "Où puis-je effectuer des achats pour cumuler des points ?",
      "answer_fr-CA": `Les achats admissibles peuvent inclure des produits spécifiques achetés directement auprès d’un représentant des ventes directes de Henkel Canada ou par l’intermédiaire d’un distributeur canadien affilié autorisé participant.

Les distributeurs participants incluent CanRad Beauty, Capilex Beauté, Central Beauty Supply, Chalut (Schwarzkopf Professional uniquement), ESP Salon Sales, Jean Julien, Maritime Beauty, Modern Beauty Supplies, Radiant Beauty Supplies, Salon Center, West Coast Beauty et Windsor Beauty Supply (Schwarzkopf Professional uniquement).

De plus, les distributeurs suivants participent également, mais disposent d'une période plus longue pour approuver votre inscription : Beauté Star, Icon Salon Systems et SalonCentric.`,
    },
    {
      "question_en-CA":
        "My distributor house isn’t listed as a participating distributor house. Can I still earn points for purchases with that distributor? ",
      "answer_en-CA": `If your distributor offers Henkel Canada products but isn't displayed on the dashboard, unfortunately, your purchases made through them will not be eligible for Club H points.

We're working diligently to incorporate various distributors into this loyalty program. To stay updated on any program modifications, please subscribe to our newsletter.`,
      "question_fr-CA":
        "Mon distributeur n'est pas répertoriée comme participant. Puis-je quand même gagner des points pour mes achats auprès de ce distributeur ?",
      "answer_fr-CA": `Si votre distributeur propose des produits Henkel Canada mais n’est pas affiché sur le tableau de bord, malheureusement, vos achats effectués par son intermédiaire ne seront cependant pas éligibles aux points Club H.

Nous travaillons avec diligence pour intégrer divers distributeurs dans ce programme de fidélité. Pour rester informé de toute modification du programme, inscrivez-vous à notre infolettre.`,
    },
    {
      "question_en-CA":
        "How quickly will I receive and be able to use my points?",
      "answer_en-CA": `You can earn points through qualifying purchases and activities at any time. All points associated with a purchase of qualifying products will become available in your dashboard within the first 10 days of the following month. For points associated with activities in your dashboard, please refer to the terms and conditions for more information on when points will be credited to your account.

You can redeem your points for rewards available on your Dashboard as soon as they are credited to your account.`,
      "question_fr-CA":
        "Dans combien de temps vais-je recevoir mes points et pouvoir les utiliser ?",
      "answer_fr-CA": `Vous pouvez gagner des points grâce à des achats et des activités éligibles à tout moment. Tous les points associés à un achat de produits éligibles seront affichés dans votre tableau de bord dans les 10 premiers jours du mois suivant. Pour les points associés aux activités dans votre tableau de bord, référez-vous aux termes et conditions pour plus d’informations sur le moment où les points seront crédités à votre compte.

Vous pouvez échanger vos points contre des récompenses disponibles sur votre tableau de bord dès qu’ils sont crédités sur votre compte.`,
    },
    {
      "question_en-CA": "Do Points expire?",
      "answer_en-CA":
        "All unused points expire upon 12 months of being credited to your account. For example, Points credited to your Account on January 10, 2023 would expire February 1, 2024.  Additionally, points will expire if your account remains inactive for 12 months or more. If you have any questions about your point activity or expiration, please refer to your Dashboard or contact our Customer Care.",
      "question_fr-CA": "Les points expirent-ils ?",
      "answer_fr-CA":
        "Tous les points inutilisés expirent 12 mois après avoir été crédités sur votre compte. Par exemple, les points crédités sur votre compte le 10 janvier 2023 expireront le 1er février 2024. De plus, les points expireront si votre compte reste inactif pendant 12 mois ou plus. Si vous avez des questions sur l’activité ou l’expiration de vos points, référez-vous à votre tableau de bord ou communiquez avec notre service client.",
    },
  ],
  sweeps: [
    {
      "question_en-CA": "What are the rules?",
      "answer_en-CA":
        "*No purchase necessary. Open to individuals who (i) are the owner of a salon operating in Canada (excluding Northwest Territories, the Yukon and Nunavut; the “Territories”), or an independent operator within a salon operating business in Canada (excluding the Territories); and (ii) a legal resident of Canada (excluding NU, YT and NT; the “Territories”) who has reached the legal age of majority in their province or territory of residence. Starts March 18, 2024 at 12:01 am ET and ends on June 30, 2024 at 11:59 p.m. ET. Register for for a Club H account after January 1, 2024 to enter (visit www.clubh.ca to register). There are 36 regionally allocated prizes (6 for ON, 6 for QC and 3 for each other province). Each prize is a $100 CAD credit (either for colour products (12 prizes), styling products (12 prizes) or hair care products (12 prizes)). Odds depend on number of eligible entries per province. Math skill-testing question required. [See full rules](https://clubh.ca/sweeps-terms).",
      "question_fr-CA": "What are the rules?",
      "answer_fr-CA":
        "*No purchase necessary. Open to individuals who (i) are the owner of a salon operating in Canada (excluding Northwest Territories, the Yukon and Nunavut; the “Territories”), or an independent operator within a salon operating business in Canada (excluding the Territories); and (ii) a legal resident of Canada (excluding NU, YT and NT; the “Territories”) who has reached the legal age of majority in their province or territory of residence. Starts March 18, 2024 at 12:01 am ET and ends on June 30, 2024 at 11:59 p.m. ET. Register for for a Club H account after January 1, 2024 to enter (visit www.clubh.ca to register). There are 36 regionally allocated prizes (6 for ON, 6 for QC and 3 for each other province). Each prize is a $100 CAD credit (either for colour products (12 prizes), styling products (12 prizes) or hair care products (12 prizes)). Odds depend on number of eligible entries per province. Math skill-testing question required. [See full rules](https://clubh.ca/sweeps-terms).",
    },
    {
      "question_en-CA":
        "Who is eligible to participate in the Club H Giveaway?",
      "answer_en-CA":
        "The giveaway is open to all customers who sign up for Club H by June 30th, with eligibility extending across each province in Canada, excluding the territories. Both salon owners and independent stylists are encouraged to enter by joining Club H.",
      "question_fr-CA":
        "Who is eligible to participate in the Club H Giveaway?",
      "answer_fr-CA":
        "The giveaway is open to all customers who sign up for Club H by June 30th, with eligibility extending across each province in Canada, excluding the territories. Both salon owners and independent stylists are encouraged to enter by joining Club H.",
    },
    {
      "question_en-CA": "How are winners selected for the Club H Giveaway?",
      "answer_en-CA":
        "Winners will be chosen at random from the pool of entrants within each province. Ontario and Quebec will each have two winners per category due to the higher volume of participants expected from these locations. All other provinces will have 1 winner per category. In total, 36 prizes will be awarded.",
      "question_fr-CA": "How are winners selected for the Club H Giveaway?",
      "answer_fr-CA":
        "Winners will be chosen at random from the pool of entrants within each province. Ontario and Quebec will each have two winners per category due to the higher volume of participants expected from these locations. All other provinces will have 1 winner per category. In total, 36 prizes will be awarded.",
    },
    {
      "question_en-CA": "What prizes can be won in the Club H Giveaway?",
      "answer_en-CA":
        "Each selected winner will receive a $100 credit that can be used towards colour, styling, or hair care products. The specific category of products (colour, styling, or care) will be randomly assigned to winners.",
      "question_fr-CA": "What prizes can be won in the Club H Giveaway?",
      "answer_fr-CA":
        "Each selected winner will receive a $100 credit that can be used towards colour, styling, or hair care products. The specific category of products (colour, styling, or care) will be randomly assigned to winners.",
    },
    {
      "question_en-CA": "Can I choose the products I win in the giveaway?",
      "answer_en-CA":
        "Winners will have the opportunity to select their products within the assigned category from brands that they have historical purchases of, ensuring the rewards are tailored to their preferences and needs.",
      "question_fr-CA": "Can I choose the products I win in the giveaway?",
      "answer_fr-CA":
        "Winners will have the opportunity to select their products within the assigned category from brands that they have historical purchases of, ensuring the rewards are tailored to their preferences and needs.",
    },
    {
      "question_en-CA": "When will the winners be announced?",
      "answer_en-CA":
        "The winners will be announced shortly after the giveaway closes at the end of June. We aim to notify winners and distribute prizes as swiftly as possible following the selection process.",
      "question_fr-CA": "When will the winners be announced?",
      "answer_fr-CA":
        "The winners will be announced shortly after the giveaway closes at the end of June. We aim to notify winners and distribute prizes as swiftly as possible following the selection process.",
    },
    {
      "question_en-CA": "How will I know if I've won?",
      "answer_en-CA":
        "Winners will be notified via the email address provided upon Club H registration. Please ensure your contact information is up to date to not miss any potential winning announcements.",
      "question_fr-CA": "How will I know if I've won?",
      "answer_fr-CA":
        "Winners will be notified via the email address provided upon Club H registration. Please ensure your contact information is up to date to not miss any potential winning announcements.",
    },
    {
      "question_en-CA":
        "How will I receive my prize if I am selected as a winner in the Club H Giveaway?",
      "answer_en-CA":
        "A representative from Club H will reach out to you to assist in selecting your products. Once you've made your selection, we'll take care of everything else. Your chosen products will be mailed directly to your specified location, ensuring you receive your prize without any hassle.",
      "question_fr-CA":
        "How will I receive my prize if I am selected as a winner in the Club H Giveaway?",
      "answer_fr-CA":
        "A representative from Club H will reach out to you to assist in selecting your products. Once you've made your selection, we'll take care of everything else. Your chosen products will be mailed directly to your specified location, ensuring you receive your prize without any hassle.",
    },
    {
      "question_en-CA":
        "What happens if I can't make a selection for my prize?",
      "answer_en-CA":
        "If you encounter any issues selecting your prize or if you have specific preferences, our Club H support team will be on hand to assist you, ensuring you can fully enjoy your giveaway reward.",
      "question_fr-CA":
        "What happens if I can't make a selection for my prize?",
      "answer_fr-CA":
        "If you encounter any issues selecting your prize or if you have specific preferences, our Club H support team will be on hand to assist you, ensuring you can fully enjoy your giveaway reward.",
    },
    {
      "question_en-CA":
        "Are there any restrictions on what I can select as my prize?",
      "answer_en-CA":
        "Your $100 credit must be used within the specific product category you win (color, styling, or hair care), and selections are subject to availability. Our team will guide you through the available options to ensure satisfaction with your prize.",
      "question_fr-CA":
        "Are there any restrictions on what I can select as my prize?",
      "answer_fr-CA":
        "Your $100 credit must be used within the specific product category you win (color, styling, or hair care), and selections are subject to availability. Our team will guide you through the available options to ensure satisfaction with your prize.",
    },
  ],
};
