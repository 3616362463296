import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { print } from "graphql/language/printer";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import * as Sentry from "@sentry/react";
import { apiDomain, apiSiteId } from "../config";
import typePolicies from "./typePolicies";
import { setApolloClient, getJwt } from "../utils/auth";

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const formattedQuery =
      operation?.query != null ? print(operation.query) : null;
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.withScope(function (scope) {
        scope.setTag("error-source", "graphql-client");
        scope.setExtra("locations", locations);
        scope.setExtra("message", message);
        scope.setExtra("path", path);
        if (formattedQuery != null) {
          scope.setExtra("query", formattedQuery);
        }
        Sentry.captureException(new Error(`GraphQL Error: ${message}`));
      });
      console.error(
        `[GraphQL error]\nMessage: ${message}\nLocation: ${JSON.stringify(
          locations,
          null,
          2
        )}\nPath: ${path}\nQuery: ${formattedQuery || "N/A"}`
      );
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${JSON.stringify(networkError, null, 2)}`);
  }
});

const authLink = setContext((request, previousContext) => {
  const jwt = getJwt();
  return jwt != null
    ? {
        headers: { authorization: `Bearer ${jwt}` },
      }
    : null;
});

const httpLink = createHttpLink({
  uri: `https://${apiDomain}/${apiSiteId}/graphql`,
  credentials: "include",
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies,
  }),
});

setApolloClient(apolloClient);

export const client = apolloClient;
