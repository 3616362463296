import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom";
import browserLang from "browser-lang";
import storage from "local-storage-fallback";
import { locales, defaultLocale, isLocalEnv } from "../../config";

import { ToastProvider } from "../../ui/ToastNotification/ToastNotification";

const messagesLoader = {
  //don't load in default locale
  "fr-CA": () => import("../../messages/compiled/fr-CA.json"),
};

const polyfillIntl = async (locale) => {
  const language = locale.split(/[-_]/)[0];
  try {
    if (!Intl.PluralRules) {
      //below locales need to be explicilty imported
      await import("@formatjs/intl-pluralrules/polyfill");
      if (language === "en") {
        await import(`@formatjs/intl-pluralrules/locale-data/en.js`);
      }
      if (language === "fr") {
        await import(`@formatjs/intl-pluralrules/locale-data/fr.js`);
      }
    }

    if (!Intl.RelativeTimeFormat) {
      await import("@formatjs/intl-relativetimeformat/polyfill");
      if (locale === "en-CA") {
        await import(`@formatjs/intl-relativetimeformat/locale-data/en-CA.js`);
      }

      if (locale === "fr-CA") {
        await import(`@formatjs/intl-relativetimeformat/locale-data/fr-CA.js`);
      }
    }
  } catch (e) {
    console.error(e);
    // throw e;
  }
};

export const Intl = () => {
  const { locale } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    const localeIds = locales.map((itm) => itm.id);

    //1. handle if locale is not specified or incorrect - first check local storage then fall back to browser-lang
    if (!localeIds.includes(locale)) {
      const localeFromLocalStorage = storage.getItem("vite-intl-locale");
      const detectedLocale = localeIds.includes(localeFromLocalStorage)
        ? localeFromLocalStorage
        : browserLang({
            languages: localeIds,
            fallback: defaultLocale,
          });
      return navigate(
        `/${detectedLocale}${location.pathname}${location.search}`,
        { replace: true }
      );
    }

    //2. polyfill intl if needed
    polyfillIntl(locale);

    //3. handle message loading
    if (messagesLoader[locale] != undefined) {
      messagesLoader[locale]().then((results) => {
        setMessages(results.default);
      });
    } else {
      setMessages(null);
    }

    //4. set last known locale as local storage
    storage.setItem("vite-intl-locale", locale);
  }, [locale]);

  return locales.map((itm) => itm.id).includes(locale) ? (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={locale}
      messages={messages}
      //timeZone
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          if (isLocalEnv) console.warn("Missing translation", err.message);
          return;
        }
        throw err;
      }}
    >
      <Outlet />
      <ToastProvider position="bottom-center" autoClose={5000} />
    </IntlProvider>
  ) : null;
};
