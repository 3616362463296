import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { isBrowser, apiSiteId } from "../config";
import AppError from "./error";
import EventHub from "./events/EventHub";
import storage from "local-storage-fallback";
import { apiFetch } from "./api-fetch";
import { set } from "lodash";

let jwt = null;
let jwtExpires = null;
let apolloClient = null;

export const getJwt = () => {
  if (jwt) {
    return jwt;
  }
  return Cookies.get("id_token_clubh");
};

export const setJwt = (token) => {
  // console.info({ token });
  jwt = token;
  if (token == null) {
    Cookies.remove("id_token_clubh");
  } else {
    Cookies.set("id_token_clubh", token, { expires: 365 });
    // if (token) {
    //   const decoded = jwtDecode(jwt);
    //   jwtExpires = decoded.exp * 1000;
    // } else {
    //   jwtExpires = null;
    // }
  }
};

// export const isJwtExpired = () => {
//   if (getJwt() == null) {
//     return false;
//   }
//   return true;
// };

export const setApolloClient = (client) => {
  apolloClient = client;
};

export const setUserToStorage = async (user) => {
  if (!isBrowser) {
    return;
  }
  // EventHub.dispatch('setUser', user);
  if (user) {
    storage.setItem(
      "user",
      JSON.stringify({
        attributes: user,
        created: new Date().getTime().toString(),
      })
    );
  } else {
    storage.removeItem("user");
  }
};

export const getUserFromStorage = () => {
  if (!isBrowser || !storage.getItem("user")) {
    return null;
  }
  const userStorage = JSON.parse(storage.getItem("user"));
  // TODO: inspect "created" property and if older than specified period, delete local storage
  return userStorage.attributes;
};

export const setUserPropertyToStorage = async (property, value) => {
  if (!isBrowser) {
    return;
  }
  const user = getUserFromStorage();
  if (user) {
    user[property] = value;
    setUserToStorage(user);
  }
};

export const isLoggedIn = () => {
  const user = getUserFromStorage();
  // const loggedIn = Cookies.get("loggedIn");
  return user != null;
};

export const signUp = (userInput) => {
  return apiFetch({ path: `/${apiSiteId}/auth/register`, data: userInput });
};

export const verify = ({ token, captcha }) => {
  return apiFetch({
    path: `/${apiSiteId}/auth/verify`,
    data: { token, captcha },
  })
    .then(async ({ result, response }) => {
      // setJwt(result.authTokens.jwt.token);
      setUserToStorage(result.user);
      EventHub.dispatch({ event: "auth", action: "signIn", data: result.user });
      return result;
    })
    .catch((err) => {
      console.error({ err, type: "auth.verify" });
      throw err;
    });
};

export const signIn = (userInput) => {
  return apiFetch({ path: `/${apiSiteId}/auth/login`, data: userInput })
    .then(async ({ result, response }) => {
      // setJwt(result.authTokens.jwt.token);
      //console.info({ loginResult: result });
      const { jwtToken, user, ...metadata } = result;
      setJwt(jwtToken);
      setUserToStorage({ ...metadata, ...user });
      EventHub.dispatch({ event: "auth", action: "signIn", data: result.user });
      if (apolloClient) {
        apolloClient.resetStore();
        //apolloClient.reFetchObservableQueries();
        //apolloClient.refetchQueries({ include: ["GetHeaderCartData"] });
      }
      return result;
    })
    .catch((err) => {
      console.error({ err, type: "auth.signIn" });
      throw err;
    });
};

export const signOut = () => {
  console.info("signout");
  if (apolloClient) {
    apolloClient.resetStore();
  }
  setJwt(null);
  setUserToStorage(null);
  EventHub.dispatch({ event: "auth", action: "signOut" });
  return apiFetch({
    path: `/${apiSiteId}/logout`,
    //method: "get",
  });
};

export const getRefreshFetchRequest = (returnUser = false) => {
  return apiFetch({
    path: `/${apiSiteId}/auth/refresh`,
    data: returnUser ? { returnUser } : null,
    retry: false,
  })
    .then(({ result }) => {
      // setJwt((result || {}).token);
      return result;
    })
    .catch(async (err) => {
      if (
        err instanceof AppError &&
        ["TOKEN_ERR_INVALID_REFRESH_TOKEN", "NOT_FOUND"].includes(err.type)
      ) {
        await signOut();
      }

      throw err;
    });
};

export const forgotPassword = (userInput) => {
  return apiFetch({
    path: `/${apiSiteId}/auth/forgot`,
    data: userInput,
  });
};

export const resetPassword = ({ token, password, captcha }) => {
  return apiFetch({
    path: `/${apiSiteId}/auth/reset/${token}`,
    data: { password, captcha },
  });
};

export const currentAuthenticatedUser = async () => {
  // if (!isLoggedIn()) {
  //   return null;
  // }

  const result = await getRefreshFetchRequest(true);
  if (result != null) {
    const { user, ...metadata } = result;
    setUserToStorage({ ...metadata, ...user });
    EventHub.dispatch({
      event: "auth",
      action: "currentAuthenticatedUser",
      data: user,
    });
    return user;
  }
  if (getUserFromStorage() != null && getJwt() == null) {
    await signOut();
  }
  return null;
};
