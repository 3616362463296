import styled from "styled-components";

export const Root = styled.header`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TopBar = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--color-text-2);
  background-color: var(--color-bg-3);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.13px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 1023px) {
    height: 32px;
    /* padding: 0 8px; */
  }

  @media (min-width: 1024px) {
    height: 40px;
    padding: 0 32px;
  }
`;

export const TopBarBody = styled.div`
  width: 100%;
  max-width: 600px;

  .slick-prev,
  .slick-next {
    background-color: var(--color-bg-3);
    z-index: 1;

    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }

    @media (min-width: 1024px) {
      width: 40px;
      height: 40px;
    }
  }

  .slick-prev {
    @media (max-width: 1023px) {
      left: 0;
    }
  }
  .slick-next {
    @media (max-width: 1023px) {
      right: 0;
    }
  }

  .slick-prev::before,
  .slick-next::before {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    opacity: 1;

    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }

    @media (min-width: 1024px) {
      width: 40px;
      height: 40px;
    }
  }
  .slick-prev::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%237f8696' d='M13.373 4.702 8.262 9.807v.383l5.111 5.105-1.057 1.057-5.551-5.539V9.184l5.551-5.539 1.057 1.057Z'/%3E%3C/svg%3E");
  }
  .slick-next::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%237f8696' d='m6.765 15.295 5.111-5.105v-.383L6.765 4.702l1.057-1.057 5.551 5.539v1.629l-5.551 5.539-1.057-1.057Z'/%3E%3C/svg%3E");
  }
`;

export const TopBarSlide = styled.div`
  padding-top: 6px;
  padding-left: 40px;
  padding-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;

  @media (max-width: 1023px) {
    padding-right: 8px;
    padding-left: 24px;
  }

  @media (min-width: 1024px) {
    padding: 0 32px;

    &.--isSticky {
      position: fixed;
      top: 0;
      z-index: 2;
      box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.05);
    }
  }

  @media (min-width: 1400px) {
    padding: 0 48px;
  }
`;

export const HeaderContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1304px;

  @media (max-width: 1023px) {
    height: 72px;
  }

  @media (min-width: 1024px) {
    height: 80px;

    &.--isSticky {
      height: 56px;
    }
  }
`;

export const MobileMenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const LogoLink = styled.a`
  @media (max-width: 1023px) {
    margin-right: auto;
    margin-left: 14px;
  }
`;

export const Logo = styled.img`
  height: auto;

  @media (max-width: 1023px) {
    width: 104px;
  }

  @media (min-width: 1024px) {
    width: 140px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`;

export const NavItem = styled.li`
  text-align: center;

  @media (min-width: 1024px) {
    padding: 0 12px;
  }

  @media (min-width: 1200px) {
    padding: 0 16px;
  }

  a:hover,
  a.--isActive {
    color: var(--color-accent);
    transition: color 200ms ease;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 8px;

  &:hover {
    color: var(--color-accent);
    transition: color 200ms ease;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

export const UserContainer = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    margin-left: 16px;
  }
`;

export const Action = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 24px;
  border-radius: 50%;

  @media (max-width: 1023px) {
    &.--hideOnMobile {
      display: none;
    }

    & + & {
      margin-left: 6px;
    }
  }

  @media (min-width: 1024px) {
    &.--hideOnDesktop {
      display: none;
    }

    & + & {
      margin-left: 8px;
    }
  }

  &:hover,
  &.--isActive {
    background-color: var(--color-bg-3);
  }

  svg {
    pointer-events: none;
  }
`;

export const ActionBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  min-width: 16px;
  max-width: 32px;
  height: 16px;
  padding: 0 4px;
  color: #fff;
  background-color: var(--color-accent);
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
