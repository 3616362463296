import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TableScrollContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;

  @media (max-width: 1023px) {
    padding-left: 16px;
  }

  @media (min-width: 1024px) {
    justify-content: center;
    padding: 0 24px;
  }
`;

export const Table = styled.table`
  flex-shrink: 0;
  max-width: 1304px;

  thead > tr {
    background-color: #f5f4f8;
  }

  thead > tr > th {
    height: 64px;
    padding: 0 24px;

    &:last-child {
      text-align: right;

      & > div {
        justify-content: flex-end;
      }
    }
  }

  tbody > tr > td {
    height: 80px;
    padding: 0 24px;
    border-bottom: 1px solid #dcdfe9;

    &:last-child {
      text-align: right;
    }
  }
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 376px;
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    height: 480px;
  }

  @media (min-width: 1024px) {
    height: 640px;
  }
`;

export const ActiveFilters = styled.span`
  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    margin-left: 24px;
  }
`;
