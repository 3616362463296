import React from "react";
import { Helmet } from "react-helmet-async";

import {
  Root,
  Container,
  TitleContainer,
  AppendContainer,
} from "./PageTitle.style";

import { Typography } from "../../ui/Typography/Typography";

export const PageTitle = ({ title = "", append = null }) => {
  return (
    <Root>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <TitleContainer>
          <Typography as="h1" type="h1">
            {title}
          </Typography>
        </TitleContainer>
        <AppendContainer>{append}</AppendContainer>
      </Container>
    </Root>
  );
};
