import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "../../Link/Link";

import { Root, Title, Actions } from "./Discover.style";

import { Button } from "../../../ui/Button/Button";
import { Typography } from "../../../ui/Typography/Typography";

import { UserContext } from "../../../context/UserContext";

export function Discover() {
  const { user } = useContext(UserContext);

  return (
    <Root>
      <Title>
        <Typography type="h1">
          <FormattedMessage
            defaultMessage="Discover{br}the Future{br}of Loyalty"
            values={{ br: <br /> }}
          />
        </Typography>
      </Title>
      <Actions>
        <Button as={Link} to="/membership-benefits" variant="secondary">
          <FormattedMessage defaultMessage="Membership Benefits" />
        </Button>
        {user ? (
          <Button as={Link} to="/dashboard">
            <FormattedMessage defaultMessage="My Dashboard" />
          </Button>
        ) : (
          <Button as={Link} to="/auth/register">
            <FormattedMessage defaultMessage="join now" />
          </Button>
        )}
      </Actions>
    </Root>
  );
}
