import { gql } from "@apollo/client";

export function categoriesConfig({ intl }) {
  return [
    {
      value: "care",
      label: intl.formatMessage({
        defaultMessage: "Care",
      }),
    },
    {
      value: "styling",
      label: intl.formatMessage({
        defaultMessage: "Styling",
      }),
    },
    {
      value: "color",
      label: intl.formatMessage({
        defaultMessage: "Color",
      }),
    },
    {
      value: "accessories-tools",
      label: intl.formatMessage({
        defaultMessage: "Accessories & Tools",
      }),
    },
    {
      value: "business-support",
      label: intl.formatMessage({
        defaultMessage: "Business Support",
      }),
    },
    {
      value: "education",
      label: intl.formatMessage({
        defaultMessage: "Education",
      }),
    },
  ];
}

export const sortConfig = [
  { label: "Sort Name Asc", value: "name-asc", key: "n", dir: "1" },
  {
    label: "Sort Latest Items",
    value: "lastest-desc",
    key: "dc",
    dir: "-1",
  },
  {
    label: "Sort Points Asc",
    value: "points-asc",
    key: "ecommerce.price.amount",
    dir: "1",
  },
  {
    label: "Sort Points Desc",
    value: "points-desc",
    key: "ecommerce.price.amount",
    dir: "-1",
  },
];

export const GET_REWARDS = gql`
  query GetRewards(
    $sort: [SortInput]
    $page: Int
    $filters: String
    $limit: Int
    $locale: String
  ) {
    content {
      findClubH(
        sort: $sort
        page: $page
        filters: $filters
        limit: $limit
        locale: $locale
      ) {
        docs {
          ... on Reward {
            _id
            n
            sl
            dc
            dm
            brand {
              _id
              n
            }
            gallery {
              media {
                _id
              }
            }
            howToUse
            data {
              category
              isNew
              isLimited
              isFeatured
              features {
                heatProtection
                colorSafe
                ammoniaFree
                sulfateFree
                parabenFree
                crueltyFree
                vegan
              }
            }
            ecommerce {
              price {
                amount
                currency
              }
              fullPrice {
                amount
                currency
              }
              sku
            }
          }
        }
        page
        pages
        limit
        total
      }
    }
  }
`;

export const GET_REWARD_ITEM = gql`
  query GetRewardItem($slug: String!, $locale: String) {
    content {
      findBySlug(slug: $slug, locale: $locale) {
        ... on Reward {
          _id
          n
          desc
          sl
          dc
          dm
          tags
          brand {
            _id
            n
          }
          gallery {
            media {
              _id
            }
          }
          howToUse
          data {
            size
            category
            isNew
            isLimited
            isFeatured
            features {
              heatProtection
              colorSafe
              ammoniaFree
              sulfateFree
              parabenFree
              crueltyFree
              vegan
            }
          }
          ecommerce {
            price {
              amount
              currency
            }
            fullPrice {
              amount
              currency
            }
            sku
          }
        }
      }
    }
  }
`;

export const GET_WISHLIST = gql`
  query GetWishlist($locale: String) {
    me {
      wishlist(locale: $locale) {
        _id
        items {
          _id
          product {
            ... on Reward {
              _id
            }
          }
        }
      }
    }
  }
`;

export const GET_WISHLIST_DETAILS = gql`
  query GetWishlistDetails($locale: String) {
    me {
      wishlist(locale: $locale) {
        _id
        items {
          _id
          product {
            ... on Reward {
              _id
              e
              n
              sl
              brand {
                _id
                n
              }
              gallery {
                media {
                  _id
                }
              }
              ecommerce {
                price {
                  amount
                  currency
                }
                fullPrice {
                  amount
                  currency
                }
                sku
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CART = gql`
  query GetCart($locale: String) {
    me {
      cart(locale: $locale) {
        _id
        items {
          _id
          product {
            ... on Reward {
              _id
            }
          }
        }
      }
    }
  }
`;

export const GET_CART_DETAILS = gql`
  query GetCartDetails($locale: String) {
    me {
      pointsBalance
      user {
        _id
        phone
        firstname
        lastname
        salon {
          _id
          address {
            str1
            str2
            city
            state
            postal
            country
            data {
              addressType
            }
          }
        }
      }
      cart(locale: $locale) {
        _id
        totals {
          baseCurrency
          taxRate
          pointsTotal {
            amount
            currency
          }
          subTotal {
            amount
            currency
          }
          taxTotal {
            amount
            currency
          }
          shippingTotal {
            amount
            currency
          }
          grandTotal {
            amount
            currency
          }
        }
        items {
          _id
          qty
          totals {
            unitTotal {
              amount
              currency
            }
            pointsUnitTotal {
              amount
              currency
            }
            subTotal {
              amount
              currency
            }
            pointsSubTotal {
              amount
              currency
            }
          }
          product {
            ... on Reward {
              _id
              n
              desc
              type
              gallery {
                media {
                  _id
                }
              }
              data {
                category
              }
              brand {
                _id
                n
              }
              ecommerce {
                forcedQtyOptions
                isTaxable
                sku
                price {
                  amount
                  currency
                }
                fullPrice {
                  amount
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const rewardTagPath = "data.content.findRewardTags";
export const GET_REWARD_TAGS = gql`
  query GetRewardTags($startsWith: String) {
    content {
      findRewardTags(startsWith: $startsWith)
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation AddToCart($productId: ID!, $qty: Int!, $locale: String) {
    cartItemAdd(productId: $productId, qty: $qty, locale: $locale) {
      _id
      totals {
        baseCurrency
        taxRate
        pointsTotal {
          amount
          currency
        }
        subTotal {
          amount
          currency
        }
        taxTotal {
          amount
          currency
        }
        shippingTotal {
          amount
          currency
        }
        grandTotal {
          amount
          currency
        }
      }
      items {
        _id
        qty
        totals {
          unitTotal {
            amount
            currency
          }
          pointsUnitTotal {
            amount
            currency
          }
          subTotal {
            amount
            currency
          }
          pointsSubTotal {
            amount
            currency
          }
        }
        product {
          ... on Reward {
            _id
            n
            desc
            type
            gallery {
              media {
                _id
              }
            }
            data {
              category
            }
            ecommerce {
              forcedQtyOptions
              isTaxable
              sku
            }
          }
        }
      }
    }
  }
`;

export const ADD_TO_WISHLIST = gql`
  mutation AddToWishlist($productId: ID!, $locale: String) {
    wishlistItemAdd(productId: $productId, locale: $locale) {
      _id
      e
      key
      qty
      product {
        ... on Reward {
          _id
          n
          type
        }
      }
    }
  }
`;

export const REMOVE_FROM_WISHLIST = gql`
  mutation RemoveFromWishlist($productId: ID!, $locale: String) {
    wishlistItemRemove(productId: $productId, locale: $locale) {
      _id
      items {
        _id
        product {
          ... on Reward {
            _id
            n
            type
          }
        }
      }
    }
  }
`;

export const UPDATE_CART = gql`
  mutation UpdateCart($productId: ID!, $qty: Int!, $locale: String) {
    cartItemUpdate(productId: $productId, qty: $qty, locale: $locale) {
      _id
      items {
        _id
        product {
          ... on Reward {
            _id
            n
            type
          }
        }
      }
    }
  }
`;

export const CHECKOUT = gql`
  mutation Checkout($data: OrderInput!, $locale: String) {
    cartPlaceOrder(order: $data, locale: $locale) {
      _id
      fid
    }
  }
`;

// export const UPDATE_CART = gql`;
//   mutation UpdateCart($productId: ID, $qty: Int, $locale: String) {
//     cartItemUpdate(productId: $productId, qty: $qty, $locale: String) {
//       _id
//       totals {
//         baseCurrency
//         taxRate
//         pointsTotal {
//           amount
//           currency
//         }
//         subTotal {
//           amount
//           currency
//         }
//         taxTotal {
//           amount
//           currency
//         }
//         shippingTotal {
//           amount
//           currency
//         }
//         grandTotal {
//           amount
//           currency
//         }
//       }
//       items {
//         _id
//         qty
//         totals {
//           unitTotal {
//             amount
//             currency
//           }
//           pointsUnitTotal {
//             amount
//             currency
//           }
//           subTotal {
//             amount
//             currency
//           }
//           pointsSubTotal {
//             amount
//             currency
//           }
//         }
//         product {
//           ... on Reward {
//             _id
//             n
//             desc
//             type
//             gallery {
//               media {
//                 _id
//               }
//             }
//             data {
//               category
//             }
//             ecommerce {
//               forcedQtyOptions
//               isTaxable
//               sku
//             }
//           }
//         }
//       }
//     }
//   }
// `;
