import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    padding: 0 80px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 845px;
  padding-top: 48px;

  h1 {
    margin-bottom: 40px;
    font-weight: 700;

    @media (max-width: 1023px) {
      font-size: 28px;
      line-height: 1.25;
    }

    @media (min-width: 1024px) {
      font-size: 32px;
      line-height: 1.14;
    }
  }

  h2 {
    margin-top: 36px;
    margin-bottom: 12px;
    font-weight: 700;
    line-height: 1.38;

    @media (max-width: 1023px) {
      font-size: 16px;
    }

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }

  h3 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 500;

    @media (max-width: 1023px) {
      font-size: 13px;
      line-height: 1.54;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
      line-height: 1.38;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.71;
    letter-spacing: 0.14px;
  }

  P + p {
    margin-top: 16px;
  }

  ul,
  table {
    margin: 16px 0;
  }

  table {
    width: 100%;
  }

  thead {
    font-weight: 700;
    text-align: left;
  }

  th,
  td {
    padding: 4px 8px;
    font-size: 16px;
    line-height: 1.71;
    letter-spacing: 0.14px;
    border: 1px solid var(--color-border-1);
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    padding: 80px 0;
  }

  @media (min-width: 1024px) {
    padding: 120px 0;
  }
`;
