import styled from "styled-components";

export const Root = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  background-color: #27166a;

  @media (max-width: 1023px) {
    padding: 40px 24px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1304px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 148px 1fr 108px;
    align-items: flex-start;
    padding: 40px 48px;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 208px 1fr 144px;
  }
`;

export const Logo = styled.img`
  display: block;
  width: 64px;
  height: auto;
`;

export const NavContainer = styled.div`
  @media (max-width: 1023px) {
    margin: 32px 0;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 30px;
  }

  @media (min-width: 1400px) {
    column-gap: 96px;
  }
`;

export const NavBlock = styled.div`
  @media (max-width: 1023px) {
    text-align: center;

    & + & {
      margin-top: 28px;
    }

    &:last-child {
      margin-top: 0;
    }
  }
`;

export const Title = styled.h6`
  display: inline-block;
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 1023px) {
    margin-bottom: 6px;
    text-align: center;

    &.--mobile {
      display: none;
    }
  }
`;

export const Nav = styled.ul`
  color: #8f95a5;
  font-size: 14px;
  line-height: 1.67;
  list-style: none;

  @media (max-width: 1023px) {
    text-align: center;
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
    font-weight: 500;
    letter-spacing: 0.12px;
  }

  a:hover {
    color: #fff;
  }
`;

export const SocialContainer = styled.div`
  color: #fff;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 1024px) {
    border-top: 1px solid #3d2b86;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1304px;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.12px;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 1024px) {
    column-gap: 56px;
    height: 55px;
    padding: 0 48px;
  }
`;

export const BottomNav = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 32px;
  }
`;

export const BottomNavItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 1023px) {
    padding: 0 8px;
    text-align: center;
  }

  @media (min-width: 1024px) {
    & + & {
      margin-left: 16px;
    }
  }

  a:hover {
    color: #fff;
  }
`;
