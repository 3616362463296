import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/react";
import { MaskedInput } from "../../ui/MaskedInput/MaskedInput";
import { IMask } from "react-imask";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  isEmpty,
  // , set
} from "lodash";
import {
  useOutletContext,
  // useNavigate
} from "react-router-dom";

import { Form } from "./MyAccount.style";
import {
  toast,
  ToastNotification,
} from "../../ui/ToastNotification/ToastNotification";
import { TextInput } from "../../ui/TextInput/TextInput";
import { Select } from "../../ui/Select/Select";
import { Button } from "../../ui/Button/Button";
// import { ButtonLink } from "../../ui/ButtonLink/ButtonLink";

import { SALON_UPDATE } from "./config";
import { provinces } from "../../config/checkout";
import { Typography } from "../../ui/Typography/Typography";
import { ModalRoot } from "../Modals/ModalRoot/ModalRoot";
import { ModalBonusPointsEarned } from "../Modals/ModalBonusPointsEarned/ModalBonusPointsEarned";
import { getUserAvatar } from "../../utils/user";

const schema = yup.object({
  n: yup.string().required().label("salon name").default(""),
  address: yup.object({
    str1: yup.string().required().label("salon address 1").default(""),
    str2: yup.string().label("salon address 2").default(""),
    state: yup.string().required().default(""),
    city: yup.string().required().default(""),
    postal: yup.string().required().label("postal code").default(""),
  }),
  data: yup.object({
    salonBirthday: yup
      .string()
      .test(
        "date validation",
        "Invalid date or format. The value should in MM/DD/YYYY format.",
        (val) => {
          if (!val) {
            return true;
          }
          return DateTime.fromFormat(val, "M/d/yyyy").isValid;
        }
      )
      .label("salon birthday")
      .default(""),
    website: yup.string().default(""),
  }),
});

export const MySalon = ({ data }) => {
  // const navigate = useNavigate();
  const outletContext = useOutletContext();
  const userData = outletContext?.me?.user || data?.me?.user;
  const intl = useIntl();
  // const [statusMessage, setStatusMessage] = useState();
  const [modalBonusPointsEarned, setModalBonusPointsEarned] = useState(null);

  const [updateSalon] = useMutation(SALON_UPDATE, {
    refetchQueries: ["GetDashboardData"],
  });

  const {
    control,
    handleSubmit,
    watch,
    // setValue,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: schema.default(),
  });

  const formValue = watch();
  // console.info({ formValue });

  useEffect(() => {
    if (userData) {
      reset({
        n: userData?.salon?.n ?? "",
        data: {
          salonBirthday: userData?.salon?.data?.salonBirthday ?? "",
          website: userData?.salon?.data?.website ?? "",
        },
        address: {
          str1: userData?.salon?.address?.str1 ?? "",
          str2: userData?.salon?.address?.str2 ?? "",
          state: userData?.salon?.address?.state ?? "",
          city: userData?.salon?.address?.city ?? "",
          postal: userData?.salon?.address?.postal ?? "",
        },
      });
    }
  }, [userData?.salon?._id, userData?.salon?.dm]);

  const onSubmit = (data) => {
    if (userData?.salon?._id == null) {
      return toast.error(
        <ToastNotification>
          <FormattedMessage defaultMessage="User must have salon associted to update this form." />
        </ToastNotification>
      );
    }
    return updateSalon({
      variables: {
        data,
      },
    })
      .then((result) => {
        toast.success(
          <ToastNotification>
            <FormattedMessage defaultMessage="Your salon profile successfully updated!" />
          </ToastNotification>
        );
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        const bonusPoints = result?.data?.updateMySalon?.bonusPoints?.[0];
        if (bonusPoints) {
          setModalBonusPointsEarned({
            pts: bonusPoints?.points,
            bonusItemName: bonusPoints?.note,
            pointsBalance: result?.data?.updateMySalon?.pointsBalance ?? 0,
            firstName: userData?.firstname,
            avatarSrc: getUserAvatar(userData),
          });
        }
      })
      .catch((err) => {
        toast.error(
          <ToastNotification>
            <FormattedMessage defaultMessage="An error occurred while updating salon profile.  Please refresh and try again." />
          </ToastNotification>
        );
        Sentry.captureException(err);
        console.error({ err, type: "my-salon" });
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography type="h2" style={{ marginBottom: 48, textAlign: "center" }}>
        <FormattedMessage defaultMessage="Salon Profile" />
      </Typography>

      <Controller
        name="n"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Salon Name",
            })}
            error={errors?.n}
          />
        )}
      />
      <Controller
        name="address.str1"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Salon Address 1",
            })}
            error={errors?.address?.str1}
          />
        )}
      />
      <Controller
        name="address.str2"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Salon Address 2 (optional)",
            })}
            error={errors?.address?.str2}
          />
        )}
      />
      <Controller
        name="address.state"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            control={control}
            label={intl.formatMessage({
              defaultMessage: "Province",
            })}
            options={provinces}
            error={errors?.address?.state}
          />
        )}
      />
      <Controller
        name="address.city"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "City",
            })}
            error={errors?.address?.city}
          />
        )}
      />
      <Controller
        name="address.postal"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Postal Code",
            })}
            error={errors?.address?.postal}
          />
        )}
      />
      <Controller
        name="data.website"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Website (optional)",
            })}
            error={errors?.data?.website}
          />
        )}
      />
      <Controller
        name="data.salonBirthday"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <MaskedInput
              id="birthday"
              mask={Date}
              unmask={false}
              pattern="MM/dd/yyyy"
              format={(date) => DateTime.fromISO(date).toFormat("MM/dd/yyyy")}
              parse={(str) => {
                const localDateTime = DateTime.fromFormat(str, "MM/dd/yyyy", {
                  zone: "America/Los_Angeles",
                });
                const utcDateTime = localDateTime.toUTC().toISO();
                return utcDateTime;
              }}
              blocks={{
                MM: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 12,
                },
                dd: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 31,
                },
                yyyy: {
                  mask: IMask.MaskedRange,
                  from: 1800,
                  to: DateTime.now().year,
                },
              }}
              label={intl.formatMessage({
                defaultMessage: "Salon Birthday (MM/DD/YYYY)",
              })}
              error={errors?.data?.salonBirthday}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          );
        }}
      />

      <Button
        variant="auth"
        arrow={false}
        disabled={!isEmpty(errors) || isSubmitting || !isValid || !isDirty}
        type="submit"
        style={{ marginTop: 24 }}
      >
        <FormattedMessage defaultMessage="save changes" />
      </Button>

      {userData?.salon?.ref?.length > 0 && (
        <div style={{ marginTop: "50px" }}>
          <p>Linked Accounts:</p>
          <ol style={{ listStylePosition: "inside" }}>
            {userData?.salon?.ref?.map((ref) => (
              <li key={`${ref.distributor.n}-${ref.ext_id}`}>
                {ref.distributor.n} [Account # {ref.ext_id}]
              </li>
            ))}
          </ol>
          <br />
          <p>
            To add another distirbutor account please{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://clubhrewards.zendesk.com/"
              target="_blank"
            >
              contact support
            </a>
            .
          </p>
        </div>
      )}

      {/* <ButtonLink
        as="button"
        variant="secondary"
        type="button"
        onClick={() => navigate("/dashboard")}
        style={{ marginTop: 24, marginLeft: "auto", marginRight: "auto" }}
      >
        <FormattedMessage defaultMessage="Cancel" />
      </ButtonLink> */}

      <ModalRoot
        isVisible={modalBonusPointsEarned != null}
        onClose={() => setModalBonusPointsEarned(null)}
      >
        <ModalBonusPointsEarned
          {...modalBonusPointsEarned}
          onClose={() => setModalBonusPointsEarned(null)}
        />
      </ModalRoot>
    </Form>
  );
};
