import styled from "styled-components";

export const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.--bg {
    background-color: #f5f4f8;
  }

  @media (min-width: 1024px) {
    padding: 32px 48px 0;
  }
`;

export const ActionsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  list-style: none;

  & + & {
    margin-top: 32px;
  }
`;

export const ActionsItem = styled.li`
  position: relative;
`;

export const BrandAction = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 40px;
  border: solid 1px #dcdfe9;
  cursor: pointer;

  &:hover {
    border: solid 1px #8f95a5;
  }

  svg {
    width: 100%;
  }
`;

export const CategoryAction = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 27px;
  padding: 0 16px;
  color: ${(p) => (p.$isActive ? "#fff" : "var(--color-border-2)")};
  background-color: ${(p) =>
    p.$isActive ? "var(--color-accent)" : "transparent"};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  border: solid 1px
    ${(p) => (p.$isActive ? "var(--color-accent)" : "var(--color-border-1)")};
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    border: solid 1px
      ${(p) => (p.$isActive ? "var(--color-accent)" : "#9095a4")};
  }
`;

export const Checkbox = styled.input`
  display: none;

  &:checked + ${BrandAction} {
    border: solid 1px #e1000f;
  }
`;

export const ExpandButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 1304px;
  height: 40px;

  @media (min-width: 1024px) {
    margin: 8px 0;
  }
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: var(--color-accent);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.12px;
  text-align: right;
`;

export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.12px;
  text-align: right;
  color: #8f95a5;

  &:hover {
    color: var(--color-accent);
  }
`;

export const AdvancedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1304px;
  padding-bottom: 32px;
  border-bottom: 1px solid #dcdfe9;
`;

export const Fields = styled.div`
  width: 100%;
`;

export const FieldsContainer = styled.div`
  &.--twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }
`;

export const RangeContainer = styled.div`
  display: flex;
`;
