import styled from "styled-components";

export const Root = styled.figure`
  border-left: 2px solid #dcdfe9;

  @media (max-width: 1023px) {
    margin: 40px 0;
    padding: 8px 0 6px 16px;
  }

  @media (min-width: 1024px) {
    margin: 48px 0;
    padding: 10px 0 8px 32px;
  }
`;

export const Quote = styled.blockquote``;

export const Caption = styled.figcaption`
  margin-top: 9px;
  color: #8f95a5;
`;
