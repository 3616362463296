import React, { useRef, useState, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import truncate from "truncate-html";
import { UserContext } from "../../context/UserContext";
import { useForm } from "react-hook-form";

import {
  Root,
  ArrowButton,
  LoadingContainer,
  Container,
  ImagesContainer,
  DotsList,
  DotsButton,
  SliderContainer,
  Slide,
  SlideImage,
  SliderFeat,
  AddToFavouriteButton,
  Form,
  BrandNameContainer,
  BrandNameLink,
  DescContainer,
  DetailsList,
  IconsList,
  IconsItem,
  IconsLink,
  IconsText,
  InputContainer,
  InputButton,
  Input,
  AddContainer,
  MoreLessButton,
  DropdownButton,
} from "./RewardsDetail.style";

import { Link } from "../Link/Link";
import { Icon } from "../../ui/Icon/Icon";
import { Button } from "../../ui/Button/Button";
import { Typography } from "../../ui/Typography/Typography";
import { Loading } from "../Loading/Loading";
import { ContentDetailTags } from "../ContentDetailTags/ContentDetailTags";
import { MenuDropdown } from "../../ui/MenuDropdown/MenuDropdown";
import { Lightbox } from "../Lightbox/Lightbox";

import IconHeatProtection from "../../assets/images/icons/product-heat-protection.svg?react";
import IconVegan from "../../assets/images/icons/product-vegan.svg?react";
import IconCrueltyFree from "../../assets/images/icons/product-cruelty-free.svg?react";
import IconSulfateFree from "../../assets/images/icons/product-sulfate-free.svg?react";
import IconParabenFree from "../../assets/images/icons/product-paraben-free.svg?react";
// import IconGlutenFree from "../../assets/images/icons/product-gluten-free.svg?react";
import IconAmmoniaFree from "../../assets/images/icons/product-ammonia-free.svg?react";
import IconColorSafe from "../../assets/images/icons/product-color-safe.svg?react";

const Arrow = ({ dir, onClick }) => {
  return (
    <ArrowButton type="button" onClick={onClick} $dir={dir}>
      {dir > 0 ? (
        <Icon icon="arrow-right-long" />
      ) : (
        <Icon icon="arrow-left-long" />
      )}
    </ArrowButton>
  );
};

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <Arrow dir={-1} />,
  nextArrow: <Arrow dir={1} />,
};

export const RewardsDetail = ({
  images = [],
  brand = { label: "", to: "/" },
  title = "",
  desc = "",
  tags = [],
  usageDirections = "",
  points,
  oldPts,
  details = [],
  features = {},
  isFeatured = false,
  isNew = false,
  isLimited = false,
  inFavorites = false,
  onAddToFavourites = null,
  onAddToCart = null,
  loading = false,
  descLimit = 284,
  instuctionsLimit = 142,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const sliderRef = useRef();
  const shareMenuRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isDescExpanded, setIsDescExpanded] = useState(false);
  const [isDirectionsExpanded, setIsDirectionsExpanded] = useState(false);
  const [shareMenuIsOpen, setShareMenuIsOpen] = useState(false);
  const encodedFullPath = encodeURIComponent(window.location.href);
  const encodedImageSrc = encodeURIComponent(images[0]);
  const encodedTitle = encodeURIComponent(title.substring(0, 280).trim());

  const { register, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      qty: 1,
    },
  });
  const qty = watch("qty");

  const handleQty = (dir) => {
    if (isNaN(qty)) return;
    if (dir < 0) {
      setValue("qty", parseInt(qty) - 1);
    } else {
      setValue("qty", parseInt(qty) + 1);
    }
  };

  useEffect(() => {
    if (location?.state?.onLoad?.method === "onAddToCart" && points != null) {
      onAddToCart(...location?.state?.onLoad?.params);
      navigate(location.pathname, { replace: true }); //clear state by passing replace=true
    }

    if (
      location?.state?.onLoad?.method === "onAddToFavourites" &&
      points != null
    ) {
      onAddToFavourites(...location?.state?.onLoad?.params);
      navigate(location.pathname, { replace: true }); //clear state by passing replace=true
    }
  }, [location?.state?.onLoad?.method, points]);

  const onSubmit = (data) => {
    if (user?._id == null) {
      return navigate(
        `/${intl.locale}/auth?redirect=${encodeURIComponent(
          location.pathname
        )}`,
        {
          state: {
            redirectState: {
              onLoad: { method: "onAddToCart", params: [data] },
            },
          },
        }
      );
    }
    onAddToCart(data);
  };

  const handleSlideGoTo = (slide) => {
    sliderRef.current.slickGoTo(slide);
  };

  let label = null;
  if (isNew) {
    label = "New";
  } else if (isLimited) {
    label = "Limited";
  } else if (isFeatured) {
    label = "Featured";
  }

  return (
    <Root>
      {loading ? (
        <LoadingContainer>
          <Loading global={false} />
        </LoadingContainer>
      ) : (
        <Container>
          <ImagesContainer>
            <DotsList>
              {images.map(({ id, image }, index) => (
                <li key={id}>
                  <DotsButton
                    className={index === currentSlide && "--active"}
                    type="button"
                    onClick={() => handleSlideGoTo(index)}
                  >
                    <img src={image} alt="" />
                  </DotsButton>
                </li>
              ))}
            </DotsList>

            <SliderContainer>
              <Slider
                ref={sliderRef}
                {...sliderSettings}
                beforeChange={(currentSlide, nextSlide) =>
                  setCurrentSlide(nextSlide)
                }
              >
                {images.map((slide) => (
                  <Slide key={slide.id}>
                    <SlideImage
                      src={slide.image}
                      alt=""
                      onClick={() => setIsZoomed(true)}
                    />
                  </Slide>
                ))}
              </Slider>
              {label && <SliderFeat>{label}</SliderFeat>}
              <AddToFavouriteButton
                type="button"
                $isMobile
                onClick={(e) => {
                  if (user?._id == null) {
                    return navigate(
                      `/${intl.locale}/auth?redirect=${encodeURIComponent(
                        location.pathname
                      )}`,
                      {
                        state: {
                          redirectState: {
                            onLoad: {
                              method: "onAddToFavourites",
                              params: [],
                            },
                          },
                        },
                      }
                    );
                  }
                  onAddToFavourites();
                }}
              >
                {inFavorites ? (
                  <Icon icon="heart-filled" />
                ) : (
                  <Icon icon="heart" />
                )}
              </AddToFavouriteButton>
            </SliderContainer>
          </ImagesContainer>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <BrandNameContainer>
              <BrandNameLink as={Link} to={brand ? brand.to : "/rewards"}>
                {brand ? brand.label : "Club H"}
              </BrandNameLink>
              <DropdownButton
                className={`shareDropdownAction ${
                  shareMenuIsOpen && "--isActive"
                }`}
                ref={shareMenuRef}
                type="button"
                onClick={() => setShareMenuIsOpen(!shareMenuIsOpen)}
              >
                <Icon icon="share" color="#8f95a5" />

                <MenuDropdown
                  isOpen={shareMenuIsOpen}
                  onClose={() => setShareMenuIsOpen(false)}
                  ignoreClassName="shareDropdownAction"
                >
                  <MenuDropdown.Item
                    as={Link}
                    to={`mailto:?subject=${encodedTitle}&body=${encodedFullPath}`}
                    target="_blank"
                  >
                    <Icon icon="mail" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="Email" />
                    </Typography>
                  </MenuDropdown.Item>
                  <MenuDropdown.Item
                    as={Link}
                    to={`https://pinterest.com/pin/create/button/?url=${encodedFullPath}&media=${encodedImageSrc}`}
                    target="_blank"
                  >
                    <Icon icon="pinterest" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="Pinterest" />
                    </Typography>
                  </MenuDropdown.Item>
                  <MenuDropdown.Item
                    as={Link}
                    to={`https://www.facebook.com/sharer/sharer.php?u=${encodedFullPath}`}
                    target="_blank"
                  >
                    <Icon icon="facebook" style={{ fontSize: 17 }} />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="Facebook" />
                    </Typography>
                  </MenuDropdown.Item>
                  <MenuDropdown.Item
                    as={Link}
                    to={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedFullPath}`}
                    target="_blank"
                  >
                    <Icon icon="x" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="X" />
                    </Typography>
                  </MenuDropdown.Item>
                  <MenuDropdown.Item
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      navigator.clipboard.writeText(window.location.href)
                    }
                  >
                    <Icon icon="link" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="Copy Link" />
                    </Typography>
                  </MenuDropdown.Item>
                </MenuDropdown>
              </DropdownButton>
            </BrandNameContainer>

            <Typography type="h2">{title}</Typography>
            {user?._id != null && (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginTop: 16,
                }}
              >
                {oldPts && (
                  <Typography
                    type="points-secondary"
                    style={{ marginRight: 6, textDecoration: "line-through" }}
                  >
                    {oldPts.toLocaleString("en-US")}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </Typography>
                )}
                <Typography
                  type="points-primary"
                  color={oldPts ? "#e1000f" : "#000"}
                >
                  {points.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </Typography>
              </div>
            )}
            <DescContainer>
              <DetailsList>
                {details.map((detail, index) => (
                  <li key={index}>
                    <Typography as="div" type="text-secondary">
                      <b>{detail.label}</b>: <span>{detail.value}</span>
                    </Typography>
                  </li>
                ))}
              </DetailsList>

              <Typography type="text-primary">
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncate(
                      desc,
                      isDescExpanded ? Number.MAX_SAFE_INTEGER : descLimit
                    ),
                  }}
                />
                {`${desc}`.length > descLimit && (
                  <>
                    {" "}
                    <MoreLessButton
                      type="button"
                      onClick={() => setIsDescExpanded(!isDescExpanded)}
                    >
                      {isDescExpanded ? (
                        <FormattedMessage defaultMessage="view less" />
                      ) : (
                        <FormattedMessage defaultMessage="view more" />
                      )}
                    </MoreLessButton>
                  </>
                )}

                {`${usageDirections}`.length > 0 && (
                  <>
                    <Typography
                      type="text-secondary"
                      style={{ marginTop: 14, marginBottom: 4 }}
                    >
                      <b>
                        <FormattedMessage defaultMessage="Usage Directions:" />
                      </b>
                    </Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncate(
                          usageDirections,
                          isDirectionsExpanded
                            ? Number.MAX_SAFE_INTEGER
                            : instuctionsLimit
                        ),
                      }}
                    />
                    {`${usageDirections}`.length > instuctionsLimit && (
                      <>
                        {" "}
                        <MoreLessButton
                          type="button"
                          onClick={() =>
                            setIsDirectionsExpanded(!isDirectionsExpanded)
                          }
                        >
                          {isDirectionsExpanded ? (
                            <FormattedMessage defaultMessage="view less" />
                          ) : (
                            <FormattedMessage defaultMessage="view more" />
                          )}
                        </MoreLessButton>
                      </>
                    )}
                  </>
                )}
              </Typography>

              {Object.keys(features || {}).length > 0 && (
                <IconsList>
                  {features?.heatProtection && (
                    <IconsItem>
                      <IconsLink
                        as={Link}
                        to="/rewards?filters=(tags:!(heat-protect))"
                      >
                        <IconHeatProtection />
                        <IconsText>
                          <FormattedMessage defaultMessage="Heat Protect" />
                        </IconsText>
                      </IconsLink>
                    </IconsItem>
                  )}
                  {features?.crueltyFree && (
                    <IconsItem>
                      <IconsLink
                        as={Link}
                        to="/rewards?filters=(tags:!(cruelty-free))"
                      >
                        <IconCrueltyFree />
                        <IconsText>
                          <FormattedMessage defaultMessage="Cruelty Free" />
                        </IconsText>
                      </IconsLink>
                    </IconsItem>
                  )}
                  {features?.sulfateFree && (
                    <IconsItem>
                      <IconsLink
                        as={Link}
                        to="/rewards?filters=(tags:!(sulfate-free))"
                      >
                        <IconSulfateFree />
                        <IconsText>
                          <FormattedMessage defaultMessage="Sulfate Free" />
                        </IconsText>
                      </IconsLink>
                    </IconsItem>
                  )}
                  {features?.parabenFree && (
                    <IconsItem>
                      <IconsLink
                        as={Link}
                        to="/rewards?filters=(tags:!(paraben-free))"
                      >
                        <IconParabenFree />
                        <IconsText>
                          <FormattedMessage defaultMessage="Paraben Free" />
                        </IconsText>
                      </IconsLink>
                    </IconsItem>
                  )}
                  {features?.vegan && (
                    <IconsItem>
                      <IconsLink
                        as={Link}
                        to="/rewards?filters=(tags:!(vegan))"
                      >
                        <IconVegan />
                        <IconsText>
                          <FormattedMessage defaultMessage="Vegan" />
                        </IconsText>
                      </IconsLink>
                    </IconsItem>
                  )}
                  {features?.colorSafe && (
                    <IconsItem>
                      <IconsLink
                        as={Link}
                        to="/rewards?filters=(tags:!(color-safe))"
                      >
                        <IconColorSafe />
                        <IconsText>
                          <FormattedMessage defaultMessage="Color Safe" />
                        </IconsText>
                      </IconsLink>
                    </IconsItem>
                  )}
                  {features?.ammoniaFree && (
                    <IconsItem>
                      <IconsLink
                        as={Link}
                        to="/rewards?filters=(tags:!(ammonia-free))"
                      >
                        <IconAmmoniaFree />
                        <IconsText>
                          <FormattedMessage defaultMessage="Ammonia Free" />
                        </IconsText>
                      </IconsLink>
                    </IconsItem>
                  )}
                </IconsList>
              )}

              <InputContainer>
                <InputButton
                  type="button"
                  onClick={() => handleQty(-1)}
                  disabled={qty < 2}
                >
                  <Icon icon="minus" />
                </InputButton>
                <Input
                  type="number"
                  {...register("qty", {
                    valueAsNumber: true,
                    min: 1,
                  })}
                />
                <InputButton type="button" onClick={() => handleQty(1)}>
                  <Icon icon="plus" />
                </InputButton>
              </InputContainer>
              <AddContainer>
                <Button type="submit" variant="auth" arrow={false}>
                  <FormattedMessage defaultMessage="Add to cart" />
                </Button>
                <AddToFavouriteButton
                  type="button"
                  $isDesktop
                  onClick={(e) => {
                    if (user?._id == null) {
                      return navigate(
                        `/${intl.locale}/auth?redirect=${encodeURIComponent(
                          location.pathname
                        )}`,
                        {
                          state: {
                            redirectState: {
                              onLoad: {
                                method: "onAddToFavourites",
                                params: [],
                              },
                            },
                          },
                        }
                      );
                    }
                    onAddToFavourites();
                  }}
                >
                  {inFavorites ? (
                    <Icon icon="heart-filled" />
                  ) : (
                    <Icon icon="heart" />
                  )}
                </AddToFavouriteButton>
              </AddContainer>
            </DescContainer>

            {tags?.length > 0 && (
              <ContentDetailTags
                tags={tags.map((t) => ({
                  label: t,
                  url: `/rewards?filters=(tags:!(${t}))`,
                }))}
                style={{ marginTop: 24 }}
              />
            )}
          </Form>
        </Container>
      )}

      {isZoomed && (
        <Lightbox
          images={images}
          initialImage={currentSlide}
          onClose={() => setIsZoomed(false)}
        />
      )}
    </Root>
  );
};
