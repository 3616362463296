import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1024px) {
    padding-left: 48px;
    padding-right: 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1304px;
`;

export const TitleContainer = styled.div`
  flex-grow: 1;

  @media (max-width: 1023px) {
    min-height: 62px;
  }

  @media (min-width: 1024px) {
    min-height: 70px;
  }
`;

export const AppendContainer = styled.div`
  flex-grow: 0;

  @media (max-width: 1023px) {
    display: none;
  }
`;
