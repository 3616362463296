import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 64px 24px;
  }

  @media (min-width: 1024px) {
    padding: 120px 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 584px;
  }
`;

export const CtaContainer = styled.div`
  @media (max-width: 1023px) {
    margin-top: 64px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Note = styled.div`
  @media (max-width: 1023px) {
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 64px;
  }
`;

export const MarkdownContainer = styled.div`
  p {
    margin-bottom: 20px;
  }

  ol {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ul {
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: disc outside none;
  }

  ul li,
  ol li {
    margin-left: 2em;
    display: list-item;
    text-align: -webkit-match-parent;
  }
`;
