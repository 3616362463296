import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ImagesContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    height: 248px;
  }

  @media (min-width: 1024px) {
    height: 650px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Content = styled.div`
  color: #fff;
  background-color: #27166a;

  @media (max-width: 1023px) {
    padding: 32px 24px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 596px;
    padding: 32px 48px;
  }
`;
