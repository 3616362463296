import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 32px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 120px;
  height: 120px;
  color: #fff;
  background-color: #ededed;
  font-size: 72px;
  border-radius: 60px;
  overflow: hidden;

  &::after {
    content: "";
    display: none;
    position: absolute;
    inset: 0;
    background-color: rgb(39, 22, 106, 0.62);
  }

  &.--uploading {
    &::after {
      display: block;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const Progress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.11px;
  transform: translate(-50%, -50%);
`;

export const UploadActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const UploadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  padding-right: 14px;
  padding-left: 7px;
  color: #8f95a5;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: solid 1px #dcdfe9;
  border-radius: 16px;

  span {
    margin-left: 6px;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: #8f95a5;
  background-color: transparent;
  font-size: 18px;
  border-radius: 16px;
  transition: all 150ms;

  &:hover {
    color: #000;
    background-color: var(--color-bg-3);
  }
`;

export const UploadAcceptedInfo = styled.div`
  margin-top: 32px;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-align: center;
`;
