import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 416px;
  background-color: #fff;

  @media (max-width: 1023px) {
    min-height: 456px;
    padding: 64px 16px 40px;
  }

  @media (min-width: 1024px) {
    min-height: 500px;
    padding: 74px 40px;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
`;
