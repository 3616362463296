import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import {
  Container,
  Title,
  Details,
  OrderStatusContainer,
  ExpandButton,
  SummaryTable,
  SummaryTableCellContainer,
  SummaryContainerMobile,
  AddressContainer,
  AddressItem,
} from "./DashboardOrderView.style";

import { NotFoundView } from "../NotFoundView/NotFoundView";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading/Loading";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Footer } from "../../components/Footer/Footer";
import { Typography } from "../../ui/Typography/Typography";
import { OrderStatusBar } from "../../components/OrderStatusBar/OrderStatusBar";
import { DashboardProductCard } from "../../components/DashboardProductCard/DashboardProductCard";
import { TotalDetails } from "../../components/TotalDetails/TotalDetails";
import { Link } from "../../components/Link/Link";
import { GET_ORDER_DETAIL, statusMap } from "../../components/Dashboard/config";
import { brands } from "../../config/brands";
import { apiDomain, apiSiteId } from "../../config";
import { DateTime } from "luxon";

export const DashboardOrderView = () => {
  const intl = useIntl();
  const { orderId } = useParams();
  const [isExpandedHistory, setIsExpandedHistory] = useState(true);
  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(GET_ORDER_DETAIL, {
    variables: { id: orderId },
  });

  const order = orderData?.order?.findById;
  console.info({ order });

  if (!orderLoading && order == null) {
    return <NotFoundView />;
  }

  const statuses = (order?.notes ?? []).map((note) => ({
    status: note.status,
    date: note.dc,
    notes: note.note,
  }));

  statuses.push({
    status: "pending",
    date: order?.dc,
    notes: "Thank you for your order! It is in queue for processing...",
  });

  statuses.sort((a, b) => b.date - a.date);

  return orderLoading ? (
    <Loading global />
  ) : (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          {
            to: "/dashboard",
            label: intl.formatMessage({
              defaultMessage: "Dashboard",
            }),
          },
          {
            to: null,
            label: `${intl.formatMessage({
              defaultMessage: "Order",
            })} #${order?.fid}`,
          },
        ]}
      />

      <Container>
        <Title>
          <Link to="/dashboard">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H107.31l18.35,18.34a8,8,0,0,1-11.32,11.32l-32-32a8,8,0,0,1,0-11.32l32-32a8,8,0,0,1,11.32,11.32L107.31,120H168A8,8,0,0,1,176,128Z"></path>
            </svg>
          </Link>
          <Typography type="h2">
            <FormattedMessage defaultMessage="Order" /> #{order?.fid}
          </Typography>
        </Title>

        <Details>
          {/* {statusMap(intl)?.[order?.status]?.icon}
          &nbsp;&nbsp; */}
          <Typography type="text-small">
            <FormattedMessage
              defaultMessage="Status {status}"
              values={{ status: statusMap(intl)?.[order?.status]?.title }}
            />
          </Typography>
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          <Typography type="text-small">
            <FormattedMessage
              defaultMessage="Last Updated {datetime}"
              values={{
                datetime: DateTime.fromMillis(order?.dm).toLocaleString(
                  DateTime.DATETIME_MED
                ),
              }}
            />
          </Typography>
        </Details>

        <OrderStatusContainer>
          {isExpandedHistory &&
            statuses.map((status, i) => (
              <OrderStatusBar
                key={i}
                status={status.status}
                date={status.date}
                notes={status.notes}
              />
            ))}
          {!isExpandedHistory && statuses.length > 0 && (
            <OrderStatusBar
              key={0}
              status={statuses[0].status}
              date={statuses[0].date}
              notes={statuses[0].notes}
            />
          )}
        </OrderStatusContainer>

        {statuses.length > 1 && (
          <ExpandButton
            type="button"
            onClick={() => setIsExpandedHistory(!isExpandedHistory)}
          >
            <Typography type="text-small">
              {isExpandedHistory ? (
                <FormattedMessage defaultMessage="Show Less" />
              ) : (
                <FormattedMessage defaultMessage="Show More" />
              )}
            </Typography>
            &nbsp;
            {isExpandedHistory ? (
              <svg
                width={14}
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M 2.75 6.306 L 11.25 6.306 L 11.25 7.695 L 2.75 7.695 Z"
                ></path>
              </svg>
            ) : (
              <svg
                width={14}
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M6.306 2.75h1.389v3.243l.313.313h3.242v1.389H7.978l-.283.283v3.272H6.306V7.978l-.283-.283H2.75v-1.39h3.243l.313-.313V2.75Z"
                />
              </svg>
            )}
          </ExpandButton>
        )}

        <SummaryTable>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>
                <Typography type="text-small" color="#8f95a5">
                  <FormattedMessage defaultMessage="Product" />
                </Typography>
              </th>
              <th>
                <Typography type="text-small" color="#8f95a5">
                  <FormattedMessage defaultMessage="Quantity" />
                </Typography>
              </th>
              <th style={{ textAlign: "right", width: "150px" }}>
                <Typography type="text-small" color="#8f95a5">
                  <FormattedMessage defaultMessage="Total" />
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {order?.items?.map((item) => {
              const product = item?.product;
              const brandConfig = brands.find(
                (b) => b.id === product.brand?._id
              );
              const imgSrc =
                product?.gallery?.[0]?.media?._id != null
                  ? `https://${apiDomain}/${apiSiteId}/asset/${product.gallery[0].media._id}?type=fill&width=604&height=752`
                  : `https://picsum.photos/id/${i + 50}/1000/1000`;
              return (
                <tr key={item._id}>
                  <td>
                    <SummaryTableCellContainer style={{ marginTop: 24 }}>
                      <DashboardProductCard
                        isEnabled={item.e}
                        imageSrc={imgSrc}
                        productName={item?.n}
                        productUrl="/"
                        brandName={brandConfig?.label}
                        brandUrl={`/brands/${brandConfig?.slug}`}
                        pts={item?.pointsUnitTotal?.amount ?? 0}
                        // oldPts={5000}
                      />
                    </SummaryTableCellContainer>
                  </td>
                  <td>
                    <SummaryTableCellContainer
                      style={{ justifyContent: "center", marginTop: 24 }}
                    >
                      <Typography type="text-secondary">
                        {item?.e ? item?.qty : <s>{item?.qty}</s>}
                      </Typography>
                    </SummaryTableCellContainer>
                  </td>
                  <td>
                    <SummaryTableCellContainer
                      style={{ justifyContent: "flex-end", marginTop: 24 }}
                    >
                      <Typography type="text-secondary">
                        {item?.e ? (
                          `${item?.pointsSubTotal?.amount.toLocaleString(
                            "en-US"
                          )} PTS`
                        ) : (
                          <s>{`${item?.pointsSubTotal?.amount.toLocaleString(
                            "en-US"
                          )} PTS`}</s>
                        )}
                      </Typography>
                    </SummaryTableCellContainer>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </SummaryTable>

        <SummaryContainerMobile>
          {order?.items?.map((item) => {
            const product = item?.product;
            const brandConfig = brands.find((b) => b.id === product.brand?._id);
            const imgSrc =
              product?.gallery?.[0]?.media?._id != null
                ? `https://${apiDomain}/${apiSiteId}/asset/${product.gallery[0].media._id}?type=fill&width=604&height=752`
                : `https://picsum.photos/id/${i + 50}/1000/1000`;

            return (
              <DashboardProductCard
                key={item._id}
                isEnabled={item.e}
                imageSrc={imgSrc}
                productName={item?.n}
                productUrl="/"
                brandName={brandConfig?.label}
                brandUrl={`/brands/${brandConfig?.slug}`}
                pts={item?.pointsUnitTotal?.amount ?? 0}
                style={{ minHeight: 80 }}
              />
            );
          })}
        </SummaryContainerMobile>

        <TotalDetails shipping={0} totalPts={order?.pointsTotal?.amount} />

        <AddressContainer>
          <AddressItem>
            <Typography type="h4" style={{ marginBottom: 8 }}>
              <FormattedMessage defaultMessage="Billing Address" />
            </Typography>
            <Typography type="text-secondary" color="#7f8696">
              {order?.billing?.address?.data?.firstname}{" "}
              {order?.billing?.address?.data?.lastname}
            </Typography>
            <Typography type="text-secondary" color="#7f8696">
              {order?.billing?.address?.str1}
            </Typography>
            {order?.billing?.address?.str2 && (
              <Typography type="text-secondary" color="#7f8696">
                {order?.billing?.address?.str2}
              </Typography>
            )}
            <Typography type="text-secondary" color="#7f8696">
              {order?.billing?.address?.city}, {order?.billing?.address?.postal}
            </Typography>
          </AddressItem>
          <AddressItem>
            <Typography type="h4" style={{ marginBottom: 8 }}>
              <FormattedMessage defaultMessage="Delivery Address" />
            </Typography>
            <Typography type="text-secondary" color="#7f8696">
              {order?.shipping?.address?.data?.firstname}{" "}
              {order?.shipping?.address?.data?.lastname}
            </Typography>
            <Typography type="text-secondary" color="#7f8696">
              {order?.shipping?.address?.str1}
            </Typography>
            {order?.shipping?.address?.str2 && (
              <Typography type="text-secondary" color="#7f8696">
                {order?.shipping?.address?.str2}
              </Typography>
            )}
            <Typography type="text-secondary" color="#7f8696">
              {order?.shipping?.address?.city},{" "}
              {order?.shipping?.address?.postal}
            </Typography>
            <Typography type="text-secondary" color="#7f8696">
              {order?.shipping?.address?.data?.addressType}
            </Typography>
          </AddressItem>
        </AddressContainer>
      </Container>

      <Footer />
    </Layout>
  );
};
