import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 376px;

  @media (max-width: 1023px) {
    padding: 48px 8px 64px;
  }

  @media (min-width: 1024px) {
    padding: 89px 48px 120px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1304px;
`;

export const Grid = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    row-gap: 64px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 22px;
    row-gap: 81px;
  }

  @media (min-width: 1400px) {
    column-gap: 32px;
    row-gap: 89px;
  }
`;
