import styled from "styled-components";

import bgDesktopSrc from "../../../assets/images/discover-bg-desktop.jpg";
import bgTabletSrc from "../../../assets/images/discover-bg-tablet.jpg";
import bgMobileSrc from "../../../assets/images/discover-bg-mobile@x2.jpg";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  height: 647px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 639px) {
    background-image: url(${bgMobileSrc});
  }

  @media (min-width: 640px) {
    background-image: url(${bgTabletSrc});
  }

  @media (min-width: 1400px) {
    background-image: url(${bgDesktopSrc});
  }

  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    padding: 0 128px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 1144px;

  @media (max-width: 1023px) {
    padding-bottom: 48px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 96px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  color: #fff;
`;

export const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;
