import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { AnimatePresence } from "framer-motion";

import { Root, Overlay, DialogContainer } from "./ModalRoot.style";

export const ModalRoot = ({
  isVisible = false,
  closeOnClickBackground = true,
  closeOnEsc = true,
  onClose = null,
  fullScreen = false,
  children,
}) => {
  useEffect(() => {
    if (!closeOnEsc) return;
    window.addEventListener("keydown", handleEscDismiss);

    return () => {
      window.removeEventListener("keydown", handleEscDismiss);
    };
  }, []);

  const handleEscDismiss = (event) => {
    if (event.keyCode === 27 && onClose) onClose();
  };

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <Root tabIndex={-1} role="dialog" aria-modal $fullScreen={fullScreen}>
          <Overlay
            key="modalOverlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose && closeOnClickBackground ? onClose : undefined}
          />
          <DialogContainer
            key="modalDialog"
            initial={{ scale: 0.95, opacity: 0, y: -20 }}
            animate={{
              scale: 1,
              opacity: 1,
              y: 0,
              transition: { duration: 0.1 },
            }}
            exit={{
              opacity: 0,
              y: 0,
              transition: { duration: 0.15 },
            }}
            $fullScreen={fullScreen}
          >
            {children}
          </DialogContainer>
        </Root>
      )}
    </AnimatePresence>,
    document.body
  );
};

ModalRoot.propTypes = {
  onClose: PropTypes.func.isRequired,
};
