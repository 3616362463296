import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    padding: 53px 0 71px 24px;
  }

  @media (min-width: 1024px) {
    /* padding: 0 67px 40px 104px; */
    padding: 0 164px 40px 164px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1147px;
`;

export const ScrollContainer = styled.div`
  @media (max-width: 1023px) {
    padding-bottom: 10px;
    overflow-x: scroll;
  }
`;

export const CellWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;

  &.--starter {
    background-color: #e9e14b;
  }
  &.--enthousiast {
    background-color: #f0aa3c;
  }
  &.--master {
    background-color: #cf2b23;
  }
  &.--icon {
    background-color: #7c2662;
  }
  &.--legend {
    background-color: #28325a;
  }
`;

export const BonusContainer = styled.div`
  display: grid;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 88px;
  }
`;

export const MonthlyPromotionsDesc = styled.div`
  margin-top: 8px;
  color: #8f95a5;
`;

export const ViewDetails = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0 8px;
  color: #e1000f;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-decoration: underline;
  white-space: nowrap;
`;
