import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;

  @media (max-width: 1023px) {
    padding: 64px 16px;
  }

  @media (min-width: 1024px) {
    padding: 70px 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1144px;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media (min-width: 1024px) {
    justify-content: space-around;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    max-width: 327px;
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    max-width: 448px;
  }
`;

export const Title = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 330px;
  }

  @media (min-width: 1024px) {
    max-width: 500px;
    margin-top: 16px;
  }
`;

export const Desc = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 330px;
    margin-top: 24px;
    margin-bottom: 56px;
  }

  @media (min-width: 1024px) {
    max-width: 350px;
    margin-top: 32px;
    margin-bottom: 40px;
  }
`;

export const UsersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 16.6666%);
  grid-template-rows: repeat(4, 25%);
`;

export const UserItem = styled.div`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  @media (max-width: 1023px) {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 20px;
  }

  @media (min-width: 1024px) {
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 32px;

    &.--offset {
      transform: translateX(36px);
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background-color: rgba(39, 22, 106, 0.5);
    opacity: 0;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
