import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  @media (max-width: 1023px) {
    padding: 8px 0;
  }

  @media (min-width: 1024px) {
    padding: 8px;

    &:hover {
      background-color: #f9f9f9;
    }
  }
`;

export const ImageContainer = styled.div`
  flex-shrink: 0;
  width: ${(p) => (p.$type === "product" ? "48px" : "64px")};
  height: 64px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Body = styled.div`
  flex-grow: 1;
`;
