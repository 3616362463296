import styled from "styled-components";

export const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1023px) {
    gap: 2px;
    padding: 0 4px;
  }

  @media (min-width: 1024px) {
    gap: 4px;
  }
`;

export const ImageLink = styled.a`
  aspect-ratio: 1/1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
