import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Root, Video, Container, Card, CardContainer } from "./Hero.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";

import videoMobilePortraitSrc from "../../../assets/video/coming-soon-hero-mobile-narrow.mp4";
import posterMobilePortraitSrc from "../../../assets/video/coming-soon-hero-mobile-narrow.jpg";
import videoMobileLandscapeSrc from "../../../assets/video/coming-soon-hero-mobile-wide.mp4";
import posterMobileLandscapeSrc from "../../../assets/video/coming-soon-hero-mobile-wide.jpg";
import videoDesktopSrc from "../../../assets/video/coming-soon-hero-desktop.mp4";
import posterDesktopSrc from "../../../assets/video/coming-soon-hero-desktop.jpg";

export const Hero = ({ variant = { mobile: "light", desktop: "light" } }) => {
  const navigate = useNavigate();
  return (
    <Root>
      <Video
        className="--mobile --portrait"
        autoPlay
        muted
        loop
        poster={posterMobilePortraitSrc}
      >
        <source src={videoMobilePortraitSrc} type="video/mp4" />
      </Video>
      <Video
        className="--mobile --landscape"
        autoPlay
        muted
        loop
        poster={posterMobileLandscapeSrc}
      >
        <source src={videoMobileLandscapeSrc} type="video/mp4" />
      </Video>
      <Video
        className="--desktop"
        autoPlay
        muted
        loop
        poster={posterDesktopSrc}
      >
        <source src={videoDesktopSrc} type="video/mp4" />
      </Video>
      <Container>
        <Card $variantMobile={variant.mobile} $variantDesktop={variant.desktop}>
          <CardContainer>
            <Typography type="h2">
              <FormattedMessage defaultMessage="Your Ultimate Reward Destination" />
            </Typography>

            <Typography type="h1" color="#e1000f" style={{ marginTop: 14 }}>
              <FormattedMessage defaultMessage="Introducing Club H" />
            </Typography>
            <Typography
              as="p"
              type="text-primary"
              style={{ marginTop: 16, marginBottom: 40 }}
            >
              <FormattedMessage defaultMessage="Our groundbreaking loyalty program designed for creators, visionaries, and trendsetters in hairstyling." />
            </Typography>

            <Button onClick={() => navigate("/auth/register")}>
              <FormattedMessage defaultMessage="join now" />
            </Button>
          </CardContainer>
        </Card>
      </Container>
    </Root>
  );
};

Hero.propTypes = {
  onRemind: PropTypes.func,
  variant: PropTypes.shape({
    mobile: PropTypes.oneOf(["dark", "light"]),
    desktop: PropTypes.oneOf(["dark", "light"]),
  }),
};
