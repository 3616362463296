export const shippingOptions = [
  {
    id: "flatrate-standard",
    default: true,
    label: "Standard",
    amount: 0,
    description(intl) {
      return intl.formatMessage({
        defaultMessage: "Standard Delivery",
      });
    },
  },
];

export const provinces = [
  {
    label: "Alberta",
    value: "AB",
  },
  {
    label: "British Columbia",
    value: "BC",
  },
  {
    label: "Manitoba",
    value: "MB",
  },
  {
    label: "New Brunswick",
    value: "NB",
  },
  {
    label: "Newfoundland and Labrador",
    value: "NL",
  },
  {
    label: "Nova Scotia",
    value: "NS",
  },
  {
    label: "Northwest Territories",
    value: "NT",
  },
  {
    label: "Nunavut",
    value: "NU",
  },
  {
    label: "Ontario",
    value: "ON",
  },
  {
    label: "Prince Edward Island",
    value: "PE",
  },
  {
    label: "Quebec",
    value: "QC",
  },
  {
    label: "Saskatchewan",
    value: "SK",
  },
  {
    label: "Yukon",
    value: "YT",
  },
];
