import React, { forwardRef } from "react";

import {
  Root,
  Label,
  Input,
  CheckmarkContainer,
  Checkmark,
  Error,
} from "./Radio.style";

import { Typography } from "../Typography/Typography";

export const Radio = forwardRef(({ label, error, style, ...props }, ref) => {
  return (
    <Root style={style}>
      <Label>
        <Typography type="text-primary">{label}</Typography>
      </Label>
      <Input ref={ref} type="radio" {...props} />
      <CheckmarkContainer>
        <Checkmark />
      </CheckmarkContainer>
      {error && <Error>{error?.message}</Error>}
    </Root>
  );
});
