import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AnimatePresence } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import { useApolloClient } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import { pick, get, isEqual, sortBy } from "lodash";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Slider from "rc-slider";
import rison from "rison";
import "rc-slider/assets/index.css";

import {
  Root,
  HeaderActions,
  HeaderAction,
  SortBySelect,
  ModalContainer,
  Header,
  ClearButton,
  CloseButton,
  Container,
  FieldContainer,
  SubmitContainer,
  CancelButton,
  AppendContainer,
} from "./FiltersMobile.style";

import {
  SearchField,
  SearchIcon,
  RangeSliderContainer,
  RangeInputContainer,
  RangeInput,
  SearchInput,
} from "../FiltersSharedComponents/Filters.style";

import {
  SearchClearIndicator,
  SearchOption,
  searchSelectStyles,
} from "../FiltersSharedComponents/searchSelectCustom";
import {
  DropdownIndicator,
  MultiValueRemove,
  selectStyles,
} from "../FiltersSharedComponents/selectCustom";
import { BrandsOption, brandsStyles } from "./brandsSelectCustom";
import { brands } from "../../config/brands";

import { Button } from "../../ui/Button/Button";
import { Icon } from "../../ui/Icon/Icon";

import { Typography } from "../../ui/Typography/Typography";

// import { parseSearchParams } from "../../utils/location";

export const FiltersMobile = ({
  sortOptions = [],
  sortQueryParam = "sort",
  categoriesConfig = [],
  showPoints = true,
  pointsConfig = { min: 0, max: 100 },
  tagsConfig,
  append,
  searchPlaceholder = "Search experiences",
}) => {
  const intl = useIntl();
  const client = useApolloClient();
  const firstRender = useRef(true);
  const onChangeEnabled = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const defaultValues = {
    brands: [],
    categories: [],
    search: "",
    tags: [],
    points: [pointsConfig.min, pointsConfig.max],
  };

  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
  });

  const watchForm = watch();

  //pull in query parameters on first render
  useEffect(() => {
    const filters = searchParams.get("filters");
    const formValue = {
      ...defaultValues,
      ...pick(filters != null ? rison.decode(filters) : {}, [
        "brands",
        "search",
        "categories",
        "tags",
        "points",
      ]),
    };

    //deal with bullshit where single values are not arrays
    const arrayVals = ["brands", "categories", "tags"];
    arrayVals.forEach((key) => {
      if (!Array.isArray(formValue[key])) {
        formValue[key] = [formValue[key]];
      }
    });

    //disable onchange for browner back/forward buttons
    if (!firstRender.current) {
      onChangeEnabled.current = false;
    }
    Object.keys(formValue).forEach((key) => {
      if (!isEqual(watchForm[key], formValue[key])) {
        setValue(key, formValue[key], { shouldDirty: true });
      }
    });
    //re-enable onchange
    if (!firstRender.current) {
      onChangeEnabled.current = true;
    }

    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [searchParams]);

  useLayoutEffect(() => {
    if (modalIsOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => (document.body.style.overflow = "visible");
  }, [modalIsOpened]);

  const onSubmit = (data) => {
    // console.info(data);
    let scrubbedData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, v]) => v != null && v !== "" && v?.length !== 0
      )
    );

    //scrub points
    if (scrubbedData.points[0] === "") {
      scrubbedData.points[0] = null;
    }
    if (scrubbedData.points[1] === "") {
      scrubbedData.points[1] = null;
    }
    if (
      (parseInt(scrubbedData.points[0], 10) === pointsConfig.min &&
        parseInt(scrubbedData.points[1], 10) === pointsConfig.max) ||
      (scrubbedData.points[0] == null && scrubbedData.points[1] == null)
    ) {
      delete scrubbedData.points;
    }

    if (Object.keys(scrubbedData).length === 0) {
      searchParams.delete("filters");
    } else {
      searchParams.set("filters", rison.encode(scrubbedData));
    }

    setSearchParams(searchParams, { preventScrollReset: true });
    setModalIsOpened(false);
  };

  return (
    <Root>
      <HeaderActions>
        <HeaderAction
          as="button"
          $isActive={modalIsOpened || isDirty}
          onClick={() => setModalIsOpened(true)}
        >
          <Icon icon="settings" />
          <FormattedMessage defaultMessage="Filters" tagName="span" />
          {isDirty && <span>&nbsp;({Object.keys(dirtyFields).length})</span>}
        </HeaderAction>
        <HeaderAction>
          <SortBySelect
            value={searchParams.get(sortQueryParam) ?? ""}
            onChange={(e) => {
              if (e.target.value === "") {
                searchParams.delete(sortQueryParam);
              } else {
                searchParams.set(sortQueryParam, e.target.value);
              }
              setSearchParams(searchParams);
            }}
          >
            <option value="">
              <FormattedMessage defaultMessage="Default Sort" />
            </option>
            {sortOptions.map((itm) => (
              <option key={itm.value} value={itm.value}>
                {itm.label}
              </option>
            ))}
          </SortBySelect>
        </HeaderAction>
      </HeaderActions>

      <AnimatePresence>
        {modalIsOpened && (
          <ModalContainer
            initial={"initial"}
            animate={"isOpen"}
            exit={"exit"}
            variants={{
              initial: { opacity: 0 },
              isOpen: { opacity: 1 },
              exit: { opacity: 0 },
            }}
          >
            <Header>
              <AppendContainer>{append}</AppendContainer>
              {isDirty && (
                <ClearButton
                  type="button"
                  onClick={() => {
                    reset();
                    searchParams.delete("filters");
                    setSearchParams(searchParams, { preventScrollReset: true });
                    setModalIsOpened(false);
                  }}
                >
                  <FormattedMessage defaultMessage="Clear All" />
                </ClearButton>
              )}
              <CloseButton onClick={() => setModalIsOpened(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.8}
                    d="m4.5 19.501 15-15M4.5 4.5l15 14.885"
                  />
                </svg>
              </CloseButton>
            </Header>

            <Container as="form" onSubmit={handleSubmit(onSubmit)}>
              <SearchField>
                <SearchIcon />
                <SearchInput
                  type="text"
                  placeholder={searchPlaceholder}
                  {...register("search")}
                />
                {/* <Controller
                  name="search"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Search a reward"
                      isClearable
                      components={{
                        ClearIndicator: SearchClearIndicator,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Option: SearchOption,
                      }}
                      options={search}
                      styles={searchSelectStyles}
                    />
                  )}
                /> */}
              </SearchField>
              <FieldContainer>
                <Typography
                  as="label"
                  type="text-small"
                  style={{ marginBottom: 8 }}
                >
                  <FormattedMessage defaultMessage="Brands" />
                </Typography>
                <Controller
                  name="brands"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select brands"
                      isClearable={false}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                        MultiValueRemove,
                        Option: BrandsOption,
                      }}
                      isMulti
                      options={sortBy(brands, ["label"]).map((b) => ({
                        value: b.id,
                        label: b.label,
                        icon: b.icon,
                      }))}
                      value={brands
                        .filter((b) => (field.value || []).includes(b.id))
                        .map((b) => ({
                          label: b.label,
                          value: b.id,
                        }))}
                      // //remove label and only pass value
                      onChange={(e, { name }) => {
                        const val = Array.isArray(e)
                          ? e.flatMap((itm) => itm.value)
                          : e?.value;
                        return field.onChange(val);
                      }}
                      styles={{ ...selectStyles, ...brandsStyles }}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer>
                <Typography
                  as="label"
                  type="text-small"
                  style={{ marginBottom: 8 }}
                >
                  <FormattedMessage defaultMessage="Categories" />
                </Typography>
                <Controller
                  name="categories"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select categories",
                      })}
                      isClearable={false}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                        MultiValueRemove,
                      }}
                      value={(typeof field.value === "string"
                        ? [field.value]
                        : field.value
                      ).map((itm) => ({
                        label: itm,
                        value: itm,
                      }))}
                      //remove label and only pass value
                      onChange={(e, { name }) => {
                        const val = Array.isArray(e)
                          ? e.flatMap((itm) => itm.value)
                          : e?.value;
                        return field.onChange(val);
                      }}
                      isMulti
                      options={categoriesConfig}
                      styles={selectStyles}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer>
                <Typography
                  as="label"
                  type="text-small"
                  style={{ marginBottom: 8 }}
                >
                  <FormattedMessage defaultMessage="Tags" />
                </Typography>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Select tags",
                      })}
                      isClearable={false}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                        MultiValueRemove,
                      }}
                      isMulti
                      defaultOptions={true}
                      loadOptions={async (inputValue) => {
                        return client
                          .query({
                            query: tagsConfig.gql,
                            variables: {
                              startsWith: inputValue,
                            },
                            fetchPolicy: "network-only",
                          })
                          .then((response) => {
                            const tags = get(response, tagsConfig.dataPath);
                            return tags.map((tag) => ({
                              label: tag,
                              value: tag,
                            }));
                          });
                      }}
                      value={
                        typeof field.value === "string"
                          ? [field.value]
                          : field.value.map((itm) => ({
                              label: itm,
                              value: itm,
                            }))
                      }
                      //remove label and only pass value
                      onChange={(e, { name }) => {
                        const val = Array.isArray(e)
                          ? e.flatMap((itm) => itm.value)
                          : e?.value;
                        return field.onChange(val);
                      }}
                      styles={selectStyles}
                    />
                  )}
                />
              </FieldContainer>

              {showPoints && (
                <FieldContainer>
                  <Typography
                    as="label"
                    type="text-small"
                    style={{ marginBottom: 8 }}
                  >
                    <FormattedMessage defaultMessage="Points" />
                  </Typography>
                  {/* <RangeSliderContainer>
                    <Controller
                      name="points"
                      control={control}
                      render={({ field }) => (
                        <Slider
                          range
                          {...field}
                          min={pointsConfig.min}
                          max={pointsConfig.max}
                          styles={{
                            handle: {
                              height: 14,
                              width: 14,
                              backgroundColor: "#e1000f",
                              opacity: 1,
                              border: 0,
                              boxShadow: "none",
                            },
                            track: {
                              backgroundColor: "#ebe8e8",
                            },
                          }}
                        />
                      )}
                    />
                  </RangeSliderContainer> */}
                  <RangeInputContainer>
                    <Controller
                      name="points"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <span style={{ fontSize: "12px" }}>
                            <FormattedMessage defaultMessage="Min:" />
                          </span>
                          <RangeInput
                            placeholder="0"
                            value={value[0]}
                            onChange={(e) => {
                              const min = e.target.value;
                              const max = value[1];
                              onChange([min, max]);
                            }}
                          />
                        </>
                      )}
                    />
                    <Controller
                      name="points"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <span style={{ fontSize: "12px" }}>
                            <FormattedMessage defaultMessage="Max:" />
                          </span>
                          <RangeInput
                            placeholder="100000"
                            value={value[1]}
                            onChange={(e) => {
                              const min = value[0];
                              const max = e.target.value;
                              onChange([min, max]);
                            }}
                          />
                        </>
                      )}
                    />
                  </RangeInputContainer>
                </FieldContainer>
              )}

              <SubmitContainer>
                <Button
                  type="submit"
                  variant="auth"
                  arrow={false}
                  //disabled={!isDirty}
                >
                  <FormattedMessage defaultMessage="apply" />
                  {Object.keys(dirtyFields).length > 0 &&
                    ` (${Object.keys(dirtyFields).length})`}
                </Button>
              </SubmitContainer>
              <CancelButton
                type="button"
                onClick={() => setModalIsOpened(false)}
              >
                <FormattedMessage defaultMessage="Cancel" />
              </CancelButton>
            </Container>
          </ModalContainer>
        )}
      </AnimatePresence>
    </Root>
  );
};
