import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  background-color: #f8f8f8;

  &.--delivered {
    background-color: #f5f4f8;
  }

  @media (max-width: 1023px) {
    padding: 20px 32px;
  }

  @media (min-width: 1024px) {
    padding: 24px 32px;
  }
`;

export const Icon = styled.div`
  margin-top: 5px;
  margin-right: 16px;
`;
