import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  position: relative;
  background-color: #fff;

  @media (max-width: 1023px) {
    width: 343px;
    min-height: 381px;
    padding: 64px 16px 40px;
  }

  @media (min-width: 1024px) {
    width: 416px;
    min-height: 420px;
    padding: 74px 40px 40px;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  text-align: center;
`;
