import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 700;
  text-align: center;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 46px;
    font-size: 28px;
  }
`;

export const Forgot = styled.span`
  display: block;
  margin-top: 16px;
  margin-bottom: 48px;
  margin-left: auto;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-align: right;
  text-decoration: underline;
`;

export const RedLink = styled.span`
  display: block;
  width: fit-content;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  color: var(--color-accent);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-align: center;
  text-decoration: underline;
`;
