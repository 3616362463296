import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { NotFoundView } from "../../views/NotFoundView/NotFoundView";
import { useParams } from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Link } from "../../components/Link/Link";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PromoBrand } from "../../components/Sections/PromoBrand/PromoBrand";
import { ExclusiveBrand } from "../../components/Sections/ExclusiveBrand/ExclusiveBrand";
import { RewardsDetail } from "../../components/RewardsDetail/RewardsDetail";
import { ProposalSlider } from "../../components/Sections/ProposalSlider/ProposalSlider";
import { Footer } from "../../components/Footer/Footer";
import { TrendingRewards } from "../../components/Sections/TrendingRewards/TrendingRewards";
import { ModalRoot } from "../../components/Modals/ModalRoot/ModalRoot";
import { ModalAddedToCart } from "../../components/Modals/ModalAddedToCart/ModalAddedToCart";
import {
  toast,
  ToastNotification,
} from "../../ui/ToastNotification/ToastNotification";
import {
  GET_REWARD_ITEM,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  GET_WISHLIST,
  categoriesConfig,
} from "../RewardsView/rewardsViewConfig";
import { apiDomain, apiSiteId } from "../../config";
import { brands } from "../../config/brands";

export const RewardsDetailView = () => {
  const intl = useIntl();
  const locale = intl.locale;
  const [modalAddToCartData, setModalAddToCartData] = useState(null);
  const { slug } = useParams();

  const {
    loading: rewardItemLoading,
    error: rewardItemError,
    data: rewardItemData,
  } = useQuery(GET_REWARD_ITEM, {
    variables: {
      slug,
      locale,
    },
  });

  const {
    loading: wishlistLoading,
    error: wishlistError,
    data: wishlistData,
  } = useQuery(GET_WISHLIST, {
    variables: {
      locale,
    },
  });

  const [addToCart] = useMutation(ADD_TO_CART);

  const [addToWishlist] = useMutation(ADD_TO_WISHLIST, {
    refetchQueries: ["GetWishlist", "GetHeaderCartData"],
  });
  const [removeFromWishlist] = useMutation(REMOVE_FROM_WISHLIST, {
    refetchQueries: ["GetWishlist", "GetHeaderCartData"],
  });

  const reward = rewardItemData?.content?.findBySlug;
  const brandConfig = brands.find((b) => b.id === reward?.brand?._id);

  const categoryConfig = categoriesConfig({ intl }).find(
    (itm) => itm.value === reward?.data?.category
  );
  const primaryImgSrc =
    reward?.gallery?.[0]?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${reward.gallery[0].media._id}?type=fill&width=604&height=752`
      : `https://picsum.photos/id/${50}/1000/1000`;

  const { __typename, ...features } = reward?.data?.features || {};
  const images =
    reward?.gallery?.length > 0
      ? reward?.gallery.map((itm) => ({
          id: itm?.media?._id,
          image: `https://${apiDomain}/${apiSiteId}/asset/${itm.media._id}?type=fill&width=604&height=752`,
        }))
      : [
          {
            id: "1",
            image: "https://picsum.photos/id/22/1000/1000",
          },
          {
            id: "2",
            image: "https://picsum.photos/id/52/1000/1000",
          },
          {
            id: "3",
            image: "https://picsum.photos/id/85/1000/1000",
          },
        ];

  const inFavorites = (wishlistData?.me?.wishlist?.items || []).some(
    (itm) => itm?.product?._id === reward?._id
  );

  const handleAddToCart = ({ qty }) => {
    setModalAddToCartData({ qty });
    return addToCart({
      variables: { productId: reward._id, qty, locale },
    });
  };

  const handleAddToFavourites = async () => {
    toast.dismiss();
    if (inFavorites) {
      toast.warn(
        <ToastNotification>
          <FormattedMessage
            defaultMessage="{productName} removed from wishlist."
            values={{ productName: reward?.n }}
          />
        </ToastNotification>
      );
      await removeFromWishlist({
        variables: { productId: reward._id, locale },
      });
    } else {
      toast.success(
        <ToastNotification>
          <FormattedMessage
            defaultMessage="{productName} added to wishlist."
            values={{ productName: reward?.n }}
          />
        </ToastNotification>
      );
      await addToWishlist({
        variables: { productId: reward._id, locale },
      });
    }
  };

  if (!rewardItemLoading && reward?._id == null) {
    return <NotFoundView />;
  }

  return (
    <Layout>
      <Header />
      {reward?.n && brandConfig?.label && (
        <Helmet>
          <title>
            {reward?.n} - {brandConfig?.label}
          </title>
        </Helmet>
      )}
      <Breadcrumbs
        breadcrumbs={[
          { to: "/", label: intl.formatMessage({ defaultMessage: "Home" }) },
          {
            to: "/rewards",
            label: intl.formatMessage({ defaultMessage: "Rewards" }),
          },
          { to: null, label: reward?.n || "" },
        ]}
      />

      <RewardsDetail
        images={images}
        brand={{
          label: brandConfig?.label,
          to: `/brands/${brandConfig?.slug}`,
        }}
        title={reward?.n}
        desc={reward?.desc}
        tags={reward?.tags}
        usageDirections={reward?.howToUse}
        points={reward?.ecommerce?.price?.amount}
        oldPts={reward?.ecommerce?.fullPrice?.amount}
        details={[
          {
            label: intl.formatMessage({ defaultMessage: "Size" }),
            value: reward?.data?.size,
          },
          {
            label: intl.formatMessage({ defaultMessage: "SKU" }),
            value: reward?.ecommerce?.sku,
          },
          ...(categoryConfig != null
            ? [
                {
                  label: intl.formatMessage({ defaultMessage: "Category" }),
                  value: (
                    <Link
                      to={`/rewards?filters=(categories:!(${reward?.data?.category}))`}
                    >
                      {
                        categoriesConfig({ intl }).find(
                          (itm) => itm.value === reward?.data?.category
                        )?.label
                      }
                    </Link>
                  ),
                },
              ]
            : []),
        ]}
        features={features}
        isFeatured={reward?.data?.isFeatured}
        isNew={reward?.data?.isNew}
        isLimited={reward?.data?.isLimited}
        inFavorites={inFavorites}
        onAddToFavourites={() => handleAddToFavourites()}
        onAddToCart={(data) => handleAddToCart(data)}
        loading={rewardItemLoading || wishlistLoading}
      />

      {brandConfig?.id && <PromoBrand brandId={brandConfig?.id} />}
      {brandConfig?.id && <ExclusiveBrand brandId={brandConfig?.id} />}

      {brandConfig?.id && (
        <TrendingRewards
          title={intl.formatMessage({ defaultMessage: "Trending Rewards" })}
          filters={`{ "type": ["reward"], "brands" : "${brandConfig?.id}"}`}
        />
      )}

      {brandConfig?.id && (
        <ProposalSlider
          variant="black"
          title={intl.formatMessage({ defaultMessage: "Trending Experiences" })}
          ctaText={intl.formatMessage({ defaultMessage: "All articles" })}
          ctaUrl={`/${intl.locale}/experience?filters=(brands:!('${brandConfig?.id}'))`}
          filters={`{ "type": ["news"], "brands" : "${brandConfig?.id}"}`}
        />
      )}

      <Footer />

      <ModalRoot
        isVisible={modalAddToCartData != null}
        onClose={() => setModalAddToCartData(null)}
      >
        <ModalAddedToCart
          onClose={() => setModalAddToCartData(null)}
          imgSrc={primaryImgSrc}
          brandName={brandConfig?.label}
          productName={reward?.n}
          points={reward?.ecommerce?.price?.amount}
          oldPts={reward?.ecommerce?.fullPrice?.amount}
          qty={modalAddToCartData?.qty ?? 1}
        />
      </ModalRoot>
    </Layout>
  );
};
