import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Root, Title, Forgot, RedLink } from "./AuthForgot.style";

import { Link } from "../Link/Link";
import { TextInput } from "../../ui/TextInput/TextInput";
import { Button } from "../../ui/Button/Button";

import { forgotPassword } from "../../utils/auth";
import { frontendDomain } from "../../config";

export function AuthForgot() {
  const intl = useIntl();
  const [statusMessage, setStatusMessage] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
      context: {
        id: "clubh",
        domain: frontendDomain,
      },
    },
  });

  const onSubmit = (data) => {
    return forgotPassword(data)
      .then(({ result }) => {
        if (result.success !== true) {
          throw new Error("Unknown error.");
        }
        reset();
        //show success message
        setStatusMessage({
          type: "success",
          message: intl.formatMessage({
            defaultMessage:
              "If email address is valid, you will receive an email with further instructions to reset password.",
          }),
        });
      })
      .catch((err) => {
        console.error({ err });
        //show error message
        setStatusMessage({
          type: "error",
          message: intl.formatMessage({
            defaultMessage: "An unknown error occurred. Please try again.",
          }),
        });
      });
  };

  return (
    <Root>
      <Title>
        <FormattedMessage defaultMessage="Reset Password" />
      </Title>

      {statusMessage != null ? (
        <p>
          {statusMessage.message}{" "}
          <button
            style={{ textDecoration: "underline" }}
            onClick={() => setStatusMessage()}
          >
            <FormattedMessage defaultMessage="dismiss" />
          </button>
        </p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                type="email"
                label={intl.formatMessage({
                  defaultMessage: "Email",
                })}
                autoComplete="username"
              />
            )}
          />

          <Forgot as={Link} to="/auth">
            <FormattedMessage defaultMessage="Back to Login" />
          </Forgot>
          <Button type="submit" variant="auth" arrow={false}>
            <FormattedMessage defaultMessage="Reset Password" />
          </Button>
          <RedLink as={Link} to="/auth/register">
            <FormattedMessage defaultMessage="Register" />
          </RedLink>
        </form>
      )}
    </Root>
  );
}
