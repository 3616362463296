import React from "react";
import { FormattedMessage } from "react-intl";

import { Root, Image, Pts } from "./HeaderUserBadge.style";
import defaultAvatarSrc from "../../assets/images/avatar.png";

import { Link } from "../Link/Link";

export function HeaderUserBadge({ url, imageSrc, pts }) {
  return (
    <Root as={Link} to={url}>
      <Image src={imageSrc || defaultAvatarSrc} />
      <Pts>
        {pts.toLocaleString("en-US")} <FormattedMessage defaultMessage="PTS" />
      </Pts>
    </Root>
  );
}
