import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import {
  Root,
  Header,
  Logo,
  Close,
  Form,
  Desc,
  ProgressContainer,
  Progress,
  ProgressBar,
  Item,
  ItemTitle,
  ItemNumber,
  ItemTitleWrapper,
  OptionsContainer,
  OptionInput,
  OptionLabel,
  OptionIcon,
  SubmitContainer,
  CancelContainer,
} from "./ModalSurvey.style";

import LogoSvg from "../../../assets/clubh-logo.svg?react";

import {
  brandPreferences,
  businessType,
  specialityInterest,
  educationInterest,
  shoppingPreferences,
} from "./config";

import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";

import { Typography } from "../../../ui/Typography/Typography";
import { Icon } from "../../../ui/Icon/Icon";
import { Button } from "../../../ui/Button/Button";
import { ButtonLink } from "../../../ui/ButtonLink/ButtonLink";
import {
  toast,
  ToastNotification,
} from "../../../ui/ToastNotification/ToastNotification";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../../ui/Tooltip/Tooltip";
import { PREFERNCES_UPDATE } from "../../MyAccount/config";
import { getUserAvatar } from "../../../utils/user";

export const ModalSurvey = ({ onClose = null, cb = null, userData }) => {
  useLockBodyScroll();
  const intl = useIntl();

  const [updatePreferences] = useMutation(PREFERNCES_UPDATE, {
    refetchQueries: ["GetDashboardData", "GetHeaderCartData"],
  });

  const {
    register,
    watch,
    reset,
    resetField,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      brandPreferences: [],
      businessType: "",
      specialityInterest: [],
      educationInterest: [],
      shoppingPreferences: [],
    },
  });
  const watchFields = watch();

  const onSubmit = (data) => {
    console.log(data);
    return updatePreferences({
      variables: {
        data,
      },
    })
      .then((result) => {
        toast.success(
          <ToastNotification>
            <FormattedMessage defaultMessage="Your preferences successfully submitted!" />
          </ToastNotification>
        );
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        const bonusPoints = result?.data?.updatePreferences?.bonusPoints?.[0];
        cb({
          pts: bonusPoints?.points,
          bonusItemName: bonusPoints?.note,
          pointsBalance: result?.data?.updatePreferences?.pointsBalance ?? 0,
          firstName: userData?.firstname,
          avatarSrc: getUserAvatar(userData),
        });
      })
      .catch((err) => {
        toast.error(
          <ToastNotification>
            <FormattedMessage defaultMessage="An error occurred while updating account profile.  Please refresh and try again." />
          </ToastNotification>
        );
        Sentry.captureException(err);
        console.error({ err, type: "my-profile" });
        cb();
      });
  };

  const progress = Object.keys(watchFields).reduce((accumulator, item) => {
    const totalFields = Object.keys(watchFields).length;
    const weight =
      watchFields[item] === "" || watchFields[item].length === 0 ? 0 : 1;
    return accumulator + (weight / totalFields) * 100;
  }, 0);

  // console.info({ progress });

  return (
    <Root>
      <Header>
        <Logo>
          <LogoSvg />
        </Logo>

        <ProgressContainer>
          <Desc>
            <Typography type="text-small" color="#8f95a5">
              <FormattedMessage defaultMessage="Complete the survey for more rewards!" />
            </Typography>
            &nbsp;
            <Tooltip placement="top-start">
              <TooltipTrigger>
                <Icon
                  icon="info-circle"
                  color="#8f95a5"
                  style={{ fontSize: 16 }}
                />
              </TooltipTrigger>
              <TooltipContent>blah</TooltipContent>
            </Tooltip>
          </Desc>
          <Progress>
            <ProgressBar key={progress} style={{ width: `${progress}%` }} />
          </Progress>
        </ProgressContainer>

        <Close type="button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 26"
            width="1em"
            height="1em"
          >
            <path d="M25.092 25.906a.899.899 0 0 1-.638-.263l-11.45-11.36L1.645 25.731a.907.907 0 0 1-1.289-1.277l11.361-11.449L.269 1.647A.908.908 0 0 1 1.547.356l11.448 11.361L24.354.269a.907.907 0 1 1 1.289 1.278l-11.36 11.448 11.448 11.361a.906.906 0 0 1-.639 1.55Z" />
          </svg>
        </Close>
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: 40 }}>
          <Typography type="h2">
            <FormattedMessage defaultMessage="Salon Preferences" />
          </Typography>
        </div>

        <Item style={{ borderTop: 0 }}>
          <SectionTitle
            num={1}
            title={intl.formatMessage({
              defaultMessage: "Brand Preferences",
            })}
            isChecked={watchFields.brandPreferences.length > 0}
          />

          <div>
            <Typography type="text-small" color="#8f95a5">
              <FormattedMessage defaultMessage="Select all that apply" />
            </Typography>

            <OptionsContainer>
              {brandPreferences.map((item) => (
                <label key={item.value + 1}>
                  <OptionInput
                    {...register("brandPreferences")}
                    type="checkbox"
                    name="brandPreferences"
                    value={item.value}
                    id={item.value}
                  />
                  <OptionLabel className="--brand" htmlFor={item.value}>
                    {item.icon}
                  </OptionLabel>
                </label>
              ))}
            </OptionsContainer>
          </div>
        </Item>

        <Item>
          <SectionTitle
            num={2}
            title={intl.formatMessage({
              defaultMessage: "Business Type",
            })}
            isChecked={watchFields.businessType.length > 0}
          />

          <div>
            <Typography type="text-small" color="#8f95a5">
              <FormattedMessage defaultMessage="Select one" />
            </Typography>

            <OptionsContainer>
              {businessType.map((item) => (
                <label key={item.value + 2}>
                  <OptionInput
                    {...register("businessType")}
                    type="radio"
                    name="businessType"
                    value={item.value}
                    id={item.value}
                  />
                  <OptionLabel className="--default" htmlFor={item.value}>
                    <OptionIcon>{item.icon}</OptionIcon>
                    <Typography type="text-primary">{item.label}</Typography>
                  </OptionLabel>
                </label>
              ))}
            </OptionsContainer>
          </div>
        </Item>

        <Item>
          <SectionTitle
            num={3}
            title={intl.formatMessage({
              defaultMessage: "Speciality & Interest",
            })}
            isChecked={watchFields.specialityInterest.length > 0}
          />

          <div>
            <Typography type="text-small" color="#8f95a5">
              <FormattedMessage defaultMessage="Select all that apply" />
            </Typography>

            <OptionsContainer>
              {specialityInterest.map((item) => (
                <label key={item.value + 3}>
                  <OptionInput
                    {...register("specialityInterest")}
                    type="checkbox"
                    name="specialityInterest"
                    value={item.value}
                    id={item.value}
                  />
                  <OptionLabel className="--default" htmlFor={item.value}>
                    <OptionIcon>{item.icon}</OptionIcon>
                    <Typography type="text-primary">{item.label}</Typography>
                  </OptionLabel>
                </label>
              ))}
            </OptionsContainer>
          </div>
        </Item>

        <Item>
          <SectionTitle
            num={4}
            title={intl.formatMessage({
              defaultMessage: "Education Interest",
            })}
            isChecked={watchFields.educationInterest.length > 0}
          />

          <div>
            <Typography type="text-small" color="#8f95a5">
              <FormattedMessage defaultMessage="Select all that apply" />
            </Typography>

            <OptionsContainer>
              {educationInterest.map((item) => (
                <label key={item.value + 4}>
                  <OptionInput
                    {...register("educationInterest")}
                    type="checkbox"
                    name="educationInterest"
                    value={item.value}
                    id={item.value}
                  />
                  <OptionLabel className="--default" htmlFor={item.value}>
                    <OptionIcon>{item.icon}</OptionIcon>
                    <Typography type="text-primary">{item.label}</Typography>
                  </OptionLabel>
                </label>
              ))}
            </OptionsContainer>
          </div>
        </Item>

        <Item>
          <SectionTitle
            num={5}
            title={intl.formatMessage({
              defaultMessage: "Shopping Preferences",
            })}
            isChecked={watchFields.shoppingPreferences.length > 0}
          />

          <div>
            <Typography type="text-small" color="#8f95a5">
              <FormattedMessage defaultMessage="Select all that apply" />
            </Typography>

            <OptionsContainer>
              {shoppingPreferences.map((item) => (
                <label key={item.value + 5}>
                  <OptionInput
                    {...register("shoppingPreferences")}
                    type="checkbox"
                    name="shoppingPreferences"
                    value={item.value}
                    id={item.value}
                  />
                  <OptionLabel className="--default" htmlFor={item.value}>
                    <OptionIcon>{item.icon}</OptionIcon>
                    <Typography type="text-primary">{item.label}</Typography>
                  </OptionLabel>
                </label>
              ))}
            </OptionsContainer>
          </div>
        </Item>

        <SubmitContainer>
          <Button
            type="submit"
            variant="auth"
            arrow={false}
            disabled={!isDirty || progress < 100}
          >
            <FormattedMessage defaultMessage="submit" />
          </Button>

          <CancelContainer>
            <ButtonLink type="button" variant="secondary" onClick={onClose}>
              <FormattedMessage defaultMessage="Cancel" />
            </ButtonLink>
          </CancelContainer>
        </SubmitContainer>
      </Form>
    </Root>
  );
};

const SectionTitle = ({ num = 1, title = "", isChecked = false }) => {
  return (
    <ItemTitle>
      <ItemTitleWrapper>
        <ItemNumber>
          <Typography type="h3">{num}</Typography>
        </ItemNumber>
        <Typography type="h3" style={{ margin: "0 16px" }}>
          {title}
        </Typography>
        {isChecked && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={16}
            height={16}
          >
            <path
              fill="#a2ecba"
              d="M12.525 5.734 7.19 11.078h-.808l-2.9-2.9.808-.808 2.32 2.325h.34l4.767-4.768ZM8.006 0C1.847 0-2.002 6.667 1.077 12c3.08 5.334 10.778 5.334 13.857 0a8 8 0 0 0 1.072-4 8.01 8.01 0 0 0-8-8Z"
            />
          </svg>
        )}
      </ItemTitleWrapper>
    </ItemTitle>
  );
};
