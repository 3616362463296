import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Slider from "react-slick";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Root,
  Title,
  Desc,
  Slide,
  ImageContainer,
  Image,
  AddToButton,
  BrandName,
  CtaContainer,
  Points,
} from "./RewardsCarousel.style";

import { Icon } from "../../../ui/Icon/Icon";
import { Button } from "../../../ui/Button/Button";
import { Typography } from "../../../ui/Typography/Typography";
import { Link } from "../../Link/Link";
import { UserContext } from "../../../context/UserContext";

import { GET_REWARDS_CAROUSEL } from "./config";
import { apiDomain, apiSiteId } from "../../../config";
import { brands } from "../../../config/brands";
import {
  toast,
  ToastNotification,
} from "../../../ui/ToastNotification/ToastNotification";

import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
} from "../../../views/RewardsView/rewardsViewConfig";

const settings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export const RewardsCarousel = ({
  title = "",
  desc = "",
  rewardsPath = "/rewards",
  filters = '{ "type": ["reward"] }',
  sort = [
    { key: "isFeatured", direction: "1" },
    { key: "startDate", direction: "-1" },
    { key: "dc", direction: "-1" },
  ],
  limit = 8,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const locale = intl.locale;
  const { user } = useContext(UserContext);
  const {
    loading: carouselLoading,
    // error: carouselError,
    data: carouselData,
  } = useQuery(GET_REWARDS_CAROUSEL, {
    variables: {
      sort,
      filters,
      limit,
      page: 1,
      locale,
    },
  });

  const [addToWishlist] = useMutation(ADD_TO_WISHLIST, {
    refetchQueries: ["GetWishlist", "GetHeaderCartData"],
  });
  const [removeFromWishlist] = useMutation(REMOVE_FROM_WISHLIST, {
    refetchQueries: ["GetWishlist", "GetHeaderCartData"],
  });

  const rewards = carouselData?.content?.findClubH;
  const wishlist = carouselData?.me?.wishlist;

  const handleAddToFavourites = async ({ item, inFavorites }) => {
    toast.dismiss();
    if (inFavorites) {
      toast.warn(
        <ToastNotification>
          <FormattedMessage
            defaultMessage="{productName} removed from wishlist."
            values={{ productName: item?.n }}
          />
        </ToastNotification>
      );
      await removeFromWishlist({
        variables: { productId: item._id, locale },
      });
    } else {
      toast.success(
        <ToastNotification>
          <FormattedMessage
            defaultMessage="{productName} added to wishlist."
            values={{ productName: item?.n }}
          />
        </ToastNotification>
      );
      await addToWishlist({
        variables: { productId: item._id, locale: intl.locale },
      });
    }
  };

  return (
    <Root>
      <Title>
        <Typography as="h2" type="h2">
          {title}
        </Typography>
      </Title>
      <Desc>
        <Typography as="p" type="text-primary">
          {desc}
        </Typography>
      </Desc>

      <Slider {...settings}>
        {(rewards?.docs || []).map((item, i) => {
          const inFavorites = (wishlist?.items || []).some(
            (itm) => itm?.product?._id === item._id
          );

          const imgSrc =
            item?.gallery?.[0]?.media?._id != null
              ? `https://${apiDomain}/${apiSiteId}/asset/${item.gallery[0].media._id}?type=fill&width=604&height=752`
              : `https://picsum.photos/id/${i + 50}/1000/1000`;

          const brandConfig = brands.find((b) => b.id === item.brand?._id);
          const brandName = brandConfig?.label ?? item.brand?.n;
          const brandSlug = brandConfig?.slug;

          return (
            <Slide key={item._id}>
              <ImageContainer>
                <Link to={`/rewards/${item.sl}`}>
                  <Image src={imgSrc} alt={item?.n} />
                </Link>
                <AddToButton
                  type="button"
                  onClick={() => {
                    if (user?._id == null) {
                      return navigate(
                        `/${intl.locale}/auth?redirect=${encodeURIComponent(
                          location.pathname
                        )}`,
                        {
                          state: {
                            redirectState: {
                              onLoad: {
                                method: "handleAddToFavourites",
                                params: [{ item, inFavorites }],
                              },
                            },
                          },
                        }
                      );
                    }
                    handleAddToFavourites({ item, inFavorites });
                  }}
                >
                  {inFavorites ? (
                    <Icon icon="heart-filled" />
                  ) : (
                    <Icon icon="heart" />
                  )}
                </AddToButton>
              </ImageContainer>
              <Link to={brandSlug ? `/brands/${brandSlug}` : "/rewards"}>
                <BrandName>
                  <Typography type="brand-primary">
                    {brandName ?? "Club H"}
                  </Typography>
                </BrandName>
              </Link>
              <Link to={`/rewards/${item.sl}`}>
                <Typography type="h4">{item?.n}</Typography>
              </Link>
              {user && (
                <Points>
                  <Typography type="points-primary">
                    {item?.ecommerce?.price?.amount.toLocaleString("en-US")}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </Typography>
                </Points>
              )}
            </Slide>
          );
        })}
      </Slider>

      <CtaContainer>
        <Button as={Link} to={rewardsPath}>
          <FormattedMessage defaultMessage="shop rewards" />
        </Button>
      </CtaContainer>
    </Root>
  );
};
