import styled from "styled-components";

export const Root = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--color-bg-2);

  @media (min-width: 1024px) {
    padding: 40px 48px;
  }
`;

export const Container = styled.div`
  width: 100%;
  /* background-color: var(--color-bg-2); */

  @media (max-width: 1023px) {
    padding: 24px 24px 64px;
  }

  @media (min-width: 1024px) {
    display: flex;
    padding: 75px 48px;
  }

  @media (min-width: 1400px) {
    max-width: 1304px;
    padding: 75px 80px;
  }
`;

export const ImageContainer = styled.div`
  @media (max-width: 1023px) {
    height: 248px;
    margin-bottom: 38px;
  }

  @media (min-width: 1024px) {
    flex-shrink: 0;
    width: 500px;
    height: 500px;
    margin-right: 80px;
  }

  @media (min-width: 1400px) {
    margin-right: 120px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const BodyContainer = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 402px;
  }

  @media (min-width: 1400px) {
    max-width: 500px;
  }
`;

export const Desc = styled.div`
  @media (max-width: 1023px) {
    margin-top: 24px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 24px;
    margin-bottom: 40px;
  }
`;
