import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import {
  Hero,
  HeroImage,
  HeroCard,
  HeroCta,
  HairStylingImage,
} from "./HowItWorksView.style";

import hairStylingSrc from "../../assets/images/hair-styling.jpg";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { BeautyBrands } from "../../components/Sections/BeautyBrands/BeautyBrands";
import { AdvantagesGrid } from "../../components/Sections/AdvantagesGrid/AdvantagesGrid";
import { EarnRedeemPoints } from "../../components/Sections/EarnRedeemPoints/EarnRedeemPoints";
import { Faq } from "../../components/Sections/Faq/Faq";
import { Footer } from "../../components/Footer/Footer";
import { Typography } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import { ClubHJourney } from "../../components/Sections/ClubHJourney/ClubHJourney";
import { LoyaltyTiers } from "../../components/LoyaltyTiers/LoyaltyTiers";
import heroVideo from "../../assets/video/how-it-works-hero-desktop.mp4";
import heroVideoMobile from "../../assets/video/how-it-works-hero-mobile.mp4";

export const HowItWorksView = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          {
            to: null,
            label: intl.formatMessage({
              defaultMessage: "Membership Benefits",
            }),
          },
        ]}
      />
      <Hero>
        <HeroImage>
          <video autoPlay muted loop playsInline>
            <source src={heroVideoMobile} media="(max-width: 1023px)" />
            <source src={heroVideo} type="video/mp4" />
          </video>
        </HeroImage>
        <HeroCard>
          <Typography type="h1">
            <FormattedMessage defaultMessage="Discover the Future of Loyalty" />
          </Typography>
          <Typography
            type="text-primary"
            color="#b1aacb"
            style={{ marginTop: 10 }}
          >
            <FormattedMessage defaultMessage="Join Henkel’s New Program and unlock a world of hair-raising rewards and elevate your hairstyling game with our new Loyalty Program." />
          </Typography>

          <HeroCta>
            <Button>
              <FormattedMessage defaultMessage="read more" />
            </Button>
          </HeroCta>
        </HeroCard>
      </Hero>
      <ClubHJourney
        title={intl.formatMessage(
          {
            defaultMessage: "Your Club H{br}journey begins here",
          },
          {
            br: <br />,
          }
        )}
        desc={intl.formatMessage(
          {
            defaultMessage:
              "Reach new heights with Club H’s tier system, which celebrates your dedication throughout the year. As you achieve each new tier, you instantly unlock new benefits that continue into the next full calendar year.{br}{br}Your point journey is seamless and transparent – monitor your growth on your personalized dashboard, updated monthly to reflect your thriving business.",
          },
          {
            br: <br />,
          }
        )}
      />

      <LoyaltyTiers />
      <EarnRedeemPoints />
      {/* <HairStylingImage>
        <img src={hairStylingSrc} alt="Hair Styling" />
      </HairStylingImage> */}
      <BeautyBrands
        ctaTextLoggedIn={intl.formatMessage({
          defaultMessage: "Shop Rewards",
        })}
        ctaUrlLoggedIn="/rewards"
        ctaTextLoggedOut={intl.formatMessage({
          defaultMessage: "Join Now",
        })}
        ctaUrlLoggedOut="/auth/register"
      />
      <AdvantagesGrid />
      <Faq bgColor="#f5f4f8" />
      <Footer />
    </Layout>
  );
};
