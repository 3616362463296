import React from "react";
import { FormattedMessage } from "react-intl";

import { Root, ImagesContainer, Content } from "./CommunityHero.style";

import { Typography } from "../../../ui/Typography/Typography";
import CommunityHeroImageSrc from "../../../assets/images/community-hp-banner-01.jpg";

export const CommunityHero = () => {
  return (
    <Root>
      <ImagesContainer>
        <img src={CommunityHeroImageSrc} alt="We are the Club H community" />
      </ImagesContainer>

      <Content>
        <Typography as="h1" type="h1">
          <FormattedMessage defaultMessage="we are the club h community" />
        </Typography>
        <Typography
          as="p"
          type="text-primary"
          color="#b1aacb"
          style={{ marginTop: 16 }}
        >
          <FormattedMessage defaultMessage="Ask questions, join challenges, get recommendations from people like you… Join our wonderful community today and don’t miss a thing!" />
        </Typography>
      </Content>
    </Root>
  );
};
