import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { Root, Header, IconContainer, Body } from "./Accordion.style";

import { Typography } from "../../ui/Typography/Typography";

export function AccordionItem({ i, q, a, expanded, setExpanded }) {
  const isOpen = i === expanded;

  return (
    <Root>
      <Header $isOpen={isOpen} onClick={() => setExpanded(isOpen ? false : i)}>
        <Typography type="h4">{q}</Typography>
        <IconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
          >
            <path
              fill="currentColor"
              d={
                isOpen
                  ? "M6.533 11.393v1.215h10.935v-1.215H6.533Z"
                  : "m13.233 11.393-.627-.627V6.533h-1.213v4.233l-.627.627H6.533v1.215h4.272l.588.588v4.272h1.215v-4.273l.588-.588h4.272v-1.214h-4.235Z"
              }
            />
          </svg>
        </IconContainer>
      </Header>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.35, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Body>
              <Typography type="text-primary">{a}</Typography>
            </Body>
          </motion.div>
        )}
      </AnimatePresence>
    </Root>
  );
}

export function Accordion({ items = [] }) {
  const [expanded, setExpanded] = useState(false);

  return items.map(({ q, a }, idx) => (
    <AccordionItem
      key={idx + q}
      i={idx}
      q={q}
      a={a}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  ));
}
