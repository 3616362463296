import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 64px;
  padding-top: 33px;
  padding-bottom: 16px;
  border-top: 1px solid var(--color-bg-3);

  @media (max-width: 1023px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1024px) {
    padding-left: 48px;
    padding-right: 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1304px;
`;

export const List = styled.ul`
  display: flex;
  max-width: 1304px;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
`;

export const DividerContainer = styled.span`
  display: flex;
  align-items: center;
  margin: 0 5px;
  color: var(--color-accent);
  font-size: 12px;
`;

export const AppendContainer = styled.div`
  display: flex;
  align-items: center;
`;
