import React from "react";
import PropTypes from "prop-types";
import { Link } from "../../Link/Link";

import {
  Root,
  Container,
  ImageContainer,
  BodyContainer,
  Desc,
} from "./Exclusive.style";

import { Button } from "../../../ui/Button/Button";
import { Typography } from "../../../ui/Typography/Typography";

export const Exclusive = ({
  image,
  imageSrc, // DEPRECATED
  title = "",
  desc = "",
  ctaText = "",
  ctaUrl = "",
}) => {
  return (
    <Root>
      <Container>
        <ImageContainer>
          {imageSrc && <img src={imageSrc} alt={title} />}
          {image}
        </ImageContainer>

        <BodyContainer>
          <Typography as="h2" type="h1">
            {title}
          </Typography>
          <Desc>
            <Typography as="p" type="text-primary">
              {desc}
            </Typography>
          </Desc>

          <Link target="_blank" to={ctaUrl}>
            <Button>{ctaText}</Button>
          </Link>
        </BodyContainer>
      </Container>
    </Root>
  );
};

Exclusive.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
};
