import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  @media (max-width: 1023px) {
  }

  @media (min-width: 1024px) {
    padding: 0 48px;
  }
`;

export const Header = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    justify-content: center;
    height: 72px;
    border-bottom: 1px solid #dcdfe9;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    padding: 66px 0 25px;
  }
`;

export const Logo = styled.div`
  @media (max-width: 1023px) {
    width: 104px;
  }

  @media (min-width: 1024px) {
    width: 140px;
  }
`;

export const Close = styled.button`
  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 24px;
    width: 32px;
    height: 32px;
    font-size: 20px;
    transform: translateY(-50%);
  }

  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 1137px;
  overflow-y: auto;

  @media (max-width: 1023px) {
    padding: 48px 24px;
  }

  @media (min-width: 1024px) {
    padding: 16px 0 48px;
  }
`;

export const InputLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #9095a4;
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 48px;
  margin-left: 16px;
  border: 0;

  @media (max-width: 1023px) {
    font-size: 24px;
    letter-spacing: -0.48px;
  }

  @media (min-width: 1024px) {
    font-size: 28px;
    letter-spacing: -0.56px;
  }

  &::placeholder {
    color: #8f95a5;
  }
`;

export const InputClearButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  background-color: #ededed;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%239095a4' d='M5.517 10.997a.5.5 0 0 1-.354-.854l2.136-2.136L5.165 5.89a.5.5 0 1 1 .7-.71l2.139 2.122 2.159-2.159a.5.5 0 0 1 .707.707L8.713 8.007l2.152 2.135a.5.5 0 0 1-.35.855.5.5 0 0 1-.35-.145l-2.157-2.14L5.87 10.85a.5.5 0 0 1-.353.147Zm4.996 0h.004Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 8px;
  transform: translateY(-50%);
`;

export const ClearAllButtonContainer = styled.div`
  position: absolute;
  right: 0;

  @media (max-width: 1023px) {
    bottom: -36px;
  }

  @media (min-width: 1024px) {
    bottom: -31px;
  }
`;

export const BrandsContainer = styled.div`
  @media (max-width: 1023px) {
    margin-top: 48px;

    &.--desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 40px;

    &.--mobile {
      display: none;
    }
  }
`;

export const BrandsAction = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 40px;
  border: solid 1px #dcdfe9;
  cursor: pointer;

  &:hover {
    border: solid 1px #8f95a5;
  }
`;

export const Checkbox = styled.input`
  display: none;

  &:checked + ${BrandsAction} {
    border: solid 1px #e1000f;
  }
`;

export const ResultsGrid = styled.div`
  display: grid;

  @media (max-width: 1023px) {
    row-gap: 37px;
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
    margin-top: 48px;
  }

  @media (min-width: 1400px) {
    column-gap: 40px;
  }
`;

export const ResultsHeaderContainer = styled.div`
  @media (min-width: 1024px) {
    padding: 0 8px;
  }
`;

export const ResultsHeader = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #dcdfe9;

  @media (max-width: 1023px) {
    margin-bottom: 11px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 27px;
    pointer-events: none;
    cursor: default;
  }
`;

export const ResultsHeaderIcon = styled.div`
  color: #8f95a5;
  font-size: 17px;

  &.--isShow {
    transform: rotate(180deg);
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: 11px;
`;

export const CategoriesContainer = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    margin-top: 6px;
    margin-bottom: 24px;
  }
`;

export const CategoriesLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 6px;
`;

export const CategoriesLink = styled.a`
  width: fit-content;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.85;
  letter-spacing: 0.13px;

  &:hover {
    color: var(--color-accent);
    text-decoration: underline;
  }
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 376px;

  @media (max-width: 1023px) {
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 48px;
  }
`;
