import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {
  Root,
  Container,
  CtaContainer,
  Note,
  MarkdownContainer,
} from "./Faq.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Accordion } from "../../Accordion/Accordion";
import { Link } from "../../Link/Link";
import { Button } from "../../../ui/Button/Button";

import { faqs } from "./config";

export function Faq({ type = "default" }) {
  const intl = useIntl();
  const { locale, defaultLocale } = intl;
  return (
    <Root>
      <Container>
        <Typography as="h2" type="h2" style={{ marginBottom: 64 }}>
          <FormattedMessage
            defaultMessage="Have questions?{br}We have answers!"
            values={{ br: <br /> }}
          />
        </Typography>

        <Accordion
          items={faqs[type].map((item) => ({
            q: item[`question_${locale}`] ?? item[`question_${defaultLocale}`],
            a: (
              <MarkdownContainer>
                <Markdown remarkPlugins={[remarkGfm]}>
                  {item[`answer_${locale}`] ?? item[`answer_${defaultLocale}`]}
                </Markdown>
              </MarkdownContainer>
            ),
          }))}
        />

        <CtaContainer>
          <Button as={Link} to="/">
            <FormattedMessage defaultMessage="see more" />
          </Button>
        </CtaContainer>

        <Note>
          <Typography type="text-primary">
            <FormattedMessage
              defaultMessage="Have more questions? Please visit our <a>support portal</a>."
              values={{
                a: (str) => (
                  <a href="https://clubhrewards.zendesk.com" target="_blank">
                    {str}
                  </a>
                ),
              }}
            />
          </Typography>
        </Note>
      </Container>
    </Root>
  );
}
