import React from "react";
import PropTypes from "prop-types";

import { Root, Arrow } from "./Button.style";

export function Button({
  variant = "primary",
  arrow = true,
  children,
  ...props
}) {
  return (
    <Root $variant={variant} $withArrow={arrow} {...props}>
      {children}

      {arrow && (
        <Arrow>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={16}
            height={16}
          >
            <path
              d="m5.683 14.354-1.055-1.058 5.111-5.1v-.389l-5.111-5.1L5.684 1.65l5.55 5.541v1.626Z"
              fill="currentColor"
            />
          </svg>
        </Arrow>
      )}
    </Root>
  );
}

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "auth", "action"]),
};
