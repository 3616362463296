import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f9f9f9;

  @media (max-width: 1023px) {
    padding: 64px 8px 48px;
  }

  @media (min-width: 1024px) {
    padding: 80px 0 48px;
  }
`;

export const Title = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 330px;
    margin-top: 23px;
  }

  @media (min-width: 1024px) {
    max-width: 500px;
    margin-top: 16px;
  }
`;

export const Desc = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 330px;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  @media (min-width: 1024px) {
    max-width: 350px;
    margin-top: 32px;
    margin-bottom: 56px;
  }
`;

export const Items = styled.div`
  display: grid;
  width: 100%;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 6px;
    align-items: center;
  }

  @media (min-width: 1400px) {
    column-gap: 8px;
  }
`;

export const Item = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    height: 120px;
  }

  @media (min-width: 1024px) {
    height: 269px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ItemTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transform: translate(-50%, -50%);

  @media (max-width: 1023px) {
    font-size: 26px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;
