import styled from "styled-components";

export const Root = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 450px;
`;

export const Item = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  @media (max-width: 1023px) {
    height: 270px;
  }

  @media (min-width: 1024px) {
    height: 270px;

    &.--short {
      height: 200px;
    }
  }

  @media (min-width: 1400px) {
    height: 350px;

    &.--short {
      height: 250px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Body = styled.div`
  padding: 24px;
  background-color: #fff;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #e1000f;
  background-color: #27166a;
  border-radius: 12px;
`;
