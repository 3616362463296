import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { navigate } from "gatsby";
import url from "url";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { Loading } from "../Loading/Loading";
import { isBrowser } from "../../config";

export const AccessControl = ({
  children,
  unAuthorizedUrlRedirect,
  addRedirectQueryParam,
  redirectQueryParam,
  hasAccess,
}) => {
  const { user, lastUserEvent, initialized } = useContext(UserContext);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // const firstLoad = true;
  // useEffect(() => {
  //   firstLoad = false;
  // }, []);

  useEffect(() => {
    const aSecondAgo = new Date(new Date().getTime() - 1000);
    const redirectUrlObj = url.parse(unAuthorizedUrlRedirect);
    const params = new URLSearchParams(redirectUrlObj.searchParams);
    if (addRedirectQueryParam) {
      const currentUrl = `${location.pathname}${location.search}`;
      params.set(redirectQueryParam, currentUrl);
      redirectUrlObj.search = `${params.toString()}`;
    }
    const isAuthorized =
      hasAccess(user) ||
      (["auth.signIn", "auth.signOut"].includes(lastUserEvent?.eventName) &&
        lastUserEvent?.date > aSecondAgo);

    if (initialized && isBrowser && !isAuthorized) {
      navigate(url.format(redirectUrlObj));
    } else if (isAuthorized) {
      setIsAuthorized(true);
    }
  }, [
    initialized,
    unAuthorizedUrlRedirect,
    addRedirectQueryParam,
    location?.href,
    redirectQueryParam,
    isBrowser,
  ]);

  return initialized && isAuthorized ? children : <Loading />;
};

AccessControl.propTypes = {
  unAuthorizedUrlRedirect: PropTypes.string,
  addRedirectQueryParam: PropTypes.bool,
  redirectQueryParam: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasAccess: PropTypes.func.isRequired,
};

AccessControl.defaultProps = {
  unAuthorizedUrlRedirect: "/auth",
  addRedirectQueryParam: true,
  redirectQueryParam: "redirect",
};
