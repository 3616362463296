import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1023px) {
    height: 60px;
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    height: 72px;
    padding: 0 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1304px;
  color: #8f95a5;
`;
