import { apiSiteId, apiDomain } from "../config";

export function getUserAvatar(user) {
  const mediaId =
    typeof user?.avatar?.media === "string"
      ? user?.avatar?.media
      : user?.avatar?.media?._id;
  if (mediaId == null) {
    return null;
  }
  return `https://${apiDomain}/${apiSiteId}/asset/${mediaId}?width=125&height=125&type=fill`;
}
