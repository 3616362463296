// import { BroadcastChannel } from 'broadcast-channel';
import EventChannel from "./EventChannel";

class EventHub {
  constructor() {
    // console.info('initializing EventHub');
    this.events = {};
    // if (typeof window !== 'undefined' && 'BroadcastChannel' in window) {
    //   this.broadcastChannel = new BroadcastChannel('EventHub');
    //   this.broadcastChannel.onmessage = e => {
    //     this.dispatchInternal(e.data, true);
    //   };
    // }
    // this.broadcastChannel = new BroadcastChannel('EventHub');
    // this.broadcastChannel.onmessage = e => {
    //   this.dispatchInternal(e, true);
    // };
  }

  registerEvent = (channelName) => {
    const eventChannel = new EventChannel(channelName);
    this.events[channelName] = eventChannel;
  };

  dispatch = ({ event, action, data }) => {
    this.dispatchInternal({ event, action, data }, false);
  };

  dispatchInternal = ({ event, action, data }, externalSource) => {
    if (typeof event !== "string" || typeof action !== "string") {
      throw new Error(
        "dispatch requires 'event' and 'action' props as strings",
        { event, action }
      );
    }
    const message = { event, action, data };
    // if not external source, broadcast it out to all listeners
    // if (externalSource === false && this.broadcastChannel != null) {
    //   this.broadcastChannel.postMessage(message);
    // }
    ((this.events[event] || {}).callbacks || []).forEach((callback) => {
      callback(message);
    });
  };

  listen = (event, callback) => {
    if (typeof callback !== "function") {
      throw new Error("No callback supplied to EventHub");
    }
    if (this.events[event] == null) {
      this.registerEvent(event);
    }

    this.events[event].registerCallback(callback);
  };

  remove = (event, callback) => {
    if (this.events.eventName != null) {
      this.events[event].removeCallback(callback);
      // cleanup empty registered events
      if (this.events[event].callbacks.length === 0) {
        delete this.events[event];
      }
    }
  };

  cleanup = () => {
    this.events = null;
    // if (this.broadcastChannel) {
    //   this.broadcastChannel.close();
    // }
  };
}

export default new EventHub();
