import React, { useRef } from "react";

import { Root, NavList, NavItem } from "./MenuDropdown.style";
import useClickOutside from "../../hooks/useClickOutside";

export const MenuDropdown = ({
  isOpen,
  onClose = null,
  ignoreClassName = null,
  children,
}) => {
  const ref = useRef(null);
  useClickOutside(ref, () => onClose && onClose(), ignoreClassName);

  return (
    <Root ref={ref} className={isOpen && "--isOpen"}>
      <NavList>{children}</NavList>
    </Root>
  );
};

MenuDropdown.Item = NavItem;
