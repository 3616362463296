import styled from "styled-components";

import patternSrc from "../../../assets/images/pattern-purple-dark@2x.png";

export const Root = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: var(--color-primary);
  background-image: url(${patternSrc});
  background-repeat: repeat-x;
  background-size: 200px auto;

  @media (max-width: 1023px) {
    padding: 72px 24px;
  }

  @media (min-width: 1024px) {
    padding: 80px 56px;
  }

  @media (min-width: 1400px) {
    padding: 80px 131px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1138px;

  @media (min-width: 1024px) {
    align-items: center;
  }
`;

export const Title = styled.div`
  @media (max-width: 1023px) {
    max-width: 300px;
  }
`;

export const CardsList = styled.ul`
  width: 100%;
  margin-top: 64px;
  list-style: none;

  @media (max-width: 1023px) {
    margin-bottom: 32px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 6px;
    width: 100%;
    margin-bottom: 40px;
  }

  @media (min-width: 1400px) {
    column-gap: 8px;
  }
`;

export const CardsItem = styled.li`
  display: flex;

  @media (max-width: 1023px) {
    & + & {
      margin-top: 40px;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
  }

  @media (min-width: 1400px) {
    padding: 0 48px;
  }
`;

export const CardsIcon = styled.div`
  @media (max-width: 1023px) {
    width: 32px;
    height: 32px;
    margin-right: 24px;
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
    font-size: 40px;
  }
`;

export const CardsText = styled.div`
  @media (min-width: 1024px) {
    max-width: 260px;
    text-align: center;
  }
`;

export const BottomParagraph = styled.div`
  width: 100%;
  margin-top: 18px;

  @media (min-width: 1024px) {
    text-align: center;
  }
`;
