import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Container,
  Grid,
  NoResultsContainer,
} from "./FavouritesGrid.style";

import { Button } from "../../ui/Button/Button";
import { Link } from "../Link/Link";
import { Typography } from "../../ui/Typography/Typography";

export const FavouritesGrid = ({ totalCount = 0, children }) => {
  return (
    <Root>
      <Container>
        {totalCount < 1 ? (
          <NoResultsContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              width={64}
              height={64}
            >
              <path
                fill="#dbdfea"
                stroke="#fff"
                strokeWidth=".5"
                d="m40.098 48.999-11.58 3.85V27.323l11.751-3.936a.4.4 0 0 0 .2-.057l10.867-3.622v14.574h2.852V17.739c0-.156-.029-.311-.086-.457a1.04 1.04 0 0 0-.2-.4.912.912 0 0 0-.285-.285.471.471 0 0 0-.2-.114c-.057-.057-.143-.057-.2-.086l-.143-.057h-.028L27.719 7.897l-.171-.057a1.14 1.14 0 0 0-.428-.086h-.057c-.147.001-.292.03-.428.086l-.171.057-12.517 4.168-.314.114-12.521 4.164-.143.057c-.057.028-.143.028-.2.086l-.171.086a.029.029 0 0 0-.028.028.514.514 0 0 0-.257.285 1.012 1.012 0 0 0-.257.456 1.694 1.694 0 0 0-.057.4v28.524a1.386 1.386 0 0 0 .969 1.34l25.584 8.528a.1.1 0 0 0 .086.028c.294.114.62.114.913 0l.086-.028 13.376-4.45-.913-2.681-.002-.003Zm-13-38.334 21.192 7.073-8.357 2.8-21.195-7.073 8.36-2.8Zm-1.426 42.183L2.854 45.233V19.708l22.818 7.615v25.525Zm1.426-28.037L5.906 17.738l8.357-2.8 21.192 7.074-8.357 2.799Z"
              />
              <path
                fill="#dbdfea"
                d="M57.877 36.45a6.074 6.074 0 0 0-4.792 2.354h-1.044a6.074 6.074 0 0 0-4.792-2.354 6.182 6.182 0 0 0-6.122 6.226 6.27 6.27 0 0 0 1.568 4.152l9.448 8.682h.84l9.378-8.61.071-.072A6.27 6.27 0 0 0 64 42.676a6.183 6.183 0 0 0-6.123-6.226Zm3.167 9.048-7.346 6.744h-2.271l-7.345-6.744a4.318 4.318 0 0 1-1.049-2.822 4.258 4.258 0 0 1 4.216-4.288 4.192 4.192 0 0 1 3.514 1.92l.283.433h3.034l.283-.433a4.192 4.192 0 0 1 3.514-1.92 4.258 4.258 0 0 1 4.216 4.288 4.318 4.318 0 0 1-1.049 2.822Z"
              />
            </svg>
            <Typography
              type="text-small"
              color="#8f95a5"
              style={{ marginTop: 28, marginBottom: 13 }}
            >
              <FormattedMessage defaultMessage="Your wishlist is empty" />
            </Typography>
            <Button as={Link} to="/rewards">
              <FormattedMessage defaultMessage="shop rewards" />
            </Button>
          </NoResultsContainer>
        ) : (
          <Grid>{children}</Grid>
        )}
      </Container>
    </Root>
  );
};
