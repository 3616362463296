import styled from "styled-components";

export const Hero = styled.div`
  width: 100%;
  position: relative;
`;

export const HeroImageContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    height: 248px;
  }

  @media (min-width: 1024px) {
    height: 650px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);

  @media (max-width: 1023px) {
    width: 100%;
    height: 135px;
  }

  @media (min-width: 1024px) {
    position: absolute;
    left: 0;
    bottom: 32px;
    width: 280px;
    height: 168px;
  }
`;

export const HeroLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media (max-width: 1023px) {
    width: 193px;
  }

  @media (min-width: 1024px) {
    width: 231px;
  }

  svg {
    width: 100%;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 24px;
    row-gap: 8px;
  }

  @media (min-width: 1024px) {
    justify-content: center;
    column-gap: 24px;
    padding: 16px;
  }
`;

export const BrandSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f4f8;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 64px 24px;
  }

  @media (min-width: 1024px) {
    padding: 80px 24px;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  margin-top: 48px;
  color: #8f95a5;
`;
