import React from "react";
import styled from "styled-components";
import { components } from "react-select";

export const OptionIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const BrandsOption = (props) => {
  const { icon } = props?.data;

  return (
    <components.Option {...props}>
      <OptionIconContainer>{icon}</OptionIconContainer>
    </components.Option>
  );
};

export const brandsStyles = {
  menuList: (baseStyles) => ({
    ...baseStyles,
    display: "flex",
    flexWrap: "wrap",
    padding: "12px 4px",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    width: 71.7,
    height: 40,
    margin: 4,
    padding: 0,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#dcdfe9",
  }),
};
