import { gql } from "@apollo/client";

export const PROFILE_UPDATE = gql`
  mutation ProfileUpdate($data: UserProfileInput!) {
    updateMyProfile(data: $data) {
      user {
        _id
      }
      bonusPoints {
        _id
        points
        note
        jobKey
      }
      pointsBalance
    }
  }
`;

export const SALON_UPDATE = gql`
  mutation SalonUpdate($data: SalonProfileInput!) {
    updateMySalon(data: $data) {
      salon {
        _id
      }
      bonusPoints {
        _id
        points
        note
        jobKey
      }
      pointsBalance
    }
  }
`;

export const PREFERNCES_UPDATE = gql`
  mutation PreferencesUpdate($data: UpdatePreferencesInput!) {
    updatePreferences(data: $data) {
      salon {
        _id
      }
      bonusPoints {
        _id
        points
        note
        jobKey
      }
      pointsBalance
    }
  }
`;
