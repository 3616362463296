import React from "react";

import { Root, ImageContainer, Content, Desc, Cta } from "./Promo.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Link } from "../../Link/Link";
import { Icon } from "../../../ui/Icon/Icon";

export const Promo = ({
  image,
  imageSrc, // DEPRECATED
  title = "",
  desc = "",
  ctaUrl = "/",
}) => {
  return (
    <Root>
      <ImageContainer>
        {imageSrc && <img src={imageSrc} alt={title} />}
        {image}
      </ImageContainer>
      <Content>
        <Typography as="h2" type="h1">
          {title}
        </Typography>
        <Desc>
          <Typography as="p" type="text-primary">
            {desc}
          </Typography>
        </Desc>
        <Cta as={Link} target="_blank" to={ctaUrl}>
          <Icon icon="arrow-right-long" />
        </Cta>
      </Content>
    </Root>
  );
};
