import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Root,
  ImageContainer,
  Container,
  Body,
} from "./OrderConfirmationView.style";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Typography } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import { Link } from "../../components/Link/Link";
import { ButtonLink } from "../../ui/ButtonLink/ButtonLink";

export const OrderConfirmationView = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.orderId) {
      navigate(`/${intl.locale}`, { replace: true });
    }
  }, [location?.state?.orderId]);

  return (
    <Layout>
      <Header />

      <Root>
        <ImageContainer />
        <Container>
          <Body>
            <Typography as="h2" type="h2">
              <FormattedMessage
                defaultMessage="Thank you{br}for your order!"
                values={{
                  br: <br />,
                }}
              />
            </Typography>
            <Typography
              type="text-small"
              color="#8f95a5"
              style={{ marginTop: 11 }}
            >
              <FormattedMessage defaultMessage="Order" /> #
              {location?.state?.orderId}
            </Typography>
            <Typography
              as="p"
              type="text-primary"
              style={{ marginTop: 24, marginBottom: 48 }}
            >
              <FormattedMessage defaultMessage="Keep an eye on your inbox – we're sending the details of your order your way! For the latest on your points and for your order history, just dash over to your dashboard. Thanks for shopping with us – you make Club H fabulous!" />
            </Typography>
            <Button
              as={Link}
              to={`/${intl.locale}/rewards`}
              variant="auth"
              arrow={false}
            >
              <FormattedMessage defaultMessage="Continue Shopping" />
            </Button>
            <ButtonLink
              as={Link}
              to={`/${intl.locale}/dashboard`}
              variant="secondary"
              style={{ marginTop: 32 }}
            >
              <FormattedMessage defaultMessage="Go to Dashboard" />
            </ButtonLink>
          </Body>
        </Container>
      </Root>

      <Footer />
    </Layout>
  );
};
