import styled from "styled-components";

import patternLightSrc from "../../../assets/images/pattern-purple-light@2x.png";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  &.--pattern-light {
    position: relative;
    background-color: #fff;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-image: url(${patternLightSrc});
      background-repeat: repeat-x;
      background-size: 280px;
      transform: rotate(180deg);
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  @media (max-width: 1023px) {
    padding: 72px 24px;
  }

  @media (min-width: 1024px) {
    padding: 137px 56px;

    &.--pattern-light {
      padding: 48px 56px 72px;
    }
  }

  @media (min-width: 1400px) {
    padding: 137px 131px;

    &.--pattern-light {
      padding: 48px 56px 104px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1138px;
  z-index: 1;

  @media (min-width: 1024px) {
    text-align: center;
  }
`;

export const CardsList = styled.ul`
  margin-top: 64px;
  list-style: none;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 6px;
    width: 100%;
  }

  @media (min-width: 1400px) {
    column-gap: 8px;
  }
`;

export const CardsItem = styled.li`
  display: flex;

  @media (max-width: 1023px) {
    & + & {
      margin-top: 40px;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
  }

  @media (min-width: 1400px) {
    padding: 0 48px;
  }
`;

export const CardsIcon = styled.div`
  @media (max-width: 1023px) {
    width: 32px;
    height: 32px;
    margin-right: 24px;
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
    font-size: 40px;
  }
`;

export const CardsText = styled.div`
  @media (min-width: 1024px) {
    text-align: center;
  }

  p.--pattern-light {
    color: #7f8696;
  }
`;
