import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--color-bg-3);

  @media (max-width: 1023px) {
    height: 248px;
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    height: 300px;
    margin-bottom: 11px;
  }

  @media (min-width: 1400px) {
    height: 376px;
  }
`;

export const ImageLink = styled.a`
  width: 100%;
  height: 100%;

  &.--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 26px;

  @media (max-width: 1023px) {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 1024px) {
    width: 32px;
    height: 32px;
  }
`;

export const OutOfStock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 24px;
  padding: 0 12px;
  background-color: #fff;
  white-space: nowrap;
  transform: translate(-50%, -50%);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
  width: 100%;
  text-align: center;

  &.--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const CtaContainer = styled.div`
  width: 100%;
  margin-top: 8px;

  @media (max-width: 1023px) {
    display: none;
  }

  svg {
    margin-right: 8px;
    font-size: 17px;
  }
`;
