import styled from "styled-components";

import bgMobileSrc from "../../../assets/images/we-are-bg-mobile@x2.jpg";
import bgTabletSrc from "../../../assets/images/we-are-bg-tablet@x2.jpg";
import bgDesktopSrc from "../../../assets/images/we-are-bg-desktop@x2.jpg";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 346px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;

  @media (max-width: 1023px) {
    background-image: url(${bgMobileSrc});
  }

  @media (min-width: 1024px) {
    background-image: url(${bgTabletSrc});
  }

  @media (min-width: 1400px) {
    background-image: url(${bgDesktopSrc});
  }
`;

export const Logo = styled.div`
  margin-top: 16px;

  @media (max-width: 1023px) {
    width: 300px;
  }

  @media (min-width: 1024px) {
    width: 337px;
  }

  @media (min-width: 1400px) {
    width: 461px;
  }
`;
