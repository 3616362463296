import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1024px) {
    height: 72px;
    padding: 0 48px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1304px;
  color: #8f95a5;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.12px;
`;

export const AppendContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Select = styled.select`
  padding-right: 22px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath fill='%238f95a5' d='m.649 4.753 1.058-1.055 5.1 5.11h.387l5.1-5.11 1.057 1.056-5.539 5.548H6.187L.649 4.753Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: 14px;
  text-align: right;
  border: 0 none;
  appearance: none;
`;
