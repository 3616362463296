import React from "react";
import PropTypes from "prop-types";
import { Link } from "../Link/Link";

import {
  Root,
  Container,
  List,
  ListItem,
  DividerContainer,
  AppendContainer,
} from "./Breadcrumbs.style";

import { Icon } from "../../ui/Icon/Icon";
import { Typography } from "../../ui/Typography/Typography";

export const Breadcrumbs = ({ breadcrumbs = [], append = null }) => {
  return (
    <Root>
      <Container>
        <List>
          {breadcrumbs.map((item, idx) => (
            <ListItem key={item.label + idx}>
              <Typography
                as={item.to && Link}
                to={item.to}
                type="text-small"
                color="#8f95a5"
              >
                {item.label}
              </Typography>
              {idx + 1 < breadcrumbs.length && (
                <DividerContainer>
                  <Icon icon="chevron-right" />
                </DividerContainer>
              )}
            </ListItem>
          ))}
        </List>

        {append && <AppendContainer>{append}</AppendContainer>}
      </Container>
    </Root>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
