import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 72px 0 64px;
  }

  @media (min-width: 1024px) {
    padding: 120px 24px;
  }

  @media (min-width: 1400px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  .slick-slider {
    width: 100%;

    @media (max-width: 1023px) {
      max-width: 376px;
    }

    @media (min-width: 1024px) {
      max-width: 952px;
    }

    @media (min-width: 1400px) {
      max-width: 1336px;
    }
  }

  .slick-prev,
  .slick-next {
    background-repeat: no-repeat;
    background-position: center;
    transform: none;
    z-index: 1;

    &::before {
      display: none;
    }

    @media (max-width: 1023px) {
      top: 108px;
      width: 32px;
      height: 32px;
      background-size: 20px;
    }

    @media (min-width: 1024px) {
      top: 130px;
      width: 40px;
      height: 40px;
      background-size: 24px;
    }

    @media (min-width: 1400px) {
      top: 168px;
    }
  }

  .slick-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' d='m9.1 17.11-4.809-4.027.578-.484H22v-1.198H4.842l-.563-.472L9.1 6.891l-1.01-.846L2 11.148v1.706l6.093 5.1L9.1 17.11Z'/%3E%3C/svg%3E");

    @media (max-width: 1023px) {
      left: 7px;
    }

    @media (min-width: 1024px) {
      left: 12px;
    }

    @media (min-width: 1400px) {
      left: 16px;
    }
  }

  .slick-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' d='m14.9 6.889 4.809 4.027-.578.484H2v1.198h17.158l.563.472-4.821 4.038 1.01.846L22 12.851v-1.706l-6.093-5.1-1.007.844Z'/%3E%3C/svg%3E");

    @media (max-width: 1023px) {
      right: 7px;
    }

    @media (min-width: 1024px) {
      right: 12px;
    }

    @media (min-width: 1400px) {
      right: 16px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 330px;
    margin-top: 23px;
  }

  @media (min-width: 1024px) {
    max-width: 500px;
    margin-top: 16px;
  }
`;

export const Desc = styled.div`
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 330px;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  @media (min-width: 1024px) {
    max-width: 350px;
    margin-top: 32px;
    margin-bottom: 56px;
  }
`;

export const Slide = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 0 7px;
  }

  @media (min-width: 1024px) {
    padding: 0 12px;
  }

  @media (min-width: 1400px) {
    padding: 0 16px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    height: 248px;
  }

  @media (min-width: 1024px) {
    height: 300px;
  }

  @media (min-width: 1400px) {
    height: 376px;
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const AddToButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  font-size: 32px;
`;

export const BrandName = styled.div`
  @media (max-width: 1023px) {
    margin-top: 15px;
    margin-bottom: 4px;
  }

  @media (min-width: 1024px) {
    margin-top: 14px;
    margin-bottom: 12px;
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    margin-top: 48px;
  }

  @media (min-width: 1024px) {
    margin-top: 58px;
  }
`;

export const Points = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;

  @media (max-width: 1023px) {
    display: none;
  }
`;
