import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.$withBackground && "#f5f4f8"};

  @media (max-width: 1023px) {
    padding: 64px 4px;
  }

  @media (min-width: 1024px) {
    padding: 120px 80px;
  }

  @media (min-width: 1400px) {
    padding: 120px 128px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    margin-left: 6px;
    font-size: 19px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1142px;
  margin: 64px 0;
`;
