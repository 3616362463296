import guy_tang from "../assets/images/community-users/guy_tang.jpg";
import larisadoll from "../assets/images/community-users/larisadoll.jpg";
import staygold31 from "../assets/images/community-users/staygold31.jpg";
import hairbyreema from "../assets/images/community-users/hairbyreema.jpg";
import daniellepriano from "../assets/images/community-users/daniellepriano.jpg";
import danilobozic from "../assets/images/community-users/danilo.bozic.jpg";
import claytonhawkins from "../assets/images/community-users/claytonhawkins.jpg";
import omgartistry from "../assets/images/community-users/omgartistry.jpg";
import lo_wheelerdavis from "../assets/images/community-users/lo_wheelerdavis.jpg";
import mirellamanelli from "../assets/images/community-users/mirellamanelli.jpg";
import juliuscaesar from "../assets/images/community-users/juliuscaesar.jpg";
import nomadbarber from "../assets/images/community-users/nomadbarber.jpg";
import yogacolourist from "../assets/images/community-users/yogacolourist.jpg";
import grahamnation from "../assets/images/community-users/grahamnation.jpg";
import frances_hairartist from "../assets/images/community-users/frances_hairartist.jpg";
import lgibsoncolorist from "../assets/images/community-users/lgibsoncolorist.jpg";
import stylistricardosantiago from "../assets/images/community-users/stylistricardosantiago.jpg";
import jill901 from "../assets/images/community-users/jill901.jpg";
import blackcombbarber from "../assets/images/community-users/blackcombbarber.jpg";
import richardmannah from "../assets/images/community-users/richardmannah.jpg";
import staellecolorful from "../assets/images/community-users/staellecolorful.jpg";
import hairbyarmineh from "../assets/images/community-users/hairbyarmineh.jpg";
import watchmyhairbounce from "../assets/images/community-users/watchmyhairbounce.jpg";
import michelleoliverhair from "../assets/images/community-users/michelleoliverhair.jpg";

export const communityUsers = [
  {
    name: "Guy Tang",
    handle: "guy_tang",
    image: guy_tang,
    link: "https://www.instagram.com/guy_tang/",
    brands: "MyIdentity",
    followers1000x: "2,300",
  },
  {
    name: "Larisa Love",
    handle: "larisadoll",
    image: larisadoll,
    link: "https://www.instagram.com/larisadoll/",
    brands: "Joico",
    followers1000x: "530",
  },
  {
    name: "Sofie Staygold",
    handle: "staygold31",
    image: staygold31,
    link: "https://www.instagram.com/staygold31/",
    brands: "STMNT",
    followers1000x: "496",
  },
  {
    name: "Reema Jaber",
    handle: "hairbyreema",
    image: hairbyreema,
    link: "https://www.instagram.com/hairbyreema ",
    brands: "SKP",
    followers1000x: "355",
  },
  {
    name: "Danielle Priano",
    handle: "daniellepriano",
    image: daniellepriano,
    link: "https://www.instagram.com/daniellepriano/",
    brands: "SexyHair",
    followers1000x: "300",
  },
  {
    name: "Danilo Bozic ",
    handle: "danilo.bozic",
    image: danilobozic,
    link: "https://www.instagram.com/danilo.bozic",
    brands: "SKP, Alterna ",
    followers1000x: "257",
  },
  {
    name: "Clayton Hawkins",
    handle: "claytonhawkins",
    image: claytonhawkins,
    link: "https://www.instagram.com/claytonhawkins/",
    brands: "SexyHair",
    followers1000x: "181",
  },
  {
    name: "Olivia Thompson",
    handle: "omgartistry",
    image: omgartistry,
    link: "https://www.instagram.com/omgartistry/",
    brands: "Joico",
    followers1000x: "178",
  },
  {
    name: "Lo Wheeler",
    handle: "lo_wheelerdavis",
    image: lo_wheelerdavis,
    link: "https://www.instagram.com/lo_wheelerdavis/",
    brands: "Kenra",
    followers1000x: "164",
  },
  {
    name: "Mirella Manelli",
    handle: "mirellamanelli",
    image: mirellamanelli,
    link: "https://www.instagram.com/mirellamanelli",
    brands: "Kenra",
    followers1000x: "163",
  },
  {
    name: "Julius Cvesar",
    handle: "juliuscaesar",
    image: juliuscaesar,
    link: "https://www.instagram.com/juliuscaesar/",
    brands: "STMNT",
    followers1000x: "162",
  },
  {
    name: "Nomad Barber",
    handle: "nomadbarber",
    image: nomadbarber,
    link: "https://www.instagram.com/nomadbarber/",
    brands: "STMNT",
    followers1000x: "110",
  },
  {
    name: "Sharon Mudavanhu",
    handle: "yogacolourist",
    image: yogacolourist,
    link: "https://www.instagram.com/yogacolourist",
    brands: "Joico",
    followers1000x: "105",
  },
  {
    name: "Graham Nation",
    handle: "grahamnation",
    image: grahamnation,
    link: "https://www.instagram.com/grahamnation/",
    brands: "SexyHair",
    followers1000x: "93",
  },
  {
    name: "Frances Gonzalez",
    handle: "frances_hairartist",
    image: frances_hairartist,
    link: "https://www.instagram.com/frances_hairartist/",
    brands: "Kenra",
    followers1000x: "76",
  },
  {
    name: "Laura Gibson",
    handle: "lgibsoncolorist",
    image: lgibsoncolorist,
    link: "https://www.instagram.com/lgibsoncolorist/",
    brands: "Joico",
    followers1000x: "73",
  },
  {
    name: "Ricardo Santiago",
    handle: "stylistricardosantiago",
    image: stylistricardosantiago,
    link: "https://www.instagram.com/stylistricardosantiago/",
    brands: "Joico",
    followers1000x: "63",
  },
  {
    name: "Jill Buck",
    handle: "jill901",
    image: jill901,
    link: "https://www.instagram.com/jill901/",
    brands: "Joico, ABC, STMNT",
    followers1000x: "53.7",
  },
  {
    name: "Gregory Dennis",
    handle: "blackcombbarber",
    image: blackcombbarber,
    link: "https://www.instagram.com/blackcombbarber/",
    brands: "STMNT",
    followers1000x: "30",
  },
  {
    name: "Richard Mannah",
    handle: "richardmannah",
    image: richardmannah,
    link: "https://www.instagram.com/richardmannah/",
    brands: "Joico",
    followers1000x: "27.5",
  },
  {
    name: "Staelle Colorful",
    handle: "staellecolorful",
    image: staellecolorful,
    link: "https://www.instagram.com/staellecolorful/",
    brands: "Joico",
    followers1000x: "14.3",
  },
  {
    name: "Armineh Damanpak",
    handle: "hairbyarmineh",
    image: hairbyarmineh,
    link: "https://www.instagram.com/hairbyarmineh/",
    brands: "Joico",
    followers1000x: "10.5",
  },
  {
    name: "Jeannetta Walker-Rodgers",
    handle: "watchmyhairbounce",
    image: watchmyhairbounce,
    link: "https://www.instagram.com/watchmyhairbounce/",
    brands: "Joico",
    followers1000x: "8.5",
  },
  {
    name: "Michelle Oliver",
    handle: "michelleoliverhair",
    image: michelleoliverhair,
    link: "https://www.instagram.com/michelleoliverhair",
    brands: "SKP, Alterna ",
    followers1000x: "7",
  },
];

export function communityVideos({ intl }) {
  return [
    {
      title: intl.formatMessage({
        defaultMessage: "STMNT Grooming Goods",
      }),
      brands: "STMNT",
      description: intl.formatMessage({
        defaultMessage:
          '"We are the pioneers of modern barbering. Our mission is to do more than contribute; we are looking to rewrite the standards of grooming."\n\nTake grooming to the next level with STMNT Grooming Goods; a distinctive brand brought to life by @staygold31, @juliuscaesar & @nomadbarber. #makeyourstmnt"',
      }),
      locale: "en-CA",
      link: "https://www.youtube.com/embed/34MvgP46Kyg",
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Peggy Gou and Authentic Beauty Concept",
      }),
      brands: "AUTHENTIC BEAUTY CONCEPT",
      description: intl.formatMessage({
        defaultMessage:
          "Meet Peggy Gou, an authentic voice with edge - one of the world's most inspiring artists, DJs, record producers & style icons and new face of our brand!",
      }),
      locale: "en-CA",
      link: "https://www.youtube.com/embed/gKo4XqR76H4",
    },
    {
      title: intl.formatMessage({
        defaultMessage: "Welcome to the New DevaCurl",
      }),
      brands: "DEVACURL",
      description: intl.formatMessage({
        defaultMessage:
          "Brand new products for Curl Flexibility. Enhanced formulas. Dermatologist co-developed and stylist approved. More sustainable packaging. Welcome to DevaCurl 3.0",
      }),
      locale: "en-CA",
      link: "https://www.youtube.com/embed/cQiTw2y6VLs?start=60",
    },
    {
      title: intl.formatMessage({
        defaultMessage:
          "Alterna Caviar Anti-Aging Collection. Luxurious Haircare for Healthy Hair ",
      }),
      brands: "ALTERNA",
      description: intl.formatMessage({
        defaultMessage:
          "Learn about Alterna Caviar: Transformational, luxurious haircare for healthy, youthful-looking hair with proven performance and potent, clean formulas and ingredients.",
      }),
      locale: "en-CA",
      link: "https://www.youtube.com/embed/M2CVBeBb4L8",
    },
  ];
}
