import styled from "styled-components";

export const Root = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  background-color: var(--color-primary);

  @media (max-width: 1023px) {
    padding-top: 64px;
    padding-bottom: 40px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1304px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 148px 1fr 144px;
    align-items: center;
    padding: 40px 48px;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 208px 1fr 144px;
  }
`;

export const Logo = styled.img`
  display: block;
  width: 104px;
  height: auto;
`;

export const CtaContainer = styled.div`
  @media (max-width: 1023px) {
    padding: 40px 0;
  }
`;

export const SocialContainer = styled.div`
  color: #fff;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #b0abc8;

  @media (max-width: 1023px) {
    margin-top: 64px;
    padding-top: 38px;
  }

  @media (min-width: 1024px) {
  }
`;

export const BottomContainer = styled.div`
  align-items: center;
  width: 100%;
  max-width: 1304px;
  color: #b0abc8;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 146px 1fr;
    height: 55px;
    padding: 0 48px;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 208px 1fr;
  }
`;

export const BottomNav = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 38px;
  }
`;

export const BottomNavItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 1023px) {
    padding: 3px 8px;
    text-align: center;
  }

  @media (min-width: 1024px) {
    & + & {
      margin-left: 16px;
    }
  }
`;
