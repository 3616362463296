import React, { forwardRef } from "react";

import {
  Root,
  Label,
  Input,
  CheckmarkContainer,
  Checkmark,
  Error,
} from "./Checkbox.style";

export const Checkbox = forwardRef(({ label, error, ...props }, ref) => {
  return (
    <Root>
      <Label>{label}</Label>
      <Input ref={ref} type="checkbox" {...props} />
      <CheckmarkContainer>
        <Checkmark $isChecked={props.value}>
          <svg xmlns="http://www.w3.org/2000/svg" width={7} height={5}>
            <path
              fill="#fff"
              d="m6.375 0-3.69 3.691h-.257l-1.8-1.8-.625.625 2.242 2.242h.626L7.004.625Z"
            />
          </svg>
        </Checkmark>
      </CheckmarkContainer>
      {error && <Error>{error?.message}</Error>}
    </Root>
  );
});
