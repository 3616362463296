import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Root } from "./NotFoundView.style";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/ComingSoon/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Footer } from "../../components/ComingSoon/Footer/Footer";
import { Typography } from "../../ui/Typography/Typography";
import { Button } from "../../ui/Button/Button";
import { Link } from "../../components/Link/Link";

export const NotFoundView = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Header />
      <Breadcrumbs breadcrumbs={[]} />

      <Root>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          width={64}
          height={64}
        >
          <path
            fill="#dbdfea"
            stroke="#fff"
            strokeWidth="1.5"
            d="m5.933 62.142-3.512-3.513 9.9-9.9v-3.277C.277 28.172 11.457 4.334 32.444 2.544 53.431.754 68.486 22.355 59.542 41.425c-7.19 15.332-26.441 20.517-40.359 10.871h-3.404l-9.846 9.846Zm51.14-32.3C57.07 12.633 38.439 1.881 23.537 10.488c-14.902 8.607-14.898 30.118.007 38.72a22.351 22.351 0 0 0 11.177 2.993c12.339-.015 22.338-10.015 22.352-22.354v-.005Z"
          />
          <path
            fill="#dbdfea"
            d="M47.5 61.25a13.66 13.66 0 0 1-9.723-4.027A13.66 13.66 0 0 1 33.75 47.5a13.66 13.66 0 0 1 4.027-9.723A13.66 13.66 0 0 1 47.5 33.75a13.66 13.66 0 0 1 9.723 4.027A13.66 13.66 0 0 1 61.25 47.5a13.66 13.66 0 0 1-4.027 9.723A13.66 13.66 0 0 1 47.5 61.25Z"
          />
          <path
            fill="#fff"
            d="M47.5 35C40.596 35 35 40.596 35 47.5S40.596 60 47.5 60 60 54.404 60 47.5 54.404 35 47.5 35m0-2.5c4.007 0 7.773 1.56 10.607 4.393A14.904 14.904 0 0 1 62.5 47.5c0 4.007-1.56 7.773-4.393 10.607A14.904 14.904 0 0 1 47.5 62.5c-4.007 0-7.773-1.56-10.607-4.393A14.904 14.904 0 0 1 32.5 47.5c0-4.007 1.56-7.773 4.393-10.607A14.904 14.904 0 0 1 47.5 32.5Z"
          />
          <path
            fill="#fff"
            stroke="#fff"
            strokeWidth=".5"
            d="m53.076 43.038-1.115-1.115-3.886 3.886h-1.153l-3.885-3.886-1.116 1.115 3.923 3.923v1.078l-3.923 3.924 1.115 1.115 3.923-3.923h1.079l3.923 3.923 1.115-1.115-3.882-3.886v-1.153l3.882-3.886Z"
          />
        </svg>
        <Typography type="h3" style={{ marginTop: 30 }}>
          <FormattedMessage defaultMessage="Page Not Found" />
        </Typography>
        <Typography
          type="text-small"
          color="#8f95a5"
          style={{ marginTop: 11, marginBottom: 28 }}
        >
          <FormattedMessage defaultMessage="The page you’re looking for does not exist." />
        </Typography>
        <Button as={Link} to={"/" + intl.locale}>
          <FormattedMessage defaultMessage="back to homepage" />
        </Button>
      </Root>

      <Footer />
    </Layout>
  );
};
