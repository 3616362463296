import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 10px;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);

  @media (max-width: 1023px) {
    width: 327px;
    height: 185px;
  }

  @media (min-width: 1024px) {
    width: 435px;
    height: 246px;
  }
`;

export const Body = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  @media (max-width: 1023px) {
    width: 56px;
    height: 56px;
    margin-right: 24px;
    font-size: 56px;
  }

  @media (min-width: 1024px) {
    width: 89px;
    height: 89px;
    margin-right: 38px;
    margin-left: 8px;
    font-size: 89px;
  }
`;

export const Tier = styled.div`
  color: var(--color-accent);
  text-transform: uppercase;
`;

export const Pts = styled.div`
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 26px;
    line-height: 1.5;
  }

  @media (min-width: 1024px) {
    font-size: 34px;
    line-height: 54px;
  }
`;

export const NextLevel = styled.div`
  color: var(--color-secondary);
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-secondary);
  border-top: 1px dashed #b0abc8;

  @media (max-width: 1023px) {
    height: 40px;
  }

  @media (min-width: 1024px) {
    height: 52px;
  }
`;
