import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Root, Container, TextContainer, CtaContainer } from "./Discover.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Button } from "../../../ui/Button/Button";

export const Discover = () => {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <TextContainer>
          <Typography as="h2" type="h2">
            <FormattedMessage
              defaultMessage="Discover the future{br}of loyalty"
              values={{ br: <br /> }}
            />
          </Typography>
          <Typography
            as="p"
            type="text-primary"
            style={{ maxWidth: 306, marginTop: 28 }}
          >
            <FormattedMessage defaultMessage="Rewards that advance your career and enhance your business." />
          </Typography>

          <CtaContainer>
            <Button
              variant="secondary"
              onClick={() => navigate("/en-CA/terms")}
            >
              <FormattedMessage defaultMessage="learn more" />
            </Button>
            <Button
              onClick={() => navigate("/auth/register")}
              style={{ marginLeft: 8 }}
            >
              <FormattedMessage defaultMessage="join now" />
            </Button>
          </CtaContainer>
        </TextContainer>
      </Container>
    </Root>
  );
};

Discover.propTypes = {
  onRemind: PropTypes.func,
};
