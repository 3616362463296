import { gql } from "@apollo/client";

export const GET_DASHBOARD_DATA = gql`
  query GetDashboardData {
    me {
      pointsBalance
      user {
        _id
        dc
        dm
        avatar {
          media {
            _id
          }
        }
        firstname
        lastname
        phone
        email
        smsOptIn
        timezone
        data {
          promotions
          profession
          distributorSignup {
            _id
            accountNumber
          }
        }
        salon {
          _id
          n
          dc
          dm
          address {
            str1
            str2
            city
            state
            postal
          }
          data {
            salonBirthday
            website
          }
          ref {
            ext_id
            distributor {
              n
            }
          }
        }
      }
    }
    loyaltyresult {
      find(
        collection: {
          sort: [{ key: "runDate", direction: "-1" }]
          filters: [
            {
              key: "qualifyingTier"
              operator: "idIn"
              value: "starter,enthusiast,master,icon"
            }
          ]
          page: 1
          limit: 1
        }
      ) {
        docs {
          _id
          qualifyingTier
        }
        page
        pages
        limit
        total
      }
    }
  }
`;

export const GET_ORDERS_HISTORY = gql`
  query GetOrdersHistory(
    $page: Int
    $sort: [SortInput]
    $filters: [FilterInput]
  ) {
    loyalty {
      orders(collection: { sort: $sort, filters: $filters, page: $page }) {
        docs {
          _id
          fid
          dc
          dm
          status
          salon {
            _id
          }
          billing {
            address {
              data {
                firstname
                lastname
              }
            }
          }
          grandTotal {
            amount
            currency
          }
          pointsTotal {
            amount
            currency
          }
          salon {
            _id
            n
          }
        }
        page
        pages
        limit
        total
      }
    }
  }
`;

export const GET_ORDER_DETAIL = gql`
  query GetOrderDetail($id: String) {
    order {
      findById(id: $id) {
        _id
        fid
        status
        salon {
          _id
          n
        }
        notes {
          notify
          status
          note
          _id
          dc
        }
        items {
          _id
          e
          n
          sku
          qty
          pointsSubTotal {
            amount
            currency
          }
          subTotal {
            amount
            currency
          }
          pointsUnitTotal {
            amount
            currency
          }
          configuration {
            _id
          }
          product {
            brand {
              _id
              n
            }
            ecommerce {
              sku
            }
            data {
              printconfig
            }
            gallery {
              media {
                _id
              }
            }
          }
        }
        billing {
          address {
            str1
            str2
            city
            postal
            state
            country
            data {
              phone
              firstname
              lastname
            }
          }
        }
        shipping {
          address {
            str1
            str2
            city
            postal
            state
            country
            data {
              phone
              firstname
              lastname
              addressType
            }
          }
        }
        pointsTotal {
          amount
          currency
        }
        grandTotal {
          amount
          currency
        }
        subTotal {
          amount
          currency
        }
        shippingTotal {
          amount
          currency
        }
        taxTotal {
          amount
          currency
        }
        taxRate
        dc
        dm
      }
    }
  }
`;

export const GET_POINTS_HISTORY = gql`
  query GetPointsHistory(
    $page: Int
    $sort: [SortInput]
    $filters: [FilterInput]
  ) {
    loyaltyresult {
      find(collection: { sort: $sort, filters: $filters, page: $page }) {
        docs {
          _id
          dc
          dm
          runDate
          jobKey
          hasSalonUser
          salon_users {
            _id
            firstname
            lastname
          }
          dist_users {
            _id
            firstname
            lastname
          }
          salon {
            _id
            n
          }
          distributors {
            n
          }
          configuration {
            _id
            scope
            recurrence
            data {
              loyaltyConfig
            }
          }
          qualifyingTier
          metrics {
            aggregationGroup
            metricName
            metricValue
          }
          points
          redeemed
          expired
          expires
          note
        }
        page
        pages
        limit
        total
      }
    }
  }
`;

export const statusMap = (intl) => {
  return {
    delivered: {
      title: intl.formatMessage({
        defaultMessage: "Delivered",
      }),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 21"
          width={16}
          height={16}
        >
          <path
            d="M9.6 13.3c-.1 0-.2 0-.3-.1-.1 0-.2-.1-.2-.2l-2.3-2.3c-.1-.1-.1-.2-.2-.2 0-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3 0-.1.1-.2.2-.2.2-.1.3-.2.5-.2s.4.1.5.2l1.8 1.8L13.2 8c.1-.1.2-.1.2-.2.1 0 .2-.1.3-.1.1 0 .2 0 .3.1.1 0 .2.1.2.2.1.1.2.3.2.5s0 .4-.2.5l-4.1 4.1c-.1.1-.2.1-.2.2h-.3Zm.9 7.3c-2 0-4-.6-5.6-1.7-1.7-1.1-3-2.7-3.7-4.5-.8-1.8-1-3.9-.6-5.8.4-2 1.4-3.8 2.8-5.2C4.8 1.9 6.6 1 8.5.6c2-.4 4-.2 5.8.6 1.8.8 3.4 2.1 4.5 3.7 1.1 1.7 1.7 3.6 1.7 5.6 0 2.7-1.1 5.2-3 7.1-1.7 1.9-4.3 3-7 3Zm0-18.7c-1.7 0-3.4.5-4.8 1.4-1.4 1-2.5 2.3-3.2 3.9-.7 1.6-.8 3.3-.5 5 .3 1.7 1.2 3.2 2.4 4.4 1.2 1.2 2.7 2 4.4 2.4 1.7.3 3.4.2 5-.5s2.9-1.8 3.9-3.2c.9-1.4 1.5-3.1 1.5-4.8 0-2.3-.9-4.5-2.5-6.1-1.7-1.6-3.9-2.5-6.2-2.5Z"
            className="st0"
          />
        </svg>
      ),
      defaultMessage: intl.formatMessage({
        defaultMessage:
          "Our transporter delivered your order to your shipping address!",
      }),
    },
    shipped: {
      title: intl.formatMessage({
        defaultMessage: "Shipped",
      }),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={16}
          height={16}
        >
          <path d="M23.2 11.3 20 7.7c-.3-.3-.6-.5-.9-.7-.4-.2-.7-.2-1.1-.2h-2.7v-1c0-.5-.2-.9-.5-1.2-.3-.3-.9-.6-1.3-.6H1.9c-.5 0-1 .2-1.3.5-.3.3-.5.8-.5 1.3v10.5c0 .5.2.9.5 1.2.3.3.8.5 1.3.5h.7c0 .1.1.2.1.3.2.5.6.9 1 1.2.4.3 1 .5 1.5.5.7 0 1.4-.3 1.9-.8.3-.3.6-.7.7-1.2H16.1c0 .1.1.2.1.3.2.5.6.9 1 1.2.5.3 1 .5 1.5.5.4 0 .7-.1 1.1-.2.3-.1.6-.3.9-.6.3-.3.5-.6.6-.9 0-.1.1-.2.1-.3h.7c.2 0 .5 0 .7-.1.2-.1.4-.2.6-.4.2-.2.3-.4.4-.6.1-.2.1-.4.1-.7v-3c0-.7-.3-1.4-.7-1.9ZM1.7 16.4s-.1-.1-.1-.2V5.8c0-.1 0-.1.1-.2s.1-.1.2-.1h11.6c.1 0 .1 0 .2.1s.1.1.1.2v10.8H7.9c-.1-.3-.2-.5-.4-.8-.3-.5-.7-.8-1.2-1-.5-.2-1.1-.3-1.6-.2-.5.1-1 .4-1.4.8-.3.3-.6.7-.7 1.2h-.7l-.2-.2ZM6.3 18c-.1.2-.3.4-.6.5-.3.1-.5.1-.7.1-.2 0-.5-.2-.6-.3-.2-.2-.3-.4-.3-.6 0-.2 0-.5.1-.7.1-.2.3-.4.5-.6 0-.3.3-.4.5-.4.3 0 .7.1.9.4.2.2.4.6.4.9 0 .2-.1.5-.2.7Zm13.4.2c-.2.2-.6.4-.9.4-.3 0-.7-.1-.9-.4-.2-.2-.4-.6-.4-.9 0-.3.1-.7.4-.9.2-.2.6-.4.9-.4.3 0 .7.1.9.4.2.2.4.6.4.9 0 .3-.2.6-.4.9Zm2.6-2c0 .1 0 .1-.1.2 0 0-.1.1-.2.1h-.7c-.1-.3-.2-.5-.4-.8-.3-.5-.7-.8-1.2-1-.5-.2-1.1-.3-1.6-.2-.5.1-1 .4-1.4.8-.3.3-.6.7-.7 1.2h-.9V8.3h2.7c.2 0 .3 0 .5.1s.3.2.4.3l3.2 3.6c.2.2.3.5.3.8v3.1h.1Z" />
        </svg>
      ),
      defaultMessage: intl.formatMessage({
        defaultMessage:
          "Your order is on it’s way and will be with you shortly!",
      }),
    },
    processing: {
      title: intl.formatMessage({
        defaultMessage: "Processing",
      }),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={16}
          height={16}
        >
          <path
            d="M23.6 3.2c0-.1-.1-.2-.2-.2-.1-.2-.3-.3-.5-.3s-.4.1-.5.2l-6.5 6.5L12 5.5c-.2-.2-.4-.3-.6-.4-.2-.1-.4-.1-.7-.1-.2 0-.5 0-.7.1-.2.1-.4.3-.6.4l-6 6-1.8-1.7c-.2-.2-.4-.2-.5-.2-.2 0-.4.1-.5.2-.2.1-.3.3-.3.5s.1.4.2.5l2.4 2.3 2.6 2.6c-.1.1-.1.2-.2.3-.4.7-.5 1.6-.4 2.4.2.8.6 1.6 1.3 2.1.7.5 1.5.8 2.3.8.5 0 1-.1 1.4-.3.4-.2.9-.5 1.2-.8.6-.6 1-1.4 1.1-2.2.1-.8-.1-1.7-.6-2.4l4.7-4.7 3-3 1.8 1.8c.2.2.2.3.3.3.1 0 .2.1.3.1h.3c.1 0 .2-.1.3-.2.1-.1.2-.3.2-.5s-.1-.5-.2-.6L20.4 7l3-3c.1-.1.1-.2.2-.2 0-.1.1-.2.1-.3 0-.1-.1-.2-.1-.3ZM10.4 19c-.3.4-.7.7-1.2.9-.5.1-1 .1-1.5-.1s-.8-.5-1.1-1c-.2-.4-.3-1-.2-1.4.1-.5.4-.9.7-1.3.4-.3.9-.5 1.4-.5.6 0 1.1.2 1.5.6.4.4.6.8.6 1.3.2.6 0 1.1-.2 1.5Zm.2-4.3c-.3-.2-.6-.3-.9-.4-.8-.2-1.7-.2-2.5.1l-.6.3-2.1-2.1 6.1-6c.1-.1.2-.1.3 0l3.9 3.9-4.2 4.2Z"
            className="st0"
          />
        </svg>
      ),
      defaultMessage: intl.formatMessage({
        defaultMessage: "Your order was approved and is now in process…",
      }),
    },
    pending: {
      title: intl.formatMessage({
        defaultMessage: "Order Received",
      }),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 21"
          width={16}
          height={16}
        >
          <path
            d="M10.5 21c-2.1 0-4.1-.6-5.8-1.8C3 18.1 1.6 16.4.9 14.5c-.8-1.9-1-4-.6-6s1.4-3.9 2.8-5.4C4.5 1.6 6.4.6 8.4.2c2-.4 4.1-.2 6 .6 1.9.8 3.5 2.1 4.7 3.8 1.1 1.7 1.8 3.7 1.8 5.8 0 2.8-1.1 5.4-3.1 7.4S13.3 21 10.5 21Zm0-19.4c-1.7 0-3.5.5-4.9 1.5-1.4 1-2.7 2.4-3.3 4-.7 1.6-.9 3.4-.5 5.2.3 1.7 1.2 3.3 2.4 4.6 1.3 1.2 2.9 2.1 4.6 2.4 1.7.3 3.5.2 5.2-.5 1.7-.7 3-1.8 4-3.3 1-1.5 1.5-3.2 1.5-5 0-2.4-.9-4.6-2.6-6.3-1.7-1.7-4-2.6-6.4-2.6Zm3.4 13.1c-.2 0-.4-.1-.5-.2L10 11.1c-.1-.1-.2-.3-.2-.5V6.1c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2s.4.1.5.2c.1.1.2.3.2.5v4.1l3.2 3.2c.1.1.2.3.2.5s-.1.4-.2.5c-.1.1-.3.2-.5.3Z"
            className="st1"
          />
        </svg>
      ),
      defaultMessage: intl.formatMessage({
        defaultMessage:
          "Thank you for your order! It is in queue for processing...",
      }),
    },
    // bonusPoints: {
    //   title: intl.formatMessage({
    //     defaultMessage: "Bonus Points",
    //   }),
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 20 20"
    //       width={16}
    //       height={16}
    //     >
    //       <path d="M19.4 6.1c-.4-.3-.9-.5-1.5-.5h-1.2c.5-.6.8-1.3.8-2s-.2-1.4-.7-2c-.5-.6-1.1-1-1.9-1.3-.8-.3-1.6-.3-2.4-.2-.8.1-1.6.5-2.2 1-.1.1-.3.2-.4.3C9.5.9 8.9.5 8.2.2 7.4 0 6.6-.1 5.8.1 5 .2 4.2.5 3.6 1c-.6.5-1 1.1-1.1 1.8s-.1 1.4.2 2c.2.3.3.5.5.8H2.1c-.6 0-1.1.1-1.5.5-.4.3-.6.7-.6 1.2V10c0 .5.2.9.6 1.2.3.3.8.5 1.3.5v6.5c0 .5.2.9.6 1.2.4.4.9.6 1.5.6h12c.6 0 1.1-.2 1.5-.5.4-.3.6-.8.6-1.2v-6.5c.5 0 1-.2 1.3-.5.4-.3.6-.8.6-1.2V7.3c0-.5-.2-.9-.6-1.2zm-15-3.4c.2-.3.5-.7.9-.9s.9-.3 1.4-.3c.6 0 1.3.2 1.7.6.4.4.7.9.7 1.4 0 .4-.1.8-.4 1.1-.3.4-.7.7-1.1.8-.2.1-.5.1-.7.2h-.7c-.5-.2-.9-.4-1.3-.6-.3-.3-.6-.6-.7-1 0-.5 0-.9.2-1.3zm4.7 15.8H4c-.1 0-.2 0-.2-.1-.1 0-.1-.1-.1-.2v-6.5h5.4v6.8zm0-8.2h-7c-.1 0-.2 0-.2-.1-.1 0-.1-.1-.1-.2V7.3c0-.1 0-.1.1-.2 0 0 .1-.1.2-.1h7v3.3zM11 2.7c.2-.4.5-.7 1-.9.4-.2.9-.3 1.4-.3.6 0 1.2.2 1.7.6.4.4.7.9.7 1.4 0 .4-.1.8-.4 1.1-.4.4-.8.7-1.2.8-.2.1-.5.1-.7.1h-.7c-.5-.1-.9-.3-1.3-.6-.3-.3-.6-.7-.7-1.1 0-.3 0-.7.2-1.1zm5.3 15.6c0 .1 0 .1-.1.2-.1 0-.1.1-.2.1h-5.1v-6.8h5.4v6.5zm1.9-8.3c0 .1 0 .1-.1.2-.1 0-.1.1-.2.1h-7V7h7c.1 0 .2 0 .2.1.1 0 .1.1.1.2V10z" />
    //     </svg>
    //   ),
    // },
    // reinstatementPoints: {
    //   title: intl.formatMessage({
    //     defaultMessage: "Points Refunded",
    //   }),
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 23 22"
    //       width={16}
    //       height={16}
    //     >
    //       <path
    //         d="M5.8 21.5c-.4 0-.7-.1-1-.3-.3-.2-.5-.5-.6-.8-.1-.3-.2-.6-.1-1l.8-4.8c0-.2 0-.4-.1-.6-.1-.2-.2-.4-.4-.5L1 10.2c-.2-.3-.4-.6-.5-.9-.1-.3-.1-.7 0-1 .2-.3.4-.5.6-.8.3-.2.6-.3.9-.3l4.8-.7c.2 0 .4-.1.6-.2.2-.1.3-.3.4-.5L10 1.5c.1-.3.4-.5.6-.7.3-.2.6-.3.9-.3.3 0 .6.1.9.3s.5.4.6.7l2.2 4.4c.1.2.2.3.4.5.2.1.4.2.6.2l4.8.6c.3 0 .6.2.9.4.2.2.4.5.5.8.1.3.1.6 0 1-.1.3-.2.6-.5.8l-3.5 3.4c-.1.1-.3.3-.3.5-.1.2-.1.4 0 .6l.8 4.8c.1.3 0 .7-.1 1-.1.3-.3.6-.6.8-.3.2-.6.3-.9.3-.3 0-.7 0-.9-.2l-4.3-2.3c-.2-.1-.4-.1-.6-.1-.2 0-.4 0-.6.1l-4.3 2.3c-.3.1-.5.1-.8.1zm5.7-19.6h-.1l-.1.1-2.2 4.4c-.2.4-.5.8-.8 1-.4.3-.8.5-1.3.6l-4.8.7s-.1 0-.1.1c-.1-.1-.1 0-.1 0v.1s0 .1.1.1l3.5 3.4c.3.3.6.7.7 1.1.1.4.2.9.1 1.3l-.9 4.8v.1s0 .1.1.1h.2l4.3-2.3c.4-.2.8-.3 1.3-.3s.9.1 1.3.3l4.3 2.3h.2l.1-.1v-.1l-.8-4.8c-.1-.4 0-.9.1-1.3.2-.4.4-.8.8-1.1L21 9.1s0-.1.1-.1v-.1s0-.1-.1-.1c0 0-.1 0-.1-.1L16 8c-.5-.1-.9-.2-1.3-.5s-.7-.6-.9-1l-2.2-4.4-.1-.1c.1-.1 0-.1 0-.1z"
    //         className="st0"
    //       />
    //     </svg>
    //   ),
    // },
    canceled: {
      title: intl.formatMessage({
        defaultMessage: "Canceled",
      }),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 21"
          width={16}
          height={16}
        >
          <path d="M20.6 10.4c0 2.6-1.1 5.2-3 7.1-1.9 1.9-4.4 3-7.1 3-2 0-3.9-.6-5.6-1.7-1.6-1.1-2.9-2.7-3.7-4.5-.8-1.8-1-3.8-.6-5.8.4-1.9 1.3-3.7 2.7-5.2C4.8 1.9 6.6 1 8.6.6c1.9-.4 4-.2 5.8.6 1.8.7 3.4 2 4.5 3.7 1.1 1.6 1.7 3.6 1.7 5.5Zm-3 4.9c1-1.4 1.5-3.1 1.5-4.8 0-2.3-.9-4.5-2.5-6.1-1.6-1.6-3.8-2.5-6.1-2.5-1.7 0-3.4.4-4.8 1.4-1.4 1-2.5 2.3-3.2 3.9-.7 1.6-.8 3.3-.5 5 .4 1.7 1.2 3.2 2.4 4.4 1.2 1.2 2.7 2.1 4.4 2.4 1.7.3 3.4.2 5-.5s2.9-1.8 3.8-3.2Zm-4.1-6.8-2 2 2 2c.1 0 .1.1.2.2s.1.2.1.3c0 .1 0 .3-.1.3s-.1.1-.2.2c0 .1-.1.2-.2.2-.1.1-.2.1-.3.1-.1 0-.2-.1-.3-.1 0-.1-.1-.1-.2-.2l-2-2-2 2c0 .1-.1.2-.2.2-.1.1-.2.1-.3.1-.1 0-.2-.1-.3-.1 0-.1-.1-.1-.2-.2s-.2-.3-.2-.5.1-.4.2-.5l2-2-2-2c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5c.1-.1.3-.2.5-.2s.4 0 .5.2l2 2 2-2c.1-.1.3-.2.5-.2s.4.1.5.2c.1 0 .2.1.2.2.1.1.1.2.1.3 0 .1-.1.2-.1.3 0 .1-.1.2-.2.2Z" />
        </svg>
      ),
    },
  };
};
