import styled from "styled-components";

import cardBgDarkSrc from "../../../assets/images/pattern-purple-bright-dark@2x.png";
import cardBgLightSrc from "../../../assets/images/pattern-purple-light@2x.png";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 1023px) {
    border-bottom: 1px solid #b0abc8;
  }

  @media (min-width: 1024px) {
    padding: 0 32px;
  }
`;

export const Video = styled.video`
  width: 100%;
  object-fit: cover;
  object-position: center;

  @media (max-width: 1023px) {
    &.--desktop {
      display: none;
    }
  }

  @media (max-width: 1023px) and (orientation: portrait) {
    max-height: 500px;

    &.--landscape {
      display: none;
    }
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    max-height: 100vh;

    &.--portrait {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;

    &.--mobile {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1144px;

  @media (min-width: 1024px) {
    min-height: 550px;
  }
`;

export const Card = styled.div`
  background-repeat: repeat-x;
  background-position-y: top;

  @media (max-width: 1023px) {
    width: 100%;
    min-height: 383px;
    padding: 40px 32px;
    color: ${(p) => (p.$variantMobile === "dark" ? "#fff" : "#000")};
    background-image: ${(p) =>
      p.$variantMobile === "dark"
        ? `url(${cardBgDarkSrc})`
        : `url(${cardBgLightSrc})`};
    background-color: ${(p) =>
      p.$variantMobile === "dark" ? `#27166A` : `#ffffff`};
    background-size: 138px auto;
  }

  @media (min-width: 1024px) {
    width: 435px;
    min-height: 453px;
    padding: 64px 56px;
    color: ${(p) => (p.$variantDesktop === "dark" ? "#fff" : "#000")};
    background-image: ${(p) =>
      p.$variantDesktop === "dark"
        ? `url(${cardBgDarkSrc})`
        : `url(${cardBgLightSrc})`};
    background-color: ${(p) =>
      p.$variantDesktop === "dark" ? `#27166A` : `#ffffff`};
    background-size: 120px auto;
    border: 1px solid #dbdfea;
  }
`;

export const CardContainer = styled.div`
  @media (max-width: 1023px) {
    max-width: 311px;
  }
`;
