import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 1023px) {
      height: 248px;
    }

    @media (min-width: 1024px) {
      height: 735px;
    }
  }
`;

export const Content = styled.div`
  @media (max-width: 1023px) {
    padding: 40px 24px 24px;
    background-color: #f9f9f9;
  }

  @media (min-width: 1024px) {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 595px;
    padding: 32px 48px 16px;
    background-color: #fff;
  }
`;

export const Desc = styled.div`
  @media (max-width: 1023px) {
    margin-top: 24px;
  }

  @media (min-width: 1024px) {
    margin-top: 16px;
  }
`;

export const Cta = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: auto;
  color: var(--color-accent);
  font-size: 24px;
`;
