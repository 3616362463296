import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  padding: ${(p) => !p.$fullScreen && "16px"};
  z-index: 8000;
`;

export const Overlay = styled(motion.div)`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const DialogContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(p) =>
    p.$fullScreen &&
    css`
      width: 100%;
      height: 100%;
    `}
`;
