import React from "react";
import PropTypes from "prop-types";

import { Root } from "./List.style";

export const List = ({ as = "ul", children, ...props }) => {
  return (
    <Root as={as} {...props}>
      {children}
    </Root>
  );
};

List.propTypes = {
  as: PropTypes.oneOf(["ul", "ol"]),
};
