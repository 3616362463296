import React from "react";
import { FormattedMessage } from "react-intl";

import { Container, Root, Subtitle } from "./Social.style";

import { Typography } from "../../../ui/Typography/Typography";
import { Icon } from "../../../ui/Icon/Icon";
import { Button } from "../../../ui/Button/Button";

export const Social = ({
  title = "",
  withBackground = false,
  followUrl = "https://instagram.com/clubhrewards",
  icon = "",
  children,
}) => {
  return (
    <Root $withBackground={withBackground}>
      <Subtitle>
        <Typography as="h3" type="h3">
          @clubhrewards
        </Typography>
        <Icon icon="certificate" color="#3e95ef" />
      </Subtitle>

      <Typography as="h2" type="h2">
        {title}
      </Typography>

      <Container>{children}</Container>

      <Button as="a" arrow={false} href={followUrl} target="_blank">
        <Icon
          icon={icon}
          style={{ fontSize: 19, marginRight: 6, marginLeft: -8 }}
        />
        <FormattedMessage defaultMessage="follow us" tagName="span" />
      </Button>
    </Root>
  );
};
