import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  padding-bottom: 22px;
`;

export const Label = styled.label`
  position: absolute;
  top: 24px;
  left: 16px;
  color: #8f95a5;
  font-size: 14px;
  letter-spacing: 0.14px;
  pointer-events: none;
  transition: all 150ms;
  transform: translateY(-50%);
`;

export const Error = styled.span`
  color: #cf2b23;
  font-size: 12px;
`;

export const Input = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  padding-right: ${(p) => (p.type === "password" ? "48px" : "16px")};
  color: #000;
  background-color: transparent;
  font-size: 14px;
  letter-spacing: 0.14px;
  border-radius: 5px;
  border: solid 1px #dcdfe9;
  transition: all 150ms;
  white-space: nowrap;
  outline: none;
  appearance: none;

  &:focus {
    border-color: #cf2b23;
  }

  &:focus,
  &:-webkit-autofill,
  &:not(:placeholder-shown) {
    ~ ${Label} {
      top: 0;
      padding: 0 4px;
      /* color: var(--color-accent); */
      background-color: #fff;
      font-size: 10px;
      font-weight: 500;
    }
  }

  &[disabled] {
    opacity: 0.6;
    cursor: auto;
  }

  &[readonly] {
    cursor: default;
    pointer-events: none;
  }

  &::placeholder {
    color: transparent;
  }

  /*** iPhone and iOS Form Input Zoom Fixes ***/
  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    font-size: 16px;
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    font-size: 16px;
  }
`;
