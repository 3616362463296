import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useFormState } from "react-hook-form";
import { get } from "lodash";
import { Root, SelectStyled, Label, Arrow, Error } from "./Select.style";

import { Icon } from "../../ui/Icon/Icon";

export const Select = forwardRef(
  ({ control, options, label = null, error, ...props }, ref) => {
    const { dirtyFields } = useFormState({ control });
    const isDirty = get(dirtyFields, props.name); //loadash required for fieldarray names, i.e., distributor.0.name

    return (
      <Root>
        <SelectStyled ref={ref} {...props}>
          {label && <option hidden />}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </SelectStyled>
        {label && (
          <Label $isDirty={isDirty || props.value !== ""}>{label}</Label>
        )}
        <Arrow>
          <Icon icon="chevron-down" />
        </Arrow>
        {error && <Error>{error?.message}</Error>}
      </Root>
    );
  }
);

Select.propTypes = {
  control: PropTypes.object.isRequired, // it is necessary to get the dirty state for the popup label. to work, unlike the input, we cannot do this through css
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};
