import fetch from "isomorphic-fetch";
import AppError from "./error";
import Cookies from "js-cookie";
import { getJwt } from "./auth";
import { apiDomain } from "../config";

const apiFetch = ({
  path,
  data,
  headers,
  timeout = 20000,
  method = "post",
  credentials = "include",
  ...options
}) => {
  const accessToken = getJwt();
  const requestHeaders = {
    "Content-Type": "application/json",
    ...(accessToken != null ? { authorization: `Bearer ${accessToken}` } : {}),
    ...(headers || {}),
  };
  const url = `https://${apiDomain}${path}`;
  const body = JSON.stringify(data || {});

  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort("Request timed out.");
  }, timeout);

  return fetch(url, {
    method,
    credentials,
    headers: requestHeaders,
    signal: controller.signal,
    ...(method === "post" ? { body } : {}),
    ...options,
  })
    .then(async (response) => {
      const result =
        typeof response.json === "function"
          ? await response.json()
          : await response.text();
      if (response.ok) {
        return { result, response };
      }
      const { message, code, type, data: errorData } = result;
      throw new AppError(
        message || "An unknown API error occurred.",
        code || 500,
        type || "UNKNOWN_API_ERROR",
        errorData
      );
    })
    .catch((err) => {
      console.info({ err, type: "apiFetch" });
      throw err;
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });
};

export { apiFetch };
