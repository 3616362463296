import React from "react";
import { FormattedMessage } from "react-intl";
import truncate from "truncate-html";
import {
  Root,
  Image,
  BrandName,
  Title,
  Desc,
  VideoContainer,
} from "./ContentItem.style";

import { Link } from "../Link/Link";
import { Typography } from "../../ui/Typography/Typography";

export const ContentItem = ({
  imageSrc,
  videoSrc,
  brandName,
  brandUrl,
  title,
  desc,
  descLimit = 150,
  url,
  variant,
}) => {
  const youtubeIdRegex =
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  const videoId = videoSrc != null ? youtubeIdRegex.exec(videoSrc)?.[3] : null;

  return (
    <Root className={variant && `--${variant}`}>
      {videoId != null ? (
        <VideoContainer>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=0`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </VideoContainer>
      ) : (
        <Link to={url}>
          <Image src={imageSrc} alt={title} />
        </Link>
      )}

      <BrandName as={Link} to={brandUrl}>
        {brandName}
      </BrandName>

      <Link to={url}>
        <Title className={variant && `--${variant}`}>{title}</Title>

        <Desc className={variant && `--${variant}`}>
          <Typography as="p" type="text-primary">
            <em
              dangerouslySetInnerHTML={{
                __html: truncate(desc, descLimit, { stripTags: true }),
              }}
            />{" "}
            <FormattedMessage defaultMessage="read more" tagName="span" />
          </Typography>
        </Desc>
      </Link>
    </Root>
  );
};
