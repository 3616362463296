import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-primary);

  @media (max-width: 1023px) {
    padding: 64px 32px;
  }

  @media (min-width: 1024px) {
    align-items: center;
    padding: 80px 48px;
  }
`;

export const Title = styled.div`
  margin-bottom: 70px;
  color: #fff;

  @media (max-width: 1023px) {
    max-width: 380px;
  }

  @media (min-width: 1024px) {
    max-width: 400px;
    text-align: center;
  }
`;

export const Container = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 8px;
    row-gap: 48px;
    max-width: 928px;
  }

  @media (min-width: 1400px) {
    max-width: 1144px;
  }
`;

export const Item = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    padding-left: 56px;

    & + & {
      margin-top: 40px;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-accent);

  @media (max-width: 1023px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    font-size: 32px;
  }

  @media (min-width: 1024px) {
    width: 40px;
    height: 40px;
    font-size: 40px;
  }
`;

export const ItemTitle = styled.div`
  color: #fff;
  text-transform: capitalize;

  @media (max-width: 1023px) {
    margin-bottom: 4px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    margin: 16px 0;
    text-align: center;
  }
`;

export const DescContainer = styled.div`
  color: var(--color-secondary);

  @media (max-width: 1023px) {
    text-align: left;
  }

  @media (min-width: 1024px) {
    max-width: 300px;
    text-align: center;
  }

  @media (min-width: 1400px) {
    max-width: 278px;
  }
`;
