import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 376px;
`;

export const NoResultsText = styled.div`
  margin-top: 24px;
  color: var(--color-text-2);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.13px;
  color: #7f8696;
  text-align: center;
`;

export const ClearAll = styled.button`
  margin-top: 8px;
  color: var(--color-accent);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-align: center;
  text-decoration: underline;
`;
