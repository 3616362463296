import styled from "styled-components";

export const Root = styled.div``;

export const Container = styled.div`
  flex-grow: 1;

  @media (max-width: 1023px) {
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 512px;
  }

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 528px;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    padding-bottom: 104px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 120px;
  }
`;

export const Form = styled.form`
  width: 100%;

  @media (max-width: 1023px) {
    padding-top: 48px;
  }

  @media (max-width: 1399px) {
    max-width: 336px;
  }

  @media (min-width: 1024px) {
    padding-top: 40px;
  }

  @media (min-width: 1400px) {
    max-width: 688px;
  }
`;

export const Fields = styled.div`
  width: 100%;

  @media (min-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 48px 0;
  background-color: #dcdfe9;
`;

export const Cart = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #f9f9f9;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;

    &.--desktop {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    padding: 88px;

    &.--mobile {
      display: none;
    }
  }

  @media (min-width: 1400px) {
    padding: 88px 96px;
  }
`;

export const CartWrapper = styled.div`
  width: 100%;
  max-width: 336px;
`;

export const CartToggler = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  color: ${(p) => (p.$isActive ? "var(--color-accent)" : "#8f95a5")};
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
  }

  @media (max-width: 1399px) {
    flex-direction: column-reverse;
    row-gap: 24px;
  }

  @media (max-width: 1599px) {
    margin-top: 32px;
  }

  @media (min-width: 1400px) {
    justify-content: space-between;
  }

  @media (min-width: 1600px) {
    margin-top: 40px;
  }
`;

export const SubmitButtonContainer = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 336px;
  }

  @media (min-width: 1400px) {
    max-width: 203px;
  }

  @media (min-width: 1600px) {
    max-width: 250px;
  }
`;

export const BackToCart = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  height: 29px;
  padding: 0 8px;
  color: #8f95a5;
`;

export const ShippingContainer = styled.div`
  @media (min-width: 1600px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ShippingCost = styled.div`
  @media (max-width: 1599px) {
    margin-top: 16px;
    margin-left: 32px;
  }
`;
