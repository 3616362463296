import React from "react";

import { Root, Container, Grid } from "./ProductsGrid.style";

import { NoResultsContainer } from "../NoResultsContainer/NoResultsContainer";
import { Loading } from "../Loading/Loading";

export const ProductsGrid = ({
  loading,
  totalCount = 0,
  onResetFilters = null,
  children,
}) => {
  return (
    <Root>
      {loading ? (
        <Loading global={false} />
      ) : (
        <Container>
          <Grid>
            {totalCount < 1 ? (
              <NoResultsContainer onResetFilters={onResetFilters} />
            ) : (
              children
            )}
          </Grid>
        </Container>
      )}
    </Root>
  );
};
