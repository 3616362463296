import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 48px;
  height: 64px;
  margin-right: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Qty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 16px;
  height: 16px;
  color: #fff;
  background-color: var(--color-accent);
  font-size: 10px;
  font-weight: 700;
  border-radius: 8px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Body = styled.div`
  flex-grow: 1;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Subtotal = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    column-gap: 4px;
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;
