import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import {
  Hero,
  HeroImageContainer,
  HeroContent,
  HeroLogoContainer,
  MenuContainer,
  BrandSection,
  SocialContainer,
} from "./BrandView.style";

import { brands } from "../../config/brands";
import { apiDomain, apiSiteId } from "../../config";

import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading/Loading";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Button } from "../../ui/Button/Button";
import { Link } from "../../components/Link/Link";
import { Typography } from "../../ui/Typography/Typography";
import { RewardsCarousel } from "../../components/Sections/RewardsCarousel/RewardsCarousel";
import { Promo } from "../../components/Sections/Promo/Promo";
import { NotFoundView } from "../../views/NotFoundView/NotFoundView";
import { ProposalSlider } from "../../components/Sections/ProposalSlider/ProposalSlider";
import { Exclusive } from "../../components/Sections/Exclusive/Exclusive";
import { Community } from "../../components/Sections/Community/Community";
import { WeAreClub } from "../../components/Sections/WeAreClub/WeAreClub";
import { Footer } from "../../components/Footer/Footer";
import { GET_BRAND } from "./brandViewConfig";

export const BrandView = () => {
  const intl = useIntl();
  const locale = intl.locale;
  const { brandSlug } = useParams();
  const brand = brands.find((brand) => brand.slug === brandSlug);

  const {
    loading: brandLoading,
    error: brandError,
    data: brandData,
  } = useQuery(GET_BRAND, {
    variables: {
      id: brand?.id,
      locale,
    },
    skip: brand == null,
  });

  if (brandLoading) {
    return <Loading />;
  }

  if (brand == null) {
    return <NotFoundView />;
  }

  const data = brandData?.brand?.findById;

  const brandImgSrc =
    data?.image?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.image?.media?._id}?type=fill&width=2800&height=1300`
      : "https://picsum.photos/id/77/2800/1300";
  const brandImgMobileSrc =
    data?.imageMobile?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.imageMobile?.media?._id}?type=fill&width=2800&height=1300`
      : null;

  const block1ImgSrc =
    data?.block_1?.image?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.block_1?.image?.media?._id}?type=fill&width=2800&height=1300`
      : "https://picsum.photos/id/58/1862/938";
  const block1ImgMobileSrc =
    data?.block_1?.imageMobile?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.block_1?.imageMobile?.media?._id}?type=fill&width=2800&height=1300`
      : null;

  const block2ImgSrc =
    data?.block_2?.image?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.block_2?.image?.media?._id}?type=fill&width=1300&height=1300`
      : "https://picsum.photos/id/213/1000/1000";
  const block2ImgMobileSrc =
    data?.block_2?.imageMobile?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.block_2?.imageMobile?.media?._id}?type=fill&width=2800&height=1300`
      : null;

  return (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          {
            to: null,
            label: brand.label,
          },
        ]}
      />

      <Hero>
        <HeroImageContainer>
          {
            <picture>
              {brandImgMobileSrc && (
                <source
                  media="(max-width: 1023px)"
                  srcSet={brandImgMobileSrc}
                />
              )}
              <img src={brandImgSrc} alt={brand.label} />
            </picture>
          }
        </HeroImageContainer>
        <HeroContent>
          <HeroLogoContainer>{brand.icon}</HeroLogoContainer>
        </HeroContent>
      </Hero>

      <MenuContainer>
        <Button
          as={Link}
          to={`/rewards?filters=(brands:!('${brand.id}'))`}
          variant="action"
          style={{ width: "fit-content" }}
        >
          <FormattedMessage defaultMessage="shop rewards" />
        </Button>
        <Button
          as={Link}
          to={`/experience?filters=(categories:!(education))`}
          variant="action"
          style={{ width: "fit-content" }}
        >
          <FormattedMessage defaultMessage="education" />
        </Button>
        <Button
          as={Link}
          to={`/experience?filters=(brands:!('${brand.id}'))`}
          variant="action"
          style={{ width: "fit-content" }}
        >
          <FormattedMessage defaultMessage="experiences" />
        </Button>
      </MenuContainer>

      <BrandSection>
        <Typography type="h2">
          <FormattedMessage
            defaultMessage="Discover {brandName}"
            values={{ brandName: brand.label }}
          />
        </Typography>
        <Typography
          as="p"
          type="text-primary"
          style={{ maxWidth: 500, marginTop: 24 }}
          dangerouslySetInnerHTML={{ __html: data?.desc }}
        />

        <SocialContainer>
          {data?.links?.website && (
            <a href={data?.links?.website} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="M8 0C1.842 0-2.007 6.667 1.072 12c3.079 5.333 10.777 5.333 13.856 0A8.002 8.002 0 0 0 16 8a8.01 8.01 0 0 0-8-8Zm6.857 8a6.828 6.828 0 0 1-.4 2.286h-2.619c.108-.757.163-1.521.162-2.286a16.039 16.039 0 0 0-.162-2.286h2.619c.263.733.398 1.507.4 2.286ZM8 14.858c-.835 0-1.728-1.078-2.29-2.853l.575-.575h3.429l.576.57c-.562 1.78-1.455 2.858-2.29 2.858Zm-2.684-4.572a15.162 15.162 0 0 1 0-4.571h5.367c.117.756.175 1.52.174 2.285.001.765-.057 1.529-.174 2.286H5.316Zm-3.774 0a6.733 6.733 0 0 1 0-4.571h2.62a16.203 16.203 0 0 0 0 4.571h-2.62ZM8 1.143c.835 0 1.728 1.078 2.29 2.853l-.575.575H6.286L5.71 4C6.272 2.221 7.165 1.143 8 1.143Zm5.625 2.947-.482.482h-1.52a9.103 9.103 0 0 0-1.209-2.982 6.878 6.878 0 0 1 3.211 2.5Zm-8.038-2.5a9.103 9.103 0 0 0-1.209 2.982H2.857l-.482-.482a6.88 6.88 0 0 1 3.212-2.5ZM2.375 11.911l.482-.482h1.52a9.111 9.111 0 0 0 1.209 2.982 6.885 6.885 0 0 1-3.211-2.5Zm8.039 2.5a9.111 9.111 0 0 0 1.209-2.982h1.52l.482.482a6.885 6.885 0 0 1-3.211 2.5Z"
                />
              </svg>
            </a>
          )}
          {data?.links?.facebook && (
            <a href={data?.links?.facebook} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="m11.723 9.005.444-2.9H9.389V4.23a1.448 1.448 0 0 1 1.633-1.564h1.263V.205a15.462 15.462 0 0 0-2.242-.2 3.535 3.535 0 0 0-3.784 3.9v2.2H3.715v2.9h2.544v7h3.13v-7h2.334Z"
                />
              </svg>
            </a>
          )}
          {data?.links?.instagram && (
            <a href={data?.links?.instagram} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="M8.006 3.898c-3.156 0-5.129 3.417-3.551 6.15 1.578 2.734 5.523 2.734 7.101 0a4.095 4.095 0 0 0-3.55-6.15Zm0 6.766c-2.053 0-3.335-2.221-2.309-3.999 1.026-1.777 3.591-1.777 4.618 0a2.668 2.668 0 0 1-2.309 3.999Zm5.22-6.934a.956.956 0 1 1-1.912 0 .956.956 0 0 1 1.912 0Zm2.716.971A4.736 4.736 0 0 0 14.65 1.35 4.766 4.766 0 0 0 11.299.058c-1.321-.075-5.278-.075-6.6 0a4.76 4.76 0 0 0-3.35 1.288A4.749 4.749 0 0 0 .057 4.698c-.075 1.321-.075 5.278 0 6.6a4.728 4.728 0 0 0 1.292 3.351A4.766 4.766 0 0 0 4.7 15.941c1.321.075 5.278.075 6.6 0a4.727 4.727 0 0 0 3.351-1.292 4.764 4.764 0 0 0 1.292-3.351c.075-1.321.075-5.275 0-6.6l-.001.003Zm-1.706 8.012a2.699 2.699 0 0 1-1.52 1.52c-1.053.418-3.551.321-4.715.321-1.164 0-3.665.093-4.715-.321a2.696 2.696 0 0 1-1.52-1.52c-.418-1.053-.321-3.551-.321-4.715 0-1.164-.093-3.664.321-4.714a2.7 2.7 0 0 1 1.521-1.52c1.053-.418 3.551-.321 4.719-.321 1.168 0 3.661-.093 4.71.321a2.705 2.705 0 0 1 1.52 1.52c.418 1.053.321 3.551.321 4.715 0 1.164.097 3.665-.321 4.714Z"
                />
              </svg>
            </a>
          )}
          {data?.links?.youtube && (
            <a href={data?.links?.youtube} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="M15.666 4.135a2.011 2.011 0 0 0-1.416-1.423A47.649 47.649 0 0 0 8 2.375a47.63 47.63 0 0 0-6.25.337A2.01 2.01 0 0 0 .334 4.135 20.993 20.993 0 0 0 0 8.011c-.009 1.3.103 2.597.334 3.876a1.978 1.978 0 0 0 1.415 1.4c2.074.25 4.162.363 6.251.337a47.311 47.311 0 0 0 6.251-.337 1.98 1.98 0 0 0 1.415-1.4A21.16 21.16 0 0 0 16 8.011a21.168 21.168 0 0 0-.334-3.876Zm-9.3 6.255V5.632l4.184 2.379-4.186 2.379h.002Z"
                />
              </svg>
            </a>
          )}
          {data?.links?.tiktok && (
            <a href={data?.links?.tiktok} target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="M8.661.012c.873-.013 1.74-.006 2.607-.013a4.151 4.151 0 0 0 1.167 2.78 4.697 4.697 0 0 0 2.827 1.193v2.687a7.13 7.13 0 0 1-2.8-.647 8.2 8.2 0 0 1-1.08-.62c-.007 1.947.007 3.893-.013 5.833a5.098 5.098 0 0 1-.9 2.627 4.968 4.968 0 0 1-3.94 2.14 4.864 4.864 0 0 1-2.72-.687 5.026 5.026 0 0 1-2.434-3.806c-.013-.333-.02-.667-.007-.993a5.019 5.019 0 0 1 5.824-4.454c.013.987-.027 1.973-.027 2.96a2.287 2.287 0 0 0-2.92 1.413 2.637 2.637 0 0 0-.097 1.074 2.268 2.268 0 0 0 2.333 1.913 2.241 2.241 0 0 0 1.847-1.073c.148-.209.242-.452.273-.707.067-1.193.04-2.38.047-3.573.007-2.687-.007-5.367.013-8.047Z"
                />
              </svg>
            </a>
          )}
        </SocialContainer>
      </BrandSection>

      {brand && (
        <RewardsCarousel
          title={intl.formatMessage(
            { defaultMessage: "{brandLabel} Trending Rewards" },
            { brandLabel: brand.label }
          )}
          desc={intl.formatMessage({
            defaultMessage:
              "Discover the world of Henkel Beauty Care products and the variety of brands that earn you points.",
          })}
          rewardsPath={`/rewards?filters=(brands:!('${brand.id}'))`}
          filters={`{ "type": ["reward"], "brands" : "${brand?.id}"}`}
        />
      )}
      <Promo
        image={
          <picture>
            {block1ImgMobileSrc && (
              <source media="(max-width: 1023px)" srcSet={block1ImgMobileSrc} />
            )}
            <img src={block1ImgSrc} alt={brand.label} />
          </picture>
        }
        title={data?.block_1?.title}
        desc={data?.block_1?.desc}
        ctaUrl={data?.block_1?.ctaUrl}
      />
      {brand && (
        <ProposalSlider
          variant="pattern-dark"
          imageSrc="https://picsum.photos/id/58/1862/938"
          title={intl.formatMessage(
            { defaultMessage: "{brandLabel} Experiences" },
            { brandLabel: brand.label }
          )}
          ctaText={intl.formatMessage({
            defaultMessage: "all articles",
          })}
          ctaUrl={`/experience?filters=(brands:!('${brand.id}'))`}
          filters={`{ "type": ["news"], "brands" : "${brand?.id}"}`}
        />
      )}
      <Exclusive
        image={
          <picture>
            {block2ImgMobileSrc && (
              <source media="(max-width: 1023px)" srcSet={block2ImgMobileSrc} />
            )}
            <img src={block2ImgSrc} alt={brand.label} />
          </picture>
        }
        title={data?.block_2?.title}
        desc={data?.block_2?.desc}
        ctaText={data?.block_2?.ctaLabel}
        ctaUrl={data?.block_2?.ctaUrl}
      />
      {/* <ProposalSlider
        variant="pattern-dark"
        title={intl.formatMessage(
          { defaultMessage: "{brandLabel} Promotions" },
          { brandLabel: brand.label }
        )}
        desc={intl.formatMessage({
          defaultMessage:
            "Discover the world of Henkel Beauty Care products and the variety of brands that earn you points.",
        })}
        ctaText={intl.formatMessage({
          defaultMessage: "all articles",
        })}
        ctaUrl={`/experience?filters=(brands:!('${brand.id}'))`}
      /> */}
      <Community />
      <WeAreClub />
      <Footer />
    </Layout>
  );
};
