import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import {
  isEmpty,
  // , set
} from "lodash";
import {
  // useNavigate,
  useOutletContext,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { MaskedInput } from "../../ui/MaskedInput/MaskedInput";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Form,
  // DistributorList,
  // DistributorItem,
  // DistributorHeader,
  // DistributorTitle,
  // DistributorTooltip,
  // DistributorRemoveButton,
} from "./MyAccount.style";
import {
  toast,
  ToastNotification,
} from "../../ui/ToastNotification/ToastNotification";
import { TextInput } from "../../ui/TextInput/TextInput";
import { Select } from "../../ui/Select/Select";
import { Checkbox } from "../../ui/Checkbox/Checkbox";
import { Button } from "../../ui/Button/Button";
// import { ButtonLink } from "../../ui/ButtonLink/ButtonLink";
// import { Icon } from "../../ui/Icon/Icon";
// import {
//   Tooltip,
//   TooltipTrigger,
//   TooltipContent,
// } from "../../ui/Tooltip/Tooltip";
import { AvatarUploader } from "../AvatarUploader/AvatarUploader";
import { PROFILE_UPDATE } from "./config";
import { Typography } from "../../ui/Typography/Typography";
import { ModalRoot } from "../Modals/ModalRoot/ModalRoot";
import { ModalBonusPointsEarned } from "../Modals/ModalBonusPointsEarned/ModalBonusPointsEarned";
import { getUserAvatar } from "../../utils/user";
// import {
//   apiSiteId,
//   isMasterEnv,
//   frontendDomain,
//   distributors,
// } from "../../config";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object({
  avatar: yup
    .object({
      media: yup.string(),
      focalSelector: yup.string(),
    })
    .nullable(),
  firstname: yup
    .string()
    .required()
    .label("first name")
    .transform((value) => (value === "" ? undefined : value))
    .default(""),
  lastname: yup
    .string()
    .required()
    .label("last name")
    .transform((value) => (value === "" ? undefined : value))
    .default(""),
  email: yup
    .string()
    .email()
    .lowercase()
    .trim()
    .required()
    .transform((value) => (value === "" ? undefined : value))
    .default(""),
  updatePassword: yup
    .string()
    .min(8)
    .max(24)
    .label("new password")
    .transform((value) => (value === "" ? undefined : value)),
  phone: yup
    .string()
    .matches(
      phoneRegExp,
      "Phone number is not valid.  Must have format 555-555-5555"
    )
    .min(10)
    .required()
    .label("mobile number")
    .default(""),
  smsOptIn: yup
    .boolean()
    .label("sms opt-in")
    .default(false)
    .test({
      name: "is-mobile-valid",
      exclusive: false,
      message: "mobile phone must be provided & valid",
      test: function (value, context) {
        return !value || this.parent?.phone?.length >= 10;
      },
    }),
  data: yup.object({
    profession: yup.string().required().label("profession").default(""),
    promotions: yup.boolean().label("promotions opt-in").default(false),
  }),

  // distributor: yup
  //   .array()
  //   .min(1)
  //   .max(3)
  //   .of(
  //     yup.object({
  //       _id: yup.string().required("Distributor is a required field"),
  //       accountNumber: yup
  //         .string()
  //         .required("Distributor account number is a required field")
  //         .label("account number"),
  //     })
  //   ),
});

export const MyProfile = ({ data }) => {
  const outletContext = useOutletContext();
  const userData = outletContext?.me?.user || data?.me?.user;
  const intl = useIntl();
  // const navigate = useNavigate();
  // const [statusMessage, setStatusMessage] = useState();
  const [modalBonusPointsEarned, setModalBonusPointsEarned] = useState(null);
  const [updateProfile] = useMutation(PROFILE_UPDATE, {
    refetchQueries: ["GetDashboardData", "GetHeaderCartData"],
  });

  const {
    control,
    handleSubmit,
    watch,
    // setValue,
    reset,
    trigger,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: schema.default(),
  });

  const formValue = watch();
  // console.info({ isDirty, formValue });

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "distributor",
  // });

  useEffect(() => {
    if (userData) {
      const { avatar, firstname, lastname, phone, data, email, smsOptIn } =
        userData;
      const { profession, distributorSignup, promotions } = data;

      reset({
        avatar: { media: avatar?.media?._id },
        firstname: firstname ?? "",
        lastname: lastname ?? "",
        phone: phone ?? "",
        data: {
          profession: profession ?? "",
          promotions: promotions ?? false,
        },
        email: email ?? "",
        smsOptIn: smsOptIn ? true : false,
      });
      // setValue("distributor", distributorSignup);
    }
  }, [userData?._id, userData?.dm]);

  const onSubmit = (data) => {
    // console.log(data);
    return updateProfile({
      variables: {
        data,
      },
    })
      .then((result) => {
        toast.success(
          <ToastNotification>
            <FormattedMessage defaultMessage="Your account profile successfully updated!" />
          </ToastNotification>
        );
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        const bonusPoints = result?.data?.updateMyProfile?.bonusPoints?.[0];
        if (bonusPoints) {
          setModalBonusPointsEarned({
            pts: bonusPoints?.points,
            bonusItemName: bonusPoints?.note,
            pointsBalance: result?.data?.updateMyProfile?.pointsBalance ?? 0,
            firstName: userData?.firstname,
            avatarSrc: getUserAvatar(userData),
          });
        }
      })
      .catch((err) => {
        toast.error(
          <ToastNotification>
            <FormattedMessage defaultMessage="An error occurred while updating account profile.  Please refresh and try again." />
          </ToastNotification>
        );
        Sentry.captureException(err);
        console.error({ err, type: "my-profile" });
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography type="h2" style={{ textAlign: "center" }}>
        <FormattedMessage defaultMessage="My Account" />
      </Typography>

      <AvatarUploader
        control={control}
        style={{ marginBottom: 48 }}
        showAcceptedFilesInfo
      />

      <Controller
        name="firstname"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "First Name",
            })}
            error={errors?.firstname}
          />
        )}
      />
      <Controller
        name="lastname"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            label={intl.formatMessage({
              defaultMessage: "Last Name",
            })}
            error={errors?.lastname}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            type="email"
            label={intl.formatMessage({
              defaultMessage: "Email",
            })}
            autoComplete="email"
            error={errors?.email}
          />
        )}
      />
      <Controller
        name="data.profession"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            control={control}
            label={intl.formatMessage({
              defaultMessage: "Profession",
            })}
            options={[
              { value: "Owner", label: "Owner" },
              { value: "Chair Renter", label: "Chair Renter" },
              { value: "Stylist", label: "Stylist" },
              { value: "Manager", label: "Manager" },
            ]}
            error={errors?.data?.profession}
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <MaskedInput
              mask="000-000-0000"
              label={intl.formatMessage({
                defaultMessage: "Mobile Number",
              })}
              error={errors?.phone}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e);
                trigger("smsOptIn");
              }}
              value={value}
            />
          );
        }}
      />

      <div style={{ marginBottom: 22 }} id="sms">
        <Controller
          name="smsOptIn"
          control={control}
          render={({ field }) => {
            return (
              <Checkbox
                {...field}
                label={
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <FormattedMessage defaultMessage="I agree to receive SMS based marketing communications at the mobile number above and unlock 2000 bonus points. Standard message & data rates may apply." />
                    {/* <Tooltip placement="top-start">
                    <TooltipTrigger>
                      <DistributorTooltip>
                        <Icon icon="info-circle" />
                      </DistributorTooltip>
                    </TooltipTrigger>
                    <TooltipContent>
                      <FormattedMessage defaultMessage="checkbox - special offers and promotions tooltip text" />
                    </TooltipContent>
                  </Tooltip> */}
                  </span>
                }
                error={errors?.smsOptIn}
              />
            );
          }}
        />
      </div>

      <div style={{ marginBottom: 22 }}>
        <Controller
          name="data.promotions"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              label={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <FormattedMessage defaultMessage="I agree to receive special offers and promotions" />
                  {/* <Tooltip placement="top-start">
                    <TooltipTrigger>
                      <DistributorTooltip>
                        <Icon icon="info-circle" />
                      </DistributorTooltip>
                    </TooltipTrigger>
                    <TooltipContent>
                      <FormattedMessage defaultMessage="checkbox - special offers and promotions tooltip text" />
                    </TooltipContent>
                  </Tooltip> */}
                </span>
              }
              error={errors?.data?.promotions}
            />
          )}
        />
      </div>

      <Controller
        name="updatePassword"
        control={control}
        render={({ field }) => (
          <TextInput
            {...field}
            type="password"
            label={intl.formatMessage({
              defaultMessage: "Update Password (Optional)",
            })}
            autoComplete="new-password"
            error={errors?.updatePassword}
          />
        )}
      />
      <Button
        variant="auth"
        arrow={false}
        disabled={!isEmpty(errors) || isSubmitting || !isValid || !isDirty}
        type="submit"
      >
        <FormattedMessage defaultMessage="save changes" />
      </Button>

      {/* <ButtonLink
        as="button"
        variant="secondary"
        type="button"
        onClick={() => navigate("/dashboard")}
        style={{ marginTop: 24, marginLeft: "auto", marginRight: "auto" }}
      >
        <FormattedMessage defaultMessage="Cancel" />
      </ButtonLink> */}
      <ModalRoot
        isVisible={modalBonusPointsEarned != null}
        onClose={() => setModalBonusPointsEarned(null)}
      >
        <ModalBonusPointsEarned
          {...modalBonusPointsEarned}
          onClose={() => setModalBonusPointsEarned(null)}
        />
      </ModalRoot>
    </Form>
  );
};
