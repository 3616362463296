import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  BackButton,
  List,
  ListItem,
  ImageContainer,
  Quantity,
  Title,
} from "./CheckoutCart.style";

import { Typography } from "../../ui/Typography/Typography";
import { Link } from "../Link/Link";
import { TotalDetails } from "../TotalDetails/TotalDetails";
import { apiDomain, apiSiteId } from "../../config";
import { brands } from "../../config/brands";

export const CheckoutCart = ({ cart }) => {
  const cartItems = cart?.items ?? [];

  return (
    <Root>
      <BackButton as={Link} to="/cart">
        <Typography type="text-small">
          <FormattedMessage defaultMessage="Edit Cart" />
        </Typography>
      </BackButton>
      <List>
        {cartItems.map((item, i) => {
          const product = item?.product;
          const brandConfig = brands.find((b) => b.id === product.brand?._id);
          const imgSrc =
            product?.gallery?.[0]?.media?._id != null
              ? `https://${apiDomain}/${apiSiteId}/asset/${product.gallery[0].media._id}?type=fill&width=604&height=752`
              : `https://picsum.photos/id/${i + 50}/1000/1000`;
          const oldPts = product?.ecommerce?.fullPrice?.amount;
          return (
            <ListItem key={item._id}>
              <ImageContainer>
                <img src={imgSrc} alt={product?.n} />
                <Quantity>{item?.qty}</Quantity>
              </ImageContainer>
              <div>
                <Typography type="brand-secondary">
                  {brandConfig?.label}
                </Typography>
                <Title>
                  <Typography type="h5">{product?.n}</Typography>

                  <Typography type="text-label" color="#8f95a5" block={false}>
                    {item.qty}&nbsp;x&nbsp;
                  </Typography>
                  {oldPts && (
                    <Typography
                      type="points-secondary"
                      block={false}
                      style={{ marginRight: 6, textDecoration: "line-through" }}
                    >
                      {oldPts.toLocaleString("en-US")}{" "}
                      <FormattedMessage defaultMessage="PTS" />
                    </Typography>
                  )}
                  <Typography
                    type="points-secondary"
                    color={oldPts ? "#e1000f" : "#000"}
                    block={false}
                  >
                    {product?.ecommerce?.price?.amount.toLocaleString("en-US")}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </Typography>

                  <Typography
                    type="text-small"
                    color="#8f95a5"
                    style={{ marginTop: 4 }}
                  >
                    <FormattedMessage defaultMessage="Subtotal:" />
                  </Typography>
                  <Typography type="points-secondary">
                    {item?.totals?.pointsSubTotal?.amount.toLocaleString(
                      "en-US"
                    )}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </Typography>
                </Title>
              </div>
            </ListItem>
          );
        })}
      </List>

      <TotalDetails
        subtotalPts={cart?.totals?.pointsTotal?.amount}
        totalPts={cart?.totals?.pointsTotal?.amount}
        shipping={Math.floor(cart?.totals?.shippingTotal?.amount / 100)}
      />
    </Root>
  );
};
