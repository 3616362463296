import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackButton = styled.a`
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 29px;
  color: #8f95a5;
  text-decoration: underline;

  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }

  svg {
    margin-right: 7px;
  }
`;

export const List = styled.ul`
  display: grid;
  row-gap: 24px;
  list-style: none;
`;

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: 48px 1fr;
  column-gap: 24px;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 48px;
  height: 64px;
  background-color: var(--color-bg-3);
  border: solid 1px #dcdfe9;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Quantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 16px;
  height: 16px;
  color: #fff;
  background-color: var(--color-accent);
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
`;

export const Title = styled.div`
  span {
    line-height: 17px;
  }
`;
