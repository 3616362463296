import React from "react";
import PropTypes from "prop-types";

import { Root } from "./Typography.style";

export const Typography = ({
  type = "brand-secondary",
  color = "inherit",
  block = true,
  children,
  ...props
}) => {
  return (
    <Root $type={type} $color={color} $block={block} {...props}>
      {children}
    </Root>
  );
};

Typography.propTypes = {
  type: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "points-primary",
    "points-secondary",
    "text-primary",
    "text-secondary",
    "text-small",
    "text-label",
    "brand-primary",
    "brand-secondary",
    "link",
    "tags",
    // NOTE: old
    "p4",
  ]),
  color: PropTypes.string,
  block: PropTypes.bool,
};
