import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Root, ImageContainer, Body } from "./SearchResultCard.style";

import { Typography } from "../../ui/Typography/Typography";
import { Link } from "../Link/Link";

export const SearchResultCard = ({
  type = "product",
  imageSrc = "",
  itemName = "",
  itemUrl = "",
  brandName = "",
  brandUrl = "",
  pts = null,
  oldPts,
}) => {
  return (
    <Root>
      <ImageContainer as={Link} to={itemUrl} $type={type}>
        <img src={imageSrc} alt={itemName} />
      </ImageContainer>
      <Body>
        <Link to={`${brandUrl}`}>
          <Typography type="brand-secondary" style={{ lineHeight: "21px" }}>
            {brandName}
          </Typography>
        </Link>
        <Link to={itemUrl}>
          <Typography type="h5">{itemName}</Typography>
        </Link>
        {pts && (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            {oldPts && (
              <Typography
                type="points-secondary"
                style={{ marginRight: 6, textDecoration: "line-through" }}
              >
                {oldPts.toLocaleString("en-US")}{" "}
                <FormattedMessage defaultMessage="PTS" />
              </Typography>
            )}
            <Typography
              type="points-secondary"
              color={oldPts ? "#e1000f" : "#000"}
            >
              {pts.toLocaleString("en-US")}{" "}
              <FormattedMessage defaultMessage="PTS" />
            </Typography>
          </div>
        )}
      </Body>
    </Root>
  );
};

SearchResultCard.propTypes = {
  type: PropTypes.oneOf(["product", "article"]),
  pts: PropTypes.number,
};
