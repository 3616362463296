import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import rison from "rison";
import { get } from "lodash";

import { Layout } from "../../components/Layout/Layout.jsx";
import { Header } from "../../components/Header/Header.jsx";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs.jsx";
import { PageTitle } from "../../components/PageTitle/PageTitle.jsx";
import { FiltersMobile } from "../../components/FiltersMobile/FiltersMobile.jsx";
import { FiltersDesktop } from "../../components/FiltersDesktop/FiltersDesktop.jsx";
import { SortByPanel } from "../../components/SortByPanel/SortByPanel.jsx";
import { ExperiencesHeroSlider } from "../../components/Sections/ExperiencesHeroSlider/ExperiencesHeroSlider.jsx";
import { ContentGrid } from "../../components/ContentGrid/ContentGrid.jsx";
import { Pagination } from "../../components/Pagination/Pagination.jsx";
import { ExclusiveBrand } from "../../components/Sections/ExclusiveBrand/ExclusiveBrand.jsx";
import { TrendingRewards } from "../../components/Sections/TrendingRewards/TrendingRewards.jsx";
import { Footer } from "../../components/Footer/Footer.jsx";
import { ContentItem } from "../../components/ContentItem/ContentItem.jsx";
import { Typography } from "../../ui/Typography/Typography.jsx";
import { ActiveFilters } from "../../components/ActiveFilters/ActiveFilters.jsx";

import { apiDomain, apiSiteId } from "../../config/index.js";
import { brands } from "../../config/brands.jsx";
import {
  getTitle,
  contentTypes,
  contentDataPath,
  categoriesConfig,
  sortConfig,
  contentTagsPath,
  GET_CONTENT,
  GET_CONTENT_TAGS,
} from "./contentViewConfig.js";

export const ContentView = ({ type = "news" }) => {
  if (!contentTypes.includes(type)) {
    throw new Error(`Invalid content type: ${type}`);
  }

  const intl = useIntl();
  const locale = intl.locale;
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeFilters, setActiveFilters] = useState({});

  const [
    getContent,
    {
      loading: contentLoading,
      // error: contentError,
      data: contentData,
    },
  ] = useLazyQuery(GET_CONTENT);

  const data = get(contentData, contentDataPath);

  useEffect(() => {
    // console.info({ searchParams, locale, type });
    let filters = searchParams.has("filters")
      ? rison.decode(searchParams.get("filters"))
      : {};

    //hardcode reward type filter
    filters.type = type;

    filters = JSON.stringify(filters);
    let sort = searchParams.get("sort");
    sort = sortConfig.find((s) => s.value === sort);
    sort = sort == null ? null : { key: sort.key, direction: sort.dir };
    const page = searchParams.has("page") ? searchParams.get("page") : 1;
    const limit = 51;

    const { type: filterType, ...newActiveFilters } = JSON.parse(filters);
    setActiveFilters(newActiveFilters);

    getContent({
      variables: {
        sort,
        page,
        filters,
        limit,
        locale,
      },
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-only",
    });
  }, [searchParams, locale, type]);

  const results = contentLoading
    ? "Loading..."
    : `${data?.total ?? 0} ${
        data?.total === 1
          ? intl.formatMessage({ defaultMessage: "article" })
          : intl.formatMessage({ defaultMessage: "articles" })
      }`;

  const title = getTitle({ type, intl });

  return (
    <Layout>
      <Header />

      <FiltersMobile
        sortOptions={sortConfig}
        categoriesConfig={categoriesConfig({ intl })}
        tagsConfig={{
          gql: GET_CONTENT_TAGS,
          dataPath: contentTagsPath,
        }}
        showPoints={false}
        append={
          <Typography type="p4" color="#8f95a5">
            {results}
          </Typography>
        }
      />

      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          { to: null, label: title },
        ]}
        // append={
        //   <Typography type="p4" color="#8f95a5">
        //     {results}
        //   </Typography>
        // }
      />
      <PageTitle title={title} />

      {/* <ExperiencesHeroSlider
        slides={[
          {
            id: "1",
            imageSrc: "https://picsum.photos/id/13/2800/1100",
            brandName: "alterna",
            brandUrl: "/brands/alterna",
            title: "Rosy Summer Hair Color Trend",
            desc: "My client wanted summer hair…brightened, with soft dimension and a pop of fun color,” says Joico Global Brand Ambassador and Artistic Director, Larisa…",
            url: `/${type}/1`,
          },
          {
            id: "2",
            imageSrc: "https://picsum.photos/id/14/2800/1100",
            brandName: "alterna",
            brandUrl: "/brands/alterna",
            title: "Rosy Summer Hair Color Trend",
            desc: "My client wanted summer hair…brightened, with soft dimension and a pop of fun color,” says Joico Global Brand Ambassador and Artistic Director, Larisa…",
            url: `/${type}/1`,
          },
        ]}
      /> */}

      <FiltersDesktop
        categoriesConfig={categoriesConfig({ intl })}
        tagsConfig={{
          gql: GET_CONTENT_TAGS,
          dataPath: contentTagsPath,
        }}
        showPoints={false}
        points={{ min: 0, max: 3500, initialValue: [0, 3500] }}
      />
      <SortByPanel
        sortOptions={sortConfig}
        append={
          <Typography type="p4" color="#8f95a5">
            <span style={{ marginRight: 24 }}>{results}</span>
            <ActiveFilters activeFilters={activeFilters} />
          </Typography>
        }
      />

      <ContentGrid
        totalCount={data?.total ?? 0}
        onResetFilters={() => {
          searchParams.delete("filters");
          setSearchParams(searchParams);
        }}
        loading={contentLoading}
      >
        {(data?.docs || []).map((item, i) => {
          const imgSrc =
            item?.gallery?.[0]?.media?._id != null
              ? `https://${apiDomain}/${apiSiteId}/asset/${item.gallery[0].media._id}?type=fill&width=604&height=752`
              : null;
          const brandConfig = brands.find((b) => b.id === item.brand?._id);
          const brandName = brandConfig?.label ?? item.brand?.n;
          const brandSlug = brandConfig?.slug;

          return (
            <ContentItem
              key={i}
              imageSrc={imgSrc}
              videoSrc={item?.data?.youtubeUrl} //https://youtu.be/mRVH1hpjak0?si=fyK8kjIvXz1ev1mk
              brandName={brandName ?? "Club H"}
              brandUrl={brandSlug ? `/brands/${brandSlug}` : "/experience"}
              title={item?.n}
              desc={item?.desc}
              url={`/${type}/${item.sl}`}
            />
          );
        })}
      </ContentGrid>

      <Pagination
        totalCount={data?.total ?? 0}
        currentPage={data?.page ?? 1}
        pageSize={data?.limit ?? 50}
        onPaginate={(e) => {
          if (e === 1) {
            searchParams.delete("page");
          } else {
            searchParams.set("page", e);
          }
          setSearchParams(searchParams);
        }}
        append={
          <Typography type="p4" color="#8f95a5">
            {results}
          </Typography>
        }
      />

      <ExclusiveBrand />

      <TrendingRewards
        title={intl.formatMessage({
          defaultMessage: "Trending Rewards",
        })}
      />
      <Footer />
    </Layout>
  );
};
