import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  display: inline-flex;
  column-gap: 4px;
`;

const Key = styled.div`
  display: inline-flex;
  font-weight: 700;
  text-transform: capitalize;
`;

import { brands } from "../../config/brands";

export const ActiveFilters = ({ activeFilters }) => {
  return (
    <Root>
      {Object.keys(activeFilters).map((key, idx) => {
        let filterValues;

        if (Array.isArray(activeFilters[key])) {
          switch (key) {
            case "brands":
              filterValues = activeFilters[key]
                .map(
                  (item) =>
                    brands.find((brand) => brand.id === item)?.label ||
                    "Unknown"
                )
                .join(", ");
              break;
            case "points":
              console.info(activeFilters[key]);
              if (activeFilters[key][0] === activeFilters[key][1]) {
                filterValues = `${activeFilters[key][0]}`;
              } else if (
                activeFilters[key][0] == null ||
                activeFilters[key][0] === ""
              ) {
                filterValues = `less than ${activeFilters[key][1]}`;
              } else if (
                activeFilters[key][1] == null ||
                activeFilters[key][1] === ""
              ) {
                filterValues = `greater than ${activeFilters[key][0]}`;
              } else {
                filterValues = `from ${activeFilters[key][0]} to ${activeFilters[key][1]}`;
              }

              break;
            default:
              filterValues = activeFilters[key].join(", ");
          }
        } else {
          filterValues = activeFilters[key];
        }

        return (
          <span key={key}>
            <Key>{key}</Key>: {filterValues}
            {idx !== Object.keys(activeFilters).length - 1 && ", "}
          </span>
        );
      })}
    </Root>
  );
};

ActiveFilters.propTypes = {
  activeFilters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
      PropTypes.string,
    ])
  ),
};
