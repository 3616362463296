import React from "react";
import { useIntl } from "react-intl";
import { Exclusive } from "../Exclusive/Exclusive";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GET_BRAND } from "../../../views/BrandView/brandViewConfig";
import { brands } from "../../../config/brands";
import { apiDomain, apiSiteId } from "../../../config";

export const ExclusiveBrand = ({ brandId }) => {
  const intl = useIntl();
  const locale = intl.locale;
  const brand =
    brandId == null
      ? _.sample(brands.filter((b) => [1, 2].includes(b.pillar)))
      : brands.find((brand) => brand.id === brandId);
  const {
    loading: brandLoading,
    error: brandError,
    data: brandData,
  } = useQuery(GET_BRAND, {
    variables: {
      id: brand?.id,
      locale,
    },
  });

  const data = brandData?.brand?.findById;
  const block2ImgSrc =
    data?.block_2?.image?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.block_2?.image?.media?._id}?type=fill&width=1300&height=1300`
      : "https://picsum.photos/id/213/1000/1000";
  const block2ImgMobileSrc =
    data?.block_2?.imageMobile?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${data?.block_2?.imageMobile?.media?._id}?type=fill&width=2800&height=1300`
      : null;

  return brandLoading ? null : (
    <Exclusive
      image={
        <picture>
          {block2ImgMobileSrc && (
            <source media="(max-width: 1023px)" srcSet={block2ImgMobileSrc} />
          )}
          <img src={block2ImgSrc} alt={brand.label} />
        </picture>
      }
      title={data?.block_2?.title}
      desc={data?.block_2?.desc}
      ctaText={data?.block_2?.ctaLabel}
      ctaUrl={data?.block_2?.ctaUrl}
    />
  );
};
