import styled from "styled-components";

export const Root = styled.div`
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  min-width: 180px;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transform: translateY(-20px);
  transition: 100ms ease;
  z-index: 1000;

  &.--isOpen {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 100ms ease;
  }
`;

export const NavList = styled.div`
  list-style: none;
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  width: 100%;
  min-height: 40px;
  padding: 0 16px;
  color: #000;
  white-space: nowrap;

  &:hover {
    background-color: #f9f9f9;
  }

  & + & {
    border-top: 1px solid #f9f9f9;
  }

  svg {
    font-size: 22px;
  }
`;
