import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 700;
  text-align: center;

  @media (max-width: 1023px) {
    margin-bottom: 8px;
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 16px;
    font-size: 28px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--color-text-2);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 46px;
  }
`;

export const SubtitleDivider = styled.span`
  width: 3px;
  height: 3px;
  margin: 0 8px;
  background-color: #7f8696;
  border-radius: 50%;
`;

export const BackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  font-size: 16px;
`;

export const RedLink = styled.span`
  display: block;
  width: 100%;
  margin-top: 15px;
  padding: 16px;
  color: var(--color-accent);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  text-decoration: underline;
`;

export const UploadContainer = styled.div`
  margin-top: 32px;

  @media (max-width: 1023px) {
    margin-bottom: 48px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 100px;
  }
`;

export const DistributorList = styled.ul`
  margin-top: 18px;
  padding-top: 42px;
  list-style: none;
  border-top: 1px solid #dcdfe9;
`;

export const DistributorItem = styled.li`
  margin-bottom: 8px;
`;

export const DistributorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const DistributorTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

export const DistributorTooltip = styled.div`
  display: flex;
  margin-left: 4px;
  color: #8f95a5;
  font-size: 17px;
`;

export const DistributorRemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 20px;
`;
