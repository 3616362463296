import React from "react";

import { Root, ImageLink } from "./InstagramGrid.style";
import Post1ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-1.jpg";
import Post2ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-2.jpg";
import Post3ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-3.jpg";
import Post4ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-4.jpg";
import Post5ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-5.jpg";
import Post6ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-6.jpg";
import Post7ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-7.jpg";
import Post8ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-8.jpg";
import Post9ImageSrc from "../../../../assets/images/community-instagram/CLUBH-1000x1000-Carousel-9.jpg";

export const InstagramGrid = () => {
  return (
    <Root>
      {[
        Post1ImageSrc,
        Post2ImageSrc,
        Post3ImageSrc,
        Post4ImageSrc,
        Post5ImageSrc,
        Post6ImageSrc,
        Post7ImageSrc,
        Post8ImageSrc,
        Post9ImageSrc,
      ].map((itm, i) => (
        <ImageLink
          target="_blank"
          href="https://www.instagram.com/clubhrewards"
          key={i}
        >
          <img src={itm} alt="" />
        </ImageLink>
      ))}
    </Root>
  );
};
