import React from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { NotFoundView } from "../../views/NotFoundView/NotFoundView";
import { useParams } from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { ContentDetail } from "../../components/ContentDetail/ContentDetail";
import { ProposalSlider } from "../../components/Sections/ProposalSlider/ProposalSlider";
import { TrendingRewards } from "../../components/Sections/TrendingRewards/TrendingRewards";
import { Footer } from "../../components/Footer/Footer";
import {
  GET_CONTENT_ITEM,
  categoriesConfig,
  getTitle,
  contentTypes,
} from "../ContentView/contentViewConfig";
import { apiDomain, apiSiteId } from "../../config";
import { brands } from "../../config/brands";

export const ContentDetailView = ({ type }) => {
  if (!contentTypes.includes(type)) {
    throw new Error(`Invalid content type: ${type}`);
  }
  const intl = useIntl();
  const locale = intl.locale;
  const { slug } = useParams();

  const {
    loading: contentItemLoading,
    error: contentItemError,
    data: contentItemData,
  } = useQuery(GET_CONTENT_ITEM, {
    variables: {
      slug,
      locale,
    },
  });

  const content = contentItemData?.content?.findBySlug;
  if (!contentItemLoading && content?._id == null) {
    return <NotFoundView />;
  }

  const brandConfig = brands.find((b) => b.id === content?.brand?._id);
  const primaryImgSrc =
    content?.gallery?.[0]?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${content.gallery[0].media._id}?type=fill&width=1862&height=938`
      : null;

  const primaryImgMobileSrc =
    content?.galleryMobile?.[0]?.media?._id != null
      ? `https://${apiDomain}/${apiSiteId}/asset/${content.galleryMobile[0].media._id}?type=fill&width=1862&height=938`
      : null;

  const images =
    content?.gallery?.length > 0
      ? content?.gallery.map((itm) => ({
          id: itm?.media?._id,
          image: `https://${apiDomain}/${apiSiteId}/asset/${itm.media._id}?type=fill&width=604&height=752`,
        }))
      : [
          {
            id: "1",
            image: "https://picsum.photos/id/22/1000/1000",
          },
          {
            id: "2",
            image: "https://picsum.photos/id/52/1000/1000",
          },
          {
            id: "3",
            image: "https://picsum.photos/id/85/1000/1000",
          },
        ];

  const contentTypeTitle = getTitle({ type, intl });

  return (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          ...(type === "page"
            ? []
            : [{ to: `/experience`, label: contentTypeTitle }]),
          { to: null, label: content?.n || "" },
        ]}
      />

      <ContentDetail
        images={images}
        brand={brandConfig ? brandConfig?.label : "Club H"}
        brandUrl={brandConfig ? `/brands/${brandConfig?.slug}` : "/experience"}
        title={content?.n}
        desc={content?.desc}
        publishedAt={content?.startDate || content?.dc}
        bannerSrc={primaryImgSrc}
        bannerMobileSrc={primaryImgMobileSrc}
        videoSrc={content?.data?.youtubeUrl}
        onShare={() => console.info("share")}
        loading={contentItemLoading}
      />

      <ProposalSlider
        variant="pattern-dark" //"black"
        title={intl.formatMessage({
          defaultMessage: "Other stories you might like…",
        })}
        ctaText={intl.formatMessage({
          defaultMessage: "All articles",
        })}
        ctaUrl="/experience"
        filters='{ "type": ["news"]}'
      />

      <TrendingRewards
        title={intl.formatMessage({
          defaultMessage: "Trending Rewards",
        })}
      />
      <Footer />
    </Layout>
  );
};
