import styled from "styled-components";

export const Root = styled.label`
  display: block;
  position: relative;
  margin-bottom: 7px;
  padding-left: 20px;
  user-select: none;
  cursor: pointer;
`;

export const Label = styled.span`
  display: block;
  align-items: center;
  min-height: 18px;
  color: var(--color-text-2);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.12px;

  a,
  button {
    color: var(--color-accent);
    text-decoration: underline;
  }
`;

export const Error = styled.span`
  display: block;
  color: #cf2b23;
  font-size: 12px;
`;

export const CheckmarkContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
`;

export const Checkmark = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;
  background-color: ${(p) => (p.$isChecked ? "#9095a5" : "transparent")};
  font-size: 12px;
  border: solid 1px #9095a4;
  border-radius: 2px;
`;

export const Input = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
  cursor: pointer;
`;
