import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: ${(p) => p.$topOffset}px;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 68px;
  background-color: #fff;
  border-top: 1px solid #dcdfe9;
  overflow: hidden;
  z-index: 1000;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ScrollContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  overflow-y: auto;
`;

export const NavList = styled.ul`
  width: 100%;
  margin-bottom: 44px;
  padding-left: 16px;
  list-style: none;
`;

export const NavItem = styled.li`
  a.--isActive {
    color: var(--color-accent);
  }

  & + & {
    margin-top: 16px;
  }
`;

export const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;

  &.--social {
    color: #8f95a5;
  }
`;

export const BottomNav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  padding: 24px 0;
  margin-top: auto;
  list-style: none;
`;

export const BottomNavItem = styled.li`
  padding: 0 8px;
  color: #8f95a5;
  text-align: center;
  white-space: nowrap;
`;
