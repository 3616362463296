import styled, { css } from "styled-components";

export const Root = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  height: 28px;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  transition: all 150ms;

  &:disabled {
    color: var(--color-border-1);
    cursor: default;
  }

  ${(p) =>
    p.$variant === "primary" &&
    css`
      color: var(--color-accent);
    `}

  ${(p) =>
    p.$variant === "secondary" &&
    css`
      color: var(--color-text-2);

      &:hover {
        color: #000;
      }
    `}
`;
