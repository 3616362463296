import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  ImageContainer,
  Qty,
  Body,
  Subtotal,
} from "./DashboardProductCard.style";

import { Typography } from "../../ui/Typography/Typography";
import { Link } from "../Link/Link";

export const DashboardProductCard = ({
  isEnabled = true,
  imageSrc = "",
  brandName = "",
  brandUrl = "/",
  productName = "",
  productUrl = "/",
  pts = 0,
  oldPts,
  qty = 1,
  ...props
}) => {
  return (
    <Root {...props}>
      <ImageContainer>
        <img src={imageSrc} alt={productName} />
        <Qty>{isEnabled ? qty : <s>{qty}</s>}</Qty>
      </ImageContainer>

      <Body>
        <div>
          <Typography
            as={Link}
            to={brandUrl}
            target="_blank"
            type="brand-secondary"
          >
            {isEnabled ? brandName : <s>{brandName}</s>}
          </Typography>
          <div>
            <Typography type="h5">
              {isEnabled ? productName : <s>{productName}</s>}
            </Typography>
            <div>
              <Typography
                type="text-label"
                color="#8f95a5"
                block={false}
                className="--hideOnDesktop"
              >
                {isEnabled ? qty : <s>{qty}</s>}&nbsp;x&nbsp;
              </Typography>
              {oldPts && (
                <Typography
                  type="points-secondary"
                  block={false}
                  style={{ marginRight: 6, textDecoration: "line-through" }}
                >
                  {oldPts.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </Typography>
              )}
              <Typography
                type="points-secondary"
                color={oldPts ? "#e1000f" : "#000"}
                block={false}
              >
                {isEnabled ? (
                  <>
                    {(pts / qty).toLocaleString("en-US")}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </>
                ) : (
                  <s>
                    {(pts / qty).toLocaleString("en-US")}{" "}
                    <FormattedMessage defaultMessage="PTS" />
                  </s>
                )}
              </Typography>
            </div>
          </div>
          <Subtotal>
            <Typography type="text-label" color="#8f95a5">
              <FormattedMessage defaultMessage="Subtotal:" />
            </Typography>
            <Typography type="points-secondary">
              {isEnabled ? (
                <>
                  {pts.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </>
              ) : (
                <s>
                  {pts.toLocaleString("en-US")}{" "}
                  <FormattedMessage defaultMessage="PTS" />
                </s>
              )}
            </Typography>
          </Subtotal>
        </div>
      </Body>
    </Root>
  );
};
